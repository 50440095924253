import {
    CLEAR_UPDATE_TRANSACTION_LIMIT_ERROR,
    CLEAR_UPDATE_TRANSACTION_LIMIT,
    UPDATE_TRANSACTION_LIMIT_LOADING,
    UPDATE_TRANSACTION_LIMIT_ERROR,
    UPDATE_TRANSACTION_LIMIT} 
from '../actions/types';

const initialState = {
    updateTransactionLimit: null,
    updateTransactionLimitLoading: false,
    updateTransactionLimitError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_TRANSACTION_LIMIT_ERROR:
            return {
                ...state,
                updateTransactionLimitLoading: false,
                updateTransactionLimitError: action.payload
            }
        case CLEAR_UPDATE_TRANSACTION_LIMIT:
            return {
                ...state,
                updateTransactionLimit: null,
                updateTransactionLimitLoading: false,
                updateTransactionLimitError: null
            }
        case UPDATE_TRANSACTION_LIMIT_LOADING:
            return {
                ...state,
                updateTransactionLimitLoading: true,
                updateTransactionLimitError: null
            }
        case UPDATE_TRANSACTION_LIMIT_ERROR:
            return {
                ...state,
                updateTransactionLimitLoading: false,
                updateTransactionLimitError: action.payload
            }
        case UPDATE_TRANSACTION_LIMIT:
            return {
                ...state,
                updateTransactionLimit: action.payload,
                updateTransactionLimitLoading: false,
                updateTransactionLimitError: null
            }
        default: return state
    }
}