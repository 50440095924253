import {
    CLEAR_CURRENT_USER_PROFILE_ERROR,
    CLEAR_CURRENT_USER_PROFILE,
    CURRENT_USER_PROFILE_LOADING,
    CURRENT_USER_PROFILE_ERROR,
    CURRENT_USER_PROFILE
} from '../actions/types';

const initialState = {
    current_user: null,
    current_user_loading: false,
    current_user_error: null
}

const currentUserProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case CURRENT_USER_PROFILE:
            return {
                ...state,
                current_user: action.payload,
                current_user_loading: false,
                current_user_error: null
            }
        case CURRENT_USER_PROFILE_ERROR:
            return {
                ...state,
                current_user_loading: false,
                current_user_error: action.payload
            }
        case CURRENT_USER_PROFILE_LOADING:
            return {
                ...state,
                current_user_loading: true,
                current_user_error: null
            }
        case CLEAR_CURRENT_USER_PROFILE:
            return {
                ...state,
                current_user: null,
                current_user_loading: false,
                current_user_error: null
            }
        case CLEAR_CURRENT_USER_PROFILE_ERROR:
            return {
                ...state,
                current_user_loading: false,
                current_user_error: null
            }
        default: return state
    }
}

export default currentUserProfileReducer;