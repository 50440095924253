import {
    CLEAR_TELLER_FLOAT_ERROR,
    TELLER_FLOAT_LOADING,
    CLEAR_TELLER_FLOAT,
    TELLER_FLOAT,
    TELLER_FLOAT_ERROR
} from '../actions/types';

const initialState = {
    teller_float: null,
    teller_float_error: null,
    teller_float_loading: false
}

const tellerFloatReducer = (state = initialState, action) => {
    switch (action.type) {
        case TELLER_FLOAT_ERROR:
            return {
                ...state,
                teller_float_error: action.payload,
                teller_float_loading: false
            }
        case TELLER_FLOAT:
            return {
                ...state,
                teller_float: action.payload,
                teller_float_error: null,
                teller_float_loading: false
            }
        case CLEAR_TELLER_FLOAT:
            return {
                ...state,
                teller_float: null,
                teller_float_error: null,
                teller_float_loading: false
            }
        case TELLER_FLOAT_LOADING:
            return {
                ...state,
                teller_float_error: null,
                teller_float_loading: true
            }
        case CLEAR_TELLER_FLOAT_ERROR:
            return {
                ...state,
                teller_float_error: null,
                teller_float_loading: false
            }
        default: return state
    }
}

export default tellerFloatReducer;