import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions';
import Pagination from "react-js-pagination";
import { BsFillPeopleFill } from "react-icons/bs";
import PageLoader from '../../utils/PageLoader';
import { Link, useParams } from 'react-router-dom';

function BranchUserTable(props) {
    const { handleEdit, setStore } = props;
    const dispatch = useDispatch();
    const { branch_users, branch_users_loading } = useSelector(getUser => getUser.partnerBranchUsersReducer);
    const { new_user } = useSelector(addUser => addUser.addUserReducer);
    const { updateUser } = useSelector(editUser => editUser.updateUserReducer);
    const { activateUsers } = useSelector(activeUsers => activeUsers.activateUsersReducer);
    const { partner } = useSelector(getPartners => getPartners.getPartnersReducer);
    const { partner_branches } = useSelector(getBranches => getBranches.getPartnerBranchesReducer);
    const { deactivateUsers } = useSelector(deactiveUsers => deactiveUsers.deactivateUsersReducer);
    const itemsCountPerPage = 30;

    const [page, setPage] = React.useState(1);
    const { partnerId, branchId } = useParams();

    let result = partner.data.find(partner => partner.id === partnerId);
    let branch = partner_branches.data.find(branch => branch.id === branchId);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    React.useEffect(() => {
        dispatch(actions.getPartnerBranchUsers({
            page,
            perPage: itemsCountPerPage,
            branchId
        }));
        setStore(null);

    }, [activateUsers, deactivateUsers, new_user, page, updateUser]);

    const handleChangeStatus = (user) => {
        user.status === 'ACTIVE' ? dispatch(actions.deactivateUsers(user)) : dispatch(actions.activateUsers(user));
    }

    let content = (branch_users_loading) ? <PageLoader /> : (branch_users) && <div>
        <nav className="flex py-3 px-5 text-gray-700 bg-gray-50 rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <Link to={`/partners`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    <BsFillPeopleFill className='w-4 h-4 mr-2' />
                    {result.OrganizationName}
                </Link>
                <Link to={`/partners/${partnerId}`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                    {result.OrganizationName} - Branches</Link>
                <Link to='#' className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                    {branch.branchName} Branch - Users
                </Link>
            </ol>
        </nav>

        <div className="overflow-y-auto overflow-x-hidden border-l border-t border-r sm:rounded-lg  mt-1">
            <table className="w-full text-sm text-left text-gray-500  dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Full Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Phone Number
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Email
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Branch
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Role
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {(branch_users.data.length > 0) ? branch_users.data.map(user => (
                        <tr key={user.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                {user.firstname} {user.middlename} {user.lastname}
                            </th>
                            <td className="px-6 py-4">
                                {user.mobile}
                            </td>
                            <td className="px-4 py-4">
                                {user.email}
                            </td>
                            <td className="px-6 py-4">
                                {user.branch.address}, {user.branch.city}
                            </td>
                            <td className="px-6 py-4">
                                {user.role.charAt(0).toUpperCase() + user.role.slice(1).toLowerCase()}
                            </td>
                            <td className="px-6 py-4 text-center">
                                {user.status === 'ACTIVE' ?
                                    <button onClick={() => handleChangeStatus(user)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">De-activate</button> :
                                    <button onClick={() => handleChangeStatus(user)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Activate</button>}
                            </td>
                            <td className="px-6 py-4 text-right">
                                <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => handleEdit(user)}>Edit</button>               </td>
                        </tr>
                    )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td colSpan="10" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">No data found</td>
                    </tr>}
                </tbody>
            </table>
        </div>
        {(branch_users && !branch_users_loading && branch_users.data.length > 0) &&
            <div className="py-4 flex justify-end">
                <Pagination
                    activePage={page}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={branch_users.totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                />
            </div>}
    </div>

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    )
}

export default BranchUserTable