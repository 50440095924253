import {
    CLEAR_GET_REMIT_SOURCE_ERROR,
    CLEAR_GET_REMIT_SOURCE,
    GET_REMIT_SOURCES_LOADING,
    GET_REMIT_SOURCES_ERROR,
    GET_REMIT_SOURCES
} from '../actions/types';

const initialState = {
    remit_src: null,
    remit_src_loading: false,
    remit_src_error: null,
}

const getRemitSourceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REMIT_SOURCES:
            return {
                ...state,
                remit_src: action.payload,
                remit_src_loading: false,
                remit_src_error: null,
            }
        case GET_REMIT_SOURCES_ERROR:
            return {
                ...state,
                remit_src_loading: false,
                remit_src_error: action.payload,
            }
        case GET_REMIT_SOURCES_LOADING:
            return {
                ...state,
                remit_src_loading: true,
                remit_src_error: null,
            }
        case CLEAR_GET_REMIT_SOURCE:
            return {
                ...state,
                remit_src: null,
                remit_src_loading: false,
                remit_src_error: null,
            }
        case CLEAR_GET_REMIT_SOURCE_ERROR:
            return {
                ...state,
                remit_src_loading: false,
                remit_src_error: null,
            }
        default: return state
    }
}

export default getRemitSourceReducer

