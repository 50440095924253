import React from 'react'
import Teller from '../components/teller/Teller'
import TellerReports from '../components/teller/TellerReports'

function Home() {
  return (
    <div className="grid grid-cols-12 gap-4">
        <div className="col-span-7 mx-8">
            <Teller />
        </div>
        <div className="col-span-5 border-l min-h-screen p-8">
            <TellerReports />
        </div>
    </div>
  )
}

export default Home