import {
    CLEAR_UPDATE_REFERENCE_CONFIG_ERROR,
    CLEAR_UPDATE_REFERENCE_CONFIG,
    UPDATE_REFERENCE_CONFIG_LOADING,
    UPDATE_REFERENCE_CONFIG_ERROR,
    UPDATE_REFERENCE_CONFIG
} from '../actions/types';

const initialState = {
    update_reference_config: null,
    update_reference_config_loading: false,
    update_reference_config_error: null
}

const updateCurrentUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_REFERENCE_CONFIG:
            return {
                ...state,
                update_reference_config: action.payload,
                update_reference_config_loading: false,
                update_reference_config_error: null
            }
        case UPDATE_REFERENCE_CONFIG_ERROR:
            return {
                ...state,
                update_reference_config_loading: false,
                update_reference_config_error: action.payload
            }
        case UPDATE_REFERENCE_CONFIG_LOADING:
            return {
                ...state,
                update_reference_config_loading: true,
                update_reference_config_error: null
            }
        case CLEAR_UPDATE_REFERENCE_CONFIG:
            return {
                ...state,
                update_reference_config: null,
                update_reference_config_loading: false,
                update_reference_config_error: null
            }
        case CLEAR_UPDATE_REFERENCE_CONFIG_ERROR:
            return {
                ...state,
                update_reference_config_loading: false,
                update_reference_config_error: null
            }
        default: return state
    }
}

export default updateCurrentUserReducer;