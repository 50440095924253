import React from 'react';
import * as actions from '../../store/actions';
import FormikController from '../Formik/FormikController';
import * as Yup from "yup";
import loader from '../../assets/loaders/382.gif';
import { Formik, Form } from "formik";
import PageLoader from '../utils/PageLoader';
import { useSelector, useDispatch } from "react-redux";

function ReferenceConfig() {
    const {get_reference_config,get_reference_config_loading} = useSelector(getReference => getReference.getReferenceConfigReducer);
    const { update_reference_config, update_reference_config_loading,update_reference_config_error} = useSelector(updateReference => updateReference.updateReferenceConfigReducer);
    const dispatch = useDispatch();

    const [value, setValue] = React.useState('');
  
    let refType = [
        {
            key:'Numeric',
            value:'NUMERIC'
        },
        {
            key:'Alpha-Numeric',
            value:'ALPHA-NUMERIC'
        }
    ];

    React.useEffect(() => {
        dispatch(actions.getReferenceConfig({
        }));
    }, [ update_reference_config]);

    const initialValues = {
        type: value,
        prefix: ''
    }

    const validationSchema = Yup.object({
        type: Yup.string().required('This field is required'),
        prefix: Yup.string().required('This field is required'),
    })

    const validation = Yup.object({
        type: Yup.string().required('This field is required'),
        prefix: Yup.string()
    })

    let content;

    const handleSubmit = (data) => {
        data.id = get_reference_config ?  get_reference_config.data.id : null;
        dispatch(actions.updateReferenceConfig(data));
        setValue('');
    }
    
    content = get_reference_config_loading ? <PageLoader /> : (get_reference_config) && <div>
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <tbody>
            {get_reference_config ?
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                   {get_reference_config.data.RefType}
                </th>
                <td className="px-6 py-4">
                    {get_reference_config.data.prefix}
                </td>
            </tr>
        :<td colSpan="3" className="text-center px-6 pt-4 pb-8 font-medium text-gray-900 dark:text-white whitespace-nowrap">No data found</td>}
        </tbody>
    </table>

    </div>

    return (
        <div style={{height: '520px'}} className="rounded-md overflow-y-auto max-h-fit bg-white shadow hover:shadow-md ease-in-out duration-700">
            <div className="p-4 border-b border-gray-300">
                <h1 className="font-medium text-md mb-1">Update Reference Code</h1>
                {update_reference_config_error &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                    {update_reference_config_error}
                </div>
                <button onClick={() => dispatch(actions.clearUpdateReferenceConfigError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>}
                <Formik
                    initialValues={initialValues}
                    validationSchema={(value === 'NUMERIC') ? validation : validationSchema}
                    enableReinitialize
                    onSubmit={(values, {resetForm}) => {
                        handleSubmit(values)
                        resetForm();
                    }}
                >
                    {({setFieldValue, values}) => {
                        return <Form>
                        <div className={`flex items-start}`}>
                            <div className="mr-2">
                                <FormikController
                                    control='select'
                                    type='text'
                                    onChange={(e) => {
                                        setFieldValue('type', setValue(e.target.value));
                                    }}
                                    placeholder='Type'
                                    name='type'
                                    options={refType}
                                />
                            </div>
                            <div className={`mr-2 ${value === 'NUMERIC' ? 'hidden' : 'block'}`}>
                                <FormikController
                                    control='input'
                                    type='text'
                                    placeholder='Prefix'
                                    name='prefix'
                                />
                            </div>
                            <div>
                                {(get_reference_config_loading || update_reference_config_loading) ?  <button className="bg-gray-500 rounded px-6 py-1"><img className="h-8" src={loader} alt="loader" /></button> :
                                <button type="submit" className="px-6 py-2 rounded text-white ease-in-out duration-700 bg-green-600 hover:bg-green-700 ">Update</button>}
                            </div>
                        </div>
                    </Form>
                    }}
                </Formik>
            </div>
            <div className="p-4">
                {content}
            </div>
        </div>
    )
}

export default ReferenceConfig