import {CLEAR_ADD_PARTNER_BRANCHES, CLEAR_ADD_PARTNER_BRANCHES_ERROR, ADD_PARTNER_BRANCHES_LOADING, ADD_PARTNER_BRANCHES_ERROR, ADD_PARTNER_BRANCHES} from '../actions/types';

const initialState = {
    new_partner_branch: null,
    new_partner_branch_error: null,
    new_partner_branch_loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ADD_PARTNER_BRANCHES:
            return {
                ...state,
                new_partner_branch: null,
                new_partner_branch_error: null,
                new_partner_branch_loading: false
            }
        case CLEAR_ADD_PARTNER_BRANCHES_ERROR:
            return {
                ...state,
                new_partner_branch_error: null,
                new_partner_branch_loading: false
            }
        case ADD_PARTNER_BRANCHES_LOADING:
            return {
                ...state,
                new_partner_branch_error: null,
                new_partner_branch_loading: true
            }
        case ADD_PARTNER_BRANCHES_ERROR:
            return {
                ...state,
                new_partner_branch_error: action.payload,
                new_partner_branch_loading: false
            }
        case ADD_PARTNER_BRANCHES:
            return {
                ...state,
                new_partner_branch: action.payload,
                new_partner_branch_error: null,
                new_partner_branch_loading: false
            }
        default: return state
    }
}