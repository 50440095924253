import React from 'react';
import * as actions from '../../store/actions';
import Pagination from "react-js-pagination";
import { useSelector, useDispatch } from "react-redux";
import PageLoader from '../utils/PageLoader';

function ViewFloat(props) {
  const dispatch = useDispatch();
  const {handleEdit,setStore  } = props
  const floatState = useSelector(floatState => floatState.getTellerFloatReducer);
  const float = useSelector(floatState => floatState.assignFloatReducer);
  const {update_float} = useSelector(updateFloat => updateFloat.updateFloatReducer); 
  
  const [page, setPage] = React.useState(1);
  const perPage = 25;
  
  const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

  React.useEffect(() => {
    dispatch(actions.getFloat({page, perPage}))
    setStore(null);
  }, [float,update_float,page]);



  return (
    <div className="overflow-x-auto border border-gray-300 sm:rounded-lg">
      {floatState.teller_float_loading ? <PageLoader/> : floatState.teller_float && <div>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                  <th scope="col" className="px-6 py-3">
                      Teller Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                      Supervisor's Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                      Currency
                  </th>
                  <th scope="col" className="px-6 py-3">
                      Start Balance
                  </th>
                  <th scope="col" className="px-6 py-3">
                      End Balance
                  </th>
                  <th scope="col" className="px-6 py-3">
                      Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                      Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                      <span className="sr-only">Edit</span>
                  </th>
              </tr>
          </thead>
          <tbody>
            {floatState.teller_float.data.length > 0 ? floatState.teller_float.data.map(el => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={el.id}>
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {el.teller.firstname + " " + el.teller.middlename + " " + el.teller.lastname}
                  </th>
                  <td className="px-6 py-4">
                      {el.supervisor.firstname + " " + el.supervisor.middlename + " " + el.supervisor.lastname}
                  </td>
                  <td className="px-6 py-4">
                      {el.currency.currencySymbol}
                  </td>
                  <td className="px-6 py-4">
                      {el.startingBalance}
                  </td>
                  <td className="px-6 py-4">
                      {el.endingBalance ? el.endingBalance : 'Pending...'}
                  </td>
                  <td className="px-6 py-4">
                      {el.floatDate}
                  </td>
                  <td className="px-6 py-4">
                      {el.status}
                  </td>
                <td className={` px-6 py-4 text-right`}>
                      <button className={`${(el.status === 'CONFIRMED' ) ? "hidden" : "block"} font-medium text-blue-600 dark:text-blue-500 hover:underline`} onClick={()=>handleEdit(el)}>Edit</button>
                  </td>
              </tr>
            )) : (floatState.teller_float.data.length === 0) && <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th colSpan="8" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">No data found</th>
            </tr>}
          </tbody>
      </table>
      {(floatState.teller_float && !floatState.teller_float_loading && floatState.teller_float.data.length > 0) &&
    <div className="p-4 flex justify-end">
        <Pagination
            activePage={page}
            itemsCountPerPage={perPage}
            totalItemsCount={floatState.teller_float.totalItemsCount}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
        />
    </div>}    
   </div>
}
</div>
  )
}

export default ViewFloat