import React from 'react';
import * as Yup from "yup";
import { Formik, Form } from "formik";
import * as actions from '../../store/actions';
import FormikController from '../Formik/FormikController';
import loader from '../../assets/loaders/382.gif';
import Countries from '../utils/Countries.json';
import { useDispatch, useSelector} from 'react-redux';


function AddPartner(props) {
    const  dispatch = useDispatch();
    const {store} = props
    const {new_partner_error, new_partner_loading} = useSelector(addPartner => addPartner.addPartnersReducer);
    const { updatePartnerLoading, updatePartnerError} = useSelector(editPartner => editPartner.updatePartnersReducer);

    let countries = Countries.map(cxt => {
        let obj = {
            key: cxt.name,
            value: cxt.name
        }

        return obj;
    })

    const initialState = {
        OrganizationName: store ? store.OrganizationName : "",
        addressLine1: store ? store.addressLine1 :"",
        addressLine2: store ? store.addressLine2 :"",
        city: store ? store.city :"",
        postCode: store ? store.postCode : "",
        branchName: store ? store.branchName :"",
        country: store ? store.country :"",
        contactFirstname:store ? store.contactFirstname :"",
        contactMiddlename: store ? store.contactMiddlename : "",
        contactLastname: store ? store.contactLastname : "",
        email: store ? store.email :"",
        phoneNumber: store ? store.phoneNumber :"",
        inBoundCommission: store ? store.inBoundCommission :"",
        outBoundCommission: store ? store.outBoundCommission :"",
    }

    const validationSchema = Yup.object({
        OrganizationName: Yup.string().required('This field is required'),
        addressLine1: Yup.string().required('This field is required'),
        addressLine2: Yup.string(),
        branchName: Yup.string().required('This field is required'),
        city: Yup.string().required('This field is required'),
        // postCode: Yup.string().required('This field is required'),
        country: Yup.string().required('This field is required'),
        contactFirstname: Yup.string().required('This field is required'),
        // contactMiddlename: Yup.string().required('This field is required'),
        contactLastname: Yup.string().required('This field is required'),
        email: Yup.string().required('This field is required'),
        phoneNumber: Yup.string().required('This field is required'),
        inBoundCommission: Yup.string().required('This field is required'),
        outBoundCommission: Yup.string().required('This field is required'),
    })

    const handleSubmit = (values) => {
     values.id = store ? store.id : null;
     store ? dispatch(actions.updatePartners(values)) : dispatch(actions.addPartners(values));
    }

    return (
        <div>
            <h2 className="font-medium text-gray-800 mb-3">{store ? 'Update Partner' : 'Add Partner'}</h2>
            {new_partner_error &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                    {new_partner_error}
                </div>
                <button onClick={()=>dispatch(actions.clearAddPartnersError())}type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
            </div>}
            {updatePartnerError &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                    {updatePartnerError}
                </div>
                <button onClick={()=>dispatch(actions.clearUpdatePartnersError())}type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
            </div>}
            <Formik
                initialValues={initialState}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, {resetForm}) => {
                    handleSubmit(values);
                    resetForm();
                }}
            >
                {() => (
                    <Form>
                        <div className="grid grid-cols-2 gap-3">
                            <div className="mb-1">
                                <FormikController
                                    control='input'
                                    type='text'
                                    label='Organisation Name'
                                    name='OrganizationName'
                                />
                            </div>
                            <div className="mb-1">
                                <FormikController
                                    control='input'
                                    type='text'
                                    label='HQ Branch Name'
                                    name='branchName'
                                />
                            </div>
                            <div className="mb-1">
                                <FormikController
                                    control='input'
                                    type='text'
                                    label='Address Line 1'
                                    name='addressLine1'
                                />
                            </div>
                            <div className="mb-1">
                                <FormikController
                                    control='input'
                                    type='text'
                                    placeholder="Optional"
                                    label='Address Line 2'
                                    name='addressLine2'
                                />
                            </div>
                            {/* <div className="mb-1">
                                <FormikController
                                    control='input'
                                    type='text'
                                    label='Post Code'
                                    name='postCode'
                                />
                            </div> */}
                            <div className="mb-1 col-span-2">
                                <FormikController
                                    control='input'
                                    type='text'
                                    label='City'
                                    name='city'
                                />
                            </div>
                            <div className="mb-1 col-span-2">
                                <FormikController
                                    control='select'
                                    options={countries}
                                    type='text'
                                    label='Country'
                                    name='country'
                                />
                            </div>
                            <div className="mb-1">
                                <FormikController
                                    control='input'
                                    type='text'
                                    label='Phone Number'
                                    placeholder='e.g +263773123432'
                                    name='phoneNumber'
                                />
                            </div>
                            <div className="mb-1">
                                <FormikController
                                    control='input'
                                    type='text'
                                    label='First Name'
                                    placeholder='Contact Person'
                                    name='contactFirstname'
                                />
                            </div>
                            <div className="mb-1">
                                <FormikController
                                    control='input'
                                    type='text'
                                    label='Middle Name'
                                    placeholder='Contact Person'
                                    name='contactMiddlename'
                                />
                            </div>
                            <div className="mb-1">
                                <FormikController
                                    control='input'
                                    type='text'
                                    label='Last Name'
                                    placeholder='Contact Person'
                                    name='contactLastname'
                                />
                            </div>
                            <div className="mb-1 col-span-2">
                                <FormikController
                                    control='input'
                                    type='text'
                                    label='Email'
                                    placeholder='Contact Person'
                                    name='email'
                                />
                            </div>
                            <div className="mb-1">
                                <FormikController
                                    control='input'
                                    type='text'
                                    label='Inbound'
                                    placeholder="Commission (%)"
                                    name='inBoundCommission'
                                />
                            </div>
                            <div className="mb-1">
                                <FormikController
                                    control='input'
                                    type='text'
                                    label='Outbound'
                                    placeholder="Commission (%)"
                                    name='outBoundCommission'
                                />
                            </div>
                        </div>
                        <div className="mb-3 pt-2 text-right">
                        {new_partner_loading || updatePartnerLoading ? <button className="bg-gray-500 rounded px-8 py-1"><img className="h-8" src={loader} alt="loader" /></button> : 
                        <button type='submit' className="px-6 py-2 rounded text-white ease-in-out duration-700 bg-green-600 hover:bg-green-700 ">{store ? 'Update' :'Submit'}</button>}
                        </div>
                    </Form>
                )
                }
            </Formik>
        </div>
    )
}

export default AddPartner