import React from "react"
import { Field, ErrorMessage } from "formik"

function TextArea(props) {
  const { label, name, ...rest } = props
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Field as="textarea" id={name} name={name} {...rest} />
      <div className="text-red-500 text-sm"><ErrorMessage name={name} /> </div>
    </div>
  )
}
export default TextArea