import {
    CLEAR_UPDATE_BRANCH_ERROR,
    CLEAR_UPDATE_BRANCH,
    UPDATE_BRANCH_LOADING,
    UPDATE_BRANCH_ERROR,
    UPDATE_BRANCH} 
from '../actions/types';

const initialState = {
    updateBranch: null,
    updateBranchLoading: false,
    updateBranchError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_BRANCH_ERROR:
            return {
                ...state,
                updateBranchLoading: false,
                updateBranchError: action.payload
            }
        case CLEAR_UPDATE_BRANCH:
            return {
                ...state,
                updateBranch: null,
                updateBranchLoading: false,
                updateBranchError: null
            }
        case UPDATE_BRANCH_LOADING:
            return {
                ...state,
                updateBranchLoading: true,
                updateBranchError: null
            }
        case UPDATE_BRANCH_ERROR:
            return {
                ...state,
                updateBranchLoading: false,
                updateBranchError: action.payload
            }
        case UPDATE_BRANCH:
            return {
                ...state,
                updateBranch: action.payload,
                updateBranchLoading: false,
                updateBranchError: null
            }
        default: return state
    }
}