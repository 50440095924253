import {
    CLEAR_DEACTIVATE_TRANSACTION_FEE_ERROR,
    CLEAR_DEACTIVATE_TRANSACTION_FEE,
    DEACTIVATE_TRANSACTION_FEE_LOADING,
    DEACTIVATE_TRANSACTION_FEE_ERROR,
    DEACTIVATE_TRANSACTION_FEE
} 
from '../actions/types';

const initialState = {
    deactivateTransactionFee: null,
    deactivateTransactionFeeLoading: false,
    deactivateTransactionFeeError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_DEACTIVATE_TRANSACTION_FEE_ERROR:
            return {
                ...state,
                deactivateTransactionFeeLoading: false,
                deactivateTransactionFeeError: action.payload
            }
        case CLEAR_DEACTIVATE_TRANSACTION_FEE:
            return {
                ...state,
                deactivateTransactionFee: null,
                deactivateTransactionFeeLoading: false,
                deactivateTransactionFeeError: null
            }
        case DEACTIVATE_TRANSACTION_FEE_LOADING:
            return {
                ...state,
                deactivateTransactionFeeLoading: true,
                deactivateTransactionFeeError: null
            }
        case DEACTIVATE_TRANSACTION_FEE_ERROR:
            return {
                ...state,
                deactivateTransactionFeeLoading: false,
                deactivateTransactionFeeError: action.payload
            }
        case DEACTIVATE_TRANSACTION_FEE:
            return {
                ...state,
                deactivateTransactionFee: action.payload,
                deactivateTransactionFeeLoading: false,
                deactivateTransactionFeeError: null
            }
        default: return state
    }
}