import {GET_BRANCH, GET_BRANCH_ERROR, GET_BRANCH_LOADING, CLEAR_GET_BRANCH_ERROR, CLEAR_GET_BRANCH} from '../actions/types';

const initialState = {
    branch: null,
    branch_loading: false,
    branch_error: null
}

export default function getBranchesReducer(state = initialState, action){
    switch(action.type){
        case GET_BRANCH:
            return {
                ...state,
                branch: action.payload,
                branch_loading: false,
                branch_error: null
            }
        case GET_BRANCH_ERROR:
            return {
                ...state,
                branch_loading: false,
                branch_error: action.payload
            }
        case GET_BRANCH_LOADING:
            return {
                ...state,
                branch_loading: true,
                branch_error: null
            }
        case CLEAR_GET_BRANCH_ERROR:
            return {
                ...state,
                branch_loading: false,
                branch_error: null
            }
        case CLEAR_GET_BRANCH:
            return {
                ...state,
                branch: null,
                branch_loading: false,
                branch_error: null
            }
        default: return state
    }
}