import {
    CLEAR_UPDATE_PARTNERS_ERROR,
    CLEAR_UPDATE_PARTNERS,
    UPDATE_PARTNERS_LOADING,
    UPDATE_PARTNERS_ERROR,
    UPDATE_PARTNERS} 
from '../actions/types';

const initialState = {
    updatePartner: null,
    updatePartnerLoading: false,
    updatePartnerError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_PARTNERS_ERROR:
            return {
                ...state,
                updatePartnerLoading: false,
                updatePartnerError: action.payload
            }
        case CLEAR_UPDATE_PARTNERS:
            return {
                ...state,
                updatePartner: null,
                updatePartnerLoading: false,
                updatePartnerError: null
            }
        case UPDATE_PARTNERS_LOADING:
            return {
                ...state,
                updatePartnerLoading: true,
                updatePartnerError: null
            }
        case UPDATE_PARTNERS_ERROR:
            return {
                ...state,
                updatePartnerLoading: false,
                updatePartnerError: action.payload
            }
        case UPDATE_PARTNERS:
            return {
                ...state,
                updatePartner: action.payload,
                updatePartnerLoading: false,
                updatePartnerError: null
            }
        default: return state
    }
}