import React from "react";
import { Field, ErrorMessage } from "formik";

function Input(props) {
  const { name, label, ...rest } = props
  return (
    <div>
      <label htmlFor={name}> {label}</label>
      <Field name={name} {...rest} />
      <div className="text-red-500 text-sm"><ErrorMessage name={name} /> </div>
    </div>
  )
}
export default Input;