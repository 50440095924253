import React from 'react';
import Pagination from "react-js-pagination";
import { useDispatch,useSelector } from 'react-redux';
import * as actions from '../../../store/actions';

function ViewLimits(props) {
  const dispatch = useDispatch();
  const {handleEdit} = props;
  const {new_transaction_limit} = useSelector(addTransactionLimit => addTransactionLimit.addTransactionLimitReducer);
  const {transaction_limit}= useSelector(transactionLimit => transactionLimit.getTransactionLimitReducer)
  const {updateTransactionLimit, updateTransactionLimitLoading, updateTransactionLimitError} = useSelector(updateLimit =>updateLimit.updateTransactionLimitReducer);
  const {activateTransactionLimit, activateTransactionLimitLoading, activateTransactionLimitError} = useSelector(activateLimit => activateLimit.activateTransactionLimitReducer);
  const {deactivateTransactionLimit} =useSelector( deactivateLimit => deactivateLimit.deactivateTransactionLimitReducer);
  const [page,setPage] = React.useState(1);
  const itemsCountPerPage = 20;

  React.useEffect(()=>{
    dispatch(actions.getTransactionLimit({page:page,perPage:10}))
  },[new_transaction_limit,activateTransactionLimit,deactivateTransactionLimit,updateTransactionLimit])

  const handleChangeStatus = (limit) => {
    limit.status === 'ACTIVE' ? dispatch(actions.deactivateTransactionLimit(limit)): dispatch(actions.activateTransactionLimit(limit));
  }

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  }


  let content = <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
          <th scope="col" className="px-6 py-3">
              Corridor
          </th>
          <th scope="col" className="px-10 py-3">
              Currency
          </th>
          <th scope="col" className="px-6 py-3">
              Minimum Transaction Amount
          </th>
          <th scope="col" className="px-6 py-3">
              Per Transaction
          </th>
          <th scope="col" className="px-6 py-3">
              Per Day
          </th>
          <th scope="col" className="px-6 py-3">
              Per Month
          </th>
          <th scope="col" className="px-6 py-3">
              Status
          </th>
          <th scope="col" className="px-10 py-3">
              Action
          </th>
          <th scope="col" className="px-6 py-3">
            <span className="sr-only">Edit</span>
          </th>
      </tr>
  </thead>
  <tbody>
  {(transaction_limit && transaction_limit.data.length > 0) ? transaction_limit.data.map(limit => (
            <tr key={limit.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="px-6 py-4">
              {limit.remittanceCorridor.charAt(0).toUpperCase() + limit.remittanceCorridor.slice(1).toLowerCase()}
            </td>
            <td className="px-6 py-4">
                {limit.currency.currencyName}
            </td>
            <td className="px-6 py-4">
                {limit.currency.currencySymbol + '('+ limit.minimumAmountPerTransaction + ')'}
            </td>
            <td className="px-6 py-4">
                {limit.currency.currencySymbol + '('+ limit.maximumAmountPerTransaction + ')'}
            </td>
            <td className="px-6 py-4">
                {limit.currency.currencySymbol + '('+ limit.maximumAmountPerDay + ')'}
            </td>
            <td className="px-6 py-4">
                {limit.currency.currencySymbol + '('+ limit.maximumAmountPerMonth + ')'}
            </td>
            <td className="px-6 py-4">
            {limit.status.charAt(0).toUpperCase() + limit.status.slice(1).toLowerCase()}
            </td>
            <td className="px-6 py-4 text-right">
                {limit.status === 'ACTIVE' ? 
                <button onClick={() => handleChangeStatus(limit)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">De-activate</button> : 
                <button onClick={() => handleChangeStatus(limit)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Activate</button> }
            </td>
            <td className="px-6 py-4 text-right">
                <button onClick={() => handleEdit(limit)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</button>
            </td>
        </tr>
        ))  : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">No data found</td>
            </tr>}
    </tbody>
  </table>
  return (
    <div className="overflow-x-auto border sm:rounded-lg">
        {content}
        {transaction_limit && transaction_limit.data.length > 0 && <div className="p-4 flex justify-end">
            <Pagination
                activePage={page}
                itemsCountPerPage={itemsCountPerPage}
                totalItemsCount={10}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
            />
        </div>}
    </div>
  )
}

export default ViewLimits