import React from 'react'
import RemittersTable from '../components/reports/RemittersTable';
import TransactionLogs from '../components/reports/TransactionLogs';
import AuditTrail from '../components/reports/AuditTrail';
import Dashboard from '../components/reports/Dashboard';

function Reports() {
  const [store, setStore] = React.useState(null);
  const [active, setActive] = React.useState('charts');

  let content;

  switch (active) {
    case 'charts':
      content = <Dashboard />
      break;
    case 'remitters':
      content = <RemittersTable setStore={setStore} />
      break;
    case 'transactions':
      content =  <TransactionLogs  />
      break;
    case 'audit':
      content = <AuditTrail  />
      break;
    default:
      break;
  }
  return (
    <div className="overflow-x-hidden">
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px">
              <li className="mr-2">
                  <button onClick={() => setActive('charts')} className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'charts' ? "border-green-700 text-green-700 dark:text-blue-500 dark:border-green-500" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}`}>Dashboard</button>
              </li>
              <li className="mr-2">
                  <button onClick={() => setActive('remitters')} className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'remitters' ? "border-green-700 text-green-700 dark:text-blue-500 dark:border-green-500" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}`}>Remitters</button>
              </li>
              <li className="mr-2">
                  <button onClick={() => setActive('transactions')} className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'transactions' ? "border-green-700 text-green-700 dark:text-blue-500 dark:border-green-500" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}`}>Transaction Logs</button>
              </li>
              <li className="mr-2">
                  <button onClick={() => setActive('audit')} className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'audit' ? "border-green-700 text-green-700 dark:text-blue-500 dark:border-green-500" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}`}>Audit Trail</button>
              </li>
          </ul>
      </div>
      <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 p-8 overflow-x-hidden">
              {content}
          </div>
      </div>
    </div>
  )
}

export default Reports