import {
    CLEAR_UPDATE_MESSAGE_CONFIG_ERROR,
    CLEAR_UPDATE_MESSAGE_CONFIG,
    UPDATE_MESSAGE_CONFIG_LOADING,
    UPDATE_MESSAGE_CONFIG_ERROR,
    UPDATE_MESSAGE_CONFIG} 
from '../actions/types';

const initialState = {
    update_message_config: null,
    update_message_configLoading: false,
    update_message_configError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_MESSAGE_CONFIG_ERROR:
            return {
                ...state,
                update_message_configLoading: false,
                update_message_configError: action.payload
            }
        case CLEAR_UPDATE_MESSAGE_CONFIG:
            return {
                ...state,
                update_message_config: null,
                update_message_configLoading: false,
                update_message_configError: null
            }
        case UPDATE_MESSAGE_CONFIG_LOADING:
            return {
                ...state,
                update_message_configLoading: true,
                update_message_configError: null
            }
        case UPDATE_MESSAGE_CONFIG_ERROR:
            return {
                ...state,
                update_message_configLoading: false,
                update_message_configError: action.payload
            }
        case UPDATE_MESSAGE_CONFIG:
            return {
                ...state,
                update_message_config: action.payload,
                update_message_configLoading: false,
                update_message_configError: null
            }
        default: return state
    }
}