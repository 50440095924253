import {GET_REMITTERS_BY_ID, GET_REMITTERS_BY_ID_ERROR, GET_REMITTERS_BY_ID_LOADING, CLEAR_GET_REMITTERS_BY_ID_ERROR, CLEAR_GET_REMITTERS_BY_ID} from '../actions/types';

const initialState = {
    remitters_id: null,
    remitters_id_loading: false,
    remitters_id_error: null
}

export default function getRemittersByIdReducer(state = initialState, action){
    switch(action.type){
        case GET_REMITTERS_BY_ID:
            return {
                ...state,
                remitters_id: action.payload,
                remitters_id_loading: false,
                remitters_id_error: null
            }
        case GET_REMITTERS_BY_ID_ERROR:
            return {
                ...state,
                remitters_id_loading: false,
                remitters_id_error: action.payload
            }
        case GET_REMITTERS_BY_ID_LOADING:
            return {
                ...state,
                remitters_id_loading: true,
                remitters_id_error: null
            }
        case CLEAR_GET_REMITTERS_BY_ID_ERROR:
            return {
                ...state,
                remitters_id_loading: false,
                remitters_id_error: null
            }
        case CLEAR_GET_REMITTERS_BY_ID:
            return {
                ...state,
                remitters_id: null,
                remitters_id_loading: false,
                remitters_id_error: null
            }
        default: return state
    }
}