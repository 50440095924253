import {CLEAR_ADD_RECONCILIATIONS_PARTNER_RECON, CLEAR_ADD_RECONCILIATIONS_PARTNER_RECON_ERROR, ADD_RECONCILIATIONS_PARTNER_RECON_LOADING, ADD_RECONCILIATIONS_PARTNER_RECON_ERROR, ADD_RECONCILIATIONS_PARTNER_RECON} from '../actions/types';

const initialState = {
    new_recon_partner_recon: null,
    new_recon_partner_recon_error: null,
    new_recon_partner_recon_loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ADD_RECONCILIATIONS_PARTNER_RECON:
            return {
                ...state,
                new_recon_partner_recon: null,
                new_recon_partner_recon_error: null,
                new_recon_partner_recon_loading: false
            }
        case CLEAR_ADD_RECONCILIATIONS_PARTNER_RECON_ERROR:
            return {
                ...state,
                new_recon_partner_recon_error: null,
                new_recon_partner_recon_loading: false
            }
        case ADD_RECONCILIATIONS_PARTNER_RECON_LOADING:
            return {
                ...state,
                new_recon_partner_recon_error: null,
                new_recon_partner_recon_loading: true
            }
        case ADD_RECONCILIATIONS_PARTNER_RECON_ERROR:
            return {
                ...state,
                new_recon_partner_recon_error: action.payload,
                new_recon_partner_recon_loading: false
            }
        case ADD_RECONCILIATIONS_PARTNER_RECON:
            return {
                ...state,
                new_recon_partner_recon: action.payload,
                new_recon_partner_recon_error: null,
                new_recon_partner_recon_loading: false
            }
        default: return state
    }
}