import {
    CLEAR_UPDATE_USER_ERROR,
    CLEAR_UPDATE_USER,
    UPDATE_USER_LOADING,
    UPDATE_USER_ERROR,
    UPDATE_USER
} from '../actions/types';

const initialState = {
    updateUser: null,
    updateUserLoading: false,
    updateUserError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_USER_ERROR:
            return {
                ...state,
                updateUserLoading: false,
                updateUserError: action.payload
            }
        case CLEAR_UPDATE_USER:
            return {
                ...state,
                updateUser: null,
                updateUserLoading: false,
                updateUserError: null
            }
        case UPDATE_USER_LOADING:
            return {
                ...state,
                updateUserLoading: true,
                updateUserError: null
            }
        case UPDATE_USER_ERROR:
            return {
                ...state,
                updateUserLoading: false,
                updateUserError: action.payload
            }
        case UPDATE_USER:
            return {
                ...state,
                updateUser: action.payload,
                updateUserLoading: false,
                updateUserError: null
            }
        default: return state
    }
}