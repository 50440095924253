import {
    CLEAR_CASHOUT_ERROR,
    CLEAR_CASHOUT,
    CASHOUT_LOADING,
    CASHOUT_ERROR,
    CASHOUT
} from '../actions/types';

const initialState = {
    cashout: null,
    cashoutLoading: false,
    cashoutError: null,
}

const cashoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case CASHOUT:
            return {
                ...state,
                cashout: action.payload,
                cashoutLoading: false,
                cashoutError: null,
            }
        case CASHOUT_ERROR:
            return {
                ...state,
                cashoutLoading: false,
                cashoutError: action.payload,
            }
        case CASHOUT_LOADING:
            return {
                ...state,
                cashoutLoading: true,
                cashoutError: null,
            }
        case CLEAR_CASHOUT:
            return {
                ...state,
                cashout: null,
                cashoutLoading: false,
                cashoutError: null,
            }
        case CLEAR_CASHOUT_ERROR:
            return {
                ...state,
                cashoutLoading: false,
                cashoutError: null,
            }
        default: return state
    }
}

export default cashoutReducer