import {
    CLEAR_GET_TRANSACTION_FEES_ERROR,
    CLEAR_GET_TRANSACTION_FEES,
    GET_TRANSACTION_FEES_LOADING,
    GET_TRANSACTION_FEES_ERROR,
    GET_TRANSACTION_FEES
} from '../actions/types';

const initialState = {
    get_transaction_fees: null,
    get_transaction_fees_loading: false,
    get_transaction_fees_error: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_GET_TRANSACTION_FEES_ERROR:
            return {
                ...state,
                get_transaction_fees_loading: false,
                get_transaction_fees_error: null
            }
        case CLEAR_GET_TRANSACTION_FEES:
            return {
                ...state,
                get_transaction_fees: null,
                get_transaction_fees_loading: false,
                get_transaction_fees_error: null
            }
        case GET_TRANSACTION_FEES_LOADING:
            return {
                ...state,
                get_transaction_fees_loading: true,
                get_transaction_fees_error: null
            }
        case GET_TRANSACTION_FEES_ERROR:
            return {
                ...state,
                get_transaction_fees_loading: false,
                get_transaction_fees_error: action.payload
            }
        case GET_TRANSACTION_FEES:
            return {
                ...state,
                get_transaction_fees: action.payload,
                get_transaction_fees_loading: false,
                get_transaction_fees_error: null
            }
        default: return state
    }
}