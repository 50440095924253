import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../../store/actions';
import FormikController from '../Formik/FormikController';
import * as Yup from "yup";
import Pagination from "react-js-pagination";
import loader from '../../assets/loaders/382.gif';
import { Formik, Form } from "formik";
import PageLoader from '../utils/PageLoader';

function RemitSource() {
    const dispatch = useDispatch();
    const postRemitSourceState = useSelector(postRemitSourceState => postRemitSourceState.remitSourceReducer);
    const remitSourceState = useSelector(remitSourceState => remitSourceState.getRemitSourceReducer);
    const {update_remit_source, update_remit_source_loading, update_remit_source_error} = useSelector(updateSourceState => updateSourceState.updateRemitSourceReducer);
    const [page, setPage] = React.useState(1);
    const [state, setState] = React.useState(null);
    const perPage = 6;

    const handleSubmit = (values) => {
        values.id = (state) ? state.id : null;
        state ? dispatch(actions.updateRemitSource(values)) : dispatch(actions.remitSources(values));
    }

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    const handleEdit = (value) => {
        setState(value);
    }

    let content;

    const initialValues = {
        source: state ? state.source : '',
        code: state ? state.code : ''
    }

    const validationSchema = Yup.object({
        source: Yup.string().required('This field is required'),
        code: Yup.string().required('This field is required'),
    })

    React.useEffect(() => {
        dispatch(actions.getRemitSource({
            page,
            perPage
        }));
        setState(null);
    }, [postRemitSourceState.remit_source, page, update_remit_source]);

    content = remitSourceState.remit_src_loading ? <PageLoader /> : (remitSourceState.remit_src) && <div>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <tbody>
                {remitSourceState.remit_src.data.length === 0 ? <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td colSpan="4" className="text-center px-6 pt-4 pb-8 font-medium text-gray-900 dark:text-white whitespace-nowrap">No data found</td>
                </tr> : (remitSourceState.remit_src.data.map(src => (
                    <tr key={src.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                            {src.code}
                        </th>
                        <td className="px-6 py-4">
                            {src.source}
                        </td>
                        {/* <td className="px-6 py-4">
                            {src.createdAt}
                        </td> */}
                        <td className="px-6 py-4 text-right">
                            <button onClick={() => handleEdit(src)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</button>
                        </td>
                    </tr>
                )))}
            </tbody>
        </table>
        {(remitSourceState.remit_src && !remitSourceState.remit_src_loading && remitSourceState.remit_src.data.length > 0) &&
        <div className="p-4 flex justify-end">
            <Pagination
                activePage={page}
                itemsCountPerPage={perPage}
                totalItemsCount={remitSourceState.remit_src.totalItemsCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
            />
        </div>}
    </div>

    return (
    <div className="rounded-md overflow-y-auto bg-white shadow hover:shadow-md ease-in-out duration-700 max-h-fit" style={{height: '520px'}}>
        <div className="p-4 border-b border-gray-300">
            <h1 className="font-medium text-md mb-1">Add Remit Sources</h1>
            {postRemitSourceState.remit_source_error &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                    {postRemitSourceState.remit_source_error}
                </div>
                <button onClick={dispatch(actions.clearRemitSourceError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>}

            {update_remit_source_error &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                    {update_remit_source_error}
                </div>
                <button onClick={dispatch(actions.clearUpdateRemitSourceError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values, {resetForm}) => {
                    handleSubmit(values)
                    resetForm();
                }}
            >
                <Form>
                    <div className="flex items-start">
                        <div className="mr-2">
                            <FormikController
                                control='input'
                                type='text'
                                placeholder='Source of Income'
                                name='source'
                            />
                        </div>
                        <div className="mr-2">
                            <FormikController
                                control='input'
                                type='text'
                                placeholder='Code'
                                name='code'
                            />
                        </div>
                        <div>
                            {(postRemitSourceState.remit_source_loading || update_remit_source_loading) ?  <button className="bg-gray-500 rounded px-6 py-1"><img className="h-8" src={loader} alt="loader" /></button> :
                            <button type="submit" className="px-6 py-2 rounded text-white ease-in-out duration-700 bg-green-600 hover:bg-green-700 ">{state ? "Update" : "Add"}</button>}
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
        <div className="p-4">
            {content}
        </div>
    </div>
  )
}

export default RemitSource