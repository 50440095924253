import { combineReducers } from "redux";
import getBranchesReducer from './getBranchesReducer';
import addBranchesReducer from './addBranchesReducer';
import addUserReducer from "./addUserReducer";
import getUsersReducer from "./getUsersReducer";
import loginReducer from "./loginReducer";
import addRemitterReducer from "./addRemitterReducer";
import updateBranchReducer from "./updateBranchReducer";
import updateUserReducer from "./updateUserReducer";
import addCurrencyReducer from "./addCurrencyReducer";
import getCurrencyReducer from "./getCurrencyReducer";
import updateCurrencyReducer from "./updateCurrencyReducer";
import addTransactionFeesReducer from "./addTransactionFeesReducer";
import getTransactionFeesReducer from "./getTransactionFeesReducer";
import addRemitReasonReducer from './addRemitReasonReducer';
import getRemitReasonsReducer from './getRemitReasonsReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import remitSourceReducer from './remitSourceReducer';
import getRemitSourceReducer from './getRemitSourceReducer';
import updateRemitSourceReducer from './updateRemitSourceReducer';
import updateTransactionFeeReducer from "./updateTransactionFeeReducer";
import updateRemitReasonReducer from "./updateRemitReasonReducer";
import activateCurrencyReducer from "./activateCurrencyReducer";
import deactiveCurrencyReducer from "./deactiveCurrencyReducer";
import activateBranchesReducer from "./activateBranchesReducer";
import deactiveBranchesReducer from "./deactiveBranchesReducer";
import changePasswordReducer from "./changePasswordReducer";
import currentUserProfileReducer from "./currentUserProfileReducer";
import getTransactionLimitReducer from  "./getTransactionLimitReducer";
import addTransactionLimitReducer from "./addTransactionLimitReducer";
import updateTransactionLimitReducer from "./updateTransactionLimitReducer";
import activateTransactionLimitReducer from "./activateTransactionLimitReducer";
import deactivateTransactionLimitReducer from "./deactivateTransactionLimitReducer";
import updateCurrentUserReducer from "./updateCurrentUserReducer";
import activateUsersReducer from "./activateUsersReducer";
import deactivateUsersReducer from "./deactivateUsersReducer";
import assignFloatReducer from "./assignFloatReducer";
import updateFloatReducer from "./updateFloatReducer";
import getTellerFloatReducer from "./getTellerFloatReducer";
import activateTransactionfeeReducer from "./activateTransactionfeeReducer";
import deactivateTransactionfeeReducer from "./deactivateTransactionfeeReducer";
import fetchTellersReducer from "./fetchTellersReducer";
import confirmFloatReducer from "./confirmFloatReducer";
import addPartnersReducer from "./addPartnersReducer";
import getPartnersReducer from "./getPartnersReducer";
import updatePartnersReducer from "./updatePartnersReducer";
import addPartnerBranchesReducer from "./addPartnerBranchesReducer";
import getPartnerBranchesReducer from "./getPartnerBranchesReducer";
import updatePartnerBranchesReducer from "./updatePartnerBranchesReducer";
import activatePartnerBranchesReducer from "./activatePartnerBranchesReducer";
import deactivatePartnerBranchesReducer from "./deactivatePartnerBranchesReducer";
import addPartnerUsersReducer from "./addPartnerUsersReducer";
import getPartnerUsersReducer from "./getPartnerUsersReducer";
import getRemittersByNationalIdReducer from "./getRemittersByNationalIdReducer";
import getRemittersReducer from "./getRemittersReducer";
import getRemittersByIdReducer from "./getRemittersByIdReducer";
import updateRemittersReducer from "./updateRemittersReducer";
import activateRemittersReducer from "./activateRemittersReducer";
import deactivateRemittersReducer from "./deactivateRemittersReducer";
import partnerBranchUsersReducer from "./partnerBranchUsersReducer";
import searchRemitterReducer from "./searchRemitterReducer";
import makeRemittanceReducer from "./makeRemittanceReducer";
import getTransactionLogsReducer from "./getTransactionLogsReducer";
import getTransactionLogsByIdReducer from "./getTransactionLogsByIdReducer";
import fetchRemitterBeneficiaryReducer from "./fetchRemitterBeneficiaryReducer";
import getAdminAuditTrailByIdReducer from "./getAdminAuditTrailByIdReducer";
import getAdminAuditTrailReducer from "./getAdminAuditTrailReducer";
import tellerFloatReducer from "./tellerFloatReducer";
import cashoutReducer from "./cashoutReducer";
import processRemittanceReducer from "./processRemittanceReducer";
import addReconciliationsReducer from "./addReconciliationsReducer";
import addReconciliationOveralReconReducer from "./addReconciliationOveralReconReducer";
import addReconciliationsPartnerReconReducer from "./addReconciliationsPartnerReconReducer";
import addReconciliationsIdProcessReducer from "./addReconciliationsIdProcessReducer";
import getReconciliationsReducer from "./getReconciliationsReducer";
import getReconciliationsCurrentTellerReducer from "./getReconciliationsCurrentTellerReducer";
import getReconciliationsAllCurrentReducer from "./getReconciliationAllCurrentReducer";
import getReconciliationByIdReducer from "./getReconciliationByIdReducer";
import updateReconciliationsReducer from "./updateReconciliationsReducer";
import runningBalanceReducer from "./runningBalanceReducer";
import updateFeeConfigReducer from "./updateFeeConfigReducer";
import updateRemittanceReversalReducer from "./updateRemittanceReversalReducer";
import feeConfigReducer from "./feeConfigReducer";
import activeFeeReducer from "./activeFeeReducer";
import reportsReducer from "./reportsReducer";
import getReferenceConfigReducer from "./getReferenceConfigReducer";
import updateReferenceConfigReducer from "./updateReferenceConfigReducer"
import addMessageConfigReducer from "./addMessageConfigReducer";
import getMessageConfigReducer from "./getMessageConfigReducer";
import updateMessageConfigReducer from "./updateMessageConfigReducer";
import updateMessageConfigStatusReducer from "./updateMessageConfigStatusReducer";
import graphicalSummaryReducer from "./graphicalSummaryReducer";
import reportSummaryReducer from "./reportSummaryReducer";

export default combineReducers({
    getBranchesReducer,
    addBranchesReducer,
    addUserReducer,
    getUsersReducer,
    loginReducer,
    addRemitterReducer,
    updateBranchReducer,
    updateUserReducer,
    addCurrencyReducer,
    getCurrencyReducer,
    updateCurrencyReducer,
    addTransactionFeesReducer,
    getTransactionFeesReducer,
    addRemitReasonReducer,
    getRemitReasonsReducer,
    forgotPasswordReducer,
    remitSourceReducer, 
    getRemitSourceReducer,
    updateRemitSourceReducer,
    updateTransactionFeeReducer,
    updateRemitReasonReducer,
    activateCurrencyReducer,
    deactiveCurrencyReducer,
    activateBranchesReducer,
    deactiveBranchesReducer,
    changePasswordReducer,
    currentUserProfileReducer,
    addTransactionLimitReducer,
    getTransactionLimitReducer,
    updateTransactionLimitReducer,
    activateTransactionLimitReducer,
    deactivateTransactionLimitReducer,
    activateUsersReducer,
    deactivateUsersReducer,
    updateCurrentUserReducer,
    assignFloatReducer,
    updateFloatReducer,
    getTellerFloatReducer,
    activateTransactionfeeReducer,
    deactivateTransactionfeeReducer,
    fetchTellersReducer,
    confirmFloatReducer,
    addPartnersReducer,
    getPartnersReducer,
    updatePartnersReducer,
    addPartnerBranchesReducer,
    getPartnerBranchesReducer,
    updatePartnerBranchesReducer,
    activatePartnerBranchesReducer,
    deactivatePartnerBranchesReducer,
    addPartnerUsersReducer,
    getPartnerUsersReducer,
    getRemittersByNationalIdReducer,
    getRemittersByIdReducer,
    getRemittersReducer,
    activateRemittersReducer,
    deactivateRemittersReducer,
    updateRemittersReducer,
    partnerBranchUsersReducer,
    searchRemitterReducer,
    makeRemittanceReducer,
    fetchRemitterBeneficiaryReducer,
    getTransactionLogsReducer,
    getTransactionLogsByIdReducer,
    getAdminAuditTrailReducer,
    getAdminAuditTrailByIdReducer,
    tellerFloatReducer,
    cashoutReducer,
    processRemittanceReducer,
    addReconciliationsReducer,
    addReconciliationOveralReconReducer,
    addReconciliationsPartnerReconReducer,
    addReconciliationsIdProcessReducer,
    getReconciliationsReducer,
    getReconciliationsCurrentTellerReducer,
    getReconciliationsAllCurrentReducer,
    getReconciliationByIdReducer,
    updateReconciliationsReducer,
    runningBalanceReducer,
    updateFeeConfigReducer,
    updateRemittanceReversalReducer,
    feeConfigReducer,
    activeFeeReducer,
    reportsReducer,
    getReferenceConfigReducer,
    updateReferenceConfigReducer,
    addMessageConfigReducer,
    getMessageConfigReducer,
    updateMessageConfigReducer,
    updateMessageConfigStatusReducer,
    graphicalSummaryReducer,
    reportSummaryReducer
})