import React from 'react';
import PropTypes from 'prop-types';
import { connect,useDispatch,useSelector } from 'react-redux';
import * as actions from '../../store/actions';
import Pagination from "react-js-pagination";
import PageLoader from '../utils/PageLoader';

function UsersTable(props) {
    const dispatch = useDispatch();
    const { activateUsers } = useSelector(activeUsers => activeUsers.activateUsersReducer);
    const { deactivateUsers } = useSelector(deactiveUsers =>deactiveUsers.deactivateUsersReducer);
    const { new_partner_user} = useSelector(addPartnerUser =>addPartnerUser.addPartnerUsersReducer)
    const {users, users_loading} = props.getUsersReducer;
    const {getUsers, setStore, clearUpdateUser} = props;
    const {updateUser} = props.updateUserReducer;
    const {new_user} = props.addUserReducer;
    const [page, setPage] = React.useState(1);
    const per_page = 20;

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    const handleEdit = (user) => {
        setStore(user);
    }

    
  const handleChangeStatus = (user) => {
    user.status === 'ACTIVE' ? dispatch(actions.deactivateUsers(user)): dispatch(actions.activateUsers(user));

}

    React.useEffect(() => {
        getUsers({
            page,
            perPage: per_page
        });
        clearUpdateUser();
    }, [page, getUsers, new_user, updateUser,activateUsers, deactivateUsers,new_partner_user]);

    let content = (users_loading) ? <PageLoader /> : (users) && <React.Fragment> <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
            <th scope="col" className="px-6 py-3">
                First Name
            </th>
            <th scope="col" className="px-6 py-3">
                Middle Name
            </th>
            <th scope="col" className="px-6 py-3">
                Last Name
            </th>
            <th scope="col" className="px-6 py-3">
                Phone Number
            </th>
            <th scope="col" className="px-6 py-3">
                Email
            </th>
            <th scope="col" className="px-10 py-3">
                Branch
            </th>
            <th scope="col" className="px-6 py-3">
                Role
            </th>
            <th scope="col" className="px-6 py-3">
              Status
          </th>
          <th scope="col" className="px-10 py-3">
              Action
          </th>
            <th scope="col" className="px-6 py-3">
                <span className="sr-only">Edit</span>
            </th>
        </tr>
    </thead>
    <tbody>
        {(users.data.data.length > 0) ? users.data.data.map(user => (
            <tr key={user.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                {user.firstname}
            </th>
            <td className="px-6 py-4">
                {user.middlename}
            </td>
            <td className="px-6 py-4">
                {user.lastname}
            </td>
            <td className="px-6 py-4">
                {user.mobile}
            </td>
            <td className="px-6 py-4">
                {user.email}
            </td>
            <td className="px-6 py-4">
                {user.branch ? user.branch.branchName : 'No Branch...'}
            </td>
            <td className="px-6 py-4">
                {user.role.charAt(0).toUpperCase() + user.role.slice(1).toLowerCase()}
            </td>
            <td className="px-6 py-4">
            {user.status.charAt(0).toUpperCase() + user.status.slice(1).toLowerCase()}
            </td>
            <td className="px-6 py-4 text-center">
                {user.status === 'ACTIVE' ? 
                <button onClick={() => handleChangeStatus(user)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">De-activate</button> : 
                <button onClick={() => handleChangeStatus(user)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Activate</button> }
            </td>
            <td className="px-6 py-4 text-right">
                <a href="#" onClick={() => handleEdit(user)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
            </td>
        </tr>
        ))  : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan="8" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">No data found</td>
            </tr>}
    </tbody>
</table>
    {(users && !users_loading) &&
    <div className="p-4 flex justify-end">
        <Pagination
            activePage={page}
            itemsCountPerPage={per_page}
            totalItemsCount={users.data.totalItemsCount}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
        />
    </div>}
</React.Fragment>

  return (
    <div className="overflow-y-auto overflow-x-hidden border sm:rounded-lg">
        {content}
    </div>
  )
}

UsersTable.propTypes = {
    getUsers: PropTypes.func.isRequired,
    clearUpdateUser: PropTypes.func.isRequired,
    updateUserReducer: PropTypes.object.isRequired,
    getUsersReducer: PropTypes.object.isRequired,
    addUserReducer: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    getUsersReducer: state.getUsersReducer,
    addUserReducer: state.addUserReducer,
    updateUserReducer: state.updateUserReducer,
})

export default connect(mapStateToProps, actions)(UsersTable)