import {
    CLEAR_ADD_REMIT_REASON_ERROR,
    CLEAR_ADD_REMIT_REASON,
    ADD_REMIT_REASON_LOADING,
    ADD_REMIT_REASON_ERROR,
    ADD_REMIT_REASON
} from '../actions/types';

const initialState = {
    remit_reason: null,
    remit_reason_loading: false,
    remit_reason_error: null
}

const addRemitReasonReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_REMIT_REASON:
            return {
                ...state,
                remit_reason: action.payload,
                remit_reason_loading: false,
                remit_reason_error: null
            }
        case ADD_REMIT_REASON_ERROR:
            return {
                ...state,
                remit_reason_loading: false,
                remit_reason_error: action.payload
            }
        case ADD_REMIT_REASON_LOADING:
            return {
                ...state,
                remit_reason_loading: true,
                remit_reason_error: null
            }
        case CLEAR_ADD_REMIT_REASON:
            return {
                ...state,
                remit_reason: null,
                remit_reason_loading: false,
                remit_reason_error: null
            }
        case CLEAR_ADD_REMIT_REASON_ERROR:
            return {
                ...state,
                remit_reason_loading: false,
                remit_reason_error: null
            }
        default: return state
    }
}

export default addRemitReasonReducer