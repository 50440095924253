import {CLEAR_ADD_MESSAGE_CONFIG, CLEAR_ADD_MESSAGE_CONFIG_ERROR, ADD_MESSAGE_CONFIG_LOADING, ADD_MESSAGE_CONFIG_ERROR, ADD_MESSAGE_CONFIG} from '../actions/types';

const initialState = {
    new_message_config: null,
    new_message_config_error: null,
    new_message_config_loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ADD_MESSAGE_CONFIG:
            return {
                ...state,
                new_message_config: null,
                new_message_config_error: null,
                new_message_config_loading: false
            }
        case CLEAR_ADD_MESSAGE_CONFIG_ERROR:
            return {
                ...state,
                new_message_config_error: null,
                new_message_config_loading: false
            }
        case ADD_MESSAGE_CONFIG_LOADING:
            return {
                ...state,
                new_message_config_error: null,
                new_message_config_loading: true
            }
        case ADD_MESSAGE_CONFIG_ERROR:
            return {
                ...state,
                new_message_config_error: action.payload,
                new_message_config_loading: false
            }
        case ADD_MESSAGE_CONFIG:
            return {
                ...state,
                new_message_config: action.payload,
                new_message_config_error: null,
                new_message_config_loading: false
            }
        default: return state
    }
}