import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from "formik";
import { useParams } from 'react-router-dom';
import loader from '../../../assets/loaders/382.gif';
import * as Yup from "yup";
import * as actions from '../../../store/actions';
import FormikController from '../../Formik/FormikController';

function ProcessReconciliation() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const processState = useSelector(processState => processState.addReconciliationsIdProcessReducer);
    const reportsState = useSelector(reportsState => reportsState.getReconciliationByIdReducer);

    const INITIAL_VALUE = {
        receivedcashInHand: '',
        supervisorComments: ''
    }

    const VALIDATION_SCHEMA = Yup.object().shape({
        receivedcashInHand: Yup.number().required('This field is required'),
        supervisorComments: Yup.string().required('This field is required'),
    });

    const handleSubmit = (values) => {
        values.id = id;
        dispatch(actions.addReconciliationsProcess(values));
    }

    return (
        <div>
            <Formik
                initialValues={INITIAL_VALUE}
                validationSchema={VALIDATION_SCHEMA}
                enableReinitialize
                onSubmit={(values, {resetForm}) => {
                    handleSubmit(values)
                    resetForm()
                }}
            >
                <Form>
                    <div className="grid grid-cols-12 gap-2">
                        <div className="col-span-12">
                            <h1 className="font-medium text-lg">Reconcile</h1>
                        </div>
                        {(reportsState.recon_id && reportsState.recon_id.status !== 'PENDING') &&
                        <div className="col-span-12 bg-yellow-100 rounded-lg py-3 px-4 mb-4 text-base text-yellow-700 mb-3" role="alert">
                            Reconciliation already done for this transaction
                        </div>}
                        {processState.new_recon_id_process_error && 
                        <div id="alert-2" className="col-span-12 flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                            <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                            <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                                {processState.new_recon_id_process_error}
                            </div>
                            <button onClick={()=>dispatch(actions.clearAddReconciliationsProcessError())}type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                                <span className="sr-only">Close</span>
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>
                        </div>
                        }
                        <div className="col-span-12">
                            <FormikController
                                control='input'
                                type='text'
                                label='Cash Received In Hand'
                                name='receivedcashInHand'
                            />
                        </div>
                        <div className="col-span-12">
                            <FormikController
                                control='textArea'
                                type='text'
                                label='Supervisor Comment'
                                name='supervisorComments'
                            />
                        </div>
                    </div>
                    <div className="text-right mt-4">
                        {processState.new_recon_id_process_loading ? <button className="bg-gray-500 rounded px-8 py-1"><img className="h-8" src={loader} alt="loader" /></button> :
                        <button disabled={(reportsState.recon_id && reportsState.recon_id.status === 'PENDING') ? false : true} type="submit" className="bg-green-600 text-white rounded px-3 py-2">{(reportsState.recon_id && reportsState.recon_id.status === 'PENDING') ? 'Submit' : 'Disabled'}</button>}
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default ProcessReconciliation