import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions';
import Pagination from "react-js-pagination";
import PageLoader from '../utils/PageLoader';
import handleDateFormat from '../utils/handleDateFormat';


function BranchTables(props) {
    const dispatch = useDispatch();
    const { audit_trail, audit_trail_loading, admin_trail_error } = useSelector(getAdminAuditTrail => getAdminAuditTrail.getAdminAuditTrailReducer);

    const itemsCountPerPage = 15;

    const [page, setPage] = React.useState(1);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    React.useEffect(() => {
        dispatch(actions.getAdminAuditTrail({
            page,
            perPage: itemsCountPerPage
        }));

    }, [page,]);




    let content = (audit_trail_loading) ? <PageLoader /> : (audit_trail) &&

        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400" >
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Role
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Action
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Description
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Branch
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Address
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Contact Person
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <span className="">Contact Person Number</span>
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Created At
                    </th>
                </tr>
            </thead>
            <tbody>
                {(audit_trail && audit_trail.data.length > 0) ? audit_trail.data.map(audit => (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                            {audit.user.firstname + ' ' + audit.user.lastname}
                        </th>
                        <td className="px-6 py-4">
                            {audit.user.role}
                        </td>
                        <td className="px-6 py-4">
                            {audit.actionType}
                        </td>
                        <td className="px-6 py-4">
                            {audit.actionPerformed}
                        </td>
                        <td className="px-6 py-4">
                            {audit.user.branch ? audit.user.branch.branchName : 'None'}
                        </td>
                        <td className="px-6 py-4">
                            {audit.user.branch ? audit.user.branch.address : 'None'}
                        </td>
                        <td className="px-6 py-4 ">
                            {audit.user.branch ? audit.user.branch.contactPerson : 'None'}
                        </td>
                        <td className="px-6 py-4 ">
                            {audit.user.branch ? audit.user.branch.contactPhone : 'None'}
                        </td>
                        <td className="px-6 py-4">
                            {handleDateFormat(audit.createdAt)}
                        </td>
                    </tr>
                )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan="10" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">No data found</td>
            </tr>}
            </tbody>
        </table>

    return (
        <div>
            <div className="overflow-x-auto border sm:rounded-lg">
                {content}
                {(audit_trail && audit_trail.data.length > 0 && !audit_trail_loading) &&
                    <div className="p-4 flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={audit_trail.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                        />
                    </div>}
            </div>
        </div>
    )
}



export default BranchTables;