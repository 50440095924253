function calc(date, days) {
    date.setDate(date.getDate() - Math.abs(days));
    return date;
}

const handleSimpleDateFormat = (diff) => {
    let today = new Date();
    let difference = calc(today, diff);
    let d = new Date(difference), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();

    if(month.length < 2) { month = '0' + month; }
    if(day.length < 2) { day = '0' + day; }
    return [year, month, day].join('-');
}

export default handleSimpleDateFormat
