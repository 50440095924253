import {
    CLEAR_UPDATE_CURRENCY_ERROR,
    CLEAR_UPDATE_CURRENCY,
    UPDATE_CURRENCY_LOADING,
    UPDATE_CURRENCY_ERROR,
    UPDATE_CURRENCY} 
from '../actions/types';

const initialState = {
    updateCurrency: null,
    updateCurrencyLoading: false,
    updateCurrencyError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_CURRENCY_ERROR:
            return {
                ...state,
                updateCurrencyLoading: false,
                updateCurrencyError: action.payload
            }
        case CLEAR_UPDATE_CURRENCY:
            return {
                ...state,
                updateCurrency: null,
                updateCurrencyLoading: false,
                updateCurrencyError: null
            }
        case UPDATE_CURRENCY_LOADING:
            return {
                ...state,
                updateCurrencyLoading: true,
                updateCurrencyError: null
            }
        case UPDATE_CURRENCY_ERROR:
            return {
                ...state,
                updateCurrencyLoading: false,
                updateCurrencyError: action.payload
            }
        case UPDATE_CURRENCY:
            return {
                ...state,
                updateCurrency: action.payload,
                updateCurrencyLoading: false,
                updateCurrencyError: null
            }
        default: return state
    }
}