import React from 'react'
import { useDispatch,useSelector } from 'react-redux';
import * as actions from '../../store/actions';
import Pagination from "react-js-pagination";
import PageLoader from '../utils/PageLoader';
import { Link } from 'react-router-dom';

function PartnerTable(props) {
    const {handleEdit, setStore} = props;
    const dispatch = useDispatch();
    const {new_partner} = useSelector(addPartner => addPartner.addPartnersReducer);
    const {updatePartner} = useSelector(editPartner => editPartner.updatePartnersReducer);
    const {partner, partner_loading} = useSelector(getPartners => getPartners.getPartnersReducer);
    const per_page = 20;

    const [page, setPage] = React.useState(1);
    const perPage = 30;

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    React.useEffect(()=>{
      dispatch(actions.getPartners({page, perPage}));
      setStore(null);
    },[new_partner,updatePartner, page]);

    let content = (partner_loading) ? <PageLoader /> : (partner) && <div className="overflow-x-auto border sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Organisation Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Address
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Contact Person Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Contact Number
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Email
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Inbound Commission (%)
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Outbound Commission (%)
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <span className="sr-only">View Branches</span>
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <span className="sr-only">Edit</span>
                    </th>
                </tr>
            </thead>
            <tbody>
            {partner.data.length > 0 ? partner.data.map(el => (
                <tr key={el.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {el.OrganizationName}
                    </th>
                    <td className="px-6 py-4">
                    {el.addressLine1}
                    </td>
                    <td className="px-6 py-4">
                    {el.contactFirstname + ' ' +el.contactLastname}
                    </td>
                    <td className="px-6 py-4">
                        {el.phoneNumber}
                    </td>
                    <td className="px-6 py-4">
                        {el.email} 
                    </td>
                    <td className="px-6 py-4">
                        {el.inBoundCommission} 
                    </td>
                    <td className="px-6 py-4">
                        {el.outBoundCommission} 
                    </td>
                    <td className="px-6 py-4 text-right">
                        <Link to={`partners/${el.id}`} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Branches</Link>
                    </td>
                    <td className="px-6 py-4 text-right">
                        <button onClick={() => handleEdit(el)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline" >Edit</button>
                    </td>
                </tr>
            )) : <tr>
                    <td colSpan="8" className="px-6 py-4 text-center">No data found</td>
                </tr>}
            </tbody>
        </table>
        {(partner && !partner_loading && partner.data.length > 0) &&
        <div className="p-4 flex justify-end">
            <Pagination
                activePage={page}
                itemsCountPerPage={per_page}
                totalItemsCount={partner.totalItemsCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
            />
        </div>}
    </div>

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    )
}

export default PartnerTable