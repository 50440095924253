import {GET_RECONCILIATIONS, GET_RECONCILIATIONS_ERROR, GET_RECONCILIATIONS_LOADING, CLEAR_GET_RECONCILIATIONS_ERROR, CLEAR_GET_RECONCILIATIONS} from '../actions/types';

const initialState = {
    reconciliations: null,
    reconciliations_loading: false,
    reconciliations_error: null
}

export default function getReconciliationsReducer(state = initialState, action){
    switch(action.type){
        case GET_RECONCILIATIONS:
            return {
                ...state,
                reconciliations: action.payload,
                reconciliations_loading: false,
                reconciliations_error: null
            }
        case GET_RECONCILIATIONS_ERROR:
            return {
                ...state,
                reconciliations_loading: false,
                reconciliations_error: action.payload
            }
        case GET_RECONCILIATIONS_LOADING:
            return {
                ...state,
                reconciliations_loading: true,
                reconciliations_error: null
            }
        case CLEAR_GET_RECONCILIATIONS_ERROR:
            return {
                ...state,
                reconciliations_loading: false,
                reconciliations_error: null
            }
        case CLEAR_GET_RECONCILIATIONS:
            return {
                ...state,
                reconciliations: null,
                reconciliations_loading: false,
                reconciliations_error: null
            }
        default: return state
    }
}