import {
    CLEAR_DEACTIVATE_REMITTERS_ERROR,
    CLEAR_DEACTIVATE_REMITTERS,
    DEACTIVATE_REMITTERS_LOADING,
    DEACTIVATE_REMITTERS_ERROR,
    DEACTIVATE_REMITTERS
} 
from '../actions/types';

const initialState = {
    deactivateRemitters: null,
    deactivateRemittersLoading: false,
    deactivateRemittersError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_DEACTIVATE_REMITTERS_ERROR:
            return {
                ...state,
                deactivateRemittersLoading: false,
                deactivateRemittersError: action.payload
            }
        case CLEAR_DEACTIVATE_REMITTERS:
            return {
                ...state,
                deactivateRemitters: null,
                deactivateRemittersLoading: false,
                deactivateRemittersError: null
            }
        case DEACTIVATE_REMITTERS_LOADING:
            return {
                ...state,
                deactivateRemittersLoading: true,
                deactivateRemittersError: null
            }
        case DEACTIVATE_REMITTERS_ERROR:
            return {
                ...state,
                deactivateRemittersLoading: false,
                deactivateRemittersError: action.payload
            }
        case DEACTIVATE_REMITTERS:
            return {
                ...state,
                deactivateRemitters: action.payload,
                deactivateRemittersLoading: false,
                deactivateRemittersError: null
            }
        default: return state
    }
}