import {
    CLEAR_PROCESS_REMITTANCE_ERROR,
    CLEAR_PROCESS_REMITTANCE,
    PROCESS_REMITTANCE_LOADING,
    PROCESS_REMITTANCE_ERROR,
    PROCESS_REMITTANCE
} from '../actions/types';

const initialState = {
    cashedOut: null,
    cashedOutLoading: false,
    cashedOutError: null,
}

const processRemittanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROCESS_REMITTANCE:
            return {
                ...state,
                cashedOut: action.payload,
                cashedOutLoading: false,
                cashedOutError: null,
            }
        case PROCESS_REMITTANCE_ERROR:
            return {
                ...state,
                cashedOutLoading: false,
                cashedOutError: action.payload,
            }
        case PROCESS_REMITTANCE_LOADING:
            return {
                ...state,
                cashedOutLoading: true,
                cashedOutError: null,
            }
        case CLEAR_PROCESS_REMITTANCE:
            return {
                ...state,
                cashedOut: null,
                cashedOutLoading: false,
                cashedOutError: null,
            }
        case CLEAR_PROCESS_REMITTANCE_ERROR:
            return {
                ...state,
                cashedOutLoading: false,
                cashedOutError: null,
            }
        default: return state
    }
}

export default processRemittanceReducer