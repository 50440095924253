import React from "react"
import Input from "./Input.js"
import TextArea from "./TextArea.js"
import Select from "./Select.js"
import RadioButtons from "./RadioButtons.js"
import CheckBoxes from "./CheckBoxes.js"

function FormikController(props) {
  const { control, ...rest } = props
  switch (control) {
    case "input":
      return <Input {...rest} className="border border-gray-400 rounded w-full py-1.5 px-2"/>
    case "textArea":
      return <TextArea {...rest} className="border border-gray-400 rounded w-full py-1.5 px-2"/>
    case "select":
      return <Select {...rest} className="border border-gray-400 rounded w-full py-1.5 px-2"/>
    case "radio":
      return <RadioButtons {...rest} className=""/>
    case "checkbox":
      return <CheckBoxes {...rest} className=""/>
    default:
      return null
  }
}
export default FormikController