import React from 'react';
import * as Yup from "yup";
import { Formik, Form } from "formik";
import * as actions from '../../store/actions';
import { useSelector, useDispatch } from "react-redux";
import FormikController from '../Formik/FormikController';
import loader from '../../assets/loaders/382.gif';

function AddTellerFloat(props) {
    const dispatch = useDispatch();
    const {store,setStore} = props;
    const {update_float_error,update_float_loading} = useSelector(updateFloat => updateFloat.updateFloatReducer); 
    const {get_currency} = useSelector(getCurrency => getCurrency.getCurrencyReducer);
    const tellerState = useSelector(tellerState => tellerState.fetchTellersReducer);
    const floatState = useSelector(floatState => floatState.assignFloatReducer);

    React.useEffect(() => {
        dispatch(actions.fetchTellers());
        dispatch(actions.getCurrency({page: 1, perPage: 500}));
    }, [dispatch]);
    
    const initialValues = {
        teller: store ? store.teller.id :'',
        currency: store ? store.currency.id : '',
        startingBalance: store ? store.startingBalance : '',
    }

    const validationSchema = Yup.object({
        teller: Yup.string().required('This field is required'),
        currency: Yup.string().required('This field is required'),
        startingBalance: Yup.number('Starting balance must be a number').required('This field is required'),
    })

    let currency = get_currency ? get_currency.data.map(cxt => {
        let obj = {
            key: cxt.status === 'ACTIVE' && cxt.currencyName +" "+ '('+ cxt.currencySymbol + ')',
            value:cxt.status === 'ACTIVE' && cxt.id
        }
        return ( obj);
    }) : [];

    let tellers = tellerState.tellers ? tellerState.tellers.map(cxt => {
        let obj = {
            key: cxt.firstname + ' ' + cxt.middlename + ' ' + cxt.lastname ,
            value: cxt.id
        }

        return obj;
    }) : [];

    const handleSubmit = (values) => {
        values.id = store ? store.id : null;
        store ? dispatch(actions.updateFloat(values)) : dispatch(actions.assignFloat(values));
        
    }
    
    return (
        <div>
            <h2 className="font-medium text-lg">Assign Float</h2>
            {floatState.assign_float_error &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                    {floatState.assign_float_error}
                </div>
                <button onClick={()=>dispatch(actions.clearAssignFloatError())}type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>}
            {update_float_error &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                    {update_float_error}
                </div>
                <button onClick={()=>dispatch(actions.clearUpdateFloatError())}type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values, {resetForm}) => {
                    handleSubmit(values)
                    resetForm();
                }}
            >
                <Form>
                <div className="grid grid-cols-12 gap-4 my-3">
                        <div className="col-span-12">
                            <FormikController
                                control='select'
                                type='text'
                                options={tellers}
                                label='Teller'
                                name='teller'
                            />
                        </div>
                        <div className="col-span-12">
                            <FormikController
                                control='select'
                                type='text'
                                label='Currency'
                                name='currency'
                                options ={currency}
                            />
                        </div>
                        <div className="col-span-12">
                            <FormikController
                                control='input'
                                type='text'
                                label='Starting Balance'
                                name='startingBalance'
                            />
                        </div>
                    </div>
                    <div className="text-right pt-4">
                        {floatState.assign_float_loading || update_float_loading ? <button className="bg-gray-500 rounded px-8 py-1"><img className="h-8" src={loader} alt="loader" /></button> : 
                        <button type='submit' className="px-6 py-2 rounded text-white ease-in-out duration-700 bg-green-600 hover:bg-green-700 ">{store ? 'Update' :'Add'}</button>}
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default AddTellerFloat