import React from 'react'
import Sidebar from './Sidebar'
import TopNavigation from './TopNavigation';

function AdminWrapper(props) {

  return (
    <div className="flex overflow-x-hidden">
      <Sidebar />
      <div className="ml-16">
        <TopNavigation />
        <div>
          {props.children}
        </div>
      </div>
    </div>
  )
}


export default AdminWrapper