import {
    CLEAR_DEACTIVATE_CURRENCY_ERROR,
    CLEAR_DEACTIVATE_CURRENCY,
    DEACTIVATE_CURRENCY_LOADING,
    DEACTIVATE_CURRENCY_ERROR,
    DEACTIVATE_CURRENCY
} 
from '../actions/types';

const initialState = {
    dectivateCurrency: null,
    dectivateCurrencyLoading: false,
    dectivateCurrencyError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_DEACTIVATE_CURRENCY_ERROR:
            return {
                ...state,
                deactivateCurrencyLoading: false,
                deactivateCurrencyError: action.payload
            }
        case CLEAR_DEACTIVATE_CURRENCY:
            return {
                ...state,
                deactivateCurrency: null,
                deactivateCurrencyLoading: false,
                deactivateCurrencyError: null
            }
        case DEACTIVATE_CURRENCY_LOADING:
            return {
                ...state,
                deactivateCurrencyLoading: true,
                deactivateCurrencyError: null
            }
        case DEACTIVATE_CURRENCY_ERROR:
            return {
                ...state,
                deactivateCurrencyLoading: false,
                deactivateCurrencyError: action.payload
            }
        case DEACTIVATE_CURRENCY:
            return {
                ...state,
                deactivateCurrency: action.payload,
                deactivateCurrencyLoading: false,
                deactivateCurrencyError: null
            }
        default: return state
    }
}