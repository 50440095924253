import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../store/actions';
import halfCircle from '../../../assets/loaders/785.gif';

function ConfigFee() {
    const dispatch = useDispatch();
    const configState = useSelector(configState => configState.feeConfigReducer);
    const updateFeeState = useSelector(updateFeeState => updateFeeState.updateFeeConfigReducer);

    React.useEffect(() => {
        dispatch(actions.getFeeConfig());
    }, [dispatch, updateFeeState.updateFeeConfig]);

    const handleChange = (e) => {
        let data = (e.target.name === 'flat') ? {flat: true, percentage: false} : { flat: false, percentage: true };
        data.id = configState.feeConfig.data.id;
        dispatch(actions.clearUpdateFeeConfig());
        dispatch(actions.updateFeeConfig(data));
    }

    return (
        <div>
            <div className="flex">
                <div>
                    {updateFeeState.updateFeeConfigLoading &&
                    <div className="flex items-center mb-3">
                        <img src={halfCircle} alt="loader" className="h-4" />
                        <p className="px-2 italic">Processing</p>
                    </div>}
                    <div className="form-check">
                        <input name="flat" onChange={handleChange} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" id="flexRadioDefault1" checked={configState.feeConfig && configState.feeConfig.data.flat} />
                        <label className="form-check-label inline-block text-gray-800" for="flexRadioDefault1">
                            Flat Fee
                        </label>
                    </div>
                    <div className="form-check">
                        <input name="percentage" onChange={handleChange} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" id="flexRadioDefault2" checked={configState.feeConfig && configState.feeConfig.data.percentage} />
                        <label className="form-check-label inline-block text-gray-800" for="flexRadioDefault2">
                            Percentage
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfigFee