import {
    CLEAR_GRAPHICAL_REPORT_ERROR,
    CLEAR_GRAPHICAL_REPORT,
    GRAPHICAL_REPORT_LOADING,
    GRAPHICAL_REPORT_ERROR,
    GRAPHICAL_REPORT
} from '../actions/types';

const initialState = {
    summary: null,
    summaryLoading: false,
    summaryError: null,
}

const graphicalSummaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GRAPHICAL_REPORT:
            return {
                ...state,
                summary: action.payload,
                summaryLoading: false,
                summaryError: null,
            }
        case GRAPHICAL_REPORT_ERROR:
            return {
                ...state,
                summaryLoading: false,
                summaryError: action.payload,
            }
        case GRAPHICAL_REPORT_LOADING:
            return {
                ...state,
                summaryLoading: true,
                summaryError: null,
            }
        case CLEAR_GRAPHICAL_REPORT:
            return {
                ...state,
                summary: null,
                summaryLoading: false,
                summaryError: null,
            }
        case CLEAR_GRAPHICAL_REPORT_ERROR:
            return {
                ...state,
                summaryLoading: false,
                summaryError: null,
            }
        default: return state
    }
}

export default graphicalSummaryReducer