import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../../../store/actions';
import FormikController from '../../Formik/FormikController';
import * as Yup from "yup";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loader from '../../../assets/loaders/382.gif';
import { Formik, Form } from "formik";

function AddMessage(props) {
    const dispatch = useDispatch();
    const {state,setState} = props;
    const {new_message_config, new_message_config_error, new_message_config_loading} = useSelector(addMessageConfig => addMessageConfig.addMessageConfigReducer);
    const {update_message_config, update_message_configLoading, update_message_configError} = useSelector(updateMessage =>updateMessage.updateMessageConfigReducer);

    React.useEffect(()=>{
    setState(null);
    },[update_message_config,new_message_config])

    const INITIAL_STATE = {
        tag: state ? state.tag :"",
        messageType: state ? state.messageType : "",
        text: state ? state.text: "",
       
    }

    const VALIDATION_SCHEMA = Yup.object({
        tag: Yup.string().required('This field is required'),
        messageType: Yup.string().required('This field is required'),
        text: Yup.string().required('This field is required'),
    })

    const handleSubmit = (values) => {
       values.id = state ? state.id : null;
       state ? dispatch(actions.updateMessageConfig(values)): dispatch(actions.addMessageConfig(values));
    }

    const keywords = ['{remitter} ','{currency} ','{fullamount} ','{charges} ','{beneficiary} ','{orderNumber} ']

    const messageType = [
        {
            key: 'SMS',
            value: 'SMS'
        },
        {
            key: 'Email',
            value: 'EMAIL'
        },
    ];

    
    const messageTag = [
        {
            key: 'CashIn Remitter',
            value: 'cashin_remitter'
        },
        {
            key: 'CashIn Beneficiary',
            value: 'cashin_beneficiary'
        },
        {
            key: 'CashOut Remitter',
            value: 'cashout_remitter'
        },
        {
            key: 'CashOut Beneficiary',
            value: 'cashout_beneficiary'
        },
        {
            key: 'OnHold Remitter',
            value: 'onhold_remitter'
        },
        {
            key: 'OnHold Beneficiary',
            value: 'onhold_beneficiary'
        },
        {
            key: 'Released Remitter',
            value: 'released_remitter'
        },
        {
            key: 'Released Beneficiary',
            value: 'released_beneficiary'
        },
        {
            key: 'Reversal Remitter',
            value: 'reversal_remitter'
        },
        {
            key: 'Reversal Beneficiary',
            value: 'reversal_beneficiary'
        },
    ];

    
  return (
    <div className="p-5">
        <h3 className="font-medium">{state ? 'Update Message Configuration' : "Add Message Configuration"}</h3>
        <div className="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
           <span className="font-medium"></span>Key Words to use for dynamic data - {keywords}
        </div>
        {new_message_config_error &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                   {new_message_config_error}
                </div>
                <button onClick={()=>dispatch(actions.clearAddMessageConfigError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>}

            {update_message_configError &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                    {update_message_configError}
                </div>
                <button onClick={dispatch(actions.clearUpdateMessageConfigError())}type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>}
        <div>
            <Formik
                initialValues={INITIAL_STATE}
                validationSchema={VALIDATION_SCHEMA}
                enableReinitialize
                onSubmit={(values, {resetForm}) => {
                    handleSubmit(values)
                    resetForm();
                }}
            >
                <Form>
                    <div className="grid grid-cols-2 gap-4 my-3">
                        <div className="">
                            <FormikController
                                control='select'
                                type='text'
                                options={messageType}
                                label='Message Type'
                                name='messageType'
                            />
                        </div>
                        <div className="">
                            <FormikController
                                control='select'
                                type='text'
                                label='Tag'
                                name='tag'
                                options ={messageTag}
                            />
                        </div>
                    </div>
                    <div className="">
                            <FormikController
                                control='textArea'
                                type='text'
                                label='Message Text'
                                name='text'
                                placeholder='eg. You received {currency}{fullamount} from {remitter}. Your ref number is {orderNumber}'
                            />
                        </div>
                    <div className="text-right mt-2">
                    {new_message_config_loading || update_message_configLoading ? <button className="bg-gray-500 rounded px-8 py-1"><img className="h-8" src={loader} alt="loader" /></button> : 
                        <button type='submit' className="bg-green-600 text-white rounded px-3 py-2">{state ? 'Update' :'Submit'}</button>}
                    </div>
                </Form>
            </Formik>
        </div>
    </div>
  )
}


export default AddMessage