import {
    CLEAR_GET_FLOAT_ERROR,
    CLEAR_GET_FLOAT,
    GET_FLOAT_LOADING,
    GET_FLOAT_ERROR,
    GET_FLOAT
} from '../actions/types';

const initialState = {
    teller_float: null,
    teller_float_loading: false,
    teller_float_error: null
}

const getTellerFloatReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FLOAT:
            return {
                ...state,
                teller_float: action.payload,
                teller_float_loading: false,
                teller_float_error: null
            }
        case GET_FLOAT_ERROR:
            return {
                ...state,
                teller_float_loading: false,
                teller_float_error: action.payload
            }
        case GET_FLOAT_LOADING:
            return {
                ...state,
                teller_float_loading: true,
                teller_float_error: null
            }
        case CLEAR_GET_FLOAT:
            return {
                ...state,
                teller_float: null,
                teller_float_loading: false,
                teller_float_error: null
            }
        case CLEAR_GET_FLOAT_ERROR:
            return {
                ...state,
                teller_float_loading: false,
                teller_float_error: null
            }
        default: return state
    }
}

export default getTellerFloatReducer