import {
    CLEAR_DEACTIVATE_BRANCHES_ERROR,
    CLEAR_DEACTIVATE_BRANCHES,
    DEACTIVATE_BRANCHES_LOADING,
    DEACTIVATE_BRANCHES_ERROR,
    DEACTIVATE_BRANCHES
} 
from '../actions/types';

const initialState = {
    deactivateBranches: null,
    deactivateBranchesLoading: false,
    deactivateBranchesError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_DEACTIVATE_BRANCHES_ERROR:
            return {
                ...state,
                deactivateBranchesLoading: false,
                deactivateBranchesError: action.payload
            }
        case CLEAR_DEACTIVATE_BRANCHES:
            return {
                ...state,
                deactivateBranches: null,
                deactivateBranchesLoading: false,
                deactivateBranchesError: null
            }
        case DEACTIVATE_BRANCHES_LOADING:
            return {
                ...state,
                deactivateBranchesLoading: true,
                deactivateBranchesError: null
            }
        case DEACTIVATE_BRANCHES_ERROR:
            return {
                ...state,
                deactivateBranchesLoading: false,
                deactivateBranchesError: action.payload
            }
        case DEACTIVATE_BRANCHES:
            return {
                ...state,
                deactivateBranches: action.payload,
                deactivateBranchesLoading: false,
                deactivateBranchesError: null
            }
        default: return state
    }
}