import {
    CLEAR_ACTIVATE_TRANSACTION_FEE_ERROR,
    CLEAR_ACTIVATE_TRANSACTION_FEE,
    ACTIVATE_TRANSACTION_FEE_LOADING,
    ACTIVATE_TRANSACTION_FEE_ERROR,
    ACTIVATE_TRANSACTION_FEE
} 
from '../actions/types';

const initialState = {
    activateTransactionFee: null,
    activateTransactionFeeLoading: false,
    activateTransactionFeeError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_ACTIVATE_TRANSACTION_FEE_ERROR:
            return {
                ...state,
                activateTransactionFeeLoading: false,
                activateTransactionFeeError: action.payload
            }
        case CLEAR_ACTIVATE_TRANSACTION_FEE:
            return {
                ...state,
                activateTransactionFee: null,
                activateTransactionFeeLoading: false,
                activateTransactionFeeError: null
            }
        case ACTIVATE_TRANSACTION_FEE_LOADING:
            return {
                ...state,
                activateTransactionFeeLoading: true,
                activateTransactionFeeError: null
            }
        case ACTIVATE_TRANSACTION_FEE_ERROR:
            return {
                ...state,
                activateTransactionFeeLoading: false,
                activateTransactionFeeError: action.payload
            }
        case ACTIVATE_TRANSACTION_FEE:
            return {
                ...state,
                activateTransactionFee: action.payload,
                activateTransactionFeeLoading: false,
                activateTransactionFeeError: null
            }
        default: return state
    }
}