import React from 'react';
import Pagination from "react-js-pagination";
import { useDispatch,useSelector } from 'react-redux';
import * as actions from '../../../store/actions';

function ListFees(props) {
  const dispatch = useDispatch();
  const { setStore,handleEdit}=props;
  const {transaction_fees} = useSelector(addFee => addFee.addTransactionFeesReducer);
  const {updateTransactionFee} = useSelector(updateFee => updateFee.updateTransactionFeeReducer);
  const {activateTransactionFee} = useSelector(activeFee => activeFee.activateTransactionfeeReducer);
  const {deactivateTransactionFee} = useSelector(deactiveFee => deactiveFee.deactivateTransactionfeeReducer);
  const {get_transaction_fees} = useSelector(getFee =>getFee.getTransactionFeesReducer);
  const [page, setPage] = React.useState(1);
  const perPage = 6;

  React.useEffect(()=>{
    dispatch(actions.getTransactionFees({page,perPage}))
    setStore(null);
  },[transaction_fees,activateTransactionFee,deactivateTransactionFee,updateTransactionFee, page]);

  const handleChangeStatus = (fee) => {
    fee.status === 'ACTIVE' ? dispatch(actions.deactivateTransactionFee(fee)): dispatch(actions.activateTransactionFee(fee));
  }

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  }

  return (
    <div> 
    <div className="relative overflow-x-auto sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Corridor
            </th>
            <th scope="col" className="px-6 py-3">
              Cur
            </th>
            <th scope="col" className="px-6 py-3">
              Start Amount
            </th>
            <th scope="col" className="px-6 py-3">
              End Amount
            </th>
            <th scope="col" className="px-6 py-3">
              Fee
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
            <th scope="col" className="px-6 py-3">
             Action
            </th>
            <th scope="col" className="px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody>
        {(get_transaction_fees && get_transaction_fees.data.length > 0) ? get_transaction_fees.data.map(fee => (
            <tr key={fee.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="px-6 py-4">
              {fee.remittanceCorridor.charAt(0).toUpperCase() + fee.remittanceCorridor.slice(1).toLowerCase()}
            </td>
            <td className="px-6 py-4">
                {fee.currency.currencySymbol}
            </td>
            <td className="px-6 py-4">
                {fee.startAmount}
            </td>
            <td className="px-6 py-4">
                {fee.endAmount}
            </td>
            <td className="px-6 py-4">
                {fee.fee}
            </td>
         
            <td className="px-6 py-4">
            {fee.status.charAt(0).toUpperCase() + fee.status.slice(1).toLowerCase()}
            </td>
            <td className="px-6 py-4 ">
                {fee.status === 'ACTIVE' ? 
                <button onClick={() => handleChangeStatus(fee)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">De-activate</button> : 
                <button onClick={() => handleChangeStatus(fee)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Activate</button> }
            </td>
            <td className="px-6 py-4 text-right">
                <button onClick={() => handleEdit(fee)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</button>
            </td>
        </tr>
        ))  : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">No data found</td>
            </tr>}
          </tbody>
        </table>
      </div>
      <div className="p-4 flex justify-end">
       { (get_transaction_fees && get_transaction_fees.data.length > 0) && <Pagination
            activePage={page}
            itemsCountPerPage={perPage}
            totalItemsCount={get_transaction_fees.totalItemsCount}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
        />}
    </div>
    </div>
  )
}

export default ListFees