import {
    CLEAR_ACTIVE_FEE_ERROR,
    CLEAR_ACTIVE_FEE,
    ACTIVE_FEE_LOADING,
    ACTIVE_FEE_ERROR,
    ACTIVE_FEE
} from '../actions/types';

const initialState = {
    activeFee: null,
    activeFeeLoading: false,
    activeFeeError: null
}

const activeFeeReducer = (state = initialState, action) => {
    switch(action.type){
        case ACTIVE_FEE:
            return {
                ...state,
                activeFee: action.payload,
                activeFeeLoading: false,
                activeFeeError: null
            }
        case ACTIVE_FEE_ERROR:
            return {
                ...state,
                activeFeeLoading: false,
                activeFeeError: action.payload
            }
        case ACTIVE_FEE_LOADING:
            return {
                ...state,
                activeFeeLoading: true,
                activeFeeError: null
            }
        case CLEAR_ACTIVE_FEE:
            return {
                ...state,
                activeFee: null,
                activeFeeLoading: false,
                activeFeeError: null
            }
        case CLEAR_ACTIVE_FEE_ERROR:
            return {
                ...state,
                activeFeeLoading: false,
                activeFeeError: action.payload
            }
        default: return state
    }   
}

export default activeFeeReducer;