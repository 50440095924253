import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions';
import Pagination from "react-js-pagination";
import PageLoader from '../utils/PageLoader';

function BranchTables(props) {
    const dispatch = useDispatch();
    const { setStore } = props;
    const { remitters, remitters_loading, remitters_error } = useSelector(getRemitters => getRemitters.getRemittersReducer);
    const { updateRemitter, updateRemitterLoading, updateRemitterError } = useSelector(updateRemit => updateRemit.updateRemittersReducer);
    const { activateRemitters, activateRemittersLoading, activateRemittersError } = useSelector(activeRemitter => activeRemitter.activateRemittersReducer);
    const { deactivateRemitters, deactivateRemittersLoading, deactivateRemittersError } = useSelector(deactiveRemitters => deactiveRemitters.deactivateRemittersReducer);

    const itemsCountPerPage = 15;

    const [page, setPage] = React.useState(1);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    React.useEffect(() => {
        dispatch(actions.getRemitters({
            page,
            perPage: itemsCountPerPage
        }));

    }, [page, activateRemitters, deactivateRemitters]);

    const handleEdit = (remitter) => {
        setStore(remitter);
    }

    const handleChangeStatus = (remitter) => {
        remitter.status === 'ACTIVE' ? dispatch(actions.deactivateRemitters(remitter)) : dispatch(actions.activateRemitters(remitter));
    }

    let content = (remitters_loading) ? <PageLoader /> : (remitters) && <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="px-6 py-3">
                    Name
                </th>
                <th scope="col" className="px-6 py-3">
                    Address
                </th>
                <th scope="col" className="px-6 py-3">
                    City
                </th>
                <th scope="col" className="px-6 py-3">
                    Province
                </th>
                <th scope="col" className="px-6 py-3">
                    Email
                </th>
                <th scope="col" className="px-6 py-3">
                    Phone Number
                </th>
                <th scope="col" className="px-6 py-3">
                    Status
                </th>
                <th scope="col" className="px-10 py-3">
                    Action
                </th>
                <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Edit</span>
                </th>
            </tr>
        </thead>
        <tbody>
            {(remitters && remitters.data.length > 0) ? remitters.data.map(remitter => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">

                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {remitter.firstname + ' ' + remitter.lastname}
                    </th>
                    <td className="px-6 py-4">
                        {remitter.address}
                    </td>
                    <td className="px-6 py-4">
                        {remitter.city}
                    </td>
                    <td className="px-6 py-4">
                        {remitter.province}
                    </td>
                    <td className="px-6 py-4">
                        {remitter.email}
                    </td>
                    <td className="px-6 py-4">
                        {remitter.mobile}
                    </td>
                    <td className="px-6 py-4">
                        {remitter.status.charAt(0).toUpperCase() + remitter.status.slice(1).toLowerCase()}
                    </td>
                    <td className="px-6 py-4 ">
                        {remitter.status === 'ACTIVE' ?
                            <button onClick={() => handleChangeStatus(remitter)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">De-activate</button> :
                            <button onClick={() => handleChangeStatus(remitter)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Activate</button>}
                    </td>
                    <td className="px-6 py-4 text-right">
                        <a href="#" onClick={() => handleEdit(remitter)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                    </td>

                </tr>
            )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td colSpan="8" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">No data found</td>
        </tr>}
        </tbody>
    </table>

    return (
        
        <div>
            <div className="overflow-x-auto border sm:rounded-lg">
                {content}
                {(remitters && remitters.data.length > 0 && !remitters_loading) &&
                    <div className="p-4 flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={remitters.totalItemsCount}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                    </div>}
            </div></div>
    )
}



export default BranchTables;