import React from 'react'
import AddFees from './Fees/AddFees';
import ListFees from './Fees/ListFees';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions';
import ConfigFee from './Fees/ConfigFee';

function TransactionFees() {
    const dispatch = useDispatch();
    const [active, setActive] = React.useState('list');
    const [store, setStore] = React.useState(null);
    const { transaction_fees } = useSelector(addFee => addFee.addTransactionFeesReducer);
    const { updateTransactionFee } = useSelector(updateFee => updateFee.updateTransactionFeeReducer);

    const handleEdit = (fee) => {
        setStore(fee);
        setActive('add');
    }

    const handleSubmit = (values) => {
        values.id = store ? store.id :null;
        store ? dispatch(actions.updateTransactionFee(values)): dispatch(actions.addTransactionFees(values));
    }

    React.useEffect(() => {
        if(transaction_fees || updateTransactionFee){
            setActive('list');
            dispatch(actions.clearAddTransactionFees());
            dispatch(actions.clearUpdateTransactionFee());
        }
    }, [transaction_fees, updateTransactionFee, dispatch]);

    let content;
    switch (active) {
        case 'list':
            content = <ListFees setStore={setStore} setActive={setActive} handleEdit={handleEdit}/>
            break;
        case 'add':
            content = <AddFees store={store} handleSubmit={handleSubmit} />
            break;
        case 'config':
            content = <ConfigFee />
            break;
        default:
            break;
    }

    return (
        <div style={{height: '520px'}} className="max-h-fit overflow-y-auto rounded-md bg-white shadow hover:shadow-md ease-in-out duration-700">
            <div className="mb-4 border-b border-gray-200 dark:border-gray-700 px-2">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                    <li className="mr-2" role="presentation">
                        <button onClick={() => setActive('list')} className={`"inline-block p-4 rounded-t-lg text-md" ${(active) === 'list' ? "font-medium border-b-2" : "font-normal border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}`} id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">List Transaction Fees</button>
                    </li>
                    <li className="mr-2" role="presentation">
                        <button onClick={() => setActive('add')} className={`"inline-block p-4 rounded-t-lg text-md" ${(active) === 'add' ? "font-medium border-b-2" : "font-normal hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}`} id="dashboard-tab" data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false" >{(store) ? 'Update' :'Add New'} Fees</button>
                    </li>
                    <li className="mr-2" role="presentation">
                        <button onClick={() => setActive('config')} className={`"inline-block p-4 rounded-t-lg text-md" ${(active) === 'config' ? "font-medium border-b-2" : "font-normal border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}`} id="config-tab" data-tabs-target="#config" type="button" role="tab" aria-controls="config" aria-selected="false">Fee Configuration</button>
                    </li>
                </ul>
            </div>
            <div id="myTabContent">
                <div className="p-4">
                    {content}
                </div>
            </div>
        </div>
    )
}

export default TransactionFees