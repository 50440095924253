import React from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from "react-js-pagination";
import handleDateFormat from '../../utils/handleDateFormat';

function ReconciliationTable(props) {
  const history = useHistory();

  const handleOpenMore = (id) => {
    history.push(`/reports/${id}`);
  }

  const handlePageChange = (pageNumber) => {
    props.setPage(pageNumber);
  }

  return (
    <React.Fragment>
      <h1 className="text-lg font-medium ml-1 my-4">Reconciliations</h1>
      <div className="overflow-x-auto border sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Branch Name
              </th>
              <th scope="col" className="px-6 py-3">
                Cash In
              </th>
              <th scope="col" className="px-6 py-3">
                Cash Out
              </th>
              <th scope="col" className="px-6 py-3">
                Starting Balance
              </th>
              <th scope="col" className="px-6 py-3">
                Ending Balance
              </th>
              <th scope="col" className="px-6 py-3">
                Received Cash In Hand
              </th>
              <th scope="col" className="px-6 py-3">
                Teller
              </th>
              <th scope="col" className="px-6 py-3">
                Teller's Cash In Hand
              </th>
              <th scope="col" className="px-6 py-3">
                Short Fall
              </th>
              <th scope="col" className="px-6 py-3">
                Supervisor
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Created At
              </th>
              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {(props.reportsState.reports && props.reportsState.reports.data.data.length > 0) ? props.reportsState.reports.data.data.map((el) => (
              <tr key={el.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  {el.branch?.branchName}
                </th>
                <td className="px-6 py-4">
                  {el.currency.currencySymbol} {el.cashIn}
                </td>
                <td className="px-6 py-4">
                  {el.currency.currencySymbol} {el.cashOut}
                </td>
                <td className="px-6 py-4">
                  {el.currency.currencySymbol} {el.startingBalance}
                </td>
                <td className="px-6 py-4">
                  {el.currency.currencySymbol} {el.endingBalance}
                </td>
                <td className="px-6 py-4">
                  {el.receivedcashInHand ? el.currency.currencySymbol + " " + el.receivedcashInHand : 'None'}
                </td>
                <td className="px-6 py-4">
                  {el.teller?.firstname} {el.teller?.middlename} {el.teller?.lastname}
                </td>
                <td className="px-6 py-4">
                  {el.currency.currencySymbol} {el.tellercashInHand}
                </td>
                <td className="px-6 py-4">
                  {el.shortfall ? el.currency.currencySymbol + " " + el.shortfall : 'None'}
                </td>
                <td className="px-6 py-4">
                  {el.supervisor ? el.supervisor.firstname + " " + el.supervisor.middlename + " " + el.supervisor.lastname : 'None'}
                </td>
                <td className="px-6 py-4">
                  {el.status}
                </td>
                <td className="px-6 py-4">
                  {handleDateFormat(el.createdAt)}
                </td>
                <td className="px-6 py-4 text-right">
                  <button onClick={() => handleOpenMore(el.id)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">More</button>
                </td>
              </tr>
            )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan="13" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">No Data Found</td>
              </tr>}
            
          </tbody>
        </table>
      </div>
      {(props.reportsState.reports &&  props.reportsState.reports.data && props.reportsState.reports.data.data.length > 0) &&
      <div className="py-4 flex justify-end">
          <Pagination
              activePage={props.page}
              itemsCountPerPage={props.perPage}
              totalItemsCount={props.reportsState.reports.data.totalItemsCount}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
          />
      </div>}
    </React.Fragment>

  )
}

export default ReconciliationTable