import React from 'react';
import ProcessReconciliation from './ProcessReconciliation';
import ReconciliationDetails from './ReconciliationDetails';

function ViewMore() {    
    return (
        <div className="grid grid-cols-12 gap-4">
            <div className="col-span-9 p-8 overflow-x-hidden">
                <ReconciliationDetails />
            </div>
            <div className="col-span-3 border-l min-h-screen p-8">
                <ProcessReconciliation />
            </div>
        </div>
    )
}

export default ViewMore