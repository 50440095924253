import {
    CLEAR_UPDATE_FLOAT_ERROR,
    CLEAR_UPDATE_FLOAT,
    UPDATE_FLOAT_LOADING,
    UPDATE_FLOAT_ERROR,
    UPDATE_FLOAT
} from '../actions/types';

const initialState = {
    update_float: null,
    update_float_error: null,
    update_float_loading: false
}

const updateFloatReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_FLOAT:
            return {
                ...state,
                update_float: action.payload,
                update_float_error: null,
                update_float_loading: false
            }
        case UPDATE_FLOAT_ERROR:
            return {
                ...state,
                update_float_error: action.payload,
                update_float_loading: false
            }
        case UPDATE_FLOAT_LOADING:
            return {
                ...state,
                update_float_error: null,
                update_float_loading: true
            }
        case CLEAR_UPDATE_FLOAT:
            return {
                ...state,
                update_float: null,
                update_float_error: null,
                update_float_loading: false
            }
        case CLEAR_UPDATE_FLOAT_ERROR:
            return {
                ...state,
                update_float_error: null,
                update_float_loading: false
            }
        default: return state
    }
}

export default updateFloatReducer