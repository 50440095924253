import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../../../store/actions';
import FormikController from '../../Formik/FormikController';
import * as Yup from "yup";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loader from '../../../assets/loaders/382.gif';
import { Formik, Form } from "formik";

function AddLimits(props) {
    const dispatch = useDispatch();
    const {state,setState} = props;
    const {new_transaction_limit, new_transaction_limit_error, new_transaction_limit_loading} = useSelector(addTransactionLimit => addTransactionLimit.addTransactionLimitReducer);
    const {updateTransactionLimit, updateTransactionLimitLoading, updateTransactionLimitError} = useSelector(updateLimit =>updateLimit.updateTransactionLimitReducer);
    const {get_currency} = useSelector(getCurrency => getCurrency.getCurrencyReducer);


    React.useEffect(()=>{
    setState(null);
    },[updateTransactionLimit,new_transaction_limit])

    const INITIAL_STATE = {
        remittanceCorridor: state ? state.remittanceCorridor :"",
        currency: state ? state.currency.id : "",
        minimumAmountPerTransaction: state ? state.minimumAmountPerTransaction: "",
        maximumAmountPerTransaction: state ? state.maximumAmountPerTransaction: "",
        maximumAmountPerDay: state ? state.maximumAmountPerDay:"",
        maximumAmountPerMonth: state ? state.maximumAmountPerMonth:""
    }

    const VALIDATION_SCHEMA = Yup.object({
        remittanceCorridor: Yup.string().required('This field is required'),
        currency: Yup.string().required('This field is required'),
        minimumAmountPerTransaction: Yup.number().required('This field is required'),
        maximumAmountPerTransaction: Yup.number().required('This field is required'),
        maximumAmountPerDay: Yup.number().required('This field is required'),
        maximumAmountPerMonth: Yup.number().required('This field is required'),
    })

    const handleSubmit = (values) => {
       values.id = state ? state.id : null;
       state ? dispatch(actions.updateTransactionLimit(values)): dispatch(actions.addTransactionLimit(values));
    }

    const corridor = [
        {
            key: 'Local',
            value: 'LOCAL'
        },
        {
            key: 'International',
            value: 'INTERNATIONAL'
        },
    ];

    let currency = get_currency ? get_currency.data.map(cxt => {
        let obj = {
            key: cxt.currencyName +" "+ '('+ cxt.currencySymbol + ')',
            value: cxt.id
        }

        return obj;
    }) : [];

    
  return (
    <div className="p-5">
        <h3 className="font-medium">{state ? 'Update Transaction Limit' : "Add Transaction Limit"}</h3>
        {new_transaction_limit_error &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                   {new_transaction_limit_error}
                </div>
                <button onClick={()=>dispatch(actions.clearAddTransactionLimitError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>}

            {updateTransactionLimitError &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                    {updateTransactionLimitError}
                </div>
                <button onClick={dispatch(actions.clearUpdateTransactionLimitError())}type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>}
        <div>
            <Formik
                initialValues={INITIAL_STATE}
                validationSchema={VALIDATION_SCHEMA}
                enableReinitialize
                onSubmit={(values, {resetForm}) => {
                    handleSubmit(values)
                    resetForm();
                }}
            >
                <Form>
                    <div className="grid grid-cols-2 gap-4 my-3">
                        <div className="">
                            <FormikController
                                control='select'
                                type='text'
                                options={corridor}
                                label='Remittance Corridor'
                                name='remittanceCorridor'
                            />
                        </div>
                        <div className="">
                            <FormikController
                                control='select'
                                type='text'
                                label='Currency'
                                name='currency'
                                options ={currency}
                            />
                        </div>
                        <div className="">
                            <FormikController
                                control='input'
                                type='text'
                                label='Minimum Amount'
                                name='minimumAmountPerTransaction'
                            />
                        </div>
                        <div className="">
                            <FormikController
                                control='input'
                                type='text'
                                label='Per Transaction'
                                name='maximumAmountPerTransaction'
                            />
                        </div>
                        <div className="">
                            <FormikController
                                control='input'
                                type='text'
                                label='Per Day'
                                name='maximumAmountPerDay'
                            />
                        </div>
                        <div className="">
                            <FormikController
                                control='input'
                                type='text'
                                label='Per Month'
                                name='maximumAmountPerMonth'
                            />
                        </div>
                    </div>
                    <div className="text-right">
                    {new_transaction_limit_loading || updateTransactionLimitLoading ? <button className="bg-gray-500 rounded px-8 py-1"><img className="h-8" src={loader} alt="loader" /></button> : 
                        <button type='submit' className="bg-green-600 text-white rounded px-3 py-2">{state ? 'Update' :'Submit'}</button>}
                    </div>
                </Form>
            </Formik>
        </div>
    </div>
  )
}


export default AddLimits