import {CLEAR_ADD_RECONCILIATIONS_ID_PROCESS, CLEAR_ADD_RECONCILIATIONS_ID_PROCESS_ERROR, ADD_RECONCILIATIONS_ID_PROCESS_LOADING, ADD_RECONCILIATIONS_ID_PROCESS_ERROR, ADD_RECONCILIATIONS_ID_PROCESS} from '../actions/types';

const initialState = {
    new_recon_id_process: null,
    new_recon_id_process_error: null,
    new_recon_id_process_loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ADD_RECONCILIATIONS_ID_PROCESS:
            return {
                ...state,
                new_recon_id_process: null,
                new_recon_id_process_error: null,
                new_recon_id_process_loading: false
            }
        case CLEAR_ADD_RECONCILIATIONS_ID_PROCESS_ERROR:
            return {
                ...state,
                new_recon_id_process_error: null,
                new_recon_id_process_loading: false
            }
        case ADD_RECONCILIATIONS_ID_PROCESS_LOADING:
            return {
                ...state,
                new_recon_id_process_error: null,
                new_recon_id_process_loading: true
            }
        case ADD_RECONCILIATIONS_ID_PROCESS_ERROR:
            return {
                ...state,
                new_recon_id_process_error: action.payload,
                new_recon_id_process_loading: false
            }
        case ADD_RECONCILIATIONS_ID_PROCESS:
            return {
                ...state,
                new_recon_id_process: action.payload,
                new_recon_id_process_error: null,
                new_recon_id_process_loading: false
            }
        default: return state
    }
}