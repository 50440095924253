import {GET_RECONCILIATIONS_CURRENT_TELLER, GET_RECONCILIATIONS_CURRENT_TELLER_ERROR, GET_RECONCILIATIONS_CURRENT_TELLER_LOADING, CLEAR_GET_RECONCILIATIONS_CURRENT_TELLER_ERROR, CLEAR_GET_RECONCILIATIONS_CURRENT_TELLER} from '../actions/types';

const initialState = {
    recon_current_teller: null,
    recon_current_teller_loading: false,
    recon_current_teller_error: null
}

export default function getReconciliationsCurrentTellerReducer(state = initialState, action){
    switch(action.type){
        case GET_RECONCILIATIONS_CURRENT_TELLER:
            return {
                ...state,
                recon_current_teller: action.payload,
                recon_current_teller_loading: false,
                recon_current_teller_error: null
            }
        case GET_RECONCILIATIONS_CURRENT_TELLER_ERROR:
            return {
                ...state,
                recon_current_teller_loading: false,
                recon_current_teller_error: action.payload
            }
        case GET_RECONCILIATIONS_CURRENT_TELLER_LOADING:
            return {
                ...state,
                recon_current_teller_loading: true,
                recon_current_teller_error: null
            }
        case CLEAR_GET_RECONCILIATIONS_CURRENT_TELLER_ERROR:
            return {
                ...state,
                recon_current_teller_loading: false,
                recon_current_teller_error: null
            }
        case CLEAR_GET_RECONCILIATIONS_CURRENT_TELLER:
            return {
                ...state,
                recon_current_teller: null,
                recon_current_teller_loading: false,
                recon_current_teller_error: null
            }
        default: return state
    }
}