import React from 'react'
import Pagination from "react-js-pagination";
import handleDateFormat from '../../utils/handleDateFormat';
import FormikController from '../../Formik/FormikController';
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions';
import * as Yup from "yup";
import PageLoader from '../../utils/PageLoader';
import handleSimpleDateFormat from '../../utils/handleDateSimpleFormat';

function ReportSummary() {
    const dispatch = useDispatch();
    const { partner_branches } = useSelector(getBranches => getBranches.getPartnerBranchesReducer);
    const summaryState = useSelector(summaryState => summaryState.reportSummaryReducer);
    const partnerState = useSelector(partnerState => partnerState.getPartnersReducer);
    const branchState = useSelector(branchState => branchState.getBranchesReducer);
    const [page, setPage] = React.useState(1);
    const [partnerId, setPatnerId] = React.useState('');
    const [type, setType] = React.useState('INHOUSE');
    const perPage = 15;

    let partner = partnerState.partner && partnerState.partner.data.length > 0 ? partnerState.partner.data.map(cxt => {
        let obj = cxt.status === 'ACTIVE' && {
            key: cxt.OrganizationName,
            value: cxt.id
        }

        return obj;
    }) : [];

    let branch = partner_branches && partner_branches.data.length > 0 ? partner_branches.data.map(cxt => {
        let obj = cxt.status === 'ACTIVE' && {
            key: cxt.branchName,
            value: cxt.id
        }

        return obj;
    }) : [];

    let inhouseBranch = branchState.branch && branchState.branch.data.length > 0 ? branchState.branch.data.map(cxt => {
        let obj = cxt.status === 'ACTIVE' && {
            key: cxt.branchName,
            value: cxt.id
        }

        return obj;
    }) : [];

    React.useEffect(() => {
        dispatch(actions.getBranch({page: 1, perPage: 500}));
    }, [dispatch])

    const handleUpdatePartner = (id) => {
        setPatnerId(id);
        dispatch(actions.clearGetPartnerBranches());
    }

    React.useEffect(() => {
        if(partnerId.length>0){
            let data = {
                page,
                perPage: 500,
                partnerId
            }
            dispatch(actions.getPartnerBranches(data));
        }
    }, [partnerId, dispatch]);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    const TYPES = [
        {
            key: "In-house",
            value: "INHOUSE"
        },
        {
            key: 'Partner',
            value: "PARTNER"
        }
    ]

    const ACTION = [
        {
            key: 'Cash In',
            value: "Cash In"
        },
        {
            key: 'Cash Out',
            value: "Cash Out"
        },
        {
            key: 'Transaction Reversal',
            value: 'Transaction Reversal'
        }
    ]

    React.useEffect(() => {
        dispatch(actions.getPartners({page: 1, perPage: 500}))
    }, [dispatch]);

    const INITIAL_VALUE = {
        page: page,
        perPage: perPage,
        type: type,
        partner: partnerId,
        branch: '',
        action: 'Cash In',
        start: handleSimpleDateFormat(-30),
        end: handleSimpleDateFormat(0),
    }

    const VALIDATION_SCHEMA = Yup.object().shape({
        page: Yup.number().required('Required'),
        perPage: Yup.number().required('Required'),
        type: Yup.string().required('Required'),
        partner: Yup.string(),
        branch: Yup.string(),
        action: Yup.string().required('Required'),
        start: Yup.string().required('Required'),
        end: Yup.string().required('Required'),
    });

    console.log(summaryState.summary)

    const handleSubmit = (values) => {
        values.page = page;
        values.perPage = perPage;
        values.filter = (values.branch.length > 0) ? 'BRANCH' : '';
        dispatch(actions.summaryReport(values))
    }

    React.useEffect(() => {
        dispatch(actions.summaryReport({
            page,
            perPage,
            type,
            filter: '', // Pass string BRANCH in caps
            partner: partnerId,
            branch: '',
            action: 'Cash In', //{'Cash In', 'Cash Out', 'Transaction Reversal}
            start: handleSimpleDateFormat(-30),
            end: handleSimpleDateFormat(0),
        }));
        dispatch(actions.clearGetPartnerBranches());
    }, []);

    let content = (summaryState.summaryLoading) ? <PageLoader /> : (summaryState.summary) && <div className="my-4">
        <div className="flex justify-between items-center ml-1 mb-3">
            <div>
                <h1 className="text-lg font-medium">Report Summary</h1>
            </div>
            <div>
                <Formik
                    initialValues={INITIAL_VALUE}
                    validationSchema={VALIDATION_SCHEMA}
                    enableReinitialize
                    onSubmit={(values, {resetForm}) => {
                        resetForm();
                        handleSubmit(values)
                    }}
                >
                    {({setFieldValue}) => {
                        return (
                            <Form>
                                <div className="flex items-end justify-end">
                                    <div className="ml-2">
                                        <FormikController
                                            control='select'
                                            type='text'
                                            options={TYPES}
                                            onChange={(e) => setFieldValue('type', setType(e.target.value))}
                                            label='Type'
                                            name='type'
                                        />
                                    </div>
                                    <div className="ml-2">
                                        <FormikController
                                            control='select'
                                            type='text'
                                            options={ACTION}
                                            label='Action'
                                            name='action'
                                        />
                                    </div>
                                    <div className={`ml-2 ${type === 'PARTNER' ? 'block' : 'hidden'}`}>
                                        <FormikController
                                            control='select'
                                            type='text'
                                            options={partner}
                                            onChange={(e) => setFieldValue('partner', handleUpdatePartner(e.target.value))}
                                            label='Partner'
                                            name='partner'
                                        />
                                    </div>
                                    <div className={`ml-2 ${(type === 'PARTNER' && partnerId.length === 0) ? 'hidden' : 'block'}`}>
                                        <FormikController
                                            control='select'
                                            type='text'
                                            options={type === 'INHOUSE' ? inhouseBranch : branch}
                                            label='Branch'
                                            name='branch'
                                        />
                                    </div>
                                    <div className="ml-2">
                                        <FormikController
                                            control='input'
                                            type='date'
                                            label='From'
                                            name='start'
                                        />
                                    </div>
                                    <div className="ml-2">
                                        <FormikController
                                            control='input'
                                            type='date'
                                            label='To'
                                            name='end'
                                        />
                                    </div>
                                    <div className="ml-2">
                                        <button type="submit" className="bg-green-600 text-white rounded px-3 py-2">Filter</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
        <div className="overflow-x-auto border sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Branch
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Processed Commission
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Reversed Commission
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Amount Processed
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Revenue
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Transactions Completed
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Date Created
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {(summaryState.summary && summaryState.summary.data.summary.length > 0) ? summaryState.summary.data.summary.map((el, index) => (
                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                {el.action}
                            </th>
                            <td className="px-6 py-4">
                                {el.branch}
                            </td>
                            <td className="px-6 py-4">
                                {el.currency} {el.commission ? el.commission : '0.00'}
                            </td>
                            <td className="px-6 py-4">
                                {el.currency} {el.reversed_commission ? el.reversed_commission : '0.00'}
                            </td>
                            <td className="px-6 py-4">
                                {el.currency} {el.processed}
                            </td>
                            <td className="px-6 py-4">
                                {el.currency} {el.revenue}
                            </td>
                            <td className="px-6 py-4">
                                {el.transactions}
                            </td>
                            <td className="px-6 py-4">
                                {handleDateFormat(el.date)}
                            </td>
                        </tr>
                    )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td colSpan="8" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">No Data Found</td>
                    </tr>}
                </tbody>
            </table>
        </div>
        {(summaryState.summary && summaryState.summary.data.summary.length > 0 && !summaryState.summaryLoading) &&
            <div className="py-4 flex justify-end">
                <Pagination
                    activePage={page}
                    itemsCountPerPage={perPage}
                    totalItemsCount={summaryState.summary.data.totalItemsCount}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                />
            </div>}
    </div>

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    )
}

export default ReportSummary