import {
    CLEAR_GET_REMIT_REASONS_ERROR,
    CLEAR_GET_REMIT_REASONS,
    GET_REMIT_REASONS_LOADING,
    GET_REMIT_REASONS_ERROR,
    GET_REMIT_REASONS
} from '../actions/types';

const initialState = {
    remit_reason: null,
    remit_reason_loading: false,
    remit_reason_error: null
}

const getRemitReasonsReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_REMIT_REASONS:
            return {
                ...state,
                remit_reason: action.payload,
                remit_reason_loading: false,
                remit_reason_error: null
            }
        case GET_REMIT_REASONS_ERROR:
            return {
                ...state,
                remit_reason_loading: false,
                remit_reason_error: action.payload
            }
        case GET_REMIT_REASONS_LOADING:
            return {
                ...state,
                remit_reason_loading: true,
                remit_reason_error: null
            }
        case CLEAR_GET_REMIT_REASONS:
            return {
                ...state,
                remit_reason: null,
                remit_reason_loading: false,
                remit_reason_error: null
            }
        case CLEAR_GET_REMIT_REASONS_ERROR:
            return {
                ...state,
                remit_reason_loading: false,
                remit_reason_error: null
            }
        default: return state
    }
}

export default getRemitReasonsReducer