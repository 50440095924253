import React from 'react';
import ViewMessage from './MessageConfig/ViewMessage';
import AddMessage from './MessageConfig/AddMessage';

function MessageConfig() {

  const [state,setState] = React.useState(null);

  const handleEdit = (message) => {
    setState(message)
  }

  return (
    <div className="rounded-md overflow-y-auto max-h-fit bg-white shadow hover:shadow-md ease-in-out duration-700">
        <div className="grid grid-cols-12 gap-4">
            <div className="col-span-8 pt-5 pb-5 pl-5">
                <ViewMessage handleEdit={handleEdit}/>
            </div>
            <div className="col-span-4 border-l border-gray-300">
                <AddMessage state={state} setState={setState}/>
            </div>
        </div>
    </div>
  )
}

export default MessageConfig