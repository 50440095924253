import {
    CLEAR_UPDATE_TRANSACTION_FEE_ERROR,
    CLEAR_UPDATE_TRANSACTION_FEE,
    UPDATE_TRANSACTION_FEE_LOADING,
    UPDATE_TRANSACTION_FEE_ERROR,
    UPDATE_TRANSACTION_FEE} 
from '../actions/types';

const initialState = {
    updateTransactionFee: null,
    updateTransactionFeeLoading: false,
    updateTransactionFeeError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_TRANSACTION_FEE_ERROR:
            return {
                ...state,
                updateTransactionFeeLoading: false,
                updateTransactionFeeError: action.payload
            }
        case CLEAR_UPDATE_TRANSACTION_FEE:
            return {
                ...state,
                updateTransactionFee: null,
                updateTransactionFeeLoading: false,
                updateTransactionFeeError: null
            }
        case UPDATE_TRANSACTION_FEE_LOADING:
            return {
                ...state,
                updateTransactionFeeLoading: true,
                updateTransactionFeeError: null
            }
        case UPDATE_TRANSACTION_FEE_ERROR:
            return {
                ...state,
                updateTransactionFeeLoading: false,
                updateTransactionFeeError: action.payload
            }
        case UPDATE_TRANSACTION_FEE:
            return {
                ...state,
                updateTransactionFee: action.payload,
                updateTransactionFeeLoading: false,
                updateTransactionFeeError: null
            }
        default: return state
    }
}