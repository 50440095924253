import {
    GET_MESSAGE_CONFIG,
    GET_MESSAGE_CONFIG_ERROR,
    GET_MESSAGE_CONFIG_LOADING,
    CLEAR_GET_MESSAGE_CONFIG_ERROR,
    CLEAR_GET_MESSAGE_CONFIG
} from '../actions/types';

const initialState = {
    message_config: null,
    message_config_loading: false,
    message_config_error: null,
}

export default function (state = initialState, action){
    switch(action.type){
        case GET_MESSAGE_CONFIG:
            return {
                ...state,
                message_config: action.payload,
                message_config_loading: false,
                message_config_error: null,
            }
        case GET_MESSAGE_CONFIG_ERROR:
            return {
                ...state,
                message_config_loading: false,
                message_config_error: action.payload,
            }
        case GET_MESSAGE_CONFIG_LOADING:
            return {
                ...state,
                message_config_loading: true,
                message_config_error: null,
            }
        case CLEAR_GET_MESSAGE_CONFIG_ERROR:
            return {
                ...state,
                message_config_loading: false,
                message_config_error: null,
            }
        case CLEAR_GET_MESSAGE_CONFIG:
            return {
                ...state,
                message_config: null,
                message_config_loading: false,
                message_config_error: null,
            }
        default: return state
    }
}