import {GET_ADMIN_AUDIT_TRAIL, GET_ADMIN_AUDIT_TRAIL_ERROR, GET_ADMIN_AUDIT_TRAIL_LOADING, CLEAR_GET_ADMIN_AUDIT_TRAIL_ERROR, CLEAR_GET_ADMIN_AUDIT_TRAIL} from '../actions/types';

const initialState = {
    audit_trail: null,
    audit_trail_loading: false,
    audit_trail_error: null
}

export default function getAdminAuditTrailReducer(state = initialState, action){
    switch(action.type){
        case GET_ADMIN_AUDIT_TRAIL:
            return {
                ...state,
                audit_trail: action.payload,
                audit_trail_loading: false,
                audit_trail_error: null
            }
        case GET_ADMIN_AUDIT_TRAIL_ERROR:
            return {
                ...state,
                audit_trail_loading: false,
                audit_trail_error: action.payload
            }
        case GET_ADMIN_AUDIT_TRAIL_LOADING:
            return {
                ...state,
                audit_trail_loading: true,
                audit_trail_error: null
            }
        case CLEAR_GET_ADMIN_AUDIT_TRAIL_ERROR:
            return {
                ...state,
                audit_trail_loading: false,
                audit_trail_error: null
            }
        case CLEAR_GET_ADMIN_AUDIT_TRAIL:
            return {
                ...state,
                audit_trail: null,
                audit_trail_loading: false,
                audit_trail_error: null
            }
        default: return state
    }
}