import React from 'react';
import { useField, ErrorMessage } from 'formik';
import {DebounceInput} from 'react-debounce-input';

function AmountInput(props) {
    const [field, meta] = useField(props.name);
  return (
    <div>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
        <DebounceInput 
            className={`border border-gray-400 rounded text-gray-800 w-full py-1.5 px-2`}
            {...field} 
            {...props}
            style={{width: '100%'}}
            debounceTimeout={1500}
        />
        <small className="text-red-600">{(meta && meta.touched && meta.error) && meta.error}</small>
    </div>
  )
}

export default AmountInput

