import {
    CLEAR_DEACTIVATE_PARTNER_BRANCHES_ERROR,
    CLEAR_DEACTIVATE_PARTNER_BRANCHES,
    DEACTIVATE_PARTNER_BRANCHES_LOADING,
    DEACTIVATE_PARTNER_BRANCHES_ERROR,
    DEACTIVATE_PARTNER_BRANCHES
} 
from '../actions/types';

const initialState = {
    deactivatePartnerBranches: null,
    deactivatePartnerBranchesLoading: false,
    deactivatePartnerBranchesError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_DEACTIVATE_PARTNER_BRANCHES_ERROR:
            return {
                ...state,
                deactivatePartnerBranchesLoading: false,
                deactivatePartnerBranchesError: action.payload
            }
        case CLEAR_DEACTIVATE_PARTNER_BRANCHES:
            return {
                ...state,
                deactivatePartnerBranches: null,
                deactivatePartnerBranchesLoading: false,
                deactivatePartnerBranchesError: null
            }
        case DEACTIVATE_PARTNER_BRANCHES_LOADING:
            return {
                ...state,
                deactivatePartnerBranchesLoading: true,
                deactivatePartnerBranchesError: null
            }
        case DEACTIVATE_PARTNER_BRANCHES_ERROR:
            return {
                ...state,
                deactivatePartnerBranchesLoading: false,
                deactivatePartnerBranchesError: action.payload
            }
        case DEACTIVATE_PARTNER_BRANCHES:
            return {
                ...state,
                deactivatePartnerBranches: action.payload,
                deactivatePartnerBranchesLoading: false,
                deactivatePartnerBranchesError: null
            }
        default: return state
    }
}