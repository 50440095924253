import {CLEAR_ADD_USER, CLEAR_ADD_USER_ERROR, ADD_USER_LOADING, ADD_USER_ERROR, ADD_USER} from '../actions/types';

const initialState = {
    new_user: null,
    new_user_error: null,
    new_user_loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ADD_USER:
            return {
                ...state,
                new_user: null,
                new_user_error: null,
                new_user_loading: false
            }
        case CLEAR_ADD_USER_ERROR:
            return {
                ...state,
                new_user_error: null,
                new_user_loading: false
            }
        case ADD_USER_LOADING:
            return {
                ...state,
                new_user_error: null,
                new_user_loading: true
            }
        case ADD_USER_ERROR:
            return {
                ...state,
                new_user_error: action.payload,
                new_user_loading: false
            }
        case ADD_USER:
            return {
                ...state,
                new_user: action.payload,
                new_user_error: null,
                new_user_loading: false
            }
        default: return state
    }
}