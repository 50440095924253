import React from 'react';
import { Formik, Form } from "formik";
import FormikController from '../../components/Formik/FormikController';
import SubmitButton from '../../components/Formik/SubmitButton';
import * as Yup from "yup";
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import loader from '../../assets/loaders/382.gif';
import Auth from "../../layouts/Auth/Auth"

function Login(props) {
    const {login, clearLoginError, clearForgotPassword } = props;
    const {forgotPassword} = props.forgotPasswordReducer;
    const history = useHistory();
    const {auth, auth_loading, auth_error} = props.loginReducer;
    

    const initialValues = {
        email: '',
        password: '',
    }

    React.useEffect(() => {
        (auth && auth.role === 'TELLER') ? history.push('/home') : (auth && auth.role !== 'TELLER') && history.push('/reports');
    }, [auth]);

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email format').required('Email required'),
        password: Yup.string().required("Password required"),
    })

    const handleSubmit = (data) => {
        login(data);
    }

    return (
        <Auth title="Login">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
            >

                {() => (

                    <Form className='p-3'>
                        {auth_error &&
                        <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                            <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                            <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                                {auth_error.message}
                            </div>
                            <button onClick={() => clearLoginError()} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                                <span className="sr-only">Close</span>
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </button>
                        </div>}
                        {forgotPassword &&
                        <div id="alert-2" className="flex p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
                            <svg className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                            <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
                                {forgotPassword}
                            </div>
                            <button onClick={() => clearForgotPassword()} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300" data-dismiss-target="#alert-2" aria-label="Close">
                                <span className="sr-only">Close</span>
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </button>
                        </div>}
                        <div className="mb-4 pt-2 px-2">
                            <FormikController
                                control='input'
                                type='email'
                                label='Email'
                                name='email'
                            />
                        </div>
                        <div className="mb-3 px-2">
                            <FormikController
                                control='input'
                                type='password'
                                label='Password'
                                name='password'
                            />
                        </div>

                        <div className="mb-3 pt-2">
                            {auth_loading ? <div className="mb-3 pt-3 px-2">
                                    <button type="button" className="bg-gray-500 text-white w-full rounded py-2 text-lg"><img  className="h-8 block mx-auto" src={loader} /></button>
                                </div> :
                            <SubmitButton label='Login' />}
                            <div className="text-right px-3">
                                <p><Link to="/forgot-password"className="underline text-blue-700">Forgot Password</Link></p>
                            </div>
                        </div>
                    </Form>
                )
                }

            </Formik>
        </Auth>
    )
}

Login.propTypes = {
    clearLoginError: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    clearForgotPassword: PropTypes.func.isRequired,
    loginReducer: PropTypes.object.isRequired,
    forgotPasswordReducer: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    loginReducer: state.loginReducer,
    forgotPasswordReducer: state.forgotPasswordReducer,
})

export default connect(mapStateToProps, actions)(Login)
