import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions';
import TransactionChart from './dashboard/TransactionChart';
import ReconciliationTable from './dashboard/ReconciliationTable';
import GraphicalSummary from './dashboard/GraphicalSummary';
import handleSimpleDateFormat from '../utils/handleDateSimpleFormat';
import ReportSummary from './dashboard/ReportSummary';

function Dashboard() {
    const dispatch = useDispatch();
    const reportsState = useSelector(reportsState => reportsState.reportsReducer);
    const authState = useSelector(authState => authState.loginReducer);
    const [page, setPage] = React.useState(1);
    const perPage = 15;

    React.useEffect(() => {
        dispatch(actions.reports({
            page,
            perPage,
            selector: 'DAILY',
            section: 'INDIVIDUAL',
            selectedPeriod1: handleSimpleDateFormat(-10),
            selectedPeriod2: handleSimpleDateFormat(0),
        }));
        dispatch(actions.clearAddReconciliationsProcess());
    }, [dispatch, page]);

    return (
        <div>
            <div className="grid grid-cols-3 gap-4">
                <div className="rounded border">
                    <TransactionChart reportsState={reportsState} />
                </div>
                <div className="rounded border col-span-2">
                    <GraphicalSummary />
                </div>
                <div className="col-span-3">
                    <ReconciliationTable perPage={perPage} setPage={setPage} page={page} reportsState={reportsState} />
                </div>
                <div className={`col-span-3 ${(authState.auth.partner) ? 'hidden' : 'block'}`}>
                    <ReportSummary />
                </div>
            </div>
        </div>
    )
}

export default Dashboard