import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions';
import Pagination from "react-js-pagination";
import { BsFillPeopleFill } from "react-icons/bs";
import PageLoader from '../../utils/PageLoader';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

function PartnerBranchTable({ handleEdit, setStore }) {
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(1);
    const { activatePartnerBranches } = useSelector(activateBranches => activateBranches.activatePartnerBranchesReducer);
    const { deactivatePartnerBranches } = useSelector(deactivateBranches => deactivateBranches.deactivatePartnerBranchesReducer);
    const { new_partner_branch } = useSelector(addBranch => addBranch.addPartnerBranchesReducer);
    const { partner } = useSelector(getPartners => getPartners.getPartnersReducer);
    const { updatePartnerBranches } = useSelector(updateBranch => updateBranch.updatePartnerBranchesReducer);
    const { partner_branches, partner_branches_loading } = useSelector(getBranches => getBranches.getPartnerBranchesReducer);
    const itemsCountPerPage = 30;

    const { id } = useParams();

    let result = partner.data.find(partner => partner.id === id);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    React.useEffect(() => {
        let data = {
            page,
            perPage: 30,
            partnerId: id
        }
        dispatch(actions.getPartnerBranches(data));
        dispatch(actions.clearAddPartnerBranches());
        setStore(null)
    }, [page, new_partner_branch, updatePartnerBranches, activatePartnerBranches, deactivatePartnerBranches]);

    const handleChangeStatus = (el) => {
        el.status === 'ACTIVE' ? dispatch(actions.deactivatePartnerBranches(el)) : dispatch(actions.activatePartnerBranches(el));
    }

    let content = (partner_branches_loading) ? <PageLoader /> : (partner_branches) && <div>
        <nav className="flex py-3 px-5 text-gray-700 bg-gray-50 rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <Link to={`/partners`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    <BsFillPeopleFill className='w-4 h-4 mr-2' />
                    {result.OrganizationName}
                </Link>

                <Link to='#' className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                    {result.OrganizationName} - Branches
                </Link>
            </ol>
        </nav>
  
        <div className="overflow-x-hidden border sm:rounded-lg mt-1">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Branch Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Address
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Contact Person
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Email
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Phone Number
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <span className="sr-only">Users</span>
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {(partner_branches.data.length > 0) ? (partner_branches.data.map(el => (
                        <tr key={el.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">

                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                {el.branchName}
                            </th>
                            <td className="px-6 py-4">
                                {el.address} {el.city}
                            </td>
                            <td className="px-6 py-4">
                                {el.contactPerson}
                            </td>
                            <td className="px-6 py-4">
                                {el.contactEmail}
                            </td>
                            <td className="px-6 py-4">
                                {el.contactPhone}
                            </td>
                            <td className="px-6 py-4 ">
                                {el.status === 'ACTIVE' ?
                                    <button onClick={() => handleChangeStatus(el)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">De-activate</button> :
                                    <button onClick={() => handleChangeStatus(el)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Activate</button>}
                            </td>
                            <td className="px-6 py-4 ">
                                <Link to={`/partners/${id}/branch/${el.id}`} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Users</Link>
                            </td>
                            <td className="px-6 py-4 text-right">
                                <button onClick={() => handleEdit(el)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</button>
                            </td>

                        </tr>
                    )))
                        : <tr>
                            <td colSpan="11" className="px-6 py-4 text-center">No data found</td>
                        </tr>}

                </tbody>
            </table>
            {(partner_branches && !partner_branches_loading && partner_branches.data.length > 0) &&
                <div className="p-4 flex justify-end">
                    <Pagination
                        activePage={partner_branches.activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={partner_branches.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>}
        </div>
    </div>


    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    )
}

export default PartnerBranchTable