import { CLEAR_LOGIN, CLEAR_LOGIN_ERROR, LOGIN_LOADING, LOGIN_ERROR, LOGIN} from '../actions/types';

const initialState = {
    auth: null,
    auth_loading: false,
    auth_error: null,
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_LOGIN:
            return {
                ...state,
                auth: null,
                auth_loading: false,
                auth_error: null,
            }
        case CLEAR_LOGIN_ERROR:
            return {
                ...state,
                auth_loading: false,
                auth_error: null,
            }
        case LOGIN_LOADING:
            return {
                ...state,
                auth_loading: true,
                auth_error: null,
            }
        case LOGIN_ERROR:
            return {
                ...state,
                auth_loading: false,
                auth_error: action.payload,
            }
        case LOGIN:
            return {
                ...state,
                auth: action.payload,
                auth_loading: false,
                auth_error: null,
            }
        default: return state
    }
}
