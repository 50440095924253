import {
    GET_PARTNER_USERS,
    GET_PARTNER_USERS_ERROR,
    GET_PARTNER_USERS_LOADING,
    CLEAR_GET_PARTNER_USERS_ERROR,
    CLEAR_GET_PARTNER_USERS
} from '../actions/types';

const initialState = {
    partner_users: null,
    partner_users_loading: false,
    partner_users_error: null,
}

export default function (state = initialState, action){
    switch(action.type){
        case GET_PARTNER_USERS:
            return {
                ...state,
                partner_users: action.payload,
                partner_users_loading: false,
                partner_users_error: null,
            }
        case GET_PARTNER_USERS_ERROR:
            return {
                ...state,
                partner_users_loading: false,
                partner_users_error: action.payload,
            }
        case GET_PARTNER_USERS_LOADING:
            return {
                ...state,
                partner_users_loading: true,
                partner_users_error: null,
            }
        case CLEAR_GET_PARTNER_USERS_ERROR:
            return {
                ...state,
                partner_users_loading: false,
                partner_users_error: null,
            }
        case CLEAR_GET_PARTNER_USERS:
            return {
                ...state,
                partner_users: null,
                partner_users_loading: false,
                partner_users_error: null,
            }
        default: return state
    }
}