import {
    CLEAR_ACTIVATE_PARTNER_BRANCHES_ERROR,
    CLEAR_ACTIVATE_PARTNER_BRANCHES,
    ACTIVATE_PARTNER_BRANCHES_LOADING,
    ACTIVATE_PARTNER_BRANCHES_ERROR,
    ACTIVATE_PARTNER_BRANCHES
} 
from '../actions/types';

const initialState = {
    activatePartnerBranches: null,
    activatePartnerBranchesLoading: false,
    activatePartnerBranchesError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_ACTIVATE_PARTNER_BRANCHES_ERROR:
            return {
                ...state,
                activatePartnerBranchesLoading: false,
                activatePartnerBranchesError: action.payload
            }
        case CLEAR_ACTIVATE_PARTNER_BRANCHES:
            return {
                ...state,
                activatePartnerBranches: null,
                activatePartnerBranchesLoading: false,
                activatePartnerBranchesError: null
            }
        case ACTIVATE_PARTNER_BRANCHES_LOADING:
            return {
                ...state,
                activatePartnerBranchesLoading: true,
                activatePartnerBranchesError: null
            }
        case ACTIVATE_PARTNER_BRANCHES_ERROR:
            return {
                ...state,
                activatePartnerBranchesLoading: false,
                activatePartnerBranchesError: action.payload
            }
        case ACTIVATE_PARTNER_BRANCHES:
            return {
                ...state,
                activatePartnerBranches: action.payload,
                activatePartnerBranchesLoading: false,
                activatePartnerBranchesError: null
            }
        default: return state
    }
}