import React from 'react'
import AddBranch from '../components/branch/AddBranch'
import BranchTables from '../components/branch/BranchTables'

function Branch() {
  const [store, setStore] = React.useState(null);
  return (
    <div className="grid grid-cols-12 gap-4">
        <div className="col-span-9 p-8">
            <BranchTables setStore={setStore} />
        </div>
        <div className="col-span-3 border-l min-h-screen p-8">
            <AddBranch store={store} setStore={setStore} />
        </div>
    </div>
  )
}

export default Branch