import {
    CLEAR_PARTNER_BRANCH_USERS_ERROR,
    PARTNER_BRANCH_USERS_LOADING,
    CLEAR_PARTNER_BRANCH_USERS,
    PARTNER_BRANCH_USERS_ERROR,
    PARTNER_BRANCH_USERS
} from '../actions/types';

const initialState = {
    branch_users: null,
    branch_users_loading: false,
    branch_users_error: null
}

const partnerBranchUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case PARTNER_BRANCH_USERS:
            return {
                ...state,
                branch_users: action.payload,
                branch_users_loading: false,
                branch_users_error: null
            }
        case PARTNER_BRANCH_USERS_ERROR:
            return {
                ...state,
                branch_users_loading: false,
                branch_users_error: action.payload
            }
        case CLEAR_PARTNER_BRANCH_USERS:
            return {
                ...state,
                branch_users: null,
                branch_users_loading: false,
                branch_users_error: null
            }
        case PARTNER_BRANCH_USERS_LOADING:
            return {
                ...state,
                branch_users_loading: true,
                branch_users_error: null
            }
        case CLEAR_PARTNER_BRANCH_USERS_ERROR:
            return {
                ...state,
                branch_users_loading: false,
                branch_users_error: null
            }
        default: return state
    }
}

export default partnerBranchUsersReducer;