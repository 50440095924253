import React from 'react';
import { Formik, Form } from "formik";
import FormikController from '../../Formik/FormikController';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../store/actions';
import { useHistory } from 'react-router-dom';
import PageLoader from '../../utils/PageLoader';

function ReconciliationDetails() {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const reportsState = useSelector(reportsState => reportsState.getReconciliationByIdReducer);
    const processState = useSelector(processState => processState.addReconciliationsIdProcessReducer);

    const initialValues = {
        branch: reportsState.recon_id ? reportsState.recon_id.branch.branchName : '',
        cashIn: reportsState.recon_id ? reportsState.recon_id.currency.currencySymbol + " " + reportsState.recon_id.cashIn : '',
        cashOut: reportsState.recon_id ? reportsState.recon_id.currency.currencySymbol + " " + reportsState.recon_id.cashOut : '',
        category: reportsState.recon_id ? reportsState.recon_id.category : '',
        commission: reportsState.recon_id ? reportsState.recon_id.currency.currencySymbol + " " + reportsState.recon_id.commission : '',
        endingBalance: reportsState.recon_id ? reportsState.recon_id.currency.currencySymbol + " " + reportsState.recon_id.endingBalance : '',
        partner: (reportsState.recon_id && reportsState.recon_id.partner) ? reportsState.recon_id.partner : "",
        receivedcashInHand: (reportsState.recon_id && reportsState.recon_id.receivedcashInHand) ? reportsState.recon_id.currency.currencySymbol + " " + reportsState.recon_id.receivedcashInHand : '',
        recondate: reportsState.recon_id ? reportsState.recon_id.reconDate : '',
        shortfall: (reportsState.recon_id && reportsState.recon_id.shortfall) ? reportsState.recon_id.currency.currencySymbol + " " +  reportsState.recon_id.shortfall : '',
        startingBalance: reportsState.recon_id ? reportsState.recon_id.currency.currencySymbol + " " + reportsState.recon_id.startingBalance : '',
        status: reportsState.recon_id ? reportsState.recon_id.status : '',
        supervisor: (reportsState.recon_id && reportsState.recon_id.supervisor) ? reportsState.recon_id.supervisor.firstname + " " + reportsState.recon_id.supervisor.middlename + " " + reportsState.recon_id.supervisor.lastname : "",
        teller: reportsState.recon_id ? reportsState.recon_id.teller.firstname + " " + reportsState.recon_id.teller.middlename + " " + reportsState.recon_id.teller.lastname : '',
        tellercashinhand: reportsState.recon_id ? reportsState.recon_id.currency.currencySymbol + " " + reportsState.recon_id.tellercashInHand : '',
        type: reportsState.recon_id ? reportsState.recon_id.type : '',
        weeknumber: reportsState.recon_id ? reportsState.recon_id.weeekNumber : '',
        monthNumber: reportsState.recon_id ? reportsState.recon_id.monthNumber : '',
        yearperiod: reportsState.recon_id ? reportsState.recon_id.yearPeriod : '',
        createdat: reportsState.recon_id ? reportsState.recon_id.createdAt : '',
        tellercomment: reportsState.recon_id ? reportsState.recon_id.tellerComments : '',
        supervisorcomment: reportsState.recon_id ? reportsState.recon_id.supervisorComments : '',
    }

    React.useEffect(() => {
        dispatch(actions.getReconciliationsById({id : id}))
        dispatch(actions.clearAddReconciliationsProcess());
        
        return () => {
            dispatch(actions.clearGetReconciliationsById());
        }
    }, [processState.new_recon_id_process, dispatch, id]);

    const handleRedirect = () => {
        history.push('/reports')
    }

    return (
        <div> {reportsState.recon_id_loading ? <PageLoader /> : (reportsState.recon_id) && <React.Fragment>
            <div className="flex justify-between items-center mb-3">
                <button onClick={handleRedirect} className="font-medium text-blue-800"><span className="border-b border-blue-800">Back</span></button>
                <h2 className="font-medium text-lg">Reconciliation details</h2>
            </div>
            <div>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                >
                    <Form>
                        <div className="grid grid-cols-4 gap-3">
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Partner'
                                    name='partner'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Branch'
                                    name='branch'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Cash In'
                                    name='cashIn'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Cash Out'
                                    name='cashOut'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Category'
                                    name='category'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Commission'
                                    name='commission'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Ending Balance'
                                    name='endingBalance'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Starting Balance'
                                    name='startingBalance'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Teller Cash In Hand'
                                    name='tellercashinhand'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Received Cash In Hand'
                                    name='receivedcashInHand'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Short Fall'
                                    name='shortfall'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Reconciliation Date'
                                    name='recondate'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Supervisor'
                                    name='supervisor'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Teller'
                                    name='teller'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Type'
                                    name='type'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Status'
                                    name='status'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Week Number'
                                    name='weeknumber'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Month Number'
                                    name='monthNumber'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Year period'
                                    name='yearperiod'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    disabled
                                    label='Created At'
                                    name='createdat'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='textArea'
                                    type='text'
                                    disabled
                                    label='Teller Comment'
                                    name='tellercomment'
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='textArea'
                                    type='text'
                                    disabled
                                    label='Supervisor Comment'
                                    name='supervisorcomment'
                                />
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
            </React.Fragment>}
        </div>
    )
}

export default ReconciliationDetails