import {
    CLEAR_UPDATE_FEE_CONFIG_ERROR,
    CLEAR_UPDATE_FEE_CONFIG,
    UPDATE_FEE_CONFIG_LOADING,
    UPDATE_FEE_CONFIG_ERROR,
    UPDATE_FEE_CONFIG} 
from '../actions/types';

const initialState = {
    updateFeeConfig: null,
    updateFeeConfigLoading: false,
    updateFeeConfigError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_FEE_CONFIG_ERROR:
            return {
                ...state,
                updateFeeConfigLoading: false,
                updateFeeConfigError: action.payload
            }
        case CLEAR_UPDATE_FEE_CONFIG:
            return {
                ...state,
                updateFeeConfig: null,
                updateFeeConfigLoading: false,
                updateFeeConfigError: null
            }
        case UPDATE_FEE_CONFIG_LOADING:
            return {
                ...state,
                updateFeeConfigLoading: true,
                updateFeeConfigError: null
            }
        case UPDATE_FEE_CONFIG_ERROR:
            return {
                ...state,
                updateFeeConfigLoading: false,
                updateFeeConfigError: action.payload
            }
        case UPDATE_FEE_CONFIG:
            return {
                ...state,
                updateFeeConfig: action.payload,
                updateFeeConfigLoading: false,
                updateFeeConfigError: null
            }
        default: return state
    }
}