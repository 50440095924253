import {
    CLEAR_ACTIVATE_USERS_ERROR,
    CLEAR_ACTIVATE_USERS,
    ACTIVATE_USERS_LOADING,
    ACTIVATE_USERS_ERROR,
    ACTIVATE_USERS
} 
from '../actions/types';

const initialState = {
    activateUsers: null,
    activateUsersLoading: false,
    activateUsersError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_ACTIVATE_USERS_ERROR:
            return {
                ...state,
                activateUsersLoading: false,
                activateUsersError: action.payload
            }
        case CLEAR_ACTIVATE_USERS:
            return {
                ...state,
                activateUsers: null,
                activateUsersLoading: false,
                activateUsersError: null
            }
        case ACTIVATE_USERS_LOADING:
            return {
                ...state,
                activateUsersLoading: true,
                activateUsersError: null
            }
        case ACTIVATE_USERS_ERROR:
            return {
                ...state,
                activateUsersLoading: false,
                activateUsersError: action.payload
            }
        case ACTIVATE_USERS:
            return {
                ...state,
                activateUsers: action.payload,
                activateUsersLoading: false,
                activateUsersError: null
            }
        default: return state
    }
}