import Axios from 'axios';
import jwt_decode from 'jwt-decode';

import {
    GET_BRANCH,
    GET_BRANCH_ERROR,
    GET_BRANCH_LOADING,
    CLEAR_GET_BRANCH_ERROR,
    CLEAR_GET_BRANCH,

    CLEAR_ADD_BRANCH,
    CLEAR_ADD_BRANCH_ERROR,
    ADD_BRANCH_LOADING,
    ADD_BRANCH_ERROR,
    ADD_BRANCH,

    CLEAR_ADD_USER,
    CLEAR_ADD_USER_ERROR,
    ADD_USER_LOADING,
    ADD_USER_ERROR,
    ADD_USER,

    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_LOADING,
    CLEAR_GET_USERS_ERROR,
    CLEAR_GET_USERS,

    CLEAR_LOGIN,
    CLEAR_LOGIN_ERROR,
    LOGIN_LOADING,
    LOGIN_ERROR,
    LOGIN,

    ADD_REMITTER,
    ADD_REMITTER_ERROR,
    ADD_REMITTER_LOADING,
    CLEAR_ADD_REMITTER,
    CLEAR_ADD_REMITTER_ERROR,

    CLEAR_UPDATE_BRANCH_ERROR,
    CLEAR_UPDATE_BRANCH,
    UPDATE_BRANCH_LOADING,
    UPDATE_BRANCH_ERROR,
    UPDATE_BRANCH,

    CLEAR_UPDATE_USER_ERROR,
    CLEAR_UPDATE_USER,
    UPDATE_USER_LOADING,
    UPDATE_USER_ERROR,
    UPDATE_USER,

    CLEAR_ADD_CURRENCY_ERROR,
    CLEAR_ADD_CURRENCY,
    ADD_CURRENCY_LOADING,
    ADD_CURRENCY_ERROR,
    ADD_CURRENCY,

    CLEAR_GET_CURRENCY_ERROR,
    CLEAR_GET_CURRENCY,
    GET_CURRENCY_LOADING,
    GET_CURRENCY_ERROR,
    GET_CURRENCY,

    CLEAR_UPDATE_CURRENCY_ERROR,
    CLEAR_UPDATE_CURRENCY,
    UPDATE_CURRENCY_LOADING,
    UPDATE_CURRENCY_ERROR,
    UPDATE_CURRENCY,

    
    CLEAR_UPDATE_TRANSACTION_FEE_ERROR,
    CLEAR_UPDATE_TRANSACTION_FEE,
    UPDATE_TRANSACTION_FEE_LOADING,
    UPDATE_TRANSACTION_FEE_ERROR,
    UPDATE_TRANSACTION_FEE,

    CLEAR_ADD_TRANSACTION_FEES_ERROR,
    CLEAR_ADD_TRANSACTION_FEES,
    ADD_TRANSACTION_FEES_LOADING,
    ADD_TRANSACTION_FEES_ERROR,
    ADD_TRANSACTION_FEES,

    CLEAR_GET_TRANSACTION_FEES_ERROR,
    CLEAR_GET_TRANSACTION_FEES,
    GET_TRANSACTION_FEES_LOADING,
    GET_TRANSACTION_FEES_ERROR,
    GET_TRANSACTION_FEES,

    CLEAR_ADD_REMIT_REASON_ERROR,
    CLEAR_ADD_REMIT_REASON,
    ADD_REMIT_REASON_LOADING,
    ADD_REMIT_REASON_ERROR,
    ADD_REMIT_REASON,

    CLEAR_GET_REMIT_REASONS_ERROR,
    CLEAR_GET_REMIT_REASONS,
    GET_REMIT_REASONS_LOADING,
    GET_REMIT_REASONS_ERROR,
    GET_REMIT_REASONS,

    CLEAR_UPDATE_REMIT_REASON_ERROR,
    CLEAR_UPDATE_REMIT_REASON,
    UPDATE_REMIT_REASON_LOADING,
    UPDATE_REMIT_REASON_ERROR,
    UPDATE_REMIT_REASON,

    CLEAR_ACTIVATE_CURRENCY_ERROR,
    CLEAR_ACTIVATE_CURRENCY,
    ACTIVATE_CURRENCY_LOADING,
    ACTIVATE_CURRENCY_ERROR,
    ACTIVATE_CURRENCY,

    CLEAR_DEACTIVATE_CURRENCY_ERROR,
    CLEAR_DEACTIVATE_CURRENCY,
    DEACTIVATE_CURRENCY_LOADING,
    DEACTIVATE_CURRENCY_ERROR,
    DEACTIVATE_CURRENCY,

    CLEAR_ACTIVATE_USERS_ERROR,
    CLEAR_ACTIVATE_USERS,
    ACTIVATE_USERS_LOADING,
    ACTIVATE_USERS_ERROR,
    ACTIVATE_USERS,

    CLEAR_DEACTIVATE_USERS_ERROR,
    CLEAR_DEACTIVATE_USERS,
    DEACTIVATE_USERS_LOADING,
    DEACTIVATE_USERS_ERROR,
    DEACTIVATE_USERS,

    CLEAR_ACTIVATE_TRANSACTION_FEE_ERROR,
    CLEAR_ACTIVATE_TRANSACTION_FEE,
    ACTIVATE_TRANSACTION_FEE_LOADING,
    ACTIVATE_TRANSACTION_FEE_ERROR,
    ACTIVATE_TRANSACTION_FEE,

    CLEAR_DEACTIVATE_TRANSACTION_FEE_ERROR,
    CLEAR_DEACTIVATE_TRANSACTION_FEE,
    DEACTIVATE_TRANSACTION_FEE_LOADING,
    DEACTIVATE_TRANSACTION_FEE_ERROR,
    DEACTIVATE_TRANSACTION_FEE,

    CLEAR_ACTIVATE_BRANCHES_ERROR,
    CLEAR_ACTIVATE_BRANCHES,
    ACTIVATE_BRANCHES_LOADING,
    ACTIVATE_BRANCHES_ERROR,
    ACTIVATE_BRANCHES,

    CLEAR_DEACTIVATE_BRANCHES_ERROR,
    CLEAR_DEACTIVATE_BRANCHES,
    DEACTIVATE_BRANCHES_LOADING,
    DEACTIVATE_BRANCHES_ERROR,
    DEACTIVATE_BRANCHES,

    CLEAR_FORGOT_PASSWORD_ERROR,
    CLEAR_FORGOT_PASSWORD,
    FORGOT_PASSWORD_LOADING,
    FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD,

    CLEAR_REMIT_SOURCES_ERROR,
    CLEAR_REMIT_SOURCES,
    REMIT_SOURCE_LOADING,
    REMIT_SOURCE_ERROR,
    REMIT_SOURCE,

    CLEAR_GET_REMIT_SOURCE_ERROR,
    CLEAR_GET_REMIT_SOURCE,
    GET_REMIT_SOURCES_LOADING,
    GET_REMIT_SOURCES_ERROR,
    GET_REMIT_SOURCES,

    CLEAR_UPDATE_REMIT_SOURCE_ERROR,
    CLEAR_UPDATE_REMIT_SOURCE,
    UPDATE_REMIT_SOURCE_LOADING,
    UPDATE_REMIT_SOURCE_ERROR,
    UPDATE_REMIT_SOURCE,
   

    CLEAR_CHANGE_PASSWORD_ERROR,
    CLEAR_CHANGE_PASSWORD,
    CHANGE_PASSWORD_LOADING,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD,

    CLEAR_CURRENT_USER_PROFILE_ERROR,
    CLEAR_CURRENT_USER_PROFILE,
    CURRENT_USER_PROFILE_LOADING,
    CURRENT_USER_PROFILE_ERROR,
    CURRENT_USER_PROFILE,

    GET_TRANSACTION_LIMIT,
    GET_TRANSACTION_LIMIT_ERROR,
    GET_TRANSACTION_LIMIT_LOADING,
    CLEAR_GET_TRANSACTION_LIMIT_ERROR,
    CLEAR_GET_TRANSACTION_LIMIT,

    CLEAR_ADD_TRANSACTION_LIMIT,
    CLEAR_ADD_TRANSACTION_LIMIT_ERROR,
    ADD_TRANSACTION_LIMIT_LOADING,
    ADD_TRANSACTION_LIMIT_ERROR,
    ADD_TRANSACTION_LIMIT,

    CLEAR_UPDATE_TRANSACTION_LIMIT_ERROR,
    CLEAR_UPDATE_TRANSACTION_LIMIT,
    UPDATE_TRANSACTION_LIMIT_LOADING,
    UPDATE_TRANSACTION_LIMIT_ERROR,
    UPDATE_TRANSACTION_LIMIT,

    CLEAR_ACTIVATE_TRANSACTION_LIMIT_ERROR,
    CLEAR_ACTIVATE_TRANSACTION_LIMIT,
    ACTIVATE_TRANSACTION_LIMIT_LOADING,
    ACTIVATE_TRANSACTION_LIMIT_ERROR,
    ACTIVATE_TRANSACTION_LIMIT,

    CLEAR_DEACTIVATE_TRANSACTION_LIMIT_ERROR,
    CLEAR_DEACTIVATE_TRANSACTION_LIMIT,
    DEACTIVATE_TRANSACTION_LIMIT_LOADING,
    DEACTIVATE_TRANSACTION_LIMIT_ERROR,
    DEACTIVATE_TRANSACTION_LIMIT,
    
    CLEAR_UPDATE_CURRENT_USER_PROFILE_ERROR,
    CLEAR_UPDATE_CURRENT_USER_PROFILE,
    UPDATE_CURRENT_USER_PROFILE_LOADING,
    UPDATE_CURRENT_USER_PROFILE_ERROR,
    UPDATE_CURRENT_USER_PROFILE,

    CLEAR_ASSIGN_FLOAT_ERROR,
    CLEAR_ASSIGN_FLOAT,
    ASSIGN_FLOAT_LOADING,
    ASSIGN_FLOAT_ERROR,
    ASSIGN_FLOAT,

    CLEAR_UPDATE_FLOAT_ERROR,
    CLEAR_UPDATE_FLOAT,
    UPDATE_FLOAT_LOADING,
    UPDATE_FLOAT_ERROR,
    UPDATE_FLOAT,

    CLEAR_GET_FLOAT_ERROR,
    CLEAR_GET_FLOAT,
    GET_FLOAT_LOADING,
    GET_FLOAT_ERROR,
    GET_FLOAT,

    CLEAR_FETCH_TELLERS_ERROR,
    CLEAR_FETCH_TELLERS,
    FETCH_TELLERS_LOADING,
    FETCH_TELLERS_ERROR,
    FETCH_TELLERS,

    CLEAR_CONFIRM_FLOAT_ERROR,
    CLEAR_CONFIRM_FLOAT,
    CONFIRM_FLOAT_LOADING,
    CONFIRM_FLOAT_ERROR,
    CONFIRM_FLOAT,

    GET_PARTNERS,
    GET_PARTNERS_ERROR,
    GET_PARTNERS_LOADING,
    CLEAR_GET_PARTNERS_ERROR,
    CLEAR_GET_PARTNERS,

    CLEAR_ADD_PARTNERS,
    CLEAR_ADD_PARTNERS_ERROR,
    ADD_PARTNERS_LOADING,
    ADD_PARTNERS_ERROR,
    ADD_PARTNERS,

    CLEAR_UPDATE_PARTNERS_ERROR,
    CLEAR_UPDATE_PARTNERS,
    UPDATE_PARTNERS_LOADING,
    UPDATE_PARTNERS_ERROR,
    UPDATE_PARTNERS,

    GET_PARTNER_BRANCHES,
    GET_PARTNER_BRANCHES_ERROR,
    GET_PARTNER_BRANCHES_LOADING,
    CLEAR_GET_PARTNER_BRANCHES_ERROR,
    CLEAR_GET_PARTNER_BRANCHES,

    CLEAR_ADD_PARTNER_BRANCHES,
    CLEAR_ADD_PARTNER_BRANCHES_ERROR,
    ADD_PARTNER_BRANCHES_LOADING,
    ADD_PARTNER_BRANCHES_ERROR,
    ADD_PARTNER_BRANCHES,

    CLEAR_UPDATE_PARTNER_BRANCHES_ERROR,
    CLEAR_UPDATE_PARTNER_BRANCHES,
    UPDATE_PARTNER_BRANCHES_LOADING,
    UPDATE_PARTNER_BRANCHES_ERROR,
    UPDATE_PARTNER_BRANCHES,

    CLEAR_ACTIVATE_PARTNER_BRANCHES_ERROR,
    CLEAR_ACTIVATE_PARTNER_BRANCHES,
    ACTIVATE_PARTNER_BRANCHES_LOADING,
    ACTIVATE_PARTNER_BRANCHES_ERROR,
    ACTIVATE_PARTNER_BRANCHES,

    CLEAR_DEACTIVATE_PARTNER_BRANCHES_ERROR,
    CLEAR_DEACTIVATE_PARTNER_BRANCHES,
    DEACTIVATE_PARTNER_BRANCHES_LOADING,
    DEACTIVATE_PARTNER_BRANCHES_ERROR,
    DEACTIVATE_PARTNER_BRANCHES,

    CLEAR_ADD_PARTNER_USERS,
    CLEAR_ADD_PARTNER_USERS_ERROR,
    ADD_PARTNER_USERS_LOADING,
    ADD_PARTNER_USERS_ERROR,
    ADD_PARTNER_USERS,
    
    GET_PARTNER_USERS,
    GET_PARTNER_USERS_ERROR,
    GET_PARTNER_USERS_LOADING,
    CLEAR_GET_PARTNER_USERS_ERROR,
    CLEAR_GET_PARTNER_USERS,
   
    GET_REMITTERS,
    GET_REMITTERS_ERROR,
    GET_REMITTERS_LOADING,
    CLEAR_GET_REMITTERS_ERROR,
    CLEAR_GET_REMITTERS,

    GET_REMITTERS_BY_ID,
    GET_REMITTERS_BY_ID_ERROR,
    GET_REMITTERS_BY_ID_LOADING,
    CLEAR_GET_REMITTERS_BY_ID_ERROR,
    CLEAR_GET_REMITTERS_BY_ID,

    GET_REMITTERS_BY_NATIONAL_ID,
    GET_REMITTERS_BY_NATIONAL_ID_ERROR,
    GET_REMITTERS_BY_NATIONAL_ID_LOADING,
    CLEAR_GET_REMITTERS_BY_NATIONAL_ID_ERROR,
    CLEAR_GET_REMITTERS_BY_NATIONAL_ID,

    CLEAR_UPDATE_REMITTERS_ERROR,
    CLEAR_UPDATE_REMITTERS,
    UPDATE_REMITTERS_LOADING,
    UPDATE_REMITTERS_ERROR,
    UPDATE_REMITTERS,

    CLEAR_ACTIVATE_REMITTERS_ERROR,
    CLEAR_ACTIVATE_REMITTERS,
    ACTIVATE_REMITTERS_LOADING,
    ACTIVATE_REMITTERS_ERROR,
    ACTIVATE_REMITTERS,

    CLEAR_DEACTIVATE_REMITTERS_ERROR,
    CLEAR_DEACTIVATE_REMITTERS,
    DEACTIVATE_REMITTERS_LOADING,
    DEACTIVATE_REMITTERS_ERROR,
    DEACTIVATE_REMITTERS,

    CLEAR_PARTNER_BRANCH_USERS_ERROR,
    PARTNER_BRANCH_USERS_LOADING,
    CLEAR_PARTNER_BRANCH_USERS,
    PARTNER_BRANCH_USERS_ERROR,
    PARTNER_BRANCH_USERS,

    CLEAR_SEARCH_REMITTER_ERROR,
    CLEAR_SEARCH_REMITTER,
    SEARCH_REMITTER_LOADING,
    SEARCH_REMITTER_ERROR,
    SEARCH_REMITTER,

    GET_TRANSACTION_LOGS,
    GET_TRANSACTION_LOGS_ERROR,
    GET_TRANSACTION_LOGS_LOADING,
    CLEAR_GET_TRANSACTION_LOGS_ERROR,
    CLEAR_GET_TRANSACTION_LOGS,

    GET_TRANSACTION_LOGS_BY_ID,
    GET_TRANSACTION_LOGS_BY_ID_ERROR,
    GET_TRANSACTION_LOGS_BY_ID_LOADING,
    CLEAR_GET_TRANSACTION_LOGS_BY_ID_ERROR,
    CLEAR_GET_TRANSACTION_LOGS_BY_ID,
    
    CLEAR_FETCH_REMITTER_BENEFICIARY_ERROR,
    CLEAR_FETCH_REMITTER_BENEFICIARY,
    FETCH_REMITTER_BENEFICIARY_LOADING,
    FETCH_REMITTER_BENEFICIARY_ERROR,
    FETCH_REMITTER_BENEFICIARY,

    CLEAR_MAKE_REMITTANCE_ERROR,
    CLEAR_MAKE_REMITTANCE,
    MAKE_REMITTANCE_LOADING,
    MAKE_REMITTANCE_ERROR,
    MAKE_REMITTANCE,
    
    GET_ADMIN_AUDIT_TRAIL,
    GET_ADMIN_AUDIT_TRAIL_ERROR,
    GET_ADMIN_AUDIT_TRAIL_LOADING,
    CLEAR_GET_ADMIN_AUDIT_TRAIL_ERROR,
    CLEAR_GET_ADMIN_AUDIT_TRAIL,

    GET_ADMIN_AUDIT_TRAIL_BY_ID,
    GET_ADMIN_AUDIT_TRAIL_BY_ID_ERROR,
    GET_ADMIN_AUDIT_TRAIL_BY_ID_LOADING,
    CLEAR_GET_ADMIN_AUDIT_TRAIL_BY_ID_ERROR,
    CLEAR_GET_ADMIN_AUDIT_TRAIL_BY_ID,

    CLEAR_TELLER_FLOAT_ERROR,
    TELLER_FLOAT_LOADING,
    CLEAR_TELLER_FLOAT,
    TELLER_FLOAT,
    TELLER_FLOAT_ERROR,

    CLEAR_CASHOUT_ERROR,
    CLEAR_CASHOUT,
    CASHOUT_LOADING,
    CASHOUT_ERROR,
    CASHOUT,

    CLEAR_PROCESS_REMITTANCE_ERROR,
    CLEAR_PROCESS_REMITTANCE,
    PROCESS_REMITTANCE_LOADING,
    PROCESS_REMITTANCE_ERROR,
    PROCESS_REMITTANCE,

    CLEAR_ADD_RECONCILIATIONS,
    CLEAR_ADD_RECONCILIATIONS_ERROR,
    ADD_RECONCILIATIONS_LOADING,
    ADD_RECONCILIATIONS_ERROR,
    ADD_RECONCILIATIONS,

    CLEAR_ADD_RECONCILIATIONS_OVERAL_RECON,
    CLEAR_ADD_RECONCILIATIONS_OVERAL_RECON_ERROR,
    ADD_RECONCILIATIONS_OVERAL_RECON_LOADING,
    ADD_RECONCILIATIONS_OVERAL_RECON_ERROR,
    ADD_RECONCILIATIONS_OVERAL_RECON,

    CLEAR_ADD_RECONCILIATIONS_PARTNER_RECON,
    CLEAR_ADD_RECONCILIATIONS_PARTNER_RECON_ERROR,
    ADD_RECONCILIATIONS_PARTNER_RECON_LOADING,
    ADD_RECONCILIATIONS_PARTNER_RECON_ERROR,
    ADD_RECONCILIATIONS_PARTNER_RECON,

    CLEAR_ADD_RECONCILIATIONS_ID_PROCESS,
    CLEAR_ADD_RECONCILIATIONS_ID_PROCESS_ERROR,
    ADD_RECONCILIATIONS_ID_PROCESS_LOADING,
    ADD_RECONCILIATIONS_ID_PROCESS_ERROR,
    ADD_RECONCILIATIONS_ID_PROCESS,

    GET_RECONCILIATIONS,
    GET_RECONCILIATIONS_ERROR,
    GET_RECONCILIATIONS_LOADING,
    CLEAR_GET_RECONCILIATIONS_ERROR,
    CLEAR_GET_RECONCILIATIONS,

    GET_RECONCILIATIONS_CURRENT_TELLER,
    GET_RECONCILIATIONS_CURRENT_TELLER_ERROR,
    GET_RECONCILIATIONS_CURRENT_TELLER_LOADING,
    CLEAR_GET_RECONCILIATIONS_CURRENT_TELLER_ERROR,
    CLEAR_GET_RECONCILIATIONS_CURRENT_TELLER,

    GET_RECONCILIATIONS_ALL_CURRENT,
    GET_RECONCILIATIONS_ALL_CURRENT_ERROR,
    GET_RECONCILIATIONS_ALL_CURRENT_LOADING,
    CLEAR_GET_RECONCILIATIONS_ALL_CURRENT_ERROR,
    CLEAR_GET_RECONCILIATIONS_ALL_CURRENT,

    GET_RECONCILIATIONS_BY_ID,
    GET_RECONCILIATIONS_BY_ID_ERROR,
    GET_RECONCILIATIONS_BY_ID_LOADING,
    CLEAR_GET_RECONCILIATIONS_BY_ID_ERROR,
    CLEAR_GET_RECONCILIATIONS_BY_ID,

    CLEAR_UPDATE_RECONCILIATIONS_ERROR,
    CLEAR_UPDATE_RECONCILIATIONS,
    UPDATE_RECONCILIATIONS_LOADING,
    UPDATE_RECONCILIATIONS_ERROR,
    UPDATE_RECONCILIATIONS,

    CLEAR_UPDATE_FEE_CONFIG_ERROR,
    CLEAR_UPDATE_FEE_CONFIG,
    UPDATE_FEE_CONFIG_LOADING,
    UPDATE_FEE_CONFIG_ERROR,
    UPDATE_FEE_CONFIG,

    CLEAR_RUNNING_BALANCE_ERROR,
    CLEAR_RUNNING_BALANCE,
    RUNNING_BALANCE_LOADING,
    RUNNING_BALANCE_ERROR,
    RUNNING_BALANCE,

    CLEAR_UPDATE_REMITTANCE_REVERSAL_ERROR,
    CLEAR_UPDATE_REMITTANCE_REVERSAL,
    UPDATE_REMITTANCE_REVERSAL_LOADING,
    UPDATE_REMITTANCE_REVERSAL_ERROR,
    UPDATE_REMITTANCE_REVERSAL,

    CLEAR_FEE_CONFIG_ERROR,
    CLEAR_FEE_CONFIG,
    FEE_CONFIG_LOADING,
    FEE_CONFIG_ERROR,
    FEE_CONFIG,

    CLEAR_ACTIVE_FEE_ERROR,
    CLEAR_ACTIVE_FEE,
    ACTIVE_FEE_LOADING,
    ACTIVE_FEE_ERROR,
    ACTIVE_FEE,

    CLEAR_REPORTS_ERROR,
    CLEAR_REPORTS,
    REPORTS_LOADING,
    RUN_REPORTS_ERROR,
    RUN_REPORTS,

    GET_REFERENCE_CONFIG,
    GET_REFERENCE_CONFIG_ERROR,
    GET_REFERENCE_CONFIG_LOADING,
    CLEAR_GET_REFERENCE_CONFIG_ERROR,
    CLEAR_GET_REFERENCE_CONFIG,

    CLEAR_UPDATE_REFERENCE_CONFIG_ERROR,
    CLEAR_UPDATE_REFERENCE_CONFIG,
    UPDATE_REFERENCE_CONFIG_LOADING,
    UPDATE_REFERENCE_CONFIG_ERROR,
    UPDATE_REFERENCE_CONFIG,
 
    
    CLEAR_ADD_MESSAGE_CONFIG,
    CLEAR_ADD_MESSAGE_CONFIG_ERROR,
    ADD_MESSAGE_CONFIG_LOADING,
    ADD_MESSAGE_CONFIG_ERROR,
    ADD_MESSAGE_CONFIG,

    GET_MESSAGE_CONFIG,
    GET_MESSAGE_CONFIG_ERROR,
    GET_MESSAGE_CONFIG_LOADING,
    CLEAR_GET_MESSAGE_CONFIG_ERROR,
    CLEAR_GET_MESSAGE_CONFIG,

    CLEAR_UPDATE_MESSAGE_CONFIG_ERROR,
    CLEAR_UPDATE_MESSAGE_CONFIG,
    UPDATE_MESSAGE_CONFIG_LOADING,
    UPDATE_MESSAGE_CONFIG_ERROR,
    UPDATE_MESSAGE_CONFIG,

    CLEAR_UPDATE_MESSAGE_CONFIG_STATUS_ERROR,
    CLEAR_UPDATE_MESSAGE_CONFIG_STATUS,
    UPDATE_MESSAGE_CONFIG_STATUS_LOADING,
    UPDATE_MESSAGE_CONFIG_STATUS_ERROR,
    UPDATE_MESSAGE_CONFIG_STATUS,

    CLEAR_GRAPHICAL_REPORT_ERROR,
    CLEAR_GRAPHICAL_REPORT,
    GRAPHICAL_REPORT_LOADING,
    GRAPHICAL_REPORT_ERROR,
    GRAPHICAL_REPORT,

    CLEAR_SUMMARY_REPORT_ERROR,
    CLEAR_SUMMARY_REPORT,
    SUMMARY_REPORT_LOADING,
    REPORT_SUMMARY_ERROR,
    REPORT_SUMMARY
} from './types';

let BASE_URL = process.env.REACT_APP_BASE_URL;

// ALL API CALLS GO UNDER HERE 

export const logout = () => {
    return dispatch => {
        localStorage.removeItem('remit_token');
        localStorage.removeItem('remit_refresh');
        dispatch(clearGetBranch());
        dispatch(clearAddBranch());
        dispatch(clearAddUser());
        dispatch(clearLogin());
        dispatch(clearAddRemitter());
        dispatch(clearUpdateBranch());
        dispatch(clearUpdateUser());
        dispatch(clearAddCurrency());
        dispatch(clearGetCurrency());
        dispatch(clearAddTransactionFees());
        dispatch(clearAddRemitReason());
        dispatch(clearGetRemitReasons());
        dispatch(clearForgotPassword());
        dispatch(clearRemitSource());
        dispatch(clearGetRemitSource());
        dispatch(clearUpdateRemitSource());
        dispatch(clearUpdateCurrency());
        dispatch(clearUpdateRemitReason());
        dispatch(clearActivateCurrency());
        dispatch(clearDeactivateCurrency());
        dispatch(clearActivateBranches());
        dispatch(clearDeactivateBranches());
        dispatch(clearChangePassword());
        dispatch(clearCurrentUserProfile());
        dispatch(clearUpdateCurrentUserProfile());
        dispatch(clearAddTransactionLimit());
        dispatch(clearGetTransactionLimit());
        dispatch(clearUpdateTransactionLimit());
        dispatch(clearActivateTransactionLimit());
        dispatch(clearDeactivateTransactionLimit());
        dispatch(clearActivateUsers());
        dispatch(clearDeactivateUsers());
        dispatch(clearAssignFloat());
        dispatch(clearUpdateFloat());
        dispatch(clearGetFloat());
        dispatch(clearFetchTellers());
        dispatch(clearConfirmFloat());
        dispatch(clearAddPartners());
        dispatch(clearGetPartners());
        dispatch(clearUpdatePartners());
        dispatch(clearAddPartnerBranches());
        dispatch(clearGetPartnerBranches());
        dispatch(clearUpdatePartnerBranches());
        dispatch(activatePartnerBranches());
        dispatch(deactivatePartnerBranches());
        dispatch(addPartnerUsers());
        dispatch(getPartnerUsers());
        dispatch(getRemitters());
        dispatch(getRemittersByNationalId());
        dispatch(getRemittersById());
        dispatch(updateRemitters());
        dispatch(activateRemitters());
        dispatch(deactivateRemitters());
        dispatch(clearPartnerBranchUsers());
        dispatch(clearSearchRemitter());
        dispatch(clearTransactionLogsById());
        dispatch(clearGetTransactionLogs());
        dispatch(clearFetchRemitterBeneficiary());
        dispatch(clearMakeRemittance());
        dispatch(clearGetAdminAuditTrail());
        dispatch(clearAdminAuditTrailById());
        dispatch(clearTellerFloat());
        dispatch(clearCashout());
        dispatch(clearProcessRemittance());
        dispatch(clearAddReconciliations());
        dispatch(clearAddReconciliationsOveralRecon());
        dispatch(clearAddReconciliationsPartnerRecon());
        dispatch(clearAddReconciliationsProcess());
        dispatch(clearGetReconciliations());
        dispatch(clearGetReconciliationsCurrentTeller());
        dispatch(clearGetReconciliationsAllCurrent());
        dispatch(clearGetReconciliationsById());
        dispatch(clearUpdateReconciliations());
        dispatch(clearRunningBalance());
        dispatch(clearUpdateFeeConfig());
        dispatch(clearUpdateRemittanceReversal());
        dispatch(clearActiveFee());
        dispatch(clearFeeConfig());
        dispatch(clearReports());
        dispatch(clearReferenceConfig());
        dispatch(clearUpdateReferenceConfig());
        dispatch(clearAddMessageConfig());
        dispatch(clearMessageConfig());
        dispatch(clearUpdateMessageConfig());
        dispatch(clearUpdateMessageConfigStatus());
        dispatch(clearGraphicalReport());
        dispatch(clearSummaryReport());
    }
}

export const login =(data) => dispatch => {
    dispatch(loginLoading());
    Axios.post(`${BASE_URL}/auth`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => {
        localStorage.setItem('remit_token', res.data.access_token);
        localStorage.setItem('remit_refresh', res.data.refreshToken);
        const decoded = jwt_decode(res.data.access_token);
        dispatch({
            type: LOGIN,
            payload: decoded
        })
    }).catch(err => {
        dispatch({
            type: LOGIN_ERROR,
            payload: err.response.data
        })
    })
}

export const loginLoading = ( ) => {
    return {
        type: LOGIN_LOADING
    }
}

export const clearLoginError = () => {
    return {
        type: CLEAR_LOGIN_ERROR
    }
}

export const clearLogin = ( ) => {
    return {
        type: CLEAR_LOGIN
    }
}

export const getBranch = (data) => dispatch => {
    dispatch(setGetBranchLoading());

    const runBranch = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/branches?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_BRANCH,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_BRANCH_ERROR,
                payload: err
            })
        })
    }

    runBranch();
}

export const setGetBranchLoading = () => {
    return {
        type: GET_BRANCH_LOADING
    }
}

export const clearGetBranchError = () => {
    return {
        type: CLEAR_GET_BRANCH_ERROR
    }
}

export const clearGetBranch = () => {
    return {
        type: CLEAR_GET_BRANCH
    }
}

export const addBranch = (data) => dispatch => {
    dispatch(addBranchLoading());

    const runAddBranch = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.post(`${BASE_URL}/branches`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_BRANCH,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_BRANCH_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddBranch();
}

export const addBranchLoading = () => {
    return {
        type: ADD_BRANCH_LOADING,
    }
}

export const clearAddBranchError = () => {
    return {
        type: CLEAR_ADD_BRANCH_ERROR
    }
}

export const clearAddBranch = () => {
    return {
        type: CLEAR_ADD_BRANCH
    }
}

//ADD USER 

export const addUser = (data) => dispatch => {
    dispatch(addUserLoading());

    const runAddUser = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.post(`${BASE_URL}/users`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_USER,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_USER_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddUser();
}

export const addUserLoading = () => {
    return {
        type: ADD_USER_LOADING,
    }
}

export const clearAddUserError = () => {
    return {
        type: CLEAR_ADD_USER_ERROR
    }
}

export const clearAddUser = () => {
    return {
        type: CLEAR_ADD_USER
    }
}

// get users


export const getUsers = (data) => dispatch => {
    dispatch(getUsersLoading());

    const runGetUsers = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/users?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_USERS,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: GET_USERS_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runGetUsers();
}

export const getUsersLoading = () => {
    return {
        type: GET_USERS_LOADING,
    }
}

export const clearGetUsersError = () => {
    return {
        type: CLEAR_GET_USERS_ERROR
    }
}

export const clearGetUsers = () => {
    return {
        type: CLEAR_GET_USERS
    }
}

export const addRemitter = (data) => dispatch => {
    dispatch(addRemitterLoading());

    const runAddRemitter = async () => {
        let token = await localStorage.getItem('remit_token');
        var formData = new FormData();

        formData.append('firstname', data.firstname);
        formData.append('middlename', data.middlename);
        formData.append('lastname', data.lastname);
        formData.append('nationalId', data.nationalId);
        formData.append('mobile', data.mobile);
        formData.append('email', data.email);
        formData.append('address', data.address);
        formData.append('city', data.city);
        formData.append('province', data.province);
        formData.append('idDocument', data.idDocument);
        formData.append('gender', data.gender);
        formData.append('dob', data.dob);
        formData.append('beneficiaryFirstname', data.beneficiaryFirstname);
        formData.append('beneficiaryMiddlename', data.beneficiaryMiddlename);
        formData.append('beneficiaryLastname', data.beneficiaryLastname);
        formData.append('beneficiaryNationalId', data.beneficiaryNationalId);
        formData.append('beneficiaryMobile', data.beneficiaryMobile);
        formData.append('beneficiaryAddress', data.beneficiaryAddress);
        formData.append('amount', data.amount);
        formData.append('currency', data.currency);
        formData.append('charges', data.charges);
        formData.append('source', data.source);
        formData.append('purpose', data.purpose);

        Axios.post(`${BASE_URL}/remittances/register`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(reponse => {
            dispatch({
                type: ADD_REMITTER,
                payload: reponse
            })
        }).catch(err => {
            dispatch({
                type: ADD_REMITTER_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddRemitter();
}

export const addRemitterLoading = () => {
    return {
        type: ADD_REMITTER_LOADING
    }
}

export const clearAddRemitter = () => {
    return {
        type: CLEAR_ADD_REMITTER
    }
}

export const clearAddRemitterError = () => {
    return {
        type: CLEAR_ADD_REMITTER_ERROR
    }
}

export const updateBranch = (data) => dispatch => {
    dispatch(updateBranchLoading());

    const runUpdateBranch = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.patch(`${BASE_URL}/branches/${data.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: UPDATE_BRANCH,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: UPDATE_BRANCH_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runUpdateBranch();
}

export const updateBranchLoading = () => {
    return {
        type: UPDATE_BRANCH_LOADING
    }
}

export const clearUpdateBranch = () => {
    return {
        type: CLEAR_UPDATE_BRANCH
    }
}

export const clearUpdateBranchError = () => {
    return {
        type: CLEAR_UPDATE_BRANCH_ERROR
    }
}

export const updateUser = (data) => dispatch => {
    dispatch(updateUserLoading());

    const runUpdateUser = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.patch(`${BASE_URL}/users/${data.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: UPDATE_USER,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: UPDATE_USER_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runUpdateUser();
}

export const updateUserLoading = () => {
    return {
        type: UPDATE_USER_LOADING
    }
}

export const clearUpdateUser = () => {
    return {
        type: CLEAR_UPDATE_USER
    }
}

export const clearUpdateUserError = () => {
    return {
        type: CLEAR_UPDATE_USER_ERROR
    }
}

export const addCurrency = (data) => dispatch => {
    dispatch(addCurrencyLoading());

    const runAddCurrency = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.post(`${BASE_URL}/currencies`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_CURRENCY,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_CURRENCY_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddCurrency();
}

export const addCurrencyLoading = () => {
    return {
        type: ADD_CURRENCY_LOADING
    }
}

export const clearAddCurrency = () => {
    return {
        type: CLEAR_ADD_CURRENCY
    }
}

export const clearAddCurrencyError = () => {
    return {
        type: CLEAR_ADD_CURRENCY_ERROR
    }
}

export const getCurrency = (data) => dispatch => {
    dispatch(getCurrencyLoading());

    const runGetCurrency = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/currencies?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_CURRENCY,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_CURRENCY_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runGetCurrency();
}

export const getCurrencyLoading = () => {
    return {
        type: GET_CURRENCY_LOADING
    }
}

export const clearGetCurrency = () => {
    return {
        type: CLEAR_GET_CURRENCY
    }
}

export const clearGetCurrencyError = () => {
    return {
        type: CLEAR_GET_CURRENCY_ERROR
    }
}

export const addTransactionFees = (data) => dispatch => {
    dispatch(addTransactionFeesLoading());

    const runAddFees = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.post(`${BASE_URL}/fees`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_TRANSACTION_FEES,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_TRANSACTION_FEES_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddFees();
}

export const addTransactionFeesLoading = () => {
    return {
        type: ADD_TRANSACTION_FEES_LOADING
    }
}

export const clearAddTransactionFees = () => {
    return {
        type: CLEAR_ADD_TRANSACTION_FEES
    }
}

export const clearAddTransactionFeesError = () => {
    return {
        type: CLEAR_ADD_TRANSACTION_FEES_ERROR
    }
}

export const getTransactionFees = (data) => dispatch => {
    dispatch(getTransactionFeesLoading());

    const runGetFees = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/fees?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_TRANSACTION_FEES,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_TRANSACTION_FEES_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runGetFees();
}

export const getTransactionFeesLoading = () => {
    return {
        type: GET_TRANSACTION_FEES_LOADING
    }
}

export const clearGetTransactionFees = () => {
    return {
        type: CLEAR_GET_TRANSACTION_FEES
    }
}

export const clearGetTransactionFeesError = () => {
    return {
        type: CLEAR_GET_TRANSACTION_FEES_ERROR
    }
}

export const addRemitReason = (data) => dispatch => {
    dispatch(addRemitReasonLoading());

    const runAddRemitReason = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.post(`${BASE_URL}/remit-reasons`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_REMIT_REASON,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_REMIT_REASON_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddRemitReason();
}

export const addRemitReasonLoading = () => {
    return {
        type: ADD_REMIT_REASON_LOADING
    }
}

export const clearAddRemitReason = () => {
    return {
        type: CLEAR_ADD_REMIT_REASON
    }
}

export const clearAddRemitReasonError = () => {
    return {
        type: CLEAR_ADD_REMIT_REASON_ERROR
    }
}

export const getRemitReasons = (data) => dispatch => {
    dispatch(getRemitReasonsLoading());

    const runGetRemitReasons = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/remit-reasons?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_REMIT_REASONS,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_REMIT_REASONS_ERROR,
                payload: err.response.data.message
            })
        })  
    }

    runGetRemitReasons();
}

export const getRemitReasonsLoading = () => {
    return {
        type: GET_REMIT_REASONS_LOADING
    }
}

export const clearGetRemitReasons = () => {
    return {
        type: CLEAR_GET_REMIT_REASONS
    }
}

export const clearGetRemitReasonsError = () => {
    return {
        type: CLEAR_GET_REMIT_REASONS_ERROR
    }
}

export const forgotPassword = (data) => dispatch => {
    dispatch(forgotPasswordLoading());

    Axios.patch(`${BASE_URL}/users/reset/password`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }).then(() => {
        dispatch({
            type: FORGOT_PASSWORD,
            payload: "Password has been sent to " + data.email
        })
    }).catch(err => {
        dispatch({
            type: FORGOT_PASSWORD_ERROR,
            payload: err.response.data.message
        })
    })
}


export const forgotPasswordLoading = () => {
    return {
        type: FORGOT_PASSWORD_LOADING
    }
}

export const clearForgotPassword = () => {
    return {
        type: CLEAR_FORGOT_PASSWORD
    }
}
 
export const clearForgotPasswordError = () => {
    return {
        type: CLEAR_FORGOT_PASSWORD_ERROR
    }
}

export const remitSources = (data) => dispatch => {
    dispatch(remitSourcesLoading());

    const runRemitSources = async () => {
        let token = await localStorage.getItem('remit_token'); 

        Axios.post(`${BASE_URL}/remit-sources`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: REMIT_SOURCE,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: REMIT_SOURCE_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runRemitSources();
}

export const remitSourcesLoading = () => {
    return {
        type: REMIT_SOURCE_LOADING
    }
}

export const clearRemitSource = () => {
    return {
        type: CLEAR_REMIT_SOURCES
    }
}

export const clearRemitSourceError = () => {
    return {
        type: CLEAR_REMIT_SOURCES_ERROR
    }
}

export const getRemitSource = (data) => dispatch => {
    dispatch(getRemitSourceLoading());

    const runSource = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/remit-sources?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_REMIT_SOURCES,
                payload: response.data
            })
        }).then(err => {
            dispatch({
                type: GET_REMIT_SOURCES_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runSource();
}

export const getRemitSourceLoading = () => {
    return {
        type: GET_REMIT_SOURCES_LOADING
    }
}

export const clearGetRemitSource = () => {
    return {
        type: CLEAR_GET_REMIT_SOURCE
    }
}

export const clearGetRemitSourceError = () => {
    return {
        type: CLEAR_GET_REMIT_SOURCE_ERROR
    }
}

export const updateRemitSource = (data) => dispatch => {
    dispatch(updateRemitSourceLoading());

    const runUpdateRemitSource = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/remit-sources/${data.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: UPDATE_REMIT_SOURCE,
                payload: response
            })
        }).catch(error => {
            dispatch({
                type: UPDATE_REMIT_SOURCE_ERROR,
                payload: error.response.data.message
            })
        })
    }

    runUpdateRemitSource();
}

export const updateRemitSourceLoading = () => {
    return {
        type: UPDATE_REMIT_SOURCE_LOADING
    }
}

export const clearUpdateRemitSource = () => {
    return {
        type: CLEAR_UPDATE_REMIT_SOURCE
    }
}

export const clearUpdateRemitSourceError = () => {
    return {
        type: CLEAR_UPDATE_REMIT_SOURCE_ERROR
    }
}


//EDIT CURRENCY

export const updateCurrency = (data) => dispatch => {
        dispatch(updateCurrencyLoading());

        const runUpdateCurrency = async () =>{
            let token = await localStorage.getItem('remit_token');

            Axios.patch(`${BASE_URL}/currencies/${data.id}`,data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(response => {
                dispatch({
                    type:UPDATE_CURRENCY,
                    payload:response
                })
            }).catch(err => {
                dispatch({
                    type:UPDATE_CURRENCY_ERROR,
                    payload:err.response.data.message
                })
            })
        }
runUpdateCurrency();
}

export const updateCurrencyLoading = () => {
        return {
            type: UPDATE_CURRENCY_LOADING
        }
}

export const clearUpdateCurrency = () => {
    return {
        type: CLEAR_UPDATE_CURRENCY
    }
}

export const clearUpdateCurrencyError = () =>{
    return {
        type: CLEAR_UPDATE_CURRENCY_ERROR
    }
}


//EDIT TRANSACTION FEE

export const updateTransactionFee = (data) => dispatch => {
    dispatch(updateTransactionFeeLoading());

    const runUpdateTransactionFee = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/fees/${data.id}`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:UPDATE_TRANSACTION_FEE,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:UPDATE_TRANSACTION_FEE_ERROR,
                payload:err.response.data.message
            })
        })
    }
runUpdateTransactionFee();
}

export const updateTransactionFeeLoading = () => {
    return {
        type: UPDATE_TRANSACTION_FEE_LOADING
    }
}

export const clearUpdateTransactionFee = () => {
return {
    type: CLEAR_UPDATE_TRANSACTION_FEE
}
}

export const clearUpdateTransactionFeeError = () =>{
return {
    type: CLEAR_UPDATE_TRANSACTION_FEE_ERROR
}
}

// ACTIVATE TRANSACTION FEE

export const activateTransactionFee = (data) => dispatch => {
    dispatch(activateTransactionFeeLoading());

    const runActivateTransactionFee = async () =>{
        let token = await localStorage.getItem('remit_token');
        Axios.patch(`${BASE_URL}/fees/${data.id}/activate`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:ACTIVATE_TRANSACTION_FEE,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:ACTIVATE_TRANSACTION_FEE_ERROR,
                payload:err.response.data.message
            })
        })
    }
runActivateTransactionFee();
}

export const activateTransactionFeeLoading = () => {
    return {
        type: ACTIVATE_TRANSACTION_FEE_LOADING
    }
}

export const clearActivateTransactionFee = () => {
return {
    type: CLEAR_ACTIVATE_TRANSACTION_FEE
}
}

export const clearActivateTransactionFeeError = () =>{
return {
    type: CLEAR_ACTIVATE_TRANSACTION_FEE_ERROR
}
}



//DEACTIVATE TRANSACTION FEE

export const deactivateTransactionFee = (data) => dispatch => {
    dispatch(deactivateTransactionFeeLoading());

    const runDeactivateTransactionFee = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/fees/${data.id}/suspend`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:DEACTIVATE_TRANSACTION_FEE,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:DEACTIVATE_TRANSACTION_FEE_ERROR,
                payload:err.response.data.message
            })
        })
    }
runDeactivateTransactionFee();
}

export const deactivateTransactionFeeLoading = () => {
    return {
        type: DEACTIVATE_TRANSACTION_FEE_LOADING
    }
}

export const clearDeactivateTransactionFee = () => {
    return {
        type: CLEAR_DEACTIVATE_TRANSACTION_FEE
    }
}


export const clearDeactivateTransactionFeeError = () =>{
    return {
        type: CLEAR_DEACTIVATE_TRANSACTION_FEE_ERROR
    }
}

//EDIT REMIT REASON

export const updateRemitReason = (data) => dispatch => {
    dispatch(updateRemitReasonLoading());

    const runUpdateRemitReason = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/remit-reasons/${data.id}`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:UPDATE_REMIT_REASON,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:UPDATE_REMIT_REASON_ERROR,
                payload: err.response.data.message
            })
        })
    }
runUpdateRemitReason();
}

export const updateRemitReasonLoading = () => {
    return {
        type: UPDATE_REMIT_REASON_LOADING
    }
}

export const clearUpdateRemitReason = () => {
    return {
        type: CLEAR_UPDATE_REMIT_REASON
    }
}

export const clearUpdateRemitReasonError = () =>{
    return {
        type: CLEAR_UPDATE_REMIT_REASON_ERROR
    }
}

export const changePassword = (data) => dispatch => {
    dispatch(changePasswordLoading());

    const runChangePassword = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/users/change/current-password`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: CHANGE_PASSWORD,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: CHANGE_PASSWORD_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runChangePassword();
}

export const changePasswordLoading = () => {
    return {
        type: CHANGE_PASSWORD_LOADING,
    }
}

export const clearChangePassword = () => {
    return {
        type: CLEAR_CHANGE_PASSWORD
    }
}

export const clearChangePasswordError = () => {
    return {
        type: CLEAR_CHANGE_PASSWORD_ERROR
    }
}

export const currentUserProfile = (data) => dispatch => {
    dispatch(currentUserProfileLoading());

    const runCurrentUser = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/users/${data.id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            dispatch({
                type: CURRENT_USER_PROFILE,
                payload: res
            })
        }).catch(err => {
            dispatch({
                type: CURRENT_USER_PROFILE_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runCurrentUser();
}

export const currentUserProfileLoading = () => {
    return {
        type: CURRENT_USER_PROFILE_LOADING
    }
}

export const clearCurrentUserProfile = () => {
    return {
        type: CLEAR_CURRENT_USER_PROFILE
    }
}

export const clearCurrentUserProfileError = () => {
    return {
        type: CLEAR_CURRENT_USER_PROFILE_ERROR
    }
}

export const updateCurrentUserProfile = (data) => dispatch => {
    dispatch(updateCurrentUserProfileLoading());

    const runUpdateProfile = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/users/${data.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: UPDATE_CURRENT_USER_PROFILE,
                payload: response
            })
        }).catch(error => {
            dispatch({
                type: UPDATE_CURRENT_USER_PROFILE_ERROR,
                payload: error.response.data.message
            })
        })
    }

    runUpdateProfile();
}

export const updateCurrentUserProfileLoading = () => {
    return {
        type: UPDATE_CURRENT_USER_PROFILE_LOADING
    }
}

export const clearUpdateCurrentUserProfile = () => {
    return {
        type: CLEAR_UPDATE_CURRENT_USER_PROFILE
    }
}

export const clearUpdateCurrentUserProfileError = () => {
    return {
        type: CLEAR_UPDATE_CURRENT_USER_PROFILE_ERROR
    }
}

//ACTIVATE CURRENCY

export const activateCurrency = (data) => dispatch => {
    dispatch(activateCurrencyLoading());

    const runActivateCurrency = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/currencies/${data.id}/activate`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:ACTIVATE_CURRENCY,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:ACTIVATE_CURRENCY_ERROR,
                payload:err.response.data.message
            })
        })
    }
runActivateCurrency();
}

export const activateCurrencyLoading = () => {
    return {
        type: ACTIVATE_CURRENCY_LOADING
    }
}

export const clearActivateCurrency = () => {
return {
    type: CLEAR_ACTIVATE_CURRENCY
}
}

export const clearActivateCurrencyError = () =>{
return {
    type: CLEAR_ACTIVATE_CURRENCY_ERROR
}
}



//DEACTIVATE CURRENCY

export const deactivateCurrency = (data) => dispatch => {
    dispatch(deactivateCurrencyLoading());

    const runDeactivateCurrency = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/currencies/${data.id}/deactivate`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:DEACTIVATE_CURRENCY,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:DEACTIVATE_CURRENCY_ERROR,
                payload:err.response.data.message
            })
        })
    }
runDeactivateCurrency();
}

export const deactivateCurrencyLoading = () => {
    return {
        type: DEACTIVATE_CURRENCY_LOADING
    }
}

export const clearDeactivateCurrency = () => {
return {
    type: CLEAR_DEACTIVATE_CURRENCY
}
}


export const clearDeactivateCurrencyError = () =>{
return {
    type: CLEAR_DEACTIVATE_CURRENCY_ERROR
}
}


//ACTIVATE BRANCHES

export const activateBranches = (data) => dispatch => {
    dispatch(activateBranchesLoading());

    const runActivateBranches = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/branches/${data.id}/activate`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:ACTIVATE_BRANCHES,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:ACTIVATE_BRANCHES_ERROR,
                payload:err.response.data.message
            })
        })
    }
runActivateBranches();
}

export const activateBranchesLoading = () => {
    return {
        type: ACTIVATE_BRANCHES_LOADING
    }
}

export const clearActivateBranches = () => {
return {
    type: CLEAR_ACTIVATE_BRANCHES
}
}

export const clearActivateBranchesError = () =>{
return {
    type: CLEAR_ACTIVATE_BRANCHES_ERROR
}
}



//DEACTIVATE BRANCHES

export const deactivateBranches = (data) => dispatch => {
    dispatch(deactivateBranchesLoading());

    const runDeactivateBranches = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/branches/${data.id}/deactivate`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:DEACTIVATE_BRANCHES,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:DEACTIVATE_BRANCHES_ERROR,
                payload:err.response.data.message
            })
        })
    }
runDeactivateBranches();
}

export const deactivateBranchesLoading = () => {
    return {
        type: DEACTIVATE_BRANCHES_LOADING
    }
}

export const clearDeactivateBranches = () => {
    return {
        type: CLEAR_DEACTIVATE_BRANCHES
    }
}


export const clearDeactivateBranchesError = () =>{
    return {
        type: CLEAR_DEACTIVATE_BRANCHES_ERROR
    }
}


export const assignFloat = (data) => dispatch => {
    dispatch(assignFloatLoading());

    const runAssignFloat = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.post(`${BASE_URL}/floats`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ASSIGN_FLOAT,
                payload: response
            })
        }).catch(error => {
            dispatch({
                type: ASSIGN_FLOAT_ERROR,
                payload: error.response.data.message
            })
        })
    }

    runAssignFloat();
}

export const assignFloatLoading = () => {
    return {
        type: ASSIGN_FLOAT_LOADING,
    }
}

export const clearAssignFloat = () => {
    return {
        type: CLEAR_ASSIGN_FLOAT,
    }
}

export const clearAssignFloatError = () => {
    return {
        type: CLEAR_ASSIGN_FLOAT_ERROR,
    }
}

export const updateFloat = (data) => dispatch => {
    dispatch(updateFloatLoading());

    const runUpdateFloat = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/floats/${data.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: UPDATE_FLOAT,
                payload: response
            })
        }).catch(error => {
            dispatch({
                type: UPDATE_FLOAT_ERROR,
                payload: error.response.data.message
            })
        })
    }

    runUpdateFloat();
}

export const updateFloatLoading = () => {
    return {
        type: UPDATE_FLOAT_LOADING,
    }
}

export const clearUpdateFloat = () => {
    return {
        type: CLEAR_UPDATE_FLOAT,
    }
}

export const clearUpdateFloatError = () => {
    return {
        type: CLEAR_UPDATE_FLOAT_ERROR,
    }
}

export const getFloat = (data) => dispatch => {
    dispatch(getFloatLoading());

    const runGetFloat = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/floats/?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_FLOAT,
                payload: response.data
            })
        }).catch(error => {
            dispatch({
                type: GET_FLOAT_ERROR,
                payload: error.response.data.message
            })
        })
    }

    runGetFloat();
}

export const getFloatLoading = () => {
    return {
        type: GET_FLOAT_LOADING,
    }
}

export const clearGetFloat = () => {
    return {
        type: CLEAR_GET_FLOAT,
    }
}

export const clearGetFloatError = () => {
    return {
        type: CLEAR_GET_FLOAT_ERROR,
    }
}


//ADD TRANSACTION LIMIT

export const addTransactionLimit = (data) => dispatch => {
    dispatch(addTransactionLimitLoading());

    const runAddTransactionLimit = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.post(`${BASE_URL}/rules`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_TRANSACTION_LIMIT,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_TRANSACTION_LIMIT_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddTransactionLimit();
}

export const addTransactionLimitLoading = () => {
    return {
        type: ADD_TRANSACTION_LIMIT_LOADING,
    }
}

export const clearAddTransactionLimitError = () => {
    return {
        type: CLEAR_ADD_TRANSACTION_LIMIT_ERROR
    }
}

export const clearAddTransactionLimit = () => {
    return {
        type: CLEAR_ADD_TRANSACTION_LIMIT
    }
}


//GET TRANSACTION LIMITS

export const getTransactionLimit = (data) => dispatch => {
    dispatch(setGetTransactionLimitLoading());

    const runTransactionLimit = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/rules?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_TRANSACTION_LIMIT,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_TRANSACTION_LIMIT_ERROR,
                payload: err
            })
        })
    }

    runTransactionLimit();
}

export const setGetTransactionLimitLoading = () => {
    return {
        type: GET_TRANSACTION_LIMIT_LOADING
    }
}

export const clearGetTransactionLimitError = () => {
    return {
        type: CLEAR_GET_TRANSACTION_LIMIT_ERROR
    }
}

export const clearGetTransactionLimit = () => {
    return {
        type: CLEAR_GET_TRANSACTION_LIMIT
    }
}


// UPDATE TRANSACTION LIMIT

export const updateTransactionLimit = (data) => dispatch => {
    dispatch(updateTransactionLimitLoading());

    const runUpdateTransactionLimit = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.patch(`${BASE_URL}/rules/${data.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: UPDATE_TRANSACTION_LIMIT,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: UPDATE_TRANSACTION_LIMIT_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runUpdateTransactionLimit();
}

export const updateTransactionLimitLoading = () => {
    return {
        type: UPDATE_TRANSACTION_LIMIT_LOADING
    }
}

export const clearUpdateTransactionLimit = () => {
    return {
        type: CLEAR_UPDATE_TRANSACTION_LIMIT
    }
}

export const clearUpdateTransactionLimitError = () => {
    return {
        type: CLEAR_UPDATE_TRANSACTION_LIMIT_ERROR
    }
}


// ACTIVATE TRANSACTION LIMIT

export const activateTransactionLimit = (data) => dispatch => {
    dispatch(activateTransactionLimitLoading());

    const runActivateTransactionLimit = async () =>{
        let token = await localStorage.getItem('remit_token');
        Axios.patch(`${BASE_URL}/rules/${data.id}/activate`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:ACTIVATE_TRANSACTION_LIMIT,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:ACTIVATE_TRANSACTION_LIMIT_ERROR,
                payload:err.response.data.message
            })
        })
    }
runActivateTransactionLimit();
}

export const activateTransactionLimitLoading = () => {
    return {
        type: ACTIVATE_TRANSACTION_LIMIT_LOADING
    }
}

export const clearActivateTransactionLimit = () => {
return {
    type: CLEAR_ACTIVATE_TRANSACTION_LIMIT
}
}

export const clearActivateTransactionLimitError = () =>{
return {
    type: CLEAR_ACTIVATE_TRANSACTION_LIMIT_ERROR
}
}



//DEACTIVATE TRANSACTION LIMIT

export const deactivateTransactionLimit = (data) => dispatch => {
    dispatch(deactivateTransactionLimitLoading());

    const runDeactivateTransactionLimit = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/rules/${data.id}/deactivate`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:DEACTIVATE_TRANSACTION_LIMIT,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:DEACTIVATE_TRANSACTION_LIMIT_ERROR,
                payload:err.response.data.message
            })
        })
    }
runDeactivateTransactionLimit();
}

export const deactivateTransactionLimitLoading = () => {
    return {
        type: DEACTIVATE_TRANSACTION_LIMIT_LOADING
    }
}

export const clearDeactivateTransactionLimit = () => {
return {
    type: CLEAR_DEACTIVATE_TRANSACTION_LIMIT
}
}


export const clearDeactivateTransactionLimitError = () =>{
return {
    type: CLEAR_DEACTIVATE_TRANSACTION_LIMIT_ERROR
}
}


// ACTIVATE USERS

export const activateUsers = (data) => dispatch => {
    dispatch(activateUsersLoading());

    const runActivateUsers = async () =>{
        let token = await localStorage.getItem('remit_token');
        Axios.patch(`${BASE_URL}/users/${data.id}/activate`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:ACTIVATE_USERS,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:ACTIVATE_USERS_ERROR,
                payload:err.response.data.message
            })
        })
    }
runActivateUsers();
}

export const activateUsersLoading = () => {
    return {
        type: ACTIVATE_USERS_LOADING
    }
}

export const clearActivateUsers = () => {
return {
    type: CLEAR_ACTIVATE_USERS
}
}

export const clearActivateUsersError = () =>{
return {
    type: CLEAR_ACTIVATE_USERS_ERROR
}
}



//DEACTIVATE USERS

export const deactivateUsers = (data) => dispatch => {
    dispatch(deactivateUsersLoading());

    const runDeactivateUsers = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/users/${data.id}/suspend`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:DEACTIVATE_USERS,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:DEACTIVATE_USERS_ERROR,
                payload:err.response.data.message
            })
        })
    }
runDeactivateUsers();
}

export const deactivateUsersLoading = () => {
    return {
        type: DEACTIVATE_USERS_LOADING
    }
}

export const clearDeactivateUsers = () => {
    return {
        type: CLEAR_DEACTIVATE_USERS
    }
}


export const clearDeactivateUsersError = () =>{
    return {
        type: CLEAR_DEACTIVATE_USERS_ERROR
    }
}

export const fetchTellers  = () => dispatch => {
    dispatch(fetchTellersLoading());

    const runTellers = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/users/branch-tellers`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: FETCH_TELLERS,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: FETCH_TELLERS_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runTellers();
}

export const fetchTellersLoading = () => {
    return {
        type: FETCH_TELLERS_LOADING
    }
}

export const clearFetchTellers = () => {
    return {
        type: CLEAR_FETCH_TELLERS,
    }
}

export const clearFetchTellersError = () => {
    return {
        type: CLEAR_FETCH_TELLERS_ERROR
    }
}

export const confirmFloat = (data) => dispatch => {
    dispatch(confirmFloatLoading());

    const runConfirmFloat = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/floats/${data.id}/confirm`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: CONFIRM_FLOAT,
                payload: response
            })
        }).catch(error => {
            dispatch({
                type: CONFIRM_FLOAT_ERROR,
                payload: error.response.data.message
            })
        })
    }

    runConfirmFloat();
}

export const confirmFloatLoading = () => {
    return {
        type: CONFIRM_FLOAT_LOADING,
    }
}

export const clearConfirmFloat = () => {
    return {
        type: CLEAR_CONFIRM_FLOAT,
    }
}

export const clearConfirmFloatError = () => {
    return {
        type: CLEAR_CONFIRM_FLOAT_ERROR,
    }
}


//ADD PARTNERS

export const addPartners = (data) => dispatch => {
    dispatch(addPartnersLoading());

    const runAddPartners = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.post(`${BASE_URL}/partners`, data, {     
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_PARTNERS,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_PARTNERS_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddPartners();
}

export const addPartnersLoading = () => {
    return {
        type: ADD_PARTNERS_LOADING
    }
}

export const clearAddPartners = () => {
    return {
        type: CLEAR_ADD_PARTNERS
    }
}

export const clearAddPartnersError = () => {
    return {
        type: CLEAR_ADD_PARTNERS_ERROR
    }
}

// GET PARTNERS

export const getPartners = (data) => dispatch => {
    dispatch(getPartnersLoading());

    const runGetPartners = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/partners?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_PARTNERS,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_PARTNERS_ERROR,
                payload: err.response.data.message
            })
        })  
    }

    runGetPartners();
}

export const getPartnersLoading = () => {
    return {
        type: GET_PARTNERS_LOADING
    }
}

export const clearGetPartners = () => {
    return {
        type: CLEAR_GET_PARTNERS
    }
}

export const clearGetPartnersError = () => {
    return {
        type: CLEAR_GET_PARTNERS_ERROR
    }
}


//UPDATE PARTNERS

export const updatePartners = (data) => dispatch => {
    dispatch(updatePartnersLoading());

    const runUpdatePartners = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/partners/${data.id}`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:UPDATE_PARTNERS,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:UPDATE_PARTNERS_ERROR,
                payload: err.response.data.message
            })
        })
    }
runUpdatePartners();
}

export const updatePartnersLoading = () => {
    return {
        type: UPDATE_PARTNERS_LOADING
    }
}

export const clearUpdatePartners = () => {
    return {
        type: CLEAR_UPDATE_PARTNERS
    }
}

export const clearUpdatePartnersError = () =>{
    return {
        type: CLEAR_UPDATE_PARTNERS_ERROR
    }
}


//ADD PARTNER BRANCHES

export const addPartnerBranches = (data) => dispatch => {
    dispatch(addPartnerBranchesLoading());

    const runAddPartnerBranches = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.post(`${BASE_URL}/branches`, data, {     
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_PARTNER_BRANCHES,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_PARTNER_BRANCHES_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddPartnerBranches();
}

export const addPartnerBranchesLoading = () => {
    return {
        type: ADD_PARTNER_BRANCHES_LOADING
    }
}

export const clearAddPartnerBranches = () => {
    return {
        type: CLEAR_ADD_PARTNER_BRANCHES
    }
}

export const clearAddPartnerBranchesError = () => {
    return {
        type: CLEAR_ADD_PARTNER_BRANCHES_ERROR
    }
}

// GET PARTNER BRANCHES

export const getPartnerBranches = (data) => dispatch => {
    dispatch(getPartnerBranchesLoading());

    const runGetPartnerBranches = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/branches/${data.partnerId}/partner/branches?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_PARTNER_BRANCHES,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_PARTNER_BRANCHES_ERROR,
                payload: err.response.data.message
            })
        })  
    }

    runGetPartnerBranches();
}

export const getPartnerBranchesLoading = () => {
    return {
        type: GET_PARTNER_BRANCHES_LOADING
    }
}

export const clearGetPartnerBranches = () => {
    return {
        type: CLEAR_GET_PARTNER_BRANCHES
    }
}

export const clearGetPartnerBranchesError = () => {
    return {
        type: CLEAR_GET_PARTNER_BRANCHES_ERROR
    }
}


//UPDATE PARTNER BRANCHES

export const updatePartnerBranches = (data) => dispatch => {
    dispatch(updatePartnerBranchesLoading());

    const runUpdatePartnerBranches = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/branches/${data.id}`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:UPDATE_PARTNER_BRANCHES,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:UPDATE_PARTNER_BRANCHES_ERROR,
                payload: err.response.data.message
            })
        })
    }
runUpdatePartnerBranches();
}

export const updatePartnerBranchesLoading = () => {
    return {
        type: UPDATE_PARTNER_BRANCHES_LOADING
    }
}

export const clearUpdatePartnerBranches = () => {
    return {
        type: CLEAR_UPDATE_PARTNER_BRANCHES
    }
}

export const clearUpdatePartnerBranchesError = () =>{
    return {
        type: CLEAR_UPDATE_PARTNER_BRANCHES_ERROR
    }
}


// ACTIVATE PARTNER BRANCHES

export const activatePartnerBranches = (data) => dispatch => {
    dispatch(activatePartnerBranchesLoading());

    const runActivatePartnerBranches = async () =>{
        let token = await localStorage.getItem('remit_token');
        Axios.patch(`${BASE_URL}/branches/${data.id}/activate`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:ACTIVATE_PARTNER_BRANCHES,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:ACTIVATE_PARTNER_BRANCHES_ERROR,
                payload:err.response.data.message
            })
        })
    }
runActivatePartnerBranches();
}

export const activatePartnerBranchesLoading = () => {
    return {
        type: ACTIVATE_PARTNER_BRANCHES_LOADING
    }
}

export const clearActivatePartnerBranches = () => {
return {
    type: CLEAR_ACTIVATE_PARTNER_BRANCHES
}
}

export const clearActivatePartnerBranchesError = () =>{
return {
    type: CLEAR_ACTIVATE_PARTNER_BRANCHES_ERROR
}
}



//DEACTIVATE PARTNER BRANCHES

export const deactivatePartnerBranches = (data) => dispatch => {
    dispatch(deactivatePartnerBranchesLoading());

    const runDeactivatePartnerBranches = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/branches/${data.id}/deactivate`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:DEACTIVATE_PARTNER_BRANCHES,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:DEACTIVATE_PARTNER_BRANCHES_ERROR,
                payload:err.response.data.message
            })
        })
    }
runDeactivatePartnerBranches();
}

export const deactivatePartnerBranchesLoading = () => {
    return {
        type: DEACTIVATE_PARTNER_BRANCHES_LOADING
    }
}

export const clearDeactivatePartnerBranches = () => {
return {
    type: CLEAR_DEACTIVATE_PARTNER_BRANCHES
}
}


export const clearDeactivatePartnerBranchesError = () =>{
return {
    type: CLEAR_DEACTIVATE_PARTNER_BRANCHES_ERROR
}
}


//ADD PARTNER BRANCH USER 

export const addPartnerUsers = (data) => dispatch => {
    dispatch(addPartnerUsersLoading());

    const runAddPartnerUsers = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.post(`${BASE_URL}/users/partner/create`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_PARTNER_USERS,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_PARTNER_USERS_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddPartnerUsers();
}

export const addPartnerUsersLoading = () => {
    return {
        type: ADD_PARTNER_USERS_LOADING,
    }
}

export const clearAddPartnerUsersError = () => {
    return {
        type: CLEAR_ADD_PARTNER_USERS_ERROR
    }
}

export const clearAddPartnerUsers = () => {
    return {
        type: CLEAR_ADD_PARTNER_USERS
    }
}

// GET PARTNER BRANCH USERS

export const getPartnerUsers = (data) => dispatch => {
    dispatch(setGetPartnerUsersLoading());

    const runPartnerUsers = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/users/partner-users?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_PARTNER_USERS,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_PARTNER_USERS_ERROR,
                payload: err
            })
        })
    }

    runPartnerUsers();
}

export const setGetPartnerUsersLoading = () => {
    return {
        type: GET_PARTNER_USERS_LOADING
    }
}

export const clearGetPartnerUsersError = () => {
    return {
        type: CLEAR_GET_PARTNER_USERS_ERROR
    }
}

export const clearGetPartnerUsers = () => {
    return {
        type: CLEAR_GET_PARTNER_USERS
    }
}


// GET REMITTERS

export const getRemitters = (data) => dispatch => {
    dispatch(setGetRemittersLoading());

    const runRemitters = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/clients?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_REMITTERS,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_REMITTERS_ERROR,
                payload: err
            })
        })
    }

    runRemitters();
}

export const setGetRemittersLoading = () => {
    return {
        type: GET_REMITTERS_LOADING
    }
}

export const clearGetRemittersError = () => {
    return {
        type: CLEAR_GET_REMITTERS_ERROR
    }
}

export const clearGetRemitters = () => {
    return {
        type: CLEAR_GET_REMITTERS
    }
}


// GET REMITTERS BY NATIONAL ID

export const getRemittersByNationalId = (data) => dispatch => {
    dispatch(setGetRemittersByNationalIdLoading());

    const runRemittersByNationalId = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/clients/byNationalId?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_REMITTERS_BY_NATIONAL_ID,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_REMITTERS_BY_NATIONAL_ID_ERROR,
                payload: err
            })
        })
    }

    runRemittersByNationalId();
}

export const setGetRemittersByNationalIdLoading = () => {
    return {
        type: GET_REMITTERS_BY_NATIONAL_ID_LOADING
    }
}

export const clearGetRemittersByNationalIdError = () => {
    return {
        type: CLEAR_GET_REMITTERS_BY_NATIONAL_ID_ERROR
    }
}

export const clearGetRemittersByNationalId = () => {
    return {
        type: CLEAR_GET_REMITTERS_BY_NATIONAL_ID
    }
}


// GET REMITTERS BY ID

export const getRemittersById = (data) => dispatch => {
    dispatch(getRemittersByIdLoading());

    const runGetRemittersById = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/clients/${data.remitterId}/partner/branches?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_REMITTERS_BY_ID,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_REMITTERS_BY_ID_ERROR,
                payload: err.response.data.message
            })
        })  
    }

    runGetRemittersById();
}

export const getRemittersByIdLoading = () => {
    return {
        type: GET_REMITTERS_BY_ID_LOADING
    }
}

export const clearGetRemittersById = () => {
    return {
        type: CLEAR_GET_REMITTERS_BY_ID
    }
}

export const clearGetRemittersByIdError = () => {
    return {
        type: CLEAR_GET_REMITTERS_BY_ID_ERROR
    }
}


//UPDATE REMITTERS

export const updateRemitters = (data) => dispatch => {
    dispatch(updateRemittersLoading());

    const runUpdateRemitters = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/clients/${data.id}`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:UPDATE_REMITTERS,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:UPDATE_REMITTERS_ERROR,
                payload: err.response.data.message
            })
        })
    }
runUpdateRemitters();
}

export const updateRemittersLoading = () => {
    return {
        type: UPDATE_REMITTERS_LOADING
    }
}

export const clearUpdateRemitters = () => {
    return {
        type: CLEAR_UPDATE_REMITTERS
    }
}

export const clearUpdateRemittersError = () =>{
    return {
        type: CLEAR_UPDATE_REMITTERS_ERROR
    }
}

// ACTIVATE REMITTERS

export const activateRemitters = (data) => dispatch => {
    dispatch(activateRemittersLoading());

    const runActivateRemitters = async () =>{
        let token = await localStorage.getItem('remit_token');
        Axios.patch(`${BASE_URL}/clients/${data.id}/activate`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:ACTIVATE_REMITTERS,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:ACTIVATE_REMITTERS_ERROR,
                payload:err.response.data.message
            })
        })
    }
runActivateRemitters();
}

export const activateRemittersLoading = () => {
    return {
        type: ACTIVATE_REMITTERS_LOADING
    }
}

export const clearActivateRemitters = () => {
return {
    type: CLEAR_ACTIVATE_REMITTERS
}
}

export const clearActivateRemittersError = () =>{
return {
    type: CLEAR_ACTIVATE_REMITTERS_ERROR
}
}



//DEACTIVATE REMITTERS

export const deactivateRemitters = (data) => dispatch => {
    dispatch(deactivateRemittersLoading());

    const runDeactivateRemitters = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/clients/${data.id}/suspend`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:DEACTIVATE_REMITTERS,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:DEACTIVATE_REMITTERS_ERROR,
                payload:err.response.data.message
            })
        })
    }
runDeactivateRemitters();
}

export const deactivateRemittersLoading = () => {
    return {
        type: DEACTIVATE_REMITTERS_LOADING
    }
}

export const clearDeactivateRemitters = () => {
    return {
        type: CLEAR_DEACTIVATE_REMITTERS
    }
}


export const clearDeactivateRemittersError = () =>{
    return {
        type: CLEAR_DEACTIVATE_REMITTERS_ERROR
    }
}

export const getPartnerBranchUsers = (data) => dispatch => {
    dispatch(setGetPartnerBranchUsersLoading());

    const runPartnerBranchUsers = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/users/${data.branchId}/branch/users?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            dispatch({
                type: PARTNER_BRANCH_USERS,
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: PARTNER_BRANCH_USERS_ERROR,
                payload: error
            })
        })
    }

    runPartnerBranchUsers();
}

export const clearPartnerBranchUsers = () => {
    return {
        type: CLEAR_PARTNER_BRANCH_USERS
    }
}

export const setGetPartnerBranchUsersLoading = () => {
    return {
        type: PARTNER_BRANCH_USERS_LOADING
    }
}

export const clearPartnerBranchUsersError = () => {
    return {
        type: CLEAR_PARTNER_BRANCH_USERS_ERROR
    }
}

export const searchRemitter = (nationalId) => dispatch => {
    dispatch(searchRemitterLoading());

    const runSearchRemitter = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/clients/byNationalId?nationalId=${nationalId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: SEARCH_REMITTER,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: SEARCH_REMITTER_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runSearchRemitter();
}

export const searchRemitterLoading = () => {
    return{
        type: SEARCH_REMITTER_LOADING,
    }
}

export const clearSearchRemitter = () => {
    return {
        type: CLEAR_SEARCH_REMITTER
    }
}

export const clearSearchRemitterError = () => {
    return {
        type: CLEAR_SEARCH_REMITTER_ERROR
    }
}



// GET TRANSACTION LOGS

export const getTransactionLogs = (data) => dispatch => {
    dispatch(setGetTransactionLogsLoading());

    const runTransactionLogs = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/transaction-record?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_TRANSACTION_LOGS,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_TRANSACTION_LOGS_ERROR,
                payload: err
            })
        })
    }

    runTransactionLogs();
}

export const setGetTransactionLogsLoading = () => {
    return {
        type: GET_TRANSACTION_LOGS_LOADING
    }
}

export const clearGetTransactionLogsError = () => {
    return {
        type: CLEAR_GET_TRANSACTION_LOGS_ERROR
    }
}

export const clearGetTransactionLogs = () => {
    return {
        type: CLEAR_GET_TRANSACTION_LOGS
    }
}

//GET TRANSACTION LOGS BY ID
export const getTransactionLogsById = (data) => dispatch => {
    dispatch(setGetTransactionLogsByIdLoading());

    const runTransactionLogsById = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/transaction-record/${data.logId}?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            dispatch({
                type: GET_TRANSACTION_LOGS_BY_ID,
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: GET_TRANSACTION_LOGS_BY_ID_ERROR,
                payload: error
            })
        })
    }

    runTransactionLogsById();
}

export const clearTransactionLogsById = () => {
    return {
        type: CLEAR_GET_TRANSACTION_LOGS_BY_ID
    }
}

export const setGetTransactionLogsByIdLoading = () => {
    return {
        type: GET_TRANSACTION_LOGS_BY_ID_LOADING
    }
}

export const clearTransactionLogsByIdError = () => {
    return {
        type: CLEAR_GET_TRANSACTION_LOGS_BY_ID_ERROR
    }
}

// FETCH REMITTER BENEFICIARY

export const fetchRemitterBeneficiary = (data) => dispatch => {
    dispatch(fetchRemitterBeneficiaryLoading());

    const runRecipient = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/beneficiaries?page=${data.page}&perPage=${data.perPage}&clientId=${data.clientId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: FETCH_REMITTER_BENEFICIARY,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: FETCH_REMITTER_BENEFICIARY_ERROR
            })
        })
    }

    runRecipient();
}

export const fetchRemitterBeneficiaryLoading = () => {
    return {
        type: FETCH_REMITTER_BENEFICIARY_LOADING
    }
}

export const clearFetchRemitterBeneficiary = () => {
    return {
        type: CLEAR_FETCH_REMITTER_BENEFICIARY
    }
}

export const clearFetchRemitterBeneficiaryError = () => {
    return {
        type: CLEAR_FETCH_REMITTER_BENEFICIARY_ERROR
    }
}

export const makeRemittances = (data) => dispatch => {
    dispatch(makeRemittanceLoading());

    const runRemittance = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.post(`${BASE_URL}/remittances`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: MAKE_REMITTANCE,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: MAKE_REMITTANCE_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runRemittance();
}

export const makeRemittanceLoading = () => {
    return {
        type: MAKE_REMITTANCE_LOADING
    }
}

export const clearMakeRemittance = () => {
    return {
        type: CLEAR_MAKE_REMITTANCE
    }
}

export const clearMakeRemittanceError = () => {
    return {
        type: CLEAR_MAKE_REMITTANCE_ERROR
    }
}


export const getAdminAuditTrail = (data) => dispatch => {
    dispatch(setGetAdminAuditTrailLoading());

    const runAdminAuditTrail = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/admin-trail?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_ADMIN_AUDIT_TRAIL,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_ADMIN_AUDIT_TRAIL_ERROR,
                payload: err
            })
        })
    }

    runAdminAuditTrail();
}

export const setGetAdminAuditTrailLoading = () => {
    return {
        type: GET_ADMIN_AUDIT_TRAIL_LOADING
    }
}

export const clearGetAdminAuditTrailError = () => {
    return {
        type: CLEAR_GET_ADMIN_AUDIT_TRAIL_ERROR
    }
}

export const clearGetAdminAuditTrail = () => {
    return {
        type: CLEAR_GET_ADMIN_AUDIT_TRAIL
    }
}

//GET ADMIN AUDIT TRAIL BY ID
export const getAdminAuditTrailById = (data) => dispatch => {
    dispatch(setGetAdminAuditTrailByIdLoading());

    const runAdminAuditTrailById = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/audit-trail/${data.trailId}?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            dispatch({
                type: GET_ADMIN_AUDIT_TRAIL_BY_ID,
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: GET_ADMIN_AUDIT_TRAIL_BY_ID_ERROR,
                payload: error
            })
        })
    }

    runAdminAuditTrailById();
}

export const clearAdminAuditTrailById = () => {
    return {
        type: CLEAR_GET_ADMIN_AUDIT_TRAIL_BY_ID
    }
}

export const setGetAdminAuditTrailByIdLoading = () => {
    return {
        type: GET_ADMIN_AUDIT_TRAIL_BY_ID_LOADING
    }
}

export const clearAdminAuditTrailByIdError = () => {
    return {
        type: CLEAR_GET_ADMIN_AUDIT_TRAIL_BY_ID_ERROR
    }
}

export const getTellerCurrentFloat = () => dispatch => {
    dispatch(tellerFloatLoading());

    const runTellerFloat = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/floats/current/float`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            dispatch({
                type: TELLER_FLOAT,
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: TELLER_FLOAT_ERROR,
                payload: error
            })
        })
    }

    runTellerFloat();
}

export const clearTellerFloat = () => {
    return {
        type: CLEAR_TELLER_FLOAT
    }
}

export const tellerFloatLoading = () => {
    return {
        type: TELLER_FLOAT_LOADING
    }
}

export const clearTellerFloatError = () => {
    return {
        type: CLEAR_TELLER_FLOAT_ERROR
    }
}

export const cashOut = (data) => dispatch => {
    dispatch(cashOutLoading());

    const runCashOut = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/remittances/${data.orderNumber}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            dispatch({
                type: CASHOUT,
                payload: response.data
            })
        }).catch((err) => {
            dispatch({
                type: CASHOUT_ERROR,
                payload: err
            })
        })
    }

    runCashOut();
}

export const cashOutLoading = () => {
    return {
        type: CASHOUT_LOADING
    }
}

export const clearCashout = () => {
    return {
        type: CLEAR_CASHOUT
    }
}

export const clearCashoutError = () => {
    return {
        type: CLEAR_CASHOUT_ERROR
    }
}

export const processRemittance = (data) => dispatch => {
    dispatch(processRemittanceLoading());

    const runProcessRemittance = async () => {
        let token = await localStorage.getItem('remit_token');
        var formData = new FormData();

        formData.append('tellerId', data.tellerId);
        formData.append('idDocument', data.idDocument);
        formData.append('id', data.id);

        Axios.patch(`${BASE_URL}/remittances/${data.id}/cash-out`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            dispatch({
                type: PROCESS_REMITTANCE,
                payload: response.data
            })
        }).catch((err) => {
            dispatch({
                type: PROCESS_REMITTANCE_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runProcessRemittance();
}

export const processRemittanceLoading = () => {
    return {
        type: PROCESS_REMITTANCE_LOADING
    }
}

export const clearProcessRemittance = () => {
    return {
        type: CLEAR_PROCESS_REMITTANCE
    }
}

export const clearProcessRemittanceError = () => {
    return {
        type: CLEAR_PROCESS_REMITTANCE_ERROR
    }
}

//POST RECONCILIATIONS

export const addReconciliations = (data) => dispatch => {
    dispatch(addReconciliationsLoading());

    const runAddReconciliations = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.post(`${BASE_URL}/reconciliations/${data.currency}`, data, {     
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_RECONCILIATIONS,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_RECONCILIATIONS_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddReconciliations();
}

export const addReconciliationsLoading = () => {
    return {
        type: ADD_RECONCILIATIONS_LOADING
    }
}

export const clearAddReconciliations = () => {
    return {
        type: CLEAR_ADD_RECONCILIATIONS
    }
}

export const clearAddReconciliationsError = () => {
    return {
        type: CLEAR_ADD_RECONCILIATIONS_ERROR
    }
}


//POST RECONCILIATIONS OVERAL RECON

export const addReconciliationsOveralRecon = (data) => dispatch => {
    dispatch(addReconciliationsOveralReconLoading());

    const runAddReconciliationsOveralRecon = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.post(`${BASE_URL}/reconciliations/overal/recon`, data, {     
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_RECONCILIATIONS_OVERAL_RECON,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_RECONCILIATIONS_OVERAL_RECON_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddReconciliationsOveralRecon();
}

export const addReconciliationsOveralReconLoading = () => {
    return {
        type: ADD_RECONCILIATIONS_OVERAL_RECON_LOADING
    }
}

export const clearAddReconciliationsOveralRecon = () => {
    return {
        type: CLEAR_ADD_RECONCILIATIONS_OVERAL_RECON
    }
}

export const clearAddReconciliationsOveralReconError = () => {
    return {
        type: CLEAR_ADD_RECONCILIATIONS_OVERAL_RECON_ERROR
    }
}


//POST RECONCILIATIONS PARTNER RECON

export const addReconciliationsPartnerRecon = (data) => dispatch => {
    dispatch(addReconciliationsPartnerReconLoading());

    const runAddReconciliationsPartnerRecon = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.post(`${BASE_URL}/reconciliations/partner/recon`, data, {     
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_RECONCILIATIONS_PARTNER_RECON,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_RECONCILIATIONS_PARTNER_RECON_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddReconciliationsPartnerRecon();
}

export const addReconciliationsPartnerReconLoading = () => {
    return {
        type: ADD_RECONCILIATIONS_PARTNER_RECON_LOADING
    }
}

export const clearAddReconciliationsPartnerRecon = () => {
    return {
        type: CLEAR_ADD_RECONCILIATIONS_PARTNER_RECON
    }
}

export const clearAddReconciliationsPartnerReconError = () => {
    return {
        type: CLEAR_ADD_RECONCILIATIONS_PARTNER_RECON_ERROR
    }
}


//POST RECONCILIATIONS BY ID / PROCESS

export const addReconciliationsProcess = (data) => dispatch => {
    dispatch(addReconciliationsProcessLoading());

    const runAddReconciliationsProcess = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.post(`${BASE_URL}/reconciliations/${data.id}/process`, data, {     
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_RECONCILIATIONS_ID_PROCESS,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_RECONCILIATIONS_ID_PROCESS_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddReconciliationsProcess();
}

export const addReconciliationsProcessLoading = () => {
    return {
        type: ADD_RECONCILIATIONS_ID_PROCESS_LOADING
    }
}

export const clearAddReconciliationsProcess = () => {
    return {
        type: CLEAR_ADD_RECONCILIATIONS_ID_PROCESS
    }
}

export const clearAddReconciliationsProcessError = () => {
    return {
        type: CLEAR_ADD_RECONCILIATIONS_ID_PROCESS_ERROR
    }
}


// GET RECONCILIATIONS BY ID

export const getReconciliationsById = (data) => dispatch => {
    dispatch(getReconciliationsByIdLoading());

    const runGetReconciliationsById = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/reconciliations/${data.id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_RECONCILIATIONS_BY_ID,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_RECONCILIATIONS_BY_ID_ERROR,
                payload: err.response.data.message
            })
        })  
    }

    runGetReconciliationsById();
}

export const getReconciliationsByIdLoading = () => {
    return {
        type: GET_RECONCILIATIONS_BY_ID_LOADING
    }
}

export const clearGetReconciliationsById = () => {
    return {
        type: CLEAR_GET_RECONCILIATIONS_BY_ID
    }
}

export const clearGetReconciliationsByIdError = () => {
    return {
        type: CLEAR_GET_RECONCILIATIONS_BY_ID_ERROR
    }
}


// GET RECONCILIATIONS

export const getReconciliations = (data) => dispatch => {
    dispatch(getReconciliationsLoading());

    const runGetReconciliations = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/reconciliations?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_RECONCILIATIONS,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_RECONCILIATIONS_ERROR,
                payload: err.response.data.message
            })
        })  
    }

    runGetReconciliations();
}

export const getReconciliationsLoading = () => {
    return {
        type: GET_RECONCILIATIONS_LOADING
    }
}

export const clearGetReconciliations = () => {
    return {
        type: CLEAR_GET_RECONCILIATIONS
    }
}

export const clearGetReconciliationsError = () => {
    return {
        type: CLEAR_GET_RECONCILIATIONS_ERROR
    }
}



// GET RECONCILIATIONS CURRENT TELLER

export const getReconciliationsCurrentTeller = () => dispatch => {
    dispatch(getReconciliationsCurrentTellerLoading());

    const runGetReconciliationsCurrentTeller = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/reconciliations/current/teller`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_RECONCILIATIONS_CURRENT_TELLER,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: GET_RECONCILIATIONS_CURRENT_TELLER_ERROR,
                payload: err.response.data.message
            })
        })  
    }

    runGetReconciliationsCurrentTeller();
}

export const getReconciliationsCurrentTellerLoading = () => {
    return {
        type: GET_RECONCILIATIONS_CURRENT_TELLER_LOADING
    }
}

export const clearGetReconciliationsCurrentTeller = () => {
    return {
        type: CLEAR_GET_RECONCILIATIONS_CURRENT_TELLER
    }
}

export const clearGetReconciliationsCurrentTellerError = () => {
    return {
        type: CLEAR_GET_RECONCILIATIONS_CURRENT_TELLER_ERROR
    }
}


// GET RECONCILIATIONS ALL CURRENT

export const getReconciliationsAllCurrent = (data) => dispatch => {
    dispatch(getReconciliationsAllCurrentLoading());

    const runGetReconciliationsAllCurrent = async () => {
        let token = await localStorage.getItem('remit_token');
        Axios.get(`${BASE_URL}/reconciliations/all/current?page=${data.page}&perPage=${data.perPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_RECONCILIATIONS_ALL_CURRENT,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_RECONCILIATIONS_ALL_CURRENT_ERROR,
                payload: err.response.data.message
            })
        })  
    }

    runGetReconciliationsAllCurrent();
}

export const getReconciliationsAllCurrentLoading = () => {
    return {
        type: GET_RECONCILIATIONS_ALL_CURRENT_LOADING
    }
}

export const clearGetReconciliationsAllCurrent = () => {
    return {
        type: CLEAR_GET_RECONCILIATIONS_ALL_CURRENT
    }
}

export const clearGetReconciliationsAllCurrentError = () => {
    return {
        type: CLEAR_GET_RECONCILIATIONS_ALL_CURRENT_ERROR
    }
}


//UPDATE RECONCILIATIONS

export const updateReconciliations = (data) => dispatch => {
    dispatch(updateReconciliationsLoading());

    const runUpdateReconciliations = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/reconciliations/${data.id}`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:UPDATE_RECONCILIATIONS,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:UPDATE_RECONCILIATIONS_ERROR,
                payload: err.response.data.message
            })
        })
    }
    runUpdateReconciliations();
}

export const updateReconciliationsLoading = () => {
    return {
        type: UPDATE_RECONCILIATIONS_LOADING
    }
}

export const clearUpdateReconciliations = () => {
    return {
        type: CLEAR_UPDATE_RECONCILIATIONS
    }
}

export const clearUpdateReconciliationsError = () =>{
    return {
        type: CLEAR_UPDATE_RECONCILIATIONS_ERROR
    }
}

export const runningBalance = () => dispatch => {
    dispatch(runningBalanceLoading());

    const runBalance = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/floats/teller/running/balance`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: RUNNING_BALANCE,
                payload: response
            })
        }).catch(error => {
            dispatch({
                type: RUNNING_BALANCE_ERROR,
                payload: error.response.data.message
            })
        })
    }

    runBalance();
}

export const runningBalanceLoading = () => {
    return {
        type: RUNNING_BALANCE_LOADING
    }
}

export const clearRunningBalance = () => {
    return {
        type: CLEAR_RUNNING_BALANCE
    }
}

export const clearRunningBalanceError = () => {
    return {
        type: CLEAR_RUNNING_BALANCE_ERROR
    }
}


//UPDATE FEE CONFIG

export const updateFeeConfig = (data) => dispatch => {
    dispatch(updateFeeConfigLoading());

    const runUpdateFeeConfig = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/fee-config/${data.id}`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:UPDATE_FEE_CONFIG,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:UPDATE_FEE_CONFIG_ERROR,
                payload: err.response.data.message
            })
        })
    }
    runUpdateFeeConfig();
}

export const updateFeeConfigLoading = () => {
    return {
        type: UPDATE_FEE_CONFIG_LOADING
    }
}

export const clearUpdateFeeConfig = () => {
    return {
        type: CLEAR_UPDATE_FEE_CONFIG
    }
}

export const clearUpdateFeeConfigError = () =>{
    return {
        type: CLEAR_UPDATE_FEE_CONFIG_ERROR
    }
}

//  REMITTANCE REVERSAL

export const updateRemittanceReversal = (data) => dispatch => {
    dispatch(updateRemittanceReversalLoading());

    const runUpdateRemittanceReversal = async () =>{
        let token = await localStorage.getItem('remit_token');
       
        var formData = new FormData();
        formData.append('reversalReason', data.reversalReason);
        formData.append('reversalProof', data.reversalProof);
        formData.append('id', data.id);
        
        Axios.patch(`${BASE_URL}/remittances/${data.id}/remittance-reversal`,formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:UPDATE_REMITTANCE_REVERSAL,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:UPDATE_REMITTANCE_REVERSAL_ERROR,
                payload: err.response.data.message
            })
        })
    }
    runUpdateRemittanceReversal();
}

export const updateRemittanceReversalLoading = () => {
    return {
        type: UPDATE_REMITTANCE_REVERSAL_LOADING
    }
}

export const clearUpdateRemittanceReversal = () => {
    return {
        type: CLEAR_UPDATE_REMITTANCE_REVERSAL
    }
}

export const clearUpdateRemittanceReversalError = () =>{
    return {
        type: CLEAR_UPDATE_REMITTANCE_REVERSAL_ERROR
    }
}

export const getFeeConfig = () => dispatch => {
    dispatch(getFeeConfigLoading());

    const runFeeConfig = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/fee-config`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: FEE_CONFIG,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: FEE_CONFIG_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runFeeConfig();
}

export const getFeeConfigLoading = () => {
    return {
        type: FEE_CONFIG_LOADING
    }
}

export const clearFeeConfig = () => {
    return {
        type: CLEAR_FEE_CONFIG
    }
}

export const clearFeeConfigError = () => {
    return {
        type: CLEAR_FEE_CONFIG_ERROR
    }
}

export const getActiveFee = () => dispatch => {
    dispatch(activeFeeLoading());

    const runActiveFee = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/fee-config`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ACTIVE_FEE,
                payload: response
            })
        }).catch(error => {
            dispatch({
                type: ACTIVE_FEE_ERROR,
                payload: error.response.data.message
            })
        })
    }

    runActiveFee();
}

export const activeFeeLoading = () => {
    return {
        type: ACTIVE_FEE_LOADING,
    }
}

export const clearActiveFee = () => {
    return {
        type: CLEAR_ACTIVE_FEE
    }
}

export const clearActiveFeeError = () => {
    return {
        type: CLEAR_ACTIVE_FEE_ERROR
    }
}

export const reports = (data) => dispatch => {
    dispatch(reportLoading());

    const runReports = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/reports?page=${data.page}&perPage=${data.perPage}${data.selector ? "&selector=" + data.selector : ""}${data.section ? "&section=" + data.section : ""}${data.selectedPeriod1 ? "&selectedPeriod1=" + data.selectedPeriod1 : ""}${data.selectedPeriod2 ? "&selectedPeriod2=" + data.selectedPeriod2 : ""}${data.year ? "&year=" + data.year : ""}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: RUN_REPORTS,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: RUN_REPORTS_ERROR,
                paylod: err.response.data.message
            })
        })
    }

    runReports();
}

export const reportLoading = () => {
    return {
        type: REPORTS_LOADING
    }
}

export const clearReports = () => {
    return {
        type: CLEAR_REPORTS
    }
}

export const clearReportsError = () => {
    return {
        type: CLEAR_REPORTS_ERROR
    }
}


//GET REFERENCE CONFIG

export const getReferenceConfig = () => dispatch => {
    dispatch(getReferenceConfigLoading());

    const runReferenceConfig = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/references`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_REFERENCE_CONFIG,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: GET_REFERENCE_CONFIG_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runReferenceConfig();
}

export const getReferenceConfigLoading = () => {
    return {
        type: GET_REFERENCE_CONFIG_LOADING
    }
}

export const clearReferenceConfig = () => {
    return {
        type: CLEAR_GET_REFERENCE_CONFIG
    }
}

export const clearReferenceConfigError = () => {
    return {
        type: CLEAR_GET_REFERENCE_CONFIG_ERROR
    }
}


//UPDATE REFERENCE CONFIG

export const updateReferenceConfig = (data) => dispatch => {
    dispatch(updateReferenceConfigLoading());

    const runUpdateReferenceConfig = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/references/${data.id}`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:UPDATE_REFERENCE_CONFIG,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:UPDATE_REFERENCE_CONFIG_ERROR,
                payload: err.response.data.message
            })
        })
    }
runUpdateReferenceConfig();
}

export const updateReferenceConfigLoading = () => {
    return {
        type: UPDATE_REFERENCE_CONFIG_LOADING
    }
}

export const clearUpdateReferenceConfig = () => {
    return {
        type: CLEAR_UPDATE_REFERENCE_CONFIG
    }
}

export const clearUpdateReferenceConfigError = () =>{
    return {
        type: CLEAR_UPDATE_REFERENCE_CONFIG_ERROR
    }
}


//POST MESSAGE CONFIG

export const addMessageConfig = (data) => dispatch => {
    dispatch(addMessageConfigLoading());

    const runAddMessageConfig = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.post(`${BASE_URL}/messages`, data, {     
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: ADD_MESSAGE_CONFIG,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: ADD_MESSAGE_CONFIG_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runAddMessageConfig();
}

export const addMessageConfigLoading = () => {
    return {
        type: ADD_MESSAGE_CONFIG_LOADING
    }
}

export const clearAddMessageConfig = () => {
    return {
        type: CLEAR_ADD_MESSAGE_CONFIG
    }
}

export const clearAddMessageConfigError = () => {
    return {
        type: CLEAR_ADD_MESSAGE_CONFIG_ERROR
    }
}

//GET MESSAGE CONFIG
export const getMessageConfig = () => dispatch => {
    dispatch(getMessageConfigLoading());

    const runMessageConfig = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/messages`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GET_MESSAGE_CONFIG,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: GET_MESSAGE_CONFIG_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runMessageConfig();
}

export const getMessageConfigLoading = () => {
    return {
        type: GET_MESSAGE_CONFIG_LOADING
    }
}

export const clearMessageConfig = () => {
    return {
        type: CLEAR_GET_MESSAGE_CONFIG
    }
}

export const clearMessageConfigError = () => {
    return {
        type: CLEAR_GET_MESSAGE_CONFIG_ERROR
    }
}


//UPDATE MESSAGE CONFIG

export const updateMessageConfig = (data) => dispatch => {
    dispatch(updateMessageConfigLoading());

    const runUpdateMessageConfig = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/messages/${data.id}`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:UPDATE_MESSAGE_CONFIG,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:UPDATE_MESSAGE_CONFIG_ERROR,
                payload: err.response.data.message
            })
        })
    }
runUpdateMessageConfig();
}

export const updateMessageConfigLoading = () => {
    return {
        type: UPDATE_MESSAGE_CONFIG_LOADING
    }
}

export const clearUpdateMessageConfig = () => {
    return {
        type: CLEAR_UPDATE_MESSAGE_CONFIG
    }
}

export const clearUpdateMessageConfigError = () =>{
    return {
        type: CLEAR_UPDATE_MESSAGE_CONFIG_ERROR
    }
}


//UPDATE MESSAGE CONFIG STATUS

export const updateMessageConfigStatus = (data) => dispatch => {
    dispatch(updateMessageConfigStatusLoading());

    const runUpdateMessageConfigStatus = async () =>{
        let token = await localStorage.getItem('remit_token');

        Axios.patch(`${BASE_URL}/messages/${data.id}/status`,data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type:UPDATE_MESSAGE_CONFIG_STATUS,
                payload:response
            })
        }).catch(err => {
            dispatch({
                type:UPDATE_MESSAGE_CONFIG_STATUS_ERROR,
                payload: err.response.data.message
            })
        })
    }
runUpdateMessageConfigStatus();
}

export const updateMessageConfigStatusLoading = () => {
    return {
        type: UPDATE_MESSAGE_CONFIG_STATUS_LOADING
    }
}

export const clearUpdateMessageConfigStatus = () => {
    return {
        type: CLEAR_UPDATE_MESSAGE_CONFIG_STATUS
    }
}

export const clearUpdateMessageConfigStatusError = () =>{
    return {
        type: CLEAR_UPDATE_MESSAGE_CONFIG_STATUS_ERROR
    }
}

export const graphicalReport = (data) => dispatch => {
    dispatch(graphicalReportLoading());

    const runGraph = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/reports/graphical/summary?filter=${data.filter}&type=${data.type}&selection=${data.selection}&start=${data.start}&end=${data.end}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: GRAPHICAL_REPORT,
                payload: response
            })
        }).catch(error => {
            dispatch({
                type: GRAPHICAL_REPORT_ERROR,
                payload: error.response.data.message
            })
        })
    }

    runGraph()
}

export const graphicalReportLoading = () => {
    return {
        type: GRAPHICAL_REPORT_LOADING
    }
}

export const clearGraphicalReport = () => {
    return {
        type: CLEAR_GRAPHICAL_REPORT
    }
}

export const clearGraphicalReportError = () => {
    return {
        type: CLEAR_GRAPHICAL_REPORT_ERROR
    }
}

export const summaryReport = (data) => dispatch => {
    dispatch(summaryReportLoading());

    const runSummary = async () => {
        let token = await localStorage.getItem('remit_token');

        Axios.get(`${BASE_URL}/reports/summary/reports?page=${data.page}&perPage=${data.perPage}&type=${data.type}&filter=${data.filter}&partner=${data.partner}&branch=${data.branch}&action=${data.action}&start=${data.start}&end=${data.end}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch({
                type: REPORT_SUMMARY,
                payload: response
            })
        }).catch(err => {
            dispatch({
                type: REPORT_SUMMARY_ERROR,
                payload: err.response.data.message
            })
        })
    }

    runSummary();
}

export const summaryReportLoading = () => {
    return {
        type: SUMMARY_REPORT_LOADING
    }
}

export const clearSummaryReport = () => {
    return {
        type: CLEAR_SUMMARY_REPORT
    }
}

export const clearSummaryReportError = () => {
    return {
        type: CLEAR_SUMMARY_REPORT_ERROR
    }
}

