import {GET_RECONCILIATIONS_BY_ID, GET_RECONCILIATIONS_BY_ID_ERROR, GET_RECONCILIATIONS_BY_ID_LOADING, CLEAR_GET_RECONCILIATIONS_BY_ID_ERROR, CLEAR_GET_RECONCILIATIONS_BY_ID} from '../actions/types';

const initialState = {
    recon_id: null,
    recon_id_loading: false,
    recon_id_error: null
}

export default function getReconciliationByIdReducer(state = initialState, action){
    switch(action.type){
        case GET_RECONCILIATIONS_BY_ID:
            return {
                ...state,
                recon_id: action.payload,
                recon_id_loading: false,
                recon_id_error: null
            }
        case GET_RECONCILIATIONS_BY_ID_ERROR:
            return {
                ...state,
                recon_id_loading: false,
                recon_id_error: action.payload
            }
        case GET_RECONCILIATIONS_BY_ID_LOADING:
            return {
                ...state,
                recon_id_loading: true,
                recon_id_error: null
            }
        case CLEAR_GET_RECONCILIATIONS_BY_ID_ERROR:
            return {
                ...state,
                recon_id_loading: false,
                recon_id_error: null
            }
        case CLEAR_GET_RECONCILIATIONS_BY_ID:
            return {
                ...state,
                recon_id: null,
                recon_id_loading: false,
                recon_id_error: null
            }
        default: return state
    }
}