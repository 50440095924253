import React from 'react'

const SubmitButton = ({label,children, type='submit', ...rest}) => {
  return (
    <div className="mb-3 pt-3 px-2">
    <button 
    type={type}
    className="bg-green-600 text-white w-full rounded p-2 text-lg">{label}</button>
 </div>
  )
}

export default SubmitButton