import {
    CLEAR_UPDATE_REMIT_REASON_ERROR,
    CLEAR_UPDATE_REMIT_REASON,
    UPDATE_REMIT_REASON_LOADING,
    UPDATE_REMIT_REASON_ERROR,
    UPDATE_REMIT_REASON
} 
from '../actions/types';

const initialState = {
    updateRemitReason: null,
    updateRemitReasonLoading: false,
    updateRemitReasonError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_REMIT_REASON_ERROR:
            return {
                ...state,
                updateRemitReasonLoading: false,
                updateRemitReasonError: action.payload
            }
        case CLEAR_UPDATE_REMIT_REASON:
            return {
                ...state,
                updateRemitReason: null,
                updateRemitReasonLoading: false,
                updateRemitReasonError: null
            }
        case UPDATE_REMIT_REASON_LOADING:
            return {
                ...state,
                updateRemitReasonLoading: true,
                updateRemitReasonError: null
            }
        case UPDATE_REMIT_REASON_ERROR:
            return {
                ...state,
                updateRemitReasonLoading: false,
                updateRemitReasonError: action.payload
            }
        case UPDATE_REMIT_REASON:
            return {
                ...state,
                updateRemitReason: action.payload,
                updateRemitReasonLoading: false,
                updateRemitReasonError: null
            }
        default: return state
    }
}