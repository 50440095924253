import React from 'react';
import ChangePassword from '../components/account/ChangePassword';
import Settings from '../components/account/Settings';

function Account() {
  return (
    <div className="grid grid-cols-12 gap-4 p-8 bg-gray-100 min-h-screen">
        <div className="col-span-9">
            <Settings />
        </div>
        <div className="col-span-3">
            <ChangePassword />
        </div>
    </div>
  )
}

export default Account