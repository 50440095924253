import {
    CLEAR_DEACTIVATE_USERS_ERROR,
    CLEAR_DEACTIVATE_USERS,
    DEACTIVATE_USERS_LOADING,
    DEACTIVATE_USERS_ERROR,
    DEACTIVATE_USERS
} 
from '../actions/types';

const initialState = {
    deactivateUsers: null,
    deactivateUsersLoading: false,
    deactivateUsersError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_DEACTIVATE_USERS_ERROR:
            return {
                ...state,
                deactivateUsersLoading: false,
                deactivateUsersError: action.payload
            }
        case CLEAR_DEACTIVATE_USERS:
            return {
                ...state,
                deactivateUsers: null,
                deactivateUsersLoading: false,
                deactivateUsersError: null
            }
        case DEACTIVATE_USERS_LOADING:
            return {
                ...state,
                deactivateUsersLoading: true,
                deactivateUsersError: null
            }
        case DEACTIVATE_USERS_ERROR:
            return {
                ...state,
                deactivateUsersLoading: false,
                deactivateUsersError: action.payload
            }
        case DEACTIVATE_USERS:
            return {
                ...state,
                deactivateUsers: action.payload,
                deactivateUsersLoading: false,
                deactivateUsersError: null
            }
        default: return state
    }
}