import {GET_ADMIN_AUDIT_TRAIL_BY_ID, GET_ADMIN_AUDIT_TRAIL_BY_ID_ERROR, GET_ADMIN_AUDIT_TRAIL_BY_ID_LOADING, CLEAR_GET_ADMIN_AUDIT_TRAIL_BY_ID_ERROR, CLEAR_GET_ADMIN_AUDIT_TRAIL_BY_ID} from '../actions/types';

const initialState = {
    admin_trail_id: null,
    admin_trail_id_loading: false,
    admin_trail_id_error: null
}

export default function getAdminAuditTrailByIdReducer(state = initialState, action){
    switch(action.type){
        case GET_ADMIN_AUDIT_TRAIL_BY_ID:
            return {
                ...state,
                admin_trail_id: action.payload,
                admin_trail_id_loading: false,
                admin_trail_id_error: null
            }
        case GET_ADMIN_AUDIT_TRAIL_BY_ID_ERROR:
            return {
                ...state,
                admin_trail_id_loading: false,
                admin_trail_id_error: action.payload
            }
        case GET_ADMIN_AUDIT_TRAIL_BY_ID_LOADING:
            return {
                ...state,
                admin_trail_id_loading: true,
                admin_trail_id_error: null
            }
        case CLEAR_GET_ADMIN_AUDIT_TRAIL_BY_ID_ERROR:
            return {
                ...state,
                admin_trail_id_loading: false,
                admin_trail_id_error: null
            }
        case CLEAR_GET_ADMIN_AUDIT_TRAIL_BY_ID:
            return {
                ...state,
                admin_trail_id: null,
                admin_trail_id_loading: false,
                admin_trail_id_error: null
            }
        default: return state
    }
}