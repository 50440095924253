import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const persistConfig = {
    key: 'root:remittance',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};

const middleware = [thunk];

export default () => {
    let context = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
    createStore(persistedReducer, initialState, compose(applyMiddleware(...middleware), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())) :
    createStore(persistedReducer, initialState, compose(applyMiddleware(...middleware)));

    let persistor = persistStore(context);

    return {
        store: context,
        persistor
    }
}