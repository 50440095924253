import React from 'react';
import * as actions from '../../store/actions';
import { useSelector, useDispatch } from "react-redux";
import { GiCash } from 'react-icons/gi';
import loader from '../../assets/loaders/382.gif';
import * as Yup from "yup";
import { Formik, Form } from "formik";
import FormikController from '../Formik/FormikController';
import { FaCashRegister } from 'react-icons/fa';

function TellerReports() {
    const dispatch = useDispatch();
    const cashoutState = useSelector(cashoutState => cashoutState.processRemittanceReducer);
    const processedState = useSelector(processedState => processedState.processRemittanceReducer);
    const floatState = useSelector(floatState => floatState.tellerFloatReducer);
    const reversedState = useSelector(reversedState => reversedState.updateRemittanceReversalReducer)
    const confirmState = useSelector(confirmState => confirmState.confirmFloatReducer);
    const balanceState = useSelector(balanceState => balanceState.runningBalanceReducer);
    const reconciliationState = useSelector(reconciliationState => reconciliationState.addReconciliationsReducer);
    const remittanceState = useSelector(remittanceState => remittanceState.makeRemittanceReducer);
    const [id, setId] = React.useState(null);
    const [activeId, setActiveId] = React.useState(null);
    const [index, setIndex] = React.useState(0);

    let currency = (balanceState.running_balance && balanceState.running_balance.data[index].currency) ? balanceState.running_balance.data.map(cxt => {
        let obj = {
            key: cxt.currency.currencyName,
            value: cxt.currency.id,
        }
        return obj;
    }) : [];

    React.useEffect(() => {
        dispatch(actions.getTellerCurrentFloat());
        dispatch(actions.runningBalance());
        dispatch(actions.getReconciliationsCurrentTeller());
    }, [dispatch, remittanceState.remittance, cashoutState.cashedOut, processedState.cashedOut, reversedState.updateRemittanceReversal, confirmState.confirm_float, reconciliationState.new_reconcilitiation]);

    React.useEffect(() => {
        if(balanceState.running_balance && balanceState.running_balance.data.length > 0){
            setId(balanceState.running_balance.data[index].currency ? balanceState.running_balance.data[index].currency.id : null);
        }

        return () => {
            dispatch(actions.clearConfirmFloat())
        }
    }, []);

    const handleConfirm = (id) => {
        setActiveId(id);
        let data = {
            id
        }
        dispatch(actions.confirmFloat(data));
    }

    const handleChange = (e, i) => {
        setId(e.target.value);
        setIndex(i);        
    }

    const INITIAL_VALUE = {
        currency: '',
        cashInHand: '',
        comments: ''
    }

    const VALIDATION_SCHEMA = Yup.object().shape({
        currency: Yup.string().required('This field is required'),
        cashInHand: Yup.number().required('This field is required'),
        comments: Yup.string().required('This field is required'),
    });

    const handleSubmit = (values) => {
        dispatch(actions.addReconciliations(values));
    }

    return (
        <div>
            {(balanceState.running_balance && balanceState.running_balance.data.length > 0) &&
            <div className="flex">
                { balanceState.running_balance.data.map((el, index) => (
                <label key={index} className="form-check-label inline-block mr-3 text-gray-800">
                    <div className="form-check form-check-inline">
                        <input onClick={(e) => handleChange(e, index)} className={`${!balanceState.running_balance.data[index].currency && 'hidden'} form-check-input form-check-input appearance-none rounded-full h-4 w-4 border ${(((id && balanceState.running_balance.data[index].currency) && id === balanceState.running_balance.data[index].currency.id) ) ? "bg-green-600 border-green-600" : "border-gray-300 bg-white"} focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer`} type="radio" name="inlineRadioOptions"  value={(balanceState.running_balance && balanceState.running_balance.data[index].currency) ? balanceState.running_balance.data[index].currency.id : ""} />
                            {el.currency ? el.currency.currencySymbol : ''}
                    </div>
                </label>))}
            </div>}

            {(floatState.teller_float && floatState.teller_float.length === 0 && balanceState.running_balance && balanceState.running_balance.data.length === 0) && <div className="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
            <span className="font-medium">Info alert!</span> You don't have any float assigned to you today.
            </div>}

            {(floatState.teller_float && floatState.teller_float.length > 0) && floatState.teller_float.map(el => {
               return (el.status === 'PENDING') && <div className="flex p-4 my-4 bg-blue-100 rounded-lg dark:bg-blue-200" role="alert">
               <div className="ml-3 text-sm font-medium text-blue-700 dark:text-blue-800">
                   You have been allocated <span className="font-bold">{el.currency.currencySymbol} {Number(el.startingBalance).toFixed(2)}</span>. <button onClick={() => handleConfirm(el.id)} className="font-semibold text-white bg-blue-600 rounded px-2 py-1">{(confirmState.confirm_float_loading && activeId === el.id) ? "Wait" : "Click Here"}</button> to confirm and credit your account.
               </div>
           </div>
            })}

            {/* REPORT CARDS */}
            <div className="grid grid-cols-2 gap-4 my-3">
                <div className="bg-gradient-to-r from-green-900 via-green-700 to-green-800 rounded px-3 py-7 text-center">
                    <div>
                        <GiCash className="text-white text-4xl mx-auto"  />
                        <h2 className="text-white font-medium my-1">Allocated Float</h2>
                    </div>
                    <div>
                        <h1 className="text-white text-3xl">{(balanceState.running_balance && balanceState.running_balance.data.length > 0) ?
                        ((balanceState.running_balance.data[index].currency) ? balanceState.running_balance.data[index].currency.currencySymbol : '') + " " + Number(balanceState.running_balance.data[index].startingBalance).toFixed(2)
                        : 'USD 0.00'}</h1>
                    </div>
                </div>
                <div className="bg-gradient-to-r from-green-900 via-green-700 to-green-800 rounded px-3 py-7 text-center">
                    <div>
                        <FaCashRegister className="text-white text-4xl mx-auto"  />
                        <h2 className="text-white font-medium my-1">Current Balance</h2>
                    </div>
                    <div>
                        <h1 className="text-white text-3xl">{(balanceState.running_balance && balanceState.running_balance.data.length > 0) ?
                        ((balanceState.running_balance.data[index].currency) ? balanceState.running_balance.data[index].currency.currencySymbol : '') + " " + Number(balanceState.running_balance.data[index].runningbalance).toFixed(2)
                        : 'USD 0.00'}</h1>
                    </div>
                </div>
            </div> 

            <div className="border rounded p-4 my-4">
                <h2 className="font-medium px-2">Reconcile Today's Transactions</h2>
                {
                    reconciliationState.new_reconcilitiation && <div id="alert-2" className="flex mt-4 p-4 mb-2 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
                    <svg className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                    <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
                        Ending balance successfully submitted
                    </div>
                    <button onClick={() => dispatch(actions.clearAddReconciliations())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300" data-dismiss-target="#alert-2" aria-label="Close">
                        <span className="sr-only">Close</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>
                }
                <Formik
                    initialValues={INITIAL_VALUE}
                    validationSchema={VALIDATION_SCHEMA}
                    enableReinitialize
                    onSubmit={(values, {resetForm}) => {
                        handleSubmit(values)
                        resetForm()
                    }}
                >
                    {({...rest}) => {
                        return (
                            <Form className="my-3">
                                {reconciliationState.new_reconcilitiation_error &&
                                <div id="alert-2" className="flex mt-4 p-4 mb-2 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                                    <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                                    <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                                        {reconciliationState.new_reconcilitiation_error}
                                    </div>
                                    <button onClick={() => dispatch(actions.clearAddReconciliationsError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                                        <span className="sr-only">Close</span>
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>}
                                <div className="grid grid-cols-2 gap-2 mb-4">
                                    <div className=" px-2">
                                        <FormikController
                                            control='select'
                                            type='text'
                                            disabled={(balanceState.running_balance && balanceState.running_balance.data.length > 0) ? false : true}
                                            options={currency}
                                            label='Currency'
                                            name='currency'
                                        />
                                    </div>

                                    <div className=" px-2">
                                        <FormikController
                                            control='input'
                                            type='text'
                                            disabled={(balanceState.running_balance && balanceState.running_balance.data.length > 0) ? false : true}
                                            label='Cash In Hand'
                                            name='cashInHand'
                                        />
                                    </div>
                                    <div className="col-span-2 px-2">
                                        <FormikController
                                            control='textArea'
                                            type='text'
                                            disabled={(balanceState.running_balance && balanceState.running_balance.data.length > 0) ? false : true}
                                            label='Comment'
                                            name='comments'
                                        />
                                    </div>
                                </div>
                                <div className="text-right mb-16 px-2">
                                    {reconciliationState.new_reconcilitiation_loading ? <button className="bg-gray-500 rounded px-8 py-1"><img className="h-8" src={loader} alt="loader" /></button> :
                                    <button disabled={(balanceState.running_balance && balanceState.running_balance.data.length > 0) ? false : true} type="submit" className="bg-green-600 text-white rounded px-3 py-2">Submit</button>}
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>

        </div>
    )
}

export default TellerReports