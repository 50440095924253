import {
    CLEAR_ACTIVATE_REMITTERS_ERROR,
    CLEAR_ACTIVATE_REMITTERS,
    ACTIVATE_REMITTERS_LOADING,
    ACTIVATE_REMITTERS_ERROR,
    ACTIVATE_REMITTERS
} 
from '../actions/types';

const initialState = {
    activateRemitters: null,
    activateRemittersLoading: false,
    activateRemittersError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_ACTIVATE_REMITTERS_ERROR:
            return {
                ...state,
                activateRemittersLoading: false,
                activateRemittersError: action.payload
            }
        case CLEAR_ACTIVATE_REMITTERS:
            return {
                ...state,
                activateRemitters: null,
                activateRemittersLoading: false,
                activateRemittersError: null
            }
        case ACTIVATE_REMITTERS_LOADING:
            return {
                ...state,
                activateRemittersLoading: true,
                activateRemittersError: null
            }
        case ACTIVATE_REMITTERS_ERROR:
            return {
                ...state,
                activateRemittersLoading: false,
                activateRemittersError: action.payload
            }
        case ACTIVATE_REMITTERS:
            return {
                ...state,
                activateRemitters: action.payload,
                activateRemittersLoading: false,
                activateRemittersError: null
            }
        default: return state
    }
}