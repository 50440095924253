import React from 'react'
import AddPartner from '../components/partners/AddPartner';
import PartnerTable from '../components/partners/PartnerTable';

function Partners() {
    const [store,setStore] = React.useState(null);

    const handleEdit =(user) =>{
        setStore(user);
    }
    return (
        <React.Fragment>
            <div className="grid grid-cols-12 gap-4">
                <div className="col-span-9 p-8">
                    <PartnerTable setStore={setStore} handleEdit={handleEdit} />
                </div>
                <div className="col-span-3 border-l min-h-screen p-8">
                    <AddPartner store={store} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Partners