import {
    CLEAR_ASSIGN_FLOAT_ERROR,
    CLEAR_ASSIGN_FLOAT,
    ASSIGN_FLOAT_LOADING,
    ASSIGN_FLOAT_ERROR,
    ASSIGN_FLOAT
} from '../actions/types';

const initialState = {
    assign_float: null,
    assign_float_loading: false,
    assign_float_error: null
}

const assignFloatReducer = (state = initialState, action) => {
    switch (action.type) {
        case ASSIGN_FLOAT:
            return {
                ...state,
                assign_float: action.payload,
                assign_float_loading: false,
                assign_float_error: null
            }
        case ASSIGN_FLOAT_ERROR:
            return {
                ...state,
                assign_float_loading: false,
                assign_float_error: action.payload
            }
        case ASSIGN_FLOAT_LOADING:
            return {
                ...state,
                assign_float_loading: true,
                assign_float_error: null
            }
        case CLEAR_ASSIGN_FLOAT:
            return {
                ...state,
                assign_float: null,
                assign_float_loading: false,
                assign_float_error: null
            }
        case CLEAR_ASSIGN_FLOAT_ERROR:
            return {
                ...state,
                assign_float_loading: false,
                assign_float_error: null
            }
        default: return state;
    }
}

export default assignFloatReducer