import React from 'react';
import Loader from '../../assets/loaders/Iphone-spinner-2.gif';

function PageLoader() {
  return (
    <div className="my-36">
      <img src={Loader} alt="loading" className="mx-auto block" />
    </div>
  )
}

export default PageLoader