import {
    CLEAR_MAKE_REMITTANCE_ERROR,
    CLEAR_MAKE_REMITTANCE,
    MAKE_REMITTANCE_LOADING,
    MAKE_REMITTANCE_ERROR,
    MAKE_REMITTANCE
} from '../actions/types';

const initialState = {
    remittance: null,
    remittance_error: null,
    remittance_loading: false
}

const makeRemittanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case MAKE_REMITTANCE:
            return {
                ...state,
                remittance: action.payload,
                remittance_error: null,
                remittance_loading: false
            }
        case MAKE_REMITTANCE_ERROR:
            return {
                ...state,
                remittance_error: action.payload,
                remittance_loading: false
            }
        case MAKE_REMITTANCE_LOADING:
            return {
                ...state,
                remittance_error: null,
                remittance_loading: true
            }
        case CLEAR_MAKE_REMITTANCE:
            return {
                ...state,
                remittance: null,
                remittance_error: null,
                remittance_loading: false
            }
        case CLEAR_MAKE_REMITTANCE_ERROR:
            return {
                ...state,
                remittance_error: null,
                remittance_loading: false
            }
        default: return state
    }
}

export default makeRemittanceReducer