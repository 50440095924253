import './App.css';
import { Router, Route, useHistory } from 'react-router-dom';
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import Home from './pages/Home';
import Branch from './pages/Branch';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from './store/actions';
import AdminWrapper from './components/utils/AdminWrapper';
import Users from './pages/Users';
import Configurations from './pages/Configurations';
import Partners from './pages/Partners';
import Account from './pages/Account';
import Operations from './pages/Operations';
import Reports from './pages/Reports';
import ViewMore from './components/reports/dashboard/ViewMore';
import PartnerBranches from './components/partners/PartnerBranches';
import BranchUsers from './components/partners/BranchUsers';

function App(props) {
  const {auth} = props.loginReducer;
  const history = useHistory();
  return (
    <div className="App">
      <Router history={history}>
        <Route exact path="/" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/home" render={props => {
            return (
              (auth && auth.role === 'TELLER' ? 
                <AdminWrapper>
                  <Home />
                </AdminWrapper> : 
                <Login/>
              )
            )
        }} />
        <Route exact path="/branch" render={props => {
            return (
              (auth && auth.role !== 'TELLER' ? 
                <AdminWrapper>
                  <Branch />
                </AdminWrapper> : 
                <Login/>
              )
            )
        }} /> 
                <Route exact path="/reports" render={props => {
            return (
              (auth && auth.role !== 'TELLER' ? 
                <AdminWrapper>
                  <Reports />
                </AdminWrapper> : 
                <Login/>
              )
            )
        }} /> 
        <Route exact path="/partners" render={props => {
            return (
              (auth &&  (auth.role === 'MANAGER' || auth.role === 'ADMINISTRATOR') ? 
                <AdminWrapper>
                  <Partners />
                </AdminWrapper> : 
                <Login/>
              )
            )
        }} />
        <Route exact path="/partners/:id" render={props => {
            return (
              (auth &&  (auth.role === 'MANAGER' || auth.role === 'ADMINISTRATOR') ? 
                <AdminWrapper>
                  <PartnerBranches />
                </AdminWrapper> : 
                <Login/>
              )
            )
        }} />
        <Route exact path="/partners/:partnerId/branch/:branchId" render={props => {
            return (
              (auth &&  (auth.role === 'MANAGER' || auth.role === 'ADMINISTRATOR') ? 
                <AdminWrapper>
                  <BranchUsers />
                </AdminWrapper> : 
                <Login/>
              )
            )
        }} />
        <Route exact path="/operations" render={props => {
            return (
              (auth && auth.role === 'ACCOUNTS' ? 
                <AdminWrapper>
                  <Operations />
                </AdminWrapper> : 
                <Login/>
              )
            )
        }} />
        <Route exact path="/account-settings" render={props => {
            return (
              (auth ? 
                <AdminWrapper>
                  <Account />
                </AdminWrapper> : 
                <Login/>
              )
            )
        }} />
        <Route exact path="/reports/:id" render={props => {
            return (
              (auth && auth.role !== 'TELLER' ? 
                <AdminWrapper>
                  <ViewMore />
                </AdminWrapper> : 
                <Login/>
              )
            )
        }} />
        <Route exact path="/users" render={props => {
            return (
              (auth && auth.role !== 'TELLER' ? 
                <AdminWrapper>
                  <Users />
                </AdminWrapper> : 
                <Login/>
              )
            )
        }} />
        <Route exact path="/configurations" render={props => {
            return (
              (auth && (auth.role === 'MANAGER' || auth.role === 'ADMINISTRATOR') ? 
                <AdminWrapper>
                  <Configurations />
                </AdminWrapper> :
                <Login/>
              )
            )
        }} />
      </Router>
    </div>
  );
}

App.propTypes = {
  loginReducer: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  loginReducer: state.loginReducer
})

export default connect(mapStateToProps, actions)(App);
