import {GET_RECONCILIATIONS_ALL_CURRENT, GET_RECONCILIATIONS_ALL_CURRENT_ERROR, GET_RECONCILIATIONS_ALL_CURRENT_LOADING, CLEAR_GET_RECONCILIATIONS_ALL_CURRENT_ERROR, CLEAR_GET_RECONCILIATIONS_ALL_CURRENT} from '../actions/types';

const initialState = {
    recon_all_current: null,
    recon_all_current_loading: false,
    recon_all_current_error: null
}

export default function getReconciliationsAllCurrentReducer(state = initialState, action){
    switch(action.type){
        case GET_RECONCILIATIONS_ALL_CURRENT:
            return {
                ...state,
                recon_all_current: action.payload,
                recon_all_current_loading: false,
                recon_all_current_error: null
            }
        case GET_RECONCILIATIONS_ALL_CURRENT_ERROR:
            return {
                ...state,
                recon_all_current_loading: false,
                recon_all_current_error: action.payload
            }
        case GET_RECONCILIATIONS_ALL_CURRENT_LOADING:
            return {
                ...state,
                recon_all_current_loading: true,
                recon_all_current_error: null
            }
        case CLEAR_GET_RECONCILIATIONS_ALL_CURRENT_ERROR:
            return {
                ...state,
                recon_all_current_loading: false,
                recon_all_current_error: null
            }
        case CLEAR_GET_RECONCILIATIONS_ALL_CURRENT:
            return {
                ...state,
                recon_all_current: null,
                recon_all_current_loading: false,
                recon_all_current_error: null
            }
        default: return state
    }
}