import React from 'react'
import AddPartnerBranch from './Branches/AddPartnerBranch'
import PartnerBranchTable from './Branches/PartnerBranchTable'

function PartnerBranches() { 
  const [store,setStore] = React.useState(null);

  const handleEdit =(user) =>{
    setStore(user);
  }

  return (
    <div className="grid grid-cols-12 gap-4">
        <div className="col-span-9 p-8">
            <PartnerBranchTable setStore={setStore} handleEdit={handleEdit} />
        </div>
        <div className="col-span-3 border-l min-h-screen p-8">
            <AddPartnerBranch store={store} />
        </div>
    </div>
  )
}

export default PartnerBranches