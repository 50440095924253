import {GET_REMITTERS, GET_REMITTERS_ERROR, GET_REMITTERS_LOADING, CLEAR_GET_REMITTERS_ERROR, CLEAR_GET_REMITTERS} from '../actions/types';

const initialState = {
    remitters: null,
    remitters_loading: false,
    remitters_error: null
}

export default function getRemittersReducer(state = initialState, action){
    switch(action.type){
        case GET_REMITTERS:
            return {
                ...state,
                remitters: action.payload,
                remitters_loading: false,
                remitters_error: null
            }
        case GET_REMITTERS_ERROR:
            return {
                ...state,
                remitters_loading: false,
                remitters_error: action.payload
            }
        case GET_REMITTERS_LOADING:
            return {
                ...state,
                remitters_loading: true,
                remitters_error: null
            }
        case CLEAR_GET_REMITTERS_ERROR:
            return {
                ...state,
                remitters_loading: false,
                remitters_error: null
            }
        case CLEAR_GET_REMITTERS:
            return {
                ...state,
                remitters: null,
                remitters_loading: false,
                remitters_error: null
            }
        default: return state
    }
}