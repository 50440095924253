import {
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_LOADING,
    CLEAR_GET_USERS_ERROR,
    CLEAR_GET_USERS
} from '../actions/types';

const initialState = {
    users: null,
    users_loading: false,
    users_error: null,
}

export default function (state = initialState, action){
    switch(action.type){
        case GET_USERS:
            return {
                ...state,
                users: action.payload,
                users_loading: false,
                users_error: null,
            }
        case GET_USERS_ERROR:
            return {
                ...state,
                users_loading: false,
                users_error: action.payload,
            }
        case GET_USERS_LOADING:
            return {
                ...state,
                users_loading: true,
                users_error: null,
            }
        case CLEAR_GET_USERS_ERROR:
            return {
                ...state,
                users_loading: false,
                users_error: null,
            }
        case CLEAR_GET_USERS:
            return {
                ...state,
                users: null,
                users_loading: false,
                users_error: null,
            }
        default: return state
    }
}