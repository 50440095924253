import React from 'react'
import Logo from '../../assets/vertical-removebg-preview.png';

export const Auth = (props) => {
    return (
        <div className="h-screen bg-gray-200  pt-32">
            <div className="rounded-md drop-shadow-md bg-white mx-auto w-1/4">
                <div className="border-b p-3 flex justify-between items-center">
                    <label className="text-2xl text-gray-800 p-2">{props.title}</label>
                    <img className="mr-3 h-12" src={Logo} />
                </div>
                {props.children}
            </div>
        </div>
    )
}

export default Auth;