import React from 'react';
import * as actions from '../../store/actions';
import { useSelector, useDispatch } from "react-redux";
import FormikController from '../Formik/FormikController';
import * as Yup from "yup";
import loader from '../../assets/loaders/382.gif';
import { Formik, Form } from "formik";

function Settings() {
  const dispatch = useDispatch();
  const authState = useSelector(authState => authState.loginReducer);
  const branchState = useSelector(branchState => branchState.getBranchesReducer);
  const userState = useSelector(userState => userState.currentUserProfileReducer);
  const updateUserState = useSelector(updateUserState => updateUserState.updateCurrentUserReducer);

  React.useEffect(() => {
    let data = {
      id: authState.auth.userId
    }
    dispatch(actions.currentUserProfile(data));
  }, [updateUserState.profile]);

  React.useEffect(() => {
    dispatch(actions.getBranch({page: 1, perPage: 500}));
  }, [])

  const ROLE = [
    {
        key: "Administrator",
        value: "ADMINISTRATOR"
    }, 
    { 
        key: "Accounts",
        value: "ACCOUNTS",
    }, 
    {
        key: "Teller",
        value: "TELLER",
    }
  ];

  const initialValues = {
    firstname: (userState.current_user) ? userState.current_user.data.firstname  : "",
    middlename: (userState.current_user) ? userState.current_user.data.middlename  : "",
    lastname: (userState.current_user) ? userState.current_user.data.lastname  : "",
    email: (userState.current_user) ? userState.current_user.data.email  : "",
    mobile: (userState.current_user) ? userState.current_user.data.mobile  : "",
    branch: (userState.current_user && userState.current_user.data.branch) ? userState.current_user.data.branch  : "",
    role: (userState.current_user) ? userState.current_user.data.role  : "",
    branch: (userState.current_user && userState.current_user.data.branch) ? userState.current_user.data.branch.id  : "",
    contactPerson: (userState.current_user && userState.current_user.data.branch) ? userState.current_user.data.branch.contactPerson  : "",
    contactEmail: (userState.current_user && userState.current_user.data.branch) ? userState.current_user.data.branch.contactEmail  : "",
    contactPhone: (userState.current_user && userState.current_user.data.branch) ? userState.current_user.data.branch.contactPhone  : "",
    address: (userState.current_user && userState.current_user.data.branch) ? userState.current_user.data.branch.address  : "",
    city: (userState.current_user && userState.current_user.data.branch) ? userState.current_user.data.branch.city  : "",
  }


  let branchObj = branchState.branch ? branchState.branch.data.map(cxt => {
    let obj = {
        key: cxt.branchName,
        value: cxt.id
    }

    return obj;
}) : [];

  const validationSchema = Yup.object({
    firstname: Yup.string().required('This field is required'),
    middlename: Yup.string().required('This field is required'),
    lastname: Yup.string().required('This field is required'),
    email: Yup.string().email('Must be a valid email').required('This field is required'),
    mobile: Yup.string().required('This field is required'),
    branch: Yup.string().required('This field is required'),
    role: Yup.string().required('This field is required'),
  })

  const handleSubmit = (values) => {
      values.id = authState.auth.userId;
      dispatch(actions.updateCurrentUserProfile(values));
  }

  return (
    <div className="rounded-md bg-white shadow">
      <div className="p-4 border-b border-gray-300">
        <h3 className="text-lg px-8 font-medium text-gray-700">Your personal profile info</h3>
      </div>
      <div className="py-4 px-12">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => {
              handleSubmit(values)
          }}
        >
          <Form>
          {updateUserState.profile_error &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                    {updateUserState.profile_error}
                </div>
                <button onClick={() => dispatch(actions.clearUpdateCurrentUserProfileError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>}
            <div className="flex">
                <div className="flex-1">
                  <div className="grid grid-cols-2 gap-4">
                      <div>
                          <FormikController
                              control='input'
                              type='text'
                              label='First Name'
                              name='firstname'
                          />
                      </div>
                      <div>
                        <FormikController
                            control='input'
                            type='text'
                            label='Middle Name'
                            name='middlename'
                          />
                      </div>
                      <div>
                        <FormikController
                            control='input'
                            type='text'
                            label='Last Name'
                            name='lastname'
                          />
                      </div>
                      <div>
                        <FormikController
                            control='input'
                            type='text'
                            label='Email'
                            name='email'
                          />
                      </div>
                      <div>
                        <FormikController
                            control='input'
                            type='text'
                            label='Mobile Number'
                            name='mobile'
                          />
                      </div>
                      <div>
                        <FormikController
                            control='select'
                            type='text'
                            options={ROLE}
                            label='Role'
                            name='role'
                          />
                      </div>
                  </div>
                </div>
                <div className='flex-1'>
                  <div className="grid grid-cols-2 gap-4 pl-4 ml-4 border-l ">
                      <div>
                          <FormikController
                              control='select'
                              disabled
                              type='text'
                              options={branchObj}
                              label='Branch'
                              name='branch'
                          />
                      </div>
                      <div>
                        <FormikController
                            control='input'
                            disabled
                            type='text'
                            label='Contact Person'
                            name='contactPerson'
                          />
                      </div>
                      <div>
                        <FormikController
                            control='input'
                            disabled
                            type='text'
                            label='Email'
                            name='contactEmail'
                          />
                      </div>
                      <div>
                        <FormikController
                            control='input'
                            type='text'
                            disabled
                            label='Mobile'
                            name='contactPhone'
                          />
                      </div>
                      <div className="col-span-2">
                        <FormikController
                            control='input'
                            type='text'
                            disabled
                            label='Branch Address'
                            name='address'
                          />
                      </div>
                      <div className="col-span-2">
                        <FormikController
                            control='input'
                            type='text'
                            disabled
                            label='City'
                            name='city'
                          />
                      </div>
                  </div>
                </div>
            </div>
            <div className='text-right my-5'>
              {updateUserState.profile_loading ? <button className="bg-gray-500 rounded px-8 py-1"><img className="h-8" src={loader} alt="loader" /></button> : 
              <button className="bg-green-800 text-white rounded px-3 py-2">Update</button>}
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default Settings