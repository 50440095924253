import {GET_PARTNER_BRANCHES, GET_PARTNER_BRANCHES_ERROR, GET_PARTNER_BRANCHES_LOADING, CLEAR_GET_PARTNER_BRANCHES_ERROR, CLEAR_GET_PARTNER_BRANCHES} from '../actions/types';

const initialState = {
    partner_branches: null,
    partner_branches_loading: false,
    partner_branches_error: null
}

export default function getPartnerBranchesReducer(state = initialState, action){
    switch(action.type){
        case GET_PARTNER_BRANCHES:
            return {
                ...state,
                partner_branches: action.payload,
                partner_branches_loading: false,
                partner_branches_error: null
            }
        case GET_PARTNER_BRANCHES_ERROR:
            return {
                ...state,
                partner_branches_loading: false,
                partner_branches_error: action.payload
            }
        case GET_PARTNER_BRANCHES_LOADING:
            return {
                ...state,
                partner_branches_loading: true,
                partner_branches_error: null
            }
        case CLEAR_GET_PARTNER_BRANCHES_ERROR:
            return {
                ...state,
                partner_branches_loading: false,
                partner_branches_error: null
            }
        case CLEAR_GET_PARTNER_BRANCHES:
            return {
                ...state,
                partner_branches: null,
                partner_branches_loading: false,
                partner_branches_error: null
            }
        default: return state
    }
}