import React from 'react'
import AddUser from '../components/users/AddUser'
import UsersTable from '../components/users/UsersTable'

function Users() {
  const [store, setStore] = React.useState(null);
  return (
    <div className="grid grid-cols-12 gap-4">
        <div className="col-span-9 p-8 overflow-x-hidden">
            <UsersTable setStore={setStore} />
        </div>
        <div className="col-span-3 border-l min-h-screen p-8">
            <AddUser store={store} setStore={setStore} />
        </div>
    </div>
  )
}

export default Users