import React from 'react';
import Clock from 'react-live-clock';
import { MdOutlineEmojiPeople } from 'react-icons/md';
import VerticalLogo from '../../assets/vertical-removebg-preview.png';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

function TopNavigation() {
  const authState = useSelector(authState => authState.loginReducer);

  return (
    <div className="nav-bar shadow py-3 px-8">
        <div className="flex justify-between">
          <div className="flex items-center">
            <p className="text-2xl uppercase border-r border-gray-300 pr-4">
              <Clock format={'HH:mm:ss a'} ticking={true} />
            </p>
            {authState.auth.role === 'TELLER' &&
              <Link to="/home">
              <button className="ml-4 text-lg flex items-center">
                <MdOutlineEmojiPeople  size="22" />
                <span className="ml-1 font-medium">Teller</span>
              </button>
            </Link>}
          </div>
          <div>
            <p></p>
            <img className="h-12" src={VerticalLogo} alt="remitter" />
          </div>
        </div>
    </div>
  )
}

export default TopNavigation