import {GET_PARTNERS, GET_PARTNERS_ERROR, GET_PARTNERS_LOADING, CLEAR_GET_PARTNERS_ERROR, CLEAR_GET_PARTNERS} from '../actions/types';

const initialState = {
    partner: null,
    partner_loading: false,
    partner_error: null
}

export default function getPartnersReducer(state = initialState, action){
    switch(action.type){
        case GET_PARTNERS:
            return {
                ...state,
                partner: action.payload,
                partner_loading: false,
                partner_error: null
            }
        case GET_PARTNERS_ERROR:
            return {
                ...state,
                partner_loading: false,
                partner_error: action.payload
            }
        case GET_PARTNERS_LOADING:
            return {
                ...state,
                partner_loading: true,
                partner_error: null
            }
        case CLEAR_GET_PARTNERS_ERROR:
            return {
                ...state,
                partner_loading: false,
                partner_error: null
            }
        case CLEAR_GET_PARTNERS:
            return {
                ...state,
                partner: null,
                partner_loading: false,
                partner_error: null
            }
        default: return state
    }
}