import { React, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../store/actions';
import FormikController from '../../Formik/FormikController';
import * as Yup from "yup";
import loader from '../../../assets/loaders/382.gif';
import { Formik, Form } from "formik";

function AddFees(props) {
    const dispatch = useDispatch();
    const { store, handleSubmit } = props;
    const { transaction_fees_error, transaction_fees_loading } = useSelector(addFee => addFee.addTransactionFeesReducer);
    const { get_currency } = useSelector(getCurrency => getCurrency.getCurrencyReducer);
    const { updateTransactionFeeError, updateTransactionFeeLoading } = useSelector(updateFee => updateFee.updateTransactionFeeReducer);

    let currency = get_currency ? get_currency.data.map(cxt => {
        let obj = {
            key: cxt.currencyName + " " + '(' + cxt.currencySymbol + ')',
            value: cxt.id
        }

        return obj;
    }) : [];

    const initialValues = {
        remittanceCorridor: store ? store.remittanceCorridor : '',
        currency: store ? store.currency.id : '',
        startAmount: store ? store.startAmount : '',
        endAmount: store ? store.endAmount : '',
        fee: store ? store.fee : '',
        feetype: store ? store.feetype : ''
    }

    const validationSchema = Yup.object({
        remittanceCorridor: Yup.string().required('This field is required'),
        currency: Yup.string().required('This field is required'),
        fee: Yup.number().required('This field is required'),
        feetype: Yup.string().required('This field is required'),
    })

    let corridor = [
        {
            key: "Local Corridor",
            value: "LOCAL"
        },
        {
            key: "International Corridor",
            value: "INTERNATIONAL",
        }
    ]

    let feesType = [
        {
            key: "Flat",
            value: "FLAT"
        },
        {
            key: "Percentage",
            value: "PERCENTAGE"
        }
    ]

    return (
        <div>
            {transaction_fees_error &&
                <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                    <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                    <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                        {transaction_fees_error}
                    </div>
                    <button onClick={() => dispatch(actions.clearAddTransactionFeesError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                        <span className="sr-only">Close</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button>
                </div>}

            {updateTransactionFeeError &&
                <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                    <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                    <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                        {updateTransactionFeeError}
                    </div>
                    <button onClick={() => dispatch(actions.clearUpdateTransactionFeeError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                        <span className="sr-only">Close</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button>
                </div>}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values)
                    resetForm()
                }}
            >
                  {({ values, setFieldValue }) => {
            return (
                <Form>
                    <div className="flex mb-3">
                        <div className="flex-1 mr-2">
                            <FormikController
                                control='select'
                                options={corridor}
                                label='Remittance Corridor'
                                name='remittanceCorridor'
                            />
                        </div>
                        <div className="flex-1 ml-2">
                            <FormikController
                                control='select'
                                type='text'
                                label='Currency'
                                name='currency'
                                options={currency}
                            />
                        </div>
                    </div>
                    <div className="flex mb-3">
                        <div className="flex-1 mr-2">
                            <FormikController
                                control='select'
                                options={feesType}
                                onSelect={(e) => setFieldValue('feetype',  e.target.value)}
                                label='Fees Type'
                                name='feetype'
                                value={values.feetype}
                               
                                
                                
                            />
                        </div>

                        <div className='flex-1'>
                            <div className="flex-1 ml-2">
                                <FormikController
                                    control='input'
                                    type='text'
                                    label='Transaction Fee'
                                    name='fee'
                                />
                            </div>
                        </div>
                    </div>



                    {values.feetype === "FLAT" &&
                        <div className="flex mb-3">
                            <div className="flex-1 mr-2">
                                <FormikController
                                    control='input'
                                    label='Start Amount'
                                    name='startAmount'
                                />
                            </div>

                            <div className='flex-1'>
                                <div className="flex-1 ml-2">
                                    <FormikController
                                        control='input'
                                        type='text'
                                        label='End Amount'
                                        name='endAmount'
                                    />
                                </div>
                            </div>
                        </div>}

                    <div className="text-right mt-2">
                        {transaction_fees_loading || updateTransactionFeeLoading ? <button className="bg-gray-500 rounded px-8 py-1"><img className="h-8" src={loader} alt="loader" /></button> :
                            <button type='submit' className="px-6 py-2 rounded text-white ease-in-out duration-700 bg-green-600 hover:bg-green-700 ">{store ? 'Update' : 'Add'}</button>}
                    </div>
                </Form>
                 );
                }}
            </Formik>
        </div>
    )
}

export default AddFees