import {
    CLEAR_UPDATE_RECONCILIATIONS_ERROR,
    CLEAR_UPDATE_RECONCILIATIONS,
    UPDATE_RECONCILIATIONS_LOADING,
    UPDATE_RECONCILIATIONS_ERROR,
    UPDATE_RECONCILIATIONS} 
from '../actions/types';

const initialState = {
    updateRecon: null,
    updateReconLoading: false,
    updateReconError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_RECONCILIATIONS_ERROR:
            return {
                ...state,
                updateReconLoading: false,
                updateReconError: action.payload
            }
        case CLEAR_UPDATE_RECONCILIATIONS:
            return {
                ...state,
                updateRecon: null,
                updateReconLoading: false,
                updateReconError: null
            }
        case UPDATE_RECONCILIATIONS_LOADING:
            return {
                ...state,
                updateReconLoading: true,
                updateReconError: null
            }
        case UPDATE_RECONCILIATIONS_ERROR:
            return {
                ...state,
                updateReconLoading: false,
                updateReconError: action.payload
            }
        case UPDATE_RECONCILIATIONS:
            return {
                ...state,
                updateRecon: action.payload,
                updateReconLoading: false,
                updateReconError: null
            }
        default: return state
    }
}