import {CLEAR_ADD_TRANSACTION_LIMIT, CLEAR_ADD_TRANSACTION_LIMIT_ERROR, ADD_TRANSACTION_LIMIT_LOADING, ADD_TRANSACTION_LIMIT_ERROR, ADD_TRANSACTION_LIMIT} from '../actions/types';

const initialState = {
    new_transaction_limit: null,
    new_transaction_limit_error: null,
    new_transaction_limit_loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ADD_TRANSACTION_LIMIT:
            return {
                ...state,
                new_transaction_limit: null,
                new_transaction_limit_error: null,
                new_transaction_limit_loading: false
            }
        case CLEAR_ADD_TRANSACTION_LIMIT_ERROR:
            return {
                ...state,
                new_transaction_limit_error: null,
                new_transaction_limit_loading: false
            }
        case ADD_TRANSACTION_LIMIT_LOADING:
            return {
                ...state,
                new_transaction_limit_error: null,
                new_transaction_limit_loading: true
            }
        case ADD_TRANSACTION_LIMIT_ERROR:
            return {
                ...state,
                new_transaction_limit_error: action.payload,
                new_transaction_limit_loading: false
            }
        case ADD_TRANSACTION_LIMIT:
            return {
                ...state,
                new_transaction_limit: action.payload,
                new_transaction_limit_error: null,
                new_transaction_limit_loading: false
            }
        default: return state
    }
}