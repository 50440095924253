import {
    CLEAR_DEACTIVATE_TRANSACTION_LIMIT_ERROR,
    CLEAR_DEACTIVATE_TRANSACTION_LIMIT,
    DEACTIVATE_TRANSACTION_LIMIT_LOADING,
    DEACTIVATE_TRANSACTION_LIMIT_ERROR,
    DEACTIVATE_TRANSACTION_LIMIT
} 
from '../actions/types';

const initialState = {
    deactivateTransactionLimit: null,
    deactivateTransactionLimitLoading: false,
    deactivateTransactionLimitError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_DEACTIVATE_TRANSACTION_LIMIT_ERROR:
            return {
                ...state,
                deactivateTransactionLimitLoading: false,
                deactivateTransactionLimitError: action.payload
            }
        case CLEAR_DEACTIVATE_TRANSACTION_LIMIT:
            return {
                ...state,
                deactivateTransactionLimit: null,
                deactivateTransactionLimitLoading: false,
                deactivateTransactionLimitError: null
            }
        case DEACTIVATE_TRANSACTION_LIMIT_LOADING:
            return {
                ...state,
                deactivateTransactionLimitLoading: true,
                deactivateTransactionLimitError: null
            }
        case DEACTIVATE_TRANSACTION_LIMIT_ERROR:
            return {
                ...state,
                deactivateTransactionLimitLoading: false,
                deactivateTransactionLimitError: action.payload
            }
        case DEACTIVATE_TRANSACTION_LIMIT:
            return {
                ...state,
                deactivateTransactionLimit: action.payload,
                deactivateTransactionLimitLoading: false,
                deactivateTransactionLimitError: null
            }
        default: return state
    }
}