import React from 'react'
import Currencies from '../components/configuration/Currencies'
import TransactionFees from '../components/configuration/TransactionFees'
import RemitReasons from '../components/configuration/RemitReasons'
import RemitSource from '../components/configuration/RemitSource'
import ReferenceConfig from '../components/configuration/ReferenceConfig'
import Limits from '../components/configuration/Limits'
import MessageConfig from '../components/configuration/MessageConfig'

function Configurations() {
  return (
    <div className="grid grid-cols-12 gap-4 p-8 bg-gray-100 min-h-screen">
        <div className="col-span-6">
            <Currencies />
        </div>
    
        <div className="col-span-6">
            <TransactionFees />
        </div>
        <div className="col-span-4">
            <RemitReasons />
        </div>
        <div className="col-span-4">
            <ReferenceConfig />
        </div>
        <div className="col-span-4">
            <RemitSource />
        </div>
        <div className="col-span-12">
            <Limits />
        </div>
        <div className="col-span-12">
            <MessageConfig/>
        </div>
    </div>
  )
}

export default Configurations