import {
    CLEAR_FEE_CONFIG_ERROR,
    CLEAR_FEE_CONFIG,
    FEE_CONFIG_LOADING,
    FEE_CONFIG_ERROR,
    FEE_CONFIG
} from '../actions/types';

const initialState = {
    feeConfig: null,
    feeConfigLoading: false,
    feeConfigError: null,
}

const feeConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case FEE_CONFIG:
            return {
                ...state,
                feeConfig: action.payload,
                feeConfigLoading: false,
                feeConfigError: null,
            }
        case FEE_CONFIG_ERROR:
            return {
                ...state,
                feeConfigLoading: false,
                feeConfigError: action.payload,
            }
        case FEE_CONFIG_LOADING:
            return {
                ...state,
                feeConfigLoading: true,
                feeConfigError: null,
            }
        case CLEAR_FEE_CONFIG:
            return {
                ...state,
                feeConfig: null,
                feeConfigLoading: false,
                feeConfigError: null,
            }
        case CLEAR_FEE_CONFIG_ERROR:
            return {
                ...state,
                feeConfigLoading: false,
                feeConfigError: null,
            }
        default: return state
    }
}

export default feeConfigReducer