import {
    CLEAR_FETCH_REMITTER_BENEFICIARY_ERROR,
    CLEAR_FETCH_REMITTER_BENEFICIARY,
    FETCH_REMITTER_BENEFICIARY_LOADING,
    FETCH_REMITTER_BENEFICIARY_ERROR,
    FETCH_REMITTER_BENEFICIARY
} from '../actions/types';

const initialState = {
    recipient: null,
    recipient_loading: false,
    recipient_error: null
}

const fetchRemitterBeneficiaryReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_REMITTER_BENEFICIARY:
            return {
                ...state,
                recipient: action.payload,
                recipient_loading: false,
                recipient_error: null
            }
        case FETCH_REMITTER_BENEFICIARY_ERROR:
            return {
                ...state,
                recipient_loading: false,
                recipient_error: action.payload
            }
        case FETCH_REMITTER_BENEFICIARY_LOADING:
            return {
                ...state,
                recipient_loading: true,
                recipient_error: null
            }
        case CLEAR_FETCH_REMITTER_BENEFICIARY:
            return {
                ...state,
                recipient: null,
                recipient_loading: false,
                recipient_error: null
            }
        case CLEAR_FETCH_REMITTER_BENEFICIARY_ERROR:
            return {
                ...state,
                recipient_loading: false,
                recipient_error: null
            }
        default: return state
    }
}

export default fetchRemitterBeneficiaryReducer