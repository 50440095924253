import {CLEAR_ADD_PARTNER_USERS, CLEAR_ADD_PARTNER_USERS_ERROR, ADD_PARTNER_USERS_LOADING, ADD_PARTNER_USERS_ERROR, ADD_PARTNER_USERS} from '../actions/types';

const initialState = {
    new_partner_user: null,
    new_partner_user_error: null,
    new_partner_user_loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ADD_PARTNER_USERS:
            return {
                ...state,
                new_partner_user: null,
                new_partner_user_error: null,
                new_partner_user_loading: false
            }
        case CLEAR_ADD_PARTNER_USERS_ERROR:
            return {
                ...state,
                new_partner_user_error: null,
                new_partner_user_loading: false
            }
        case ADD_PARTNER_USERS_LOADING:
            return {
                ...state,
                new_partner_user_error: null,
                new_partner_user_loading: true
            }
        case ADD_PARTNER_USERS_ERROR:
            return {
                ...state,
                new_partner_user_error: action.payload,
                new_partner_user_loading: false
            }
        case ADD_PARTNER_USERS:
            return {
                ...state,
                new_partner_user: action.payload,
                new_partner_user_error: null,
                new_partner_user_loading: false
            }
        default: return state
    }
}