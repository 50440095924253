import {
    CLEAR_ACTIVATE_TRANSACTION_LIMIT_ERROR,
    CLEAR_ACTIVATE_TRANSACTION_LIMIT,
    ACTIVATE_TRANSACTION_LIMIT_LOADING,
    ACTIVATE_TRANSACTION_LIMIT_ERROR,
    ACTIVATE_TRANSACTION_LIMIT
} 
from '../actions/types';

const initialState = {
    activateTransactionLimit: null,
    activateTransactionLimitLoading: false,
    activateTransactionLimitError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_ACTIVATE_TRANSACTION_LIMIT_ERROR:
            return {
                ...state,
                activateTransactionLimitLoading: false,
                activateTransactionLimitError: action.payload
            }
        case CLEAR_ACTIVATE_TRANSACTION_LIMIT:
            return {
                ...state,
                activateTransactionLimit: null,
                activateTransactionLimitLoading: false,
                activateTransactionLimitError: null
            }
        case ACTIVATE_TRANSACTION_LIMIT_LOADING:
            return {
                ...state,
                activateTransactionLimitLoading: true,
                activateTransactionLimitError: null
            }
        case ACTIVATE_TRANSACTION_LIMIT_ERROR:
            return {
                ...state,
                activateTransactionLimitLoading: false,
                activateTransactionLimitError: action.payload
            }
        case ACTIVATE_TRANSACTION_LIMIT:
            return {
                ...state,
                activateTransactionLimit: action.payload,
                activateTransactionLimitLoading: false,
                activateTransactionLimitError: null
            }
        default: return state
    }
}