import {
    CLEAR_UPDATE_PARTNER_BRANCHES_ERROR,
    CLEAR_UPDATE_PARTNER_BRANCHES,
    UPDATE_PARTNER_BRANCHES_LOADING,
    UPDATE_PARTNER_BRANCHES_ERROR,
    UPDATE_PARTNER_BRANCHES} 
from '../actions/types';

const initialState = {
    updatePartnerBranches: null,
    updatePartnerBranchesLoading: false,
    updatePartnerBranchesError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_PARTNER_BRANCHES_ERROR:
            return {
                ...state,
                updatePartnerBranchesLoading: false,
                updatePartnerBranchesError: action.payload
            }
        case CLEAR_UPDATE_PARTNER_BRANCHES:
            return {
                ...state,
                updatePartnerBranches: null,
                updatePartnerBranchesLoading: false,
                updatePartnerBranchesError: null
            }
        case UPDATE_PARTNER_BRANCHES_LOADING:
            return {
                ...state,
                updatePartnerBranchesLoading: true,
                updatePartnerBranchesError: null
            }
        case UPDATE_PARTNER_BRANCHES_ERROR:
            return {
                ...state,
                updatePartnerBranchesLoading: false,
                updatePartnerBranchesError: action.payload
            }
        case UPDATE_PARTNER_BRANCHES:
            return {
                ...state,
                updatePartnerBranches: action.payload,
                updatePartnerBranchesLoading: false,
                updatePartnerBranchesError: null
            }
        default: return state
    }
}