import {
    CLEAR_FORGOT_PASSWORD_ERROR,
    CLEAR_FORGOT_PASSWORD,
    FORGOT_PASSWORD_LOADING,
    FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD
} from '../actions/types';

const initialState = {
    forgotPassword: null,
    forgotPasswordLoading: false,
    forgotPasswordError: null,
}

const forgotPasswordReducer = (state = initialState, action) => {
    switch(action.type){
        case FORGOT_PASSWORD:
            return {
                ...state,
                forgotPassword: action.payload,
                forgotPasswordLoading: false,
                forgotPasswordError: null,
            }
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgotPasswordLoading: false,
                forgotPasswordError: action.payload,
            }
        case FORGOT_PASSWORD_LOADING:
            return {
                ...state,
                forgotPasswordLoading: true,
                forgotPasswordError: null,
            }
        case CLEAR_FORGOT_PASSWORD:
            return {
                ...state,
                forgotPassword: null,
                forgotPasswordLoading: false,
                forgotPasswordError: null,
            }
        case CLEAR_FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgotPasswordLoading: false,
                forgotPasswordError: null,
            }
        default: return state
    }
}

export default forgotPasswordReducer;