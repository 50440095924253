import {
    CLEAR_SEARCH_REMITTER_ERROR,
    CLEAR_SEARCH_REMITTER,
    SEARCH_REMITTER_LOADING,
    SEARCH_REMITTER_ERROR,
    SEARCH_REMITTER
} from '../actions/types';

const initialState = {
    remitter: null,
    remitter_loading: false,
    remitter_error: null
}


const searchRemitterReducer = (state = initialState, action)  => {
    switch (action.type) {
        case SEARCH_REMITTER:
            return {
                ...state,
                remitter: action.payload,
                remitter_loading: false,
                remitter_error: null
            }
        case SEARCH_REMITTER_ERROR:
            return {
                ...state,
                remitter_loading: false,
                remitter_error: action.payload
            }
        case SEARCH_REMITTER_LOADING:
            return {
                ...state,
                remitter_loading: true,
                remitter_error: null
            }
        case CLEAR_SEARCH_REMITTER:
            return {
                ...state,
                remitter: null,
                remitter_loading: false,
                remitter_error: null
            }
        case CLEAR_SEARCH_REMITTER_ERROR:
            return {
                ...state,
                remitter_loading: false,
                remitter_error: null
            }
        default: return state
    }
}

export default searchRemitterReducer