import {
    CLEAR_ADD_TRANSACTION_FEES_ERROR,
    CLEAR_ADD_TRANSACTION_FEES,
    ADD_TRANSACTION_FEES_LOADING,
    ADD_TRANSACTION_FEES_ERROR,
    ADD_TRANSACTION_FEES
} from '../actions/types';

const initialState = {
    transaction_fees: null,
    transaction_fees_loading: false,
    transaction_fees_error: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_ADD_TRANSACTION_FEES_ERROR:
            return {
                ...state,
                transaction_fees_loading: false,
                transaction_fees_error: null
            }
        case CLEAR_ADD_TRANSACTION_FEES:
            return {
                ...state,
                transaction_fees: null,
                transaction_fees_loading: false,
                transaction_fees_error: null
            }
        case ADD_TRANSACTION_FEES_LOADING:
            return {
                ...state,
                transaction_fees_loading: true,
                transaction_fees_error: null
            }
        case ADD_TRANSACTION_FEES_ERROR:
            return {
                ...state,
                transaction_fees_loading: false,
                transaction_fees_error: action.payload
            }
        case ADD_TRANSACTION_FEES:
            return {
                ...state,
                transaction_fees: action.payload,
                transaction_fees_loading: false,
                transaction_fees_error: null
            }
        default: return state
    }
}