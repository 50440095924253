import {
    CLEAR_ACTIVATE_BRANCHES_ERROR,
    CLEAR_ACTIVATE_BRANCHES,
    ACTIVATE_BRANCHES_LOADING,
    ACTIVATE_BRANCHES_ERROR,
    ACTIVATE_BRANCHES
} 
from '../actions/types';

const initialState = {
    activateBranches: null,
    activateBranchesLoading: false,
    activateBranchesError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_ACTIVATE_BRANCHES_ERROR:
            return {
                ...state,
                activateBranchesLoading: false,
                activateBranchesError: action.payload
            }
        case CLEAR_ACTIVATE_BRANCHES:
            return {
                ...state,
                activateBranches: null,
                activateBranchesLoading: false,
                activateBranchesError: null
            }
        case ACTIVATE_BRANCHES_LOADING:
            return {
                ...state,
                activateBranchesLoading: true,
                activateBranchesError: null
            }
        case ACTIVATE_BRANCHES_ERROR:
            return {
                ...state,
                activateBranchesLoading: false,
                activateBranchesError: action.payload
            }
        case ACTIVATE_BRANCHES:
            return {
                ...state,
                activateBranches: action.payload,
                activateBranchesLoading: false,
                activateBranchesError: null
            }
        default: return state
    }
}