import {
    CLEAR_GET_CURRENCY_ERROR,
    CLEAR_GET_CURRENCY,
    GET_CURRENCY_LOADING,
    GET_CURRENCY_ERROR,
    GET_CURRENCY
} from '../actions/types';

const initialState = {
    get_currency: null,
    get_currency_loading: false,
    get_currency_error: null,
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_GET_CURRENCY_ERROR:
            return {
                ...state,
                get_currency_loading: false,
                get_currency_error: null,
            }
        case CLEAR_GET_CURRENCY:
            return {
                ...state,
                get_currency: null,
                get_currency_loading: false,
                get_currency_error: null,
            }
        case GET_CURRENCY_LOADING:
            return {
                ...state,
                get_currency_loading: true,
                get_currency_error: null,
            }
        case GET_CURRENCY_ERROR:
            return {
                ...state,
                get_currency_loading: false,
                get_currency_error: action.payload,
            }
        case GET_CURRENCY:
            return {
                ...state,
                get_currency: action.payload,
                get_currency_loading: false,
                get_currency_error: null,
            }
        default: return state
    }
}