import {
    CLEAR_CONFIRM_FLOAT_ERROR,
    CLEAR_CONFIRM_FLOAT,
    CONFIRM_FLOAT_LOADING,
    CONFIRM_FLOAT_ERROR,
    CONFIRM_FLOAT
} from '../actions/types';

const initialState = {
    confirm_float: null,
    confirm_float_loading: false,
    confirm_float_error: null
}

const confirmFloatReducer = (state = initialState, action) => {
    switch(action.type){
        case CLEAR_CONFIRM_FLOAT_ERROR:
            return {
                ...state,
                confirm_float_loading: false,
                confirm_float_error: null
            }
        case CLEAR_CONFIRM_FLOAT:
            return {
                ...state,
                confirm_float: null,
                confirm_float_loading: false,
                confirm_float_error: null
            }
        case CONFIRM_FLOAT_LOADING:
            return {
                ...state,
                confirm_float_loading: true,
                confirm_float_error: null
            }
        case CONFIRM_FLOAT_ERROR:
            return {
                ...state,
                confirm_float_loading: false,
                confirm_float_error: action.payload
            }
        case CONFIRM_FLOAT:
            return {
                ...state,
                confirm_float: action.payload,
                confirm_float_loading: false,
                confirm_float_error: null
            }
        default: return state
    }
}

export default confirmFloatReducer