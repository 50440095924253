import { BsPlus, BsGearFill } from 'react-icons/bs';
import { FaChartPie, FaUserTie } from 'react-icons/fa';
import { BiLogOutCircle } from 'react-icons/bi';
import { VscSettings } from 'react-icons/vsc';
import { BsPiggyBank } from 'react-icons/bs';
import { MdBusinessCenter } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { useSelector } from "react-redux";

const SideBar = (props) => {
  const {logout} = props;
  const history = useHistory();
  const authState = useSelector(authState => authState.loginReducer);

  const handleLogout = () => {
    logout();
    history.push('/')
  }
  return (
    <div className="fixed top-0 left-0 h-screen w-16 flex flex-col bg-gray-600 dark:bg-gray-900 shadow-lg">     
       {authState.auth.role !== 'TELLER' &&
         <> 
       <Link to="/reports">
          <SideBarIcon icon={<FaChartPie size="26" />} text="Reports 💡" />
        </Link>
        <Divider />
        {authState.auth.role === 'ACCOUNTS' && <Link to="/operations">
          <SideBarIcon icon={<BsPiggyBank size="26" />} text="Business Operations 💡"  />
        </Link>}
        <Link to="/branch">
          <SideBarIcon icon={<BsPlus size="32" />} text="Add Branch 💡" />
        </Link>
        <Link to="/users">
          <SideBarIcon icon={<FaUserTie size="20" />} text="Manage Users 💡"  />
        </Link>
        {authState.auth.role !== 'ACCOUNTS' && authState.auth.accountType !== 'PARTNER' &&
        <>
        <Link to="/partners">
          <SideBarIcon icon={<MdBusinessCenter size="26" />} text="Partners 💡"  />
        </Link>
        <Link to="/configurations">
          <SideBarIcon icon={<VscSettings size="22" />} text="Configurations 💡"  />
        </Link>
        </>
        }
        </>
        }
        <Link to="/account-settings">
          <SideBarIcon icon={<BsGearFill size="22" />} text="My Account 💡" />
        </Link>
        <Divider />
        <SideBarIcon handleLogout={handleLogout} icon={<BiLogOutCircle size="23" />} text="Logout 💡"  />
    </div>
  );
};

const SideBarIcon = ({ icon, text, handleLogout }) => (
  <div onClick={handleLogout} className="sidebar-icon group">
    {icon}
    <span className="sidebar-tooltip group-hover:scale-100">
      {text}
    </span>
  </div>
);


const Divider = () => <hr className="sidebar-hr" />;

SideBar.propTypes = {
  logout: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps, actions)(SideBar);