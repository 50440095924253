import {
    CLEAR_GET_REFERENCE_CONFIG_ERROR,
    CLEAR_GET_REFERENCE_CONFIG,
    GET_REFERENCE_CONFIG_LOADING,
    GET_REFERENCE_CONFIG_ERROR,
    GET_REFERENCE_CONFIG
} from '../actions/types';

const initialState = {
    get_reference_config: null,
    get_reference_config_loading: false,
    get_reference_config_error: null,
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_GET_REFERENCE_CONFIG_ERROR:
            return {
                ...state,
                get_reference_config_loading: false,
                get_reference_config_error: null,
            }
        case CLEAR_GET_REFERENCE_CONFIG:
            return {
                ...state,
                get_reference_config: null,
                get_reference_config_loading: false,
                get_reference_config_error: null,
            }
        case GET_REFERENCE_CONFIG_LOADING:
            return {
                ...state,
                get_reference_config_loading: true,
                get_reference_config_error: null,
            }
        case GET_REFERENCE_CONFIG_ERROR:
            return {
                ...state,
                get_reference_config_loading: false,
                get_reference_config_error: action.payload,
            }
        case GET_REFERENCE_CONFIG:
            return {
                ...state,
                get_reference_config: action.payload,
                get_reference_config_loading: false,
                get_reference_config_error: null,
            }
        default: return state
    }
}