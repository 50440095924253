import {
    CLEAR_ADD_CURRENCY_ERROR,
    CLEAR_ADD_CURRENCY,
    ADD_CURRENCY_LOADING,
    ADD_CURRENCY_ERROR,
    ADD_CURRENCY
} from '../actions/types';

const initialState = {
    currency: null,
    currency_loading: false,
    currency_error: null,
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_ADD_CURRENCY_ERROR:
            return {
                ...state,
                currency_loading: false,
                currency_error: null,
            }
        case CLEAR_ADD_CURRENCY:
            return {
                ...state,
                currency: null,
                currency_loading: false,
                currency_error: null,
            }
        case ADD_CURRENCY_LOADING:
            return {
                ...state,
                currency_loading: true,
                currency_error: null,
            }
        case ADD_CURRENCY_ERROR:
            return {
                ...state,
                currency_loading: false,
                currency_error: action.payload,
            }
        case ADD_CURRENCY:
            return {
                ...state,
                currency: action.payload,
                currency_loading: false,
                currency_error: null,
            }
        default: return state
    }
}