import {
    CLEAR_SUMMARY_REPORT_ERROR,
    CLEAR_SUMMARY_REPORT,
    SUMMARY_REPORT_LOADING,
    REPORT_SUMMARY_ERROR,
    REPORT_SUMMARY
} from '../actions/types';

const initialState = {
    summary: null,
    summaryLoading: false,
    summaryError: null
}

const reportSummaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORT_SUMMARY:
            return {
                ...state,
                summary: action.payload,
                summaryLoading: false,
                summaryError: null
            }
        case REPORT_SUMMARY_ERROR:
            return {
                ...state,
                summaryLoading: false,
                summaryError: action.payload
            }
        case SUMMARY_REPORT_LOADING:
            return {
                ...state,
                summaryLoading: true,
                summaryError: null
            }
        case CLEAR_SUMMARY_REPORT:
            return {
                ...state,
                summary: null,
                summaryLoading: false,
                summaryError: null
            }
        case CLEAR_SUMMARY_REPORT_ERROR:
            return {
                ...state,
                summaryLoading: false,
                summaryError: null
            }
        default: return state
    }
}

export default reportSummaryReducer