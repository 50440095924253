import {
    ADD_REMITTER,
    ADD_REMITTER_ERROR,
    ADD_REMITTER_LOADING,
    CLEAR_ADD_REMITTER,
    CLEAR_ADD_REMITTER_ERROR
} 
from '../actions/types';

const initialState = {
    remitter: null,
    remitter_loading: false,
    remitter_error: null
}

export default function (state = initialState, action){
    switch(action.type){
        case ADD_REMITTER:
            return {
                ...state,
                remitter: action.payload,
                remitter_loading: false,
                remitter_error: null
            } 
        case ADD_REMITTER_ERROR:
            return {
                ...state,
                remitter_loading: false,
                remitter_error: action.payload
            }
        case ADD_REMITTER_LOADING:
            return {
                ...state,
                remitter_loading: true,
                remitter_error: null
            }
        case CLEAR_ADD_REMITTER:
            return {
                ...state,
                remitter: null,
                remitter_loading: false,
                remitter_error: null
            }
        case CLEAR_ADD_REMITTER_ERROR:
            return {
                ...state,
                remitter_loading: false,
                remitter_error: null
            }
        default: return state
    }
}