import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions';
import ReactApexChart from "react-apexcharts";
import handleSimpleDateFormat from '../../utils/handleDateSimpleFormat';

function GraphicalSummary() {
    const dispatch = useDispatch();
    const summaryState = useSelector(summaryState => summaryState.graphicalSummaryReducer);

    const [category, setCategory] = React.useState([]);
    const [commission, setCommision] = React.useState([]);
    const [revenue, setRevenue] = React.useState([]);
    const [processed, setProcessed] = React.useState([]);

    React.useEffect(() => {
        let cat = [];
        let com = [];
        let rev = [];
        let proc = [];
        
        if(summaryState.summary){
           summaryState.summary.data.map(el => {
               cat.push(el.period);
               proc.push(el.processed);
               rev.push(el.revenue);
               com.push(el.commission ? el.commission : 0)
           })
        }
     
        setRevenue(rev);
        setCommision(com);
        setCategory(cat);
        setProcessed(proc);
    }, [summaryState.summary]);

    const series = [{
        name: 'Processed',
        data: processed
      }, {
        name: 'Commission',
        data: commission
      }, {
        name: 'Revenue',
        data: revenue
      }];

      const options = {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: category,
        },
        yaxis: {
          title: {
            text: '$ (dollars)'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " dollars"
            }
          }
        }
      };

    React.useEffect(() => {
        dispatch(actions.graphicalReport({
            filter: 'INHOUSE',
            type: '',
            selection: '',
            start: handleSimpleDateFormat(-10),
            end: handleSimpleDateFormat(0)
        }))
    }, []);

    return (
        <div className="app">
            <div className="row">
                <div className="bg-gray-100 border-b px-4 py-2 text-lg">
                    <h2>Revenue Analytics</h2>
                </div>
                <div className="mixed-chart px-4 py-2">
                    <ReactApexChart options={options} series={series} type="bar" height={350} />
                </div>
            </div>
        </div>
    )
}

export default GraphicalSummary