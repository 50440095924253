import {
    CLEAR_FETCH_TELLERS_ERROR,
    CLEAR_FETCH_TELLERS,
    FETCH_TELLERS_LOADING,
    FETCH_TELLERS_ERROR,
    FETCH_TELLERS
} from '../actions/types';

const initialState = {
    tellers: null,
    tellers_loading: false,
    tellers_error: null
}

const fetchTellersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TELLERS:
            return {
                ...state,
                tellers: action.payload,
                tellers_loading: false,
                tellers_error: null
            }
        case FETCH_TELLERS_ERROR:
            return {
                ...state,
                tellers_loading: false,
                tellers_error: action.payload
            }
        case FETCH_TELLERS_LOADING:
            return {
                ...state,
                tellers_loading: true,
                tellers_error: null
            }
        case CLEAR_FETCH_TELLERS:
            return {
                ...state,
                tellers: null,
                tellers_loading: false,
                tellers_error: null
            }
        case CLEAR_FETCH_TELLERS_ERROR:
            return {
                ...state,
                tellers_loading: false,
                tellers_error: null
            }
        default: return state
    }
}

export default fetchTellersReducer

