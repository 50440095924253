import React from 'react';
import * as Yup from "yup";
import { Formik, Form } from "formik";
import * as actions from '../../../store/actions';
import FormikController from '../../Formik/FormikController';
import loader from '../../../assets/loaders/382.gif';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function AddPartnerBranch({store}) {
    const dispatch = useDispatch();
    const {new_partner_branch_error, new_partner_branch_loading} = useSelector(addBranch => addBranch.addPartnerBranchesReducer);
    const {partner} = useSelector(getPartners => getPartners.getPartnersReducer);
    const {updatePartnerBranches, updatePartnerBranchesLoading, updatePartnerBranchesError} = useSelector(updateBranch => updateBranch.updatePartnerBranchesReducer);

    const { id } = useParams();

    let result = partner.data.find(partner => partner.id === id);

    const INITIAL_VALUE = {
        branchName: (store) ? store.branchName : '',
        partnerId: id,
        address: (store) ? store.address : '',
        city: (store) ? store.city : '',
        contactPerson: (store) ? store.contactPerson : '',
        contactEmail: (store) ? store.contactEmail : '',
        contactPhone: (store) ? store.contactPhone : ''
    }

    const VALIDATION_SCHEMA = Yup.object({
        branchName: Yup.string().required('This field is required'),
        address: Yup.string().required('This field is required'),
        city: Yup.string().required('This field is required'),
        contactPerson: Yup.string().required('This field is required'),
        contactEmail: Yup.string().email('Enter valid email address').required('This field is required'),
        contactPhone: Yup.string().required('This field is required'),
    })

    const handleSubmit = (values) => {
        values.id = store ? store.id : null ;
        store ? dispatch(actions.updatePartnerBranches(values)) : dispatch(actions.addPartnerBranches(values));
    }

    return (
        <div>
                <h2 className="font-medium text-gray-800 mb-3">{store ? `Update ${result.OrganizationName} Branch` : `Add ${result.OrganizationName} Branch`}</h2>
            {new_partner_branch_error &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                    {new_partner_branch_error}
                </div>
                <button onClick={()=>dispatch(actions.clearAddPartnerBranchesError())}type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>}
            {updatePartnerBranchesError &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                    {updatePartnerBranchesError}
                </div>
                <button onClick={()=>dispatch(actions.clearUpdatePartnerBranchesError())}type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>}
            <Formik
                initialValues={INITIAL_VALUE}
                enableReinitialize
                validationSchema={VALIDATION_SCHEMA}
                onSubmit={(values, {resetForm}) => {
                    handleSubmit(values);
                    resetForm();
                }}
            >
                {() => (
                    <Form>
                        
                        <div className="mb-4 pt-2">
                            <FormikController
                                control='input'
                                type='text'
                                label='Branch Name'
                                name='branchName'
                            />
                        </div>
                        <div className="mb-3 ">
                            <FormikController
                                control='input'
                                type='text'
                                label='Address'
                                name='address'
                            />
                        </div>
                        <div className="mb-3 ">
                            <FormikController
                                control='input'
                                type='text'
                                label='City'
                                name='city'
                            />
                        </div>
                        <div className="mb-3 ">
                            <FormikController
                                control='input'
                                type='text'
                                label='Contact Person'
                                name='contactPerson'
                            />
                        </div>
                        <div className="mb-3 ">
                            <FormikController
                                control='input'
                                type='text'
                                label='Email'
                                name='contactEmail'
                            />
                        </div>
                        <div className="mb-3 ">
                            <FormikController
                                control='input'
                                type='text'
                                placeholder="e.g +263773372467"
                                label='Phone Number'
                                name='contactPhone'
                            />
                        </div>
                        <div className="mb-3 pt-2 text-right">
                        {new_partner_branch_loading || updatePartnerBranchesLoading ? <button className="bg-gray-500 rounded px-8 py-1"><img className="h-8" src={loader} alt="loader" /></button> : 
                        <button type='submit' className="px-6 py-2 rounded text-white ease-in-out duration-700 bg-green-600 hover:bg-green-700 ">{store ? 'Update' :'Submit'}</button>}
                        </div>
                    </Form>
                )
                }
            </Formik>
        </div>
    )
}

export default AddPartnerBranch