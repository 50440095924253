import {
    CLEAR_UPDATE_CURRENT_USER_PROFILE_ERROR,
    CLEAR_UPDATE_CURRENT_USER_PROFILE,
    UPDATE_CURRENT_USER_PROFILE_LOADING,
    UPDATE_CURRENT_USER_PROFILE_ERROR,
    UPDATE_CURRENT_USER_PROFILE
} from '../actions/types';

const initialState = {
    profile: null,
    profile_loading: false,
    profile_error: null
}

const updateCurrentUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CURRENT_USER_PROFILE:
            return {
                ...state,
                profile: action.payload,
                profile_loading: false,
                profile_error: null
            }
        case UPDATE_CURRENT_USER_PROFILE_ERROR:
            return {
                ...state,
                profile_loading: false,
                profile_error: action.payload
            }
        case UPDATE_CURRENT_USER_PROFILE_LOADING:
            return {
                ...state,
                profile_loading: true,
                profile_error: null
            }
        case CLEAR_UPDATE_CURRENT_USER_PROFILE:
            return {
                ...state,
                profile: null,
                profile_loading: false,
                profile_error: null
            }
        case CLEAR_UPDATE_CURRENT_USER_PROFILE_ERROR:
            return {
                ...state,
                profile_loading: false,
                profile_error: null
            }
        default: return state
    }
}

export default updateCurrentUserReducer;