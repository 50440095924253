import {GET_TRANSACTION_LIMIT, GET_TRANSACTION_LIMIT_ERROR, GET_TRANSACTION_LIMIT_LOADING, CLEAR_GET_TRANSACTION_LIMIT_ERROR, CLEAR_GET_TRANSACTION_LIMIT} from '../actions/types';

const initialState = {
    transaction_limit: null,
    transaction_limit_loading: false,
    transaction_limit_error: null
}

export default function getTransactionLimit(state = initialState, action){
    switch(action.type){
        case GET_TRANSACTION_LIMIT:
            return {
                ...state,
                transaction_limit: action.payload,
                transaction_limit_loading: false,
                transaction_limit_error: null
            }
        case GET_TRANSACTION_LIMIT_ERROR:
            return {
                ...state,
                transaction_limit_loading: false,
                transaction_limit_error: action.payload
            }
        case GET_TRANSACTION_LIMIT_LOADING:
            return {
                ...state,
                transaction_limit_loading: true,
                transaction_limit_error: null
            }
        case CLEAR_GET_TRANSACTION_LIMIT_ERROR:
            return {
                ...state,
                transaction_limit_loading: false,
                transaction_limit_error: null
            }
        case CLEAR_GET_TRANSACTION_LIMIT:
            return {
                ...state,
                transaction_limit: null,
                transaction_limit_loading: false,
                transaction_limit_error: null
            }
        default: return state
    }
}