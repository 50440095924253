export const CLEAR_GET_BRANCH = 'CLEAR_GET_BRANCH';
export const GET_BRANCH_ERROR = 'GET_BRANCH_ERROR';
export const GET_BRANCH = 'GET_BRANCH';
export const CLEAR_GET_BRANCH_ERROR = 'CLEAR_GET_BRANCH_ERROR';
export const GET_BRANCH_LOADING = 'GET_BRANCH_LOADING';

export const ADD_BRANCH = 'ADD_BRANCH';
export const ADD_BRANCH_ERROR = 'ADD_BRANCH_ERROR';
export const ADD_BRANCH_LOADING = 'ADD_BRANCH_LOADING';
export const CLEAR_ADD_BRANCH_ERROR = 'CLEAR_ADD_BRANCH_ERROR';
export const CLEAR_ADD_BRANCH = 'CLEAR_ADD_BRANCH';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const ADD_USER_LOADING = 'ADD_USER_LOADING';
export const CLEAR_ADD_USER_ERROR = 'CLEAR_ADD_USER_ERROR';
export const CLEAR_ADD_USER = 'CLEAR_ADD_USER';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const GET_USERS_LOADING = 'GET_USERS_LOADING';
export const CLEAR_GET_USERS_ERROR = 'CLEAR_GET_USERS_ERROR';
export const CLEAR_GET_USERS = 'CLEAR_GET_USERS';

export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';
export const CLEAR_LOGIN = 'CLEAR_LOGIN';

export const ADD_REMITTER = 'ADD_REMITTER';
export const ADD_REMITTER_ERROR = 'ADD_REMITTER_ERROR';
export const ADD_REMITTER_LOADING = 'ADD_REMITTER_LOADING';
export const CLEAR_ADD_REMITTER = 'CLEAR_ADD_REMITTER';
export const CLEAR_ADD_REMITTER_ERROR = 'CLEAR_ADD_REMITTER_ERROR';

export const UPDATE_BRANCH = 'UPDATE_BRANCH';
export const UPDATE_BRANCH_ERROR = 'UPDATE_BRANCH_ERROR';
export const UPDATE_BRANCH_LOADING = 'UPDATE_BRANCH_LOADING';
export const CLEAR_UPDATE_BRANCH = 'CLEAR_UPDATE_BRANCH';
export const CLEAR_UPDATE_BRANCH_ERROR = 'CLEAR_UPDATE_BRANCH_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';
export const CLEAR_UPDATE_USER = 'CLEAR_UPDATE_USER';
export const CLEAR_UPDATE_USER_ERROR = 'CLEAR_UPDATE_USER_ERROR';

export const ADD_CURRENCY = 'ADD_CURRENCY';
export const ADD_CURRENCY_ERROR = 'ADD_CURRENCY_ERROR';
export const ADD_CURRENCY_LOADING = 'ADD_CURRENCY_LOADING';
export const CLEAR_ADD_CURRENCY = 'CLEAR_ADD_CURRENCY';
export const CLEAR_ADD_CURRENCY_ERROR = 'CLEAR_ADD_CURRENCY_ERROR';

export const GET_CURRENCY = 'GET_CURRENCY';
export const GET_CURRENCY_ERROR = 'GET_CURRENCY_ERROR';
export const GET_CURRENCY_LOADING = 'GET_CURRENCY_LOADING';
export const CLEAR_GET_CURRENCY = 'CLEAR_GET_CURRENCY';
export const CLEAR_GET_CURRENCY_ERROR = 'CLEAR_GET_CURRENCY_ERROR';

export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const UPDATE_CURRENCY_ERROR = 'UPDATE_CURRENCY_ERROR';
export const UPDATE_CURRENCY_LOADING = 'UPDATE_CURRENCY_LOADING';
export const CLEAR_UPDATE_CURRENCY = 'CLEAR_UPDATE_CURRENCY';
export const CLEAR_UPDATE_CURRENCY_ERROR = 'CLEAR_UPDATE_CURRENCY_ERROR';

export const ADD_TRANSACTION_FEES = 'ADD_TRANSACTION_FEES';
export const ADD_TRANSACTION_FEES_ERROR = 'ADD_TRANSACTION_FEES_ERROR';
export const ADD_TRANSACTION_FEES_LOADING = 'ADD_TRANSACTION_FEES_LOADING';
export const CLEAR_ADD_TRANSACTION_FEES = 'CLEAR_ADD_TRANSACTION_FEES';
export const CLEAR_ADD_TRANSACTION_FEES_ERROR = 'CLEAR_ADD_TRANSACTION_FEES_ERROR';

export const CLEAR_GET_TRANSACTION_FEES_ERROR = 'CLEAR_GET_TRANSACTION_FEES_ERROR';
export const CLEAR_GET_TRANSACTION_FEES = 'CLEAR_GET_TRANSACTION_FEES';
export const GET_TRANSACTION_FEES_LOADING = 'GET_TRANSACTION_FEES_LOADING';
export const GET_TRANSACTION_FEES_ERROR = 'GET_TRANSACTION_FEES_ERROR';
export const GET_TRANSACTION_FEES = 'GET_TRANSACTION_FEES';

export const ADD_REMIT_REASON = 'ADD_REMIT_REASON';
export const ADD_REMIT_REASON_ERROR = 'ADD_REMIT_REASON_ERROR';
export const ADD_REMIT_REASON_LOADING = 'ADD_REMIT_REASON_LOADING';
export const CLEAR_ADD_REMIT_REASON = 'CLEAR_ADD_REMIT_REASON';
export const CLEAR_ADD_REMIT_REASON_ERROR = 'CLEAR_ADD_REMIT_REASON_ERROR';

export const GET_REMIT_REASONS = 'GET_REMIT_REASONS';
export const GET_REMIT_REASONS_ERROR = 'GET_REMIT_REASONS_ERROR';
export const GET_REMIT_REASONS_LOADING = 'GET_REMIT_REASONS_LOADING';
export const CLEAR_GET_REMIT_REASONS = 'CLEAR_GET_REMIT_REASONS';
export const CLEAR_GET_REMIT_REASONS_ERROR = 'CLEAR_GET_REMIT_REASONS_ERROR';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING';
export const CLEAR_FORGOT_PASSWORD = 'CLEAR_FORGOT_PASSWORD';
export const CLEAR_FORGOT_PASSWORD_ERROR = 'CLEAR_FORGOT_PASSWORD_ERROR';

export const REMIT_SOURCE = 'REMIT_SOURCE';
export const REMIT_SOURCE_ERROR = 'REMIT_SOURCE_ERROR';
export const REMIT_SOURCE_LOADING = 'REMIT_SOURCE_LOADING';
export const CLEAR_REMIT_SOURCES = 'CLEAR_REMIT_SOURCES';
export const CLEAR_REMIT_SOURCES_ERROR = 'CLEAR_REMIT_SOURCES_ERROR';

export const GET_REMIT_SOURCES = 'GET_REMIT_SOURCES';
export const GET_REMIT_SOURCES_ERROR = 'GET_REMIT_SOURCES_ERROR';
export const GET_REMIT_SOURCES_LOADING = 'GET_REMIT_SOURCES_LOADING';
export const CLEAR_GET_REMIT_SOURCE = 'CLEAR_GET_REMIT_SOURCE';
export const CLEAR_GET_REMIT_SOURCE_ERROR = 'CLEAR_GET_REMIT_SOURCE_ERROR';

export const UPDATE_REMIT_SOURCE = 'UPDATE_REMIT_SOURCE';
export const UPDATE_REMIT_SOURCE_ERROR = 'UPDATE_REMIT_SOURCE_ERROR';
export const UPDATE_REMIT_SOURCE_LOADING = 'UPDATE_REMIT_SOURCE_LOADING';
export const CLEAR_UPDATE_REMIT_SOURCE = 'CLEAR_UPDATE_REMIT_SOURCE';
export const CLEAR_UPDATE_REMIT_SOURCE_ERROR = 'CLEAR_UPDATE_REMIT_SOURCE_ERROR';

export const UPDATE_TRANSACTION_FEE = 'UPDATE_TRANSACTION_FEE';
export const UPDATE_TRANSACTION_FEE_ERROR = 'UPDATE_TRANSACTION_FEE_ERROR';
export const UPDATE_TRANSACTION_FEE_LOADING = 'UPDATE_TRANSACTION_FEE_LOADING';
export const CLEAR_UPDATE_TRANSACTION_FEE = 'CLEAR_UPDATE_TRANSACTION_FEE';
export const CLEAR_UPDATE_TRANSACTION_FEE_ERROR = 'CLEAR_UPDATE_TRANSACTION_FEE_ERROR';

export const UPDATE_REMIT_REASON = 'UPDATE_REMIT_REASON';
export const UPDATE_REMIT_REASON_ERROR = 'UPDATE_REMIT_REASON_ERROR';
export const UPDATE_REMIT_REASON_LOADING = 'UPDATE_REMIT_REASON_LOADING';
export const CLEAR_UPDATE_REMIT_REASON = 'CLEAR_UPDATE_REMIT_REASON';
export const CLEAR_UPDATE_REMIT_REASON_ERROR = 'CLEAR_UPDATE_REMIT_REASON_ERROR';

export const ACTIVATE_CURRENCY = 'ACTIVATE_CURRENCY';
export const ACTIVATE_CURRENCY_ERROR = 'ACTIVATE_CURRENCY_ERROR';
export const ACTIVATE_CURRENCY_LOADING = 'ACTIVATE_CURRENCY_LOADING';
export const CLEAR_ACTIVATE_CURRENCY = 'CLEAR_ACTIVATE_CURRENCY';
export const CLEAR_ACTIVATE_CURRENCY_ERROR = 'CLEAR_ACTIVATE_CURRENCY_ERROR';

export const DEACTIVATE_CURRENCY = 'DEACTIVATE_CURRENCY';
export const DEACTIVATE_CURRENCY_ERROR = 'DEACTIVATE_CURRENCY_ERROR';
export const DEACTIVATE_CURRENCY_LOADING = 'DEACTIVATE_CURRENCY_LOADING';
export const CLEAR_DEACTIVATE_CURRENCY = 'CLEAR_DEACTIVATE_CURRENCY';
export const CLEAR_DEACTIVATE_CURRENCY_ERROR = 'CLEAR_DEACTIVATE_CURRENCY_ERROR';

export const ACTIVATE_TRANSACTION_FEE = 'ACTIVATE_TRANSACTION_FEE';
export const ACTIVATE_TRANSACTION_FEE_ERROR = 'ACTIVATE_TRANSACTION_FEE_ERROR';
export const ACTIVATE_TRANSACTION_FEE_LOADING = 'ACTIVATE_TRANSACTION_FEE_LOADING';
export const CLEAR_ACTIVATE_TRANSACTION_FEE = 'CLEAR_ACTIVATE_TRANSACTION_FEE';
export const CLEAR_ACTIVATE_TRANSACTION_FEE_ERROR = 'CLEAR_ACTIVATE_TRANSACTION_FEE_ERROR';

export const DEACTIVATE_TRANSACTION_FEE = 'DEACTIVATE_TRANSACTION_FEE';
export const DEACTIVATE_TRANSACTION_FEE_ERROR = 'DEACTIVATE_TRANSACTION_FEE_ERROR';
export const DEACTIVATE_TRANSACTION_FEE_LOADING = 'DEACTIVATE_TRANSACTION_FEE_LOADING';
export const CLEAR_DEACTIVATE_TRANSACTION_FEE = 'CLEAR_DEACTIVATE_TRANSACTION_FEE';
export const CLEAR_DEACTIVATE_TRANSACTION_FEE_ERROR = 'CLEAR_DEACTIVATE_TRANSACTION_FEE_ERROR';

export const ACTIVATE_BRANCHES = 'ACTIVATE_BRANCHES';
export const ACTIVATE_BRANCHES_ERROR = 'ACTIVATE_BRANCHES_ERROR';
export const ACTIVATE_BRANCHES_LOADING = 'ACTIVATE_BRANCHES_LOADING';
export const CLEAR_ACTIVATE_BRANCHES = 'CLEAR_ACTIVATE_BRANCHES';
export const CLEAR_ACTIVATE_BRANCHES_ERROR = 'CLEAR_ACTIVATE_BRANCHES_ERROR';

export const DEACTIVATE_BRANCHES = 'DEACTIVATE_BRANCHES';
export const DEACTIVATE_BRANCHES_ERROR = 'DEACTIVATE_BRANCHES_ERROR';
export const DEACTIVATE_BRANCHES_LOADING = 'DEACTIVATE_BRANCHES_LOADING';
export const CLEAR_DEACTIVATE_BRANCHES = 'CLEAR_DEACTIVATE_BRANCHES';
export const CLEAR_DEACTIVATE_BRANCHES_ERROR = 'CLEAR_DEACTIVATE_BRANCHES_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING';
export const CLEAR_CHANGE_PASSWORD = 'CLEAR_CHANGE_PASSWORD';
export const CLEAR_CHANGE_PASSWORD_ERROR = 'CLEAR_CHANGE_PASSWORD_ERROR';

export const CURRENT_USER_PROFILE = 'CURRENT_USER_PROFILE';
export const CURRENT_USER_PROFILE_ERROR = 'CURRENT_USER_PROFILE_ERROR';
export const CURRENT_USER_PROFILE_LOADING = 'CURRENT_USER_PROFILE_LOADING';
export const CLEAR_CURRENT_USER_PROFILE = 'CLEAR_CURRENT_USER_PROFILE';
export const CLEAR_CURRENT_USER_PROFILE_ERROR = 'CLEAR_CURRENT_USER_PROFILE_ERROR';

export const ADD_TRANSACTION_LIMIT = 'ADD_TRANSACTION_LIMIT';
export const ADD_TRANSACTION_LIMIT_ERROR = 'ADD_TRANSACTION_LIMIT_ERROR';
export const ADD_TRANSACTION_LIMIT_LOADING = 'ADD_TRANSACTION_LIMIT_LOADING';
export const CLEAR_ADD_TRANSACTION_LIMIT_ERROR = 'CLEAR_ADD_TRANSACTION_LIMIT_ERROR';
export const CLEAR_ADD_TRANSACTION_LIMIT = 'CLEAR_ADD_TRANSACTION_LIMIT';

export const CLEAR_GET_TRANSACTION_LIMIT = 'CLEAR_GET_TRANSACTION_LIMIT';
export const GET_TRANSACTION_LIMIT_ERROR = 'GET_TRANSACTION_LIMIT_ERROR';
export const GET_TRANSACTION_LIMIT = 'GET_TRANSACTION_LIMIT';
export const CLEAR_GET_TRANSACTION_LIMIT_ERROR = 'CLEAR_GET_TRANSACTION_LIMIT_ERROR';
export const GET_TRANSACTION_LIMIT_LOADING = 'GET_TRANSACTION_LIMIT_LOADING';

export const UPDATE_TRANSACTION_LIMIT = 'UPDATE_TRANSACTION_LIMIT';
export const UPDATE_TRANSACTION_LIMIT_ERROR = 'UPDATE_TRANSACTION_LIMIT_ERROR';
export const UPDATE_TRANSACTION_LIMIT_LOADING = 'UPDATE_TRANSACTION_LIMIT_LOADING';
export const CLEAR_UPDATE_TRANSACTION_LIMIT = 'CLEAR_UPDATE_TRANSACTION_LIMIT';
export const CLEAR_UPDATE_TRANSACTION_LIMIT_ERROR = 'CLEAR_UPDATE_TRANSACTION_LIMIT_ERROR';

export const ACTIVATE_TRANSACTION_LIMIT = 'ACTIVATE_TRANSACTION_LIMIT';
export const ACTIVATE_TRANSACTION_LIMIT_ERROR = 'ACTIVATE_TRANSACTION_LIMIT_ERROR';
export const ACTIVATE_TRANSACTION_LIMIT_LOADING = 'ACTIVATE_TRANSACTION_LIMIT_LOADING';
export const CLEAR_ACTIVATE_TRANSACTION_LIMIT = 'CLEAR_ACTIVATE_TRANSACTION_LIMIT';
export const CLEAR_ACTIVATE_TRANSACTION_LIMIT_ERROR = 'CLEAR_ACTIVATE_TRANSACTION_LIMIT_ERROR';

export const DEACTIVATE_TRANSACTION_LIMIT = 'DEACTIVATE_TRANSACTION_LIMIT';
export const DEACTIVATE_TRANSACTION_LIMIT_ERROR = 'DEACTIVATE_TRANSACTION_LIMIT_ERROR';
export const DEACTIVATE_TRANSACTION_LIMIT_LOADING = 'DEACTIVATE_TRANSACTION_LIMIT_LOADING';
export const CLEAR_DEACTIVATE_TRANSACTION_LIMIT = 'CLEAR_DEACTIVATE_TRANSACTION_LIMIT';
export const CLEAR_DEACTIVATE_TRANSACTION_LIMIT_ERROR = 'CLEAR_DEACTIVATE_TRANSACTION_LIMIT_ERROR';

export const ACTIVATE_USERS = 'ACTIVATE_USERS';
export const ACTIVATE_USERS_ERROR = 'ACTIVATE_USERS_ERROR';
export const ACTIVATE_USERS_LOADING = 'ACTIVATE_USERS_LOADING';
export const CLEAR_ACTIVATE_USERS = 'CLEAR_ACTIVATE_USERS';
export const CLEAR_ACTIVATE_USERS_ERROR = 'CLEAR_ACTIVATE_USERS_ERROR';

export const DEACTIVATE_USERS = 'DEACTIVATE_USERS';
export const DEACTIVATE_USERS_ERROR = 'DEACTIVATE_USERS_ERROR';
export const DEACTIVATE_USERS_LOADING = 'DEACTIVATE_USERS_LOADING';
export const CLEAR_DEACTIVATE_USERS = 'CLEAR_DEACTIVATE_USERS';
export const CLEAR_DEACTIVATE_USERS_ERROR = 'CLEAR_DEACTIVATE_USERS_ERROR';

export const UPDATE_CURRENT_USER_PROFILE = 'UPDATE_CURRENT_USER_PROFILE';
export const UPDATE_CURRENT_USER_PROFILE_ERROR = 'UPDATE_CURRENT_USER_PROFILE_ERROR';
export const UPDATE_CURRENT_USER_PROFILE_LOADING = 'UPDATE_CURRENT_USER_PROFILE_LOADING';
export const CLEAR_UPDATE_CURRENT_USER_PROFILE = 'CLEAR_UPDATE_CURRENT_USER_PROFILE';
export const CLEAR_UPDATE_CURRENT_USER_PROFILE_ERROR = 'CLEAR_UPDATE_CURRENT_USER_PROFILE_ERROR';

export const ASSIGN_FLOAT = 'ASSIGN_FLOAT';
export const ASSIGN_FLOAT_ERROR = 'ASSIGN_FLOAT_ERROR';
export const ASSIGN_FLOAT_LOADING = 'ASSIGN_FLOAT_LOADING';
export const CLEAR_ASSIGN_FLOAT = 'CLEAR_ASSIGN_FLOAT';
export const CLEAR_ASSIGN_FLOAT_ERROR = 'CLEAR_ASSIGN_FLOAT_ERROR';

export const CLEAR_UPDATE_FLOAT_ERROR = 'CLEAR_UPDATE_FLOAT_ERROR';
export const CLEAR_UPDATE_FLOAT = 'CLEAR_UPDATE_FLOAT';
export const UPDATE_FLOAT_LOADING = 'UPDATE_FLOAT_LOADING';
export const UPDATE_FLOAT_ERROR = 'UPDATE_FLOAT_ERROR';
export const UPDATE_FLOAT = 'UPDATE_FLOAT';

export const GET_FLOAT = 'GET_FLOAT';
export const GET_FLOAT_ERROR = 'GET_FLOAT_ERROR';
export const GET_FLOAT_LOADING = 'GET_FLOAT_LOADING';
export const CLEAR_GET_FLOAT = 'CLEAR_GET_FLOAT';
export const CLEAR_GET_FLOAT_ERROR = 'CLEAR_GET_FLOAT_ERROR';

export const FETCH_TELLERS = 'FETCH_TELLERS';
export const FETCH_TELLERS_ERROR = 'FETCH_TELLERS_ERROR';
export const FETCH_TELLERS_LOADING = 'FETCH_TELLERS_LOADING';
export const CLEAR_FETCH_TELLERS = 'CLEAR_FETCH_TELLERS';
export const CLEAR_FETCH_TELLERS_ERROR = 'CLEAR_FETCH_TELLERS_ERROR';

export const CONFIRM_FLOAT = 'CONFIRM_FLOAT';
export const CONFIRM_FLOAT_ERROR = 'CONFIRM_FLOAT_ERROR';
export const CONFIRM_FLOAT_LOADING = 'CONFIRM_FLOAT_LOADING';
export const CLEAR_CONFIRM_FLOAT = 'CLEAR_CONFIRM_FLOAT';
export const CLEAR_CONFIRM_FLOAT_ERROR = 'CLEAR_CONFIRM_FLOAT_ERROR';

export const ADD_PARTNERS = 'ADD_PARTNERS';
export const ADD_PARTNERS_ERROR = 'ADD_PARTNERS_ERROR';
export const ADD_PARTNERS_LOADING = 'ADD_PARTNERS_LOADING';
export const CLEAR_ADD_PARTNERS = 'CLEAR_ADD_PARTNERS';
export const CLEAR_ADD_PARTNERS_ERROR = 'CLEAR_ADD_PARTNERS_ERROR';

export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_PARTNERS_ERROR = 'GET_PARTNERS_ERROR';
export const GET_PARTNERS_LOADING = 'GET_PARTNERS_LOADING';
export const CLEAR_GET_PARTNERS = 'CLEAR_GET_PARTNERS';
export const CLEAR_GET_PARTNERS_ERROR = 'CLEAR_GET_PARTNERS_ERROR';

export const UPDATE_PARTNERS = 'UPDATE_PARTNERS';
export const UPDATE_PARTNERS_ERROR = 'UPDATE_PARTNERS_ERROR';
export const UPDATE_PARTNERS_LOADING = 'UPDATE_PARTNERS_LOADING';
export const CLEAR_UPDATE_PARTNERS = 'CLEAR_UPDATE_PARTNERS';
export const CLEAR_UPDATE_PARTNERS_ERROR = 'CLEAR_UPDATE_PARTNERS_ERROR';

export const ADD_PARTNER_BRANCHES = 'ADD_PARTNER_BRANCHES';
export const ADD_PARTNER_BRANCHES_ERROR = 'ADD_PARTNER_BRANCHES_ERROR';
export const ADD_PARTNER_BRANCHES_LOADING = 'ADD_PARTNER_BRANCHES_LOADING';
export const CLEAR_ADD_PARTNER_BRANCHES = 'CLEAR_ADD_PARTNER_BRANCHES';
export const CLEAR_ADD_PARTNER_BRANCHES_ERROR = 'CLEAR_ADD_PARTNER_BRANCHES_ERROR';

export const GET_PARTNER_BRANCHES = 'GET_PARTNER_BRANCHES';
export const GET_PARTNER_BRANCHES_ERROR = 'GET_PARTNER_BRANCHES_ERROR';
export const GET_PARTNER_BRANCHES_LOADING = 'GET_PARTNER_BRANCHES_LOADING';
export const CLEAR_GET_PARTNER_BRANCHES = 'CLEAR_GET_PARTNER_BRANCHES';
export const CLEAR_GET_PARTNER_BRANCHES_ERROR = 'CLEAR_GET_PARTNER_BRANCHES_ERROR';

export const UPDATE_PARTNER_BRANCHES = 'UPDATE_PARTNER_BRANCHES';
export const UPDATE_PARTNER_BRANCHES_ERROR = 'UPDATE_PARTNER_BRANCHES_ERROR';
export const UPDATE_PARTNER_BRANCHES_LOADING = 'UPDATE_PARTNER_BRANCHES_LOADING';
export const CLEAR_UPDATE_PARTNER_BRANCHES = 'CLEAR_UPDATE_PARTNER_BRANCHES';
export const CLEAR_UPDATE_PARTNER_BRANCHES_ERROR = 'CLEAR_UPDATE_PARTNER_BRANCHES_ERROR';

export const ACTIVATE_PARTNER_BRANCHES = 'ACTIVATE_PARTNER_BRANCHES';
export const ACTIVATE_PARTNER_BRANCHES_ERROR = 'ACTIVATE_PARTNER_BRANCHES_ERROR';
export const ACTIVATE_PARTNER_BRANCHES_LOADING = 'ACTIVATE_PARTNER_BRANCHES_LOADING';
export const CLEAR_ACTIVATE_PARTNER_BRANCHES = 'CLEAR_ACTIVATE_PARTNER_BRANCHES';
export const CLEAR_ACTIVATE_PARTNER_BRANCHES_ERROR = 'CLEAR_ACTIVATE_PARTNER_BRANCHES_ERROR';

export const DEACTIVATE_PARTNER_BRANCHES = 'DEACTIVATE_PARTNER_BRANCHES';
export const DEACTIVATE_PARTNER_BRANCHES_ERROR = 'DEACTIVATE_PARTNER_BRANCHES_ERROR';
export const DEACTIVATE_PARTNER_BRANCHES_LOADING = 'DEACTIVATE_PARTNER_BRANCHES_LOADING';
export const CLEAR_DEACTIVATE_PARTNER_BRANCHES = 'CLEAR_DEACTIVATE_PARTNER_BRANCHES';
export const CLEAR_DEACTIVATE_PARTNER_BRANCHES_ERROR = 'CLEAR_DEACTIVATE_PARTNER_BRANCHES_ERROR';

export const ADD_PARTNER_USERS = 'ADD_PARTNER_USERS';
export const ADD_PARTNER_USERS_ERROR = 'ADD_PARTNER_USERS_ERROR';
export const ADD_PARTNER_USERS_LOADING = 'ADD_PARTNER_USERS_LOADING';
export const CLEAR_ADD_PARTNER_USERS = 'CLEAR_ADD_PARTNER_USERS';
export const CLEAR_ADD_PARTNER_USERS_ERROR = 'CLEAR_ADD_PARTNER_USERS_ERROR';

export const GET_PARTNER_USERS = 'GET_PARTNER_USERS';
export const GET_PARTNER_USERS_ERROR = 'GET_PARTNER_USERS_ERROR';
export const GET_PARTNER_USERS_LOADING = 'GET_PARTNER_USERS_LOADING';
export const CLEAR_GET_PARTNER_USERS = 'CLEAR_GET_PARTNER_USERS';
export const CLEAR_GET_PARTNER_USERS_ERROR = 'CLEAR_GET_PARTNER_USERS_ERROR';

export const GET_REMITTERS = 'GET_REMITTERS';
export const GET_REMITTERS_ERROR = 'GET_REMITTERS_ERROR';
export const GET_REMITTERS_LOADING = 'GET_REMITTERS_LOADING';
export const CLEAR_GET_REMITTERS = 'CLEAR_GET_REMITTERS';
export const CLEAR_GET_REMITTERS_ERROR = 'CLEAR_GET_REMITTERS_ERROR';

export const GET_REMITTERS_BY_ID = 'GET_REMITTERS_BY_ID';
export const GET_REMITTERS_BY_ID_ERROR = 'GET_REMITTERS_BY_ID_ERROR';
export const GET_REMITTERS_BY_ID_LOADING = 'GET_REMITTERS_BY_ID_LOADING';
export const CLEAR_GET_REMITTERS_BY_ID = 'CLEAR_GET_REMITTERS_BY_ID';
export const CLEAR_GET_REMITTERS_BY_ID_ERROR = 'CLEAR_GET_REMITTERS_BY_ID_ERROR';

export const GET_REMITTERS_BY_NATIONAL_ID = 'GET_REMITTERS_BY_NATIONAL_ID';
export const GET_REMITTERS_BY_NATIONAL_ID_ERROR = 'GET_REMITTERS_BY_NATIONAL_ID_ERROR';
export const GET_REMITTERS_BY_NATIONAL_ID_LOADING = 'GET_REMITTERS_BY_NATIONAL_ID_LOADING';
export const CLEAR_GET_REMITTERS_BY_NATIONAL_ID = 'CLEAR_GET_REMITTERS_BY_NATIONAL_ID';
export const CLEAR_GET_REMITTERS_BY_NATIONAL_ID_ERROR = 'CLEAR_GET_REMITTERS_BY_NATIONAL_ID_ERROR';

export const UPDATE_REMITTERS = 'UPDATE_REMITTERS';
export const UPDATE_REMITTERS_ERROR = 'UPDATE_REMITTERS_ERROR';
export const UPDATE_REMITTERS_LOADING = 'UPDATE_REMITTERS_LOADING';
export const CLEAR_UPDATE_REMITTERS = 'CLEAR_UPDATE_REMITTERS';
export const CLEAR_UPDATE_REMITTERS_ERROR = 'CLEAR_UPDATE_REMITTERS_ERROR';

export const ACTIVATE_REMITTERS = 'ACTIVATE_REMITTERS';
export const ACTIVATE_REMITTERS_ERROR = 'ACTIVATE_REMITTERS_ERROR';
export const ACTIVATE_REMITTERS_LOADING = 'ACTIVATE_REMITTERS_LOADING';
export const CLEAR_ACTIVATE_REMITTERS = 'CLEAR_ACTIVATE_REMITTERS';
export const CLEAR_ACTIVATE_REMITTERS_ERROR = 'CLEAR_ACTIVATE_REMITTERS_ERROR';

export const DEACTIVATE_REMITTERS = 'DEACTIVATE_REMITTERS';
export const DEACTIVATE_REMITTERS_ERROR = 'DEACTIVATE_REMITTERS_ERROR';
export const DEACTIVATE_REMITTERS_LOADING = 'DEACTIVATE_REMITTERS_LOADING';
export const CLEAR_DEACTIVATE_REMITTERS = 'CLEAR_DEACTIVATE_REMITTERS';
export const CLEAR_DEACTIVATE_REMITTERS_ERROR = 'CLEAR_DEACTIVATE_REMITTERS_ERROR';

export const PARTNER_BRANCH_USERS = 'PARTNER_BRANCH_USERS';
export const PARTNER_BRANCH_USERS_ERROR = 'PARTNER_BRANCH_USERS_ERROR';
export const CLEAR_PARTNER_BRANCH_USERS = 'CLEAR_PARTNER_BRANCH_USERS';
export const PARTNER_BRANCH_USERS_LOADING = 'PARTNER_BRANCH_USERS_LOADING';
export const CLEAR_PARTNER_BRANCH_USERS_ERROR = 'CLEAR_PARTNER_BRANCH_USERS_ERROR';

export const SEARCH_REMITTER = 'SEARCH_REMITTER';
export const SEARCH_REMITTER_ERROR = 'SEARCH_REMITTER_ERROR';
export const SEARCH_REMITTER_LOADING = 'SEARCH_REMITTER_LOADING';
export const CLEAR_SEARCH_REMITTER = 'CLEAR_SEARCH_REMITTER';
export const CLEAR_SEARCH_REMITTER_ERROR = 'CLEAR_SEARCH_REMITTER_ERROR';

export const GET_TRANSACTION_LOGS = 'GET_TRANSACTION_LOGS';
export const GET_TRANSACTION_LOGS_ERROR = 'GET_TRANSACTION_LOGS_ERROR';
export const GET_TRANSACTION_LOGS_LOADING = 'GET_TRANSACTION_LOGS_LOADING';
export const CLEAR_GET_TRANSACTION_LOGS = 'CLEAR_GET_TRANSACTION_LOGS';
export const CLEAR_GET_TRANSACTION_LOGS_ERROR = 'CLEAR_GET_TRANSACTION_LOGS_ERROR';

export const FETCH_REMITTER_BENEFICIARY = 'FETCH_REMITTER_BENEFICIARY';
export const FETCH_REMITTER_BENEFICIARY_ERROR = 'FETCH_REMITTER_BENEFICIARY_ERROR';
export const FETCH_REMITTER_BENEFICIARY_LOADING = 'FETCH_REMITTER_BENEFICIARY_LOADING';
export const CLEAR_FETCH_REMITTER_BENEFICIARY = 'CLEAR_FETCH_REMITTER_BENEFICIARY';
export const CLEAR_FETCH_REMITTER_BENEFICIARY_ERROR = 'CLEAR_FETCH_REMITTER_BENEFICIARY_ERROR';

export const MAKE_REMITTANCE = 'MAKE_REMITTANCE';
export const MAKE_REMITTANCE_ERROR = 'MAKE_REMITTANCE_ERROR';
export const MAKE_REMITTANCE_LOADING = 'MAKE_REMITTANCE_LOADING';
export const CLEAR_MAKE_REMITTANCE = 'CLEAR_MAKE_REMITTANCE';
export const CLEAR_MAKE_REMITTANCE_ERROR = 'CLEAR_MAKE_REMITTANCE_ERROR';

export const GET_TRANSACTION_LOGS_BY_ID = 'GET_TRANSACTION_LOGS_BY_ID';
export const GET_TRANSACTION_LOGS_BY_ID_ERROR = 'GET_TRANSACTION_LOGS_BY_ID_ERROR';
export const GET_TRANSACTION_LOGS_BY_ID_LOADING = 'GET_TRANSACTION_LOGS_BY_ID_LOADING';
export const CLEAR_GET_TRANSACTION_LOGS_BY_ID = 'CLEAR_GET_TRANSACTION_LOGS_BY_ID';
export const CLEAR_GET_TRANSACTION_LOGS_BY_ID_ERROR = 'CLEAR_GET_TRANSACTION_LOGS_BY_ID_ERROR';

export const GET_ADMIN_AUDIT_TRAIL = 'GET_ADMIN_AUDIT_TRAIL';
export const GET_ADMIN_AUDIT_TRAIL_ERROR = 'GET_ADMIN_AUDIT_TRAIL_ERROR';
export const GET_ADMIN_AUDIT_TRAIL_LOADING = 'GET_ADMIN_AUDIT_TRAIL_LOADING';
export const CLEAR_GET_ADMIN_AUDIT_TRAIL = 'CLEAR_GET_ADMIN_AUDIT_TRAIL';
export const CLEAR_GET_ADMIN_AUDIT_TRAIL_ERROR = 'CLEAR_GET_ADMIN_AUDIT_TRAIL_ERROR';

export const GET_ADMIN_AUDIT_TRAIL_BY_ID = 'GET_ADMIN_AUDIT_TRAIL_BY_ID';
export const GET_ADMIN_AUDIT_TRAIL_BY_ID_ERROR = 'GET_ADMIN_AUDIT_TRAIL_BY_ID_ERROR';
export const GET_ADMIN_AUDIT_TRAIL_BY_ID_LOADING = 'GET_ADMIN_AUDIT_TRAIL_BY_ID_LOADING';
export const CLEAR_GET_ADMIN_AUDIT_TRAIL_BY_ID = 'CLEAR_GET_ADMIN_AUDIT_TRAIL_BY_ID';
export const CLEAR_GET_ADMIN_AUDIT_TRAIL_BY_ID_ERROR = 'CLEAR_GET_ADMIN_AUDIT_TRAIL_BY_ID_ERROR';

export const TELLER_FLOAT_ERROR = 'TELLER_FLOAT_ERROR';
export const TELLER_FLOAT = 'TELLER_FLOAT';
export const CLEAR_TELLER_FLOAT = 'CLEAR_TELLER_FLOAT';
export const TELLER_FLOAT_LOADING = 'TELLER_FLOAT_LOADING';
export const CLEAR_TELLER_FLOAT_ERROR = 'CLEAR_TELLER_FLOAT_ERROR';

export const CASHOUT = 'CASHOUT';
export const CASHOUT_ERROR = 'CASHOUT_ERROR';
export const CASHOUT_LOADING = 'CASHOUT_LOADING';
export const CLEAR_CASHOUT = 'CLEAR_CASHOUT';
export const CLEAR_CASHOUT_ERROR = 'CLEAR_CASHOUT_ERROR';

export const PROCESS_REMITTANCE = 'PROCESS_REMITTANCE';
export const PROCESS_REMITTANCE_ERROR = 'PROCESS_REMITTANCE_ERROR';
export const PROCESS_REMITTANCE_LOADING = 'PROCESS_REMITTANCE_LOADING';
export const CLEAR_PROCESS_REMITTANCE = 'CLEAR_PROCESS_REMITTANCE';
export const CLEAR_PROCESS_REMITTANCE_ERROR = 'CLEAR_PROCESS_REMITTANCE_ERROR';

export const ADD_RECONCILIATIONS = 'ADD_RECONCILIATIONS';
export const ADD_RECONCILIATIONS_ERROR = 'ADD_RECONCILIATIONS_ERROR';
export const ADD_RECONCILIATIONS_LOADING = 'ADD_RECONCILIATIONS_LOADING';
export const CLEAR_ADD_RECONCILIATIONS = 'CLEAR_ADD_RECONCILIATIONS';
export const CLEAR_ADD_RECONCILIATIONS_ERROR = 'CLEAR_ADD_RECONCILIATIONS_ERROR';

export const ADD_RECONCILIATIONS_OVERAL_RECON = 'ADD_RECONCILIATIONS_OVERAL_RECON';
export const ADD_RECONCILIATIONS_OVERAL_RECON_ERROR = 'ADD_RECONCILIATIONS_OVERAL_RECON_ERROR';
export const ADD_RECONCILIATIONS_OVERAL_RECON_LOADING = 'ADD_RECONCILIATIONS_OVERAL_RECON_LOADING';
export const CLEAR_ADD_RECONCILIATIONS_OVERAL_RECON = 'CLEAR_ADD_RECONCILIATIONS_OVERAL_RECON';
export const CLEAR_ADD_RECONCILIATIONS_OVERAL_RECON_ERROR = 'CLEAR_ADD_RECONCILIATIONS_OVERAL_RECON_ERROR';

export const ADD_RECONCILIATIONS_PARTNER_RECON = 'ADD_RECONCILIATIONS_PARTNER_RECON';
export const ADD_RECONCILIATIONS_PARTNER_RECON_ERROR = 'ADD_RECONCILIATIONS_PARTNER_RECON_ERROR';
export const ADD_RECONCILIATIONS_PARTNER_RECON_LOADING = 'ADD_RECONCILIATIONS_PARTNER_RECON_LOADING';
export const CLEAR_ADD_RECONCILIATIONS_PARTNER_RECON = 'CLEAR_ADD_RECONCILIATIONS_PARTNER_RECON';
export const CLEAR_ADD_RECONCILIATIONS_PARTNER_RECON_ERROR = 'CLEAR_ADD_RECONCILIATIONS_PARTNER_RECON_ERROR';

export const ADD_RECONCILIATIONS_ID_PROCESS = 'ADD_RECONCILIATIONS_ID_PROCESS';
export const ADD_RECONCILIATIONS_ID_PROCESS_ERROR = 'ADD_RECONCILIATIONS_ID_PROCESS_ERROR';
export const ADD_RECONCILIATIONS_ID_PROCESS_LOADING = 'ADD_RECONCILIATIONS_ID_PROCESS_LOADING';
export const CLEAR_ADD_RECONCILIATIONS_ID_PROCESS = 'CLEAR_ADD_RECONCILIATIONS_ID_PROCESS';
export const CLEAR_ADD_RECONCILIATIONS_ID_PROCESS_ERROR = 'CLEAR_ADD_RECONCILIATIONS_ID_PROCESS_ERROR';

export const GET_RECONCILIATIONS = 'GET_RECONCILIATIONS';
export const GET_RECONCILIATIONS_ERROR = 'GET_RECONCILIATIONS_ERROR';
export const GET_RECONCILIATIONS_LOADING = 'GET_RECONCILIATIONS_LOADING';
export const CLEAR_GET_RECONCILIATIONS = 'CLEAR_GET_RECONCILIATIONS';
export const CLEAR_GET_RECONCILIATIONS_ERROR = 'CLEAR_GET_RECONCILIATIONS_ERROR';

export const GET_RECONCILIATIONS_CURRENT_TELLER = 'GET_RECONCILIATIONS_CURRENT_TELLER';
export const GET_RECONCILIATIONS_CURRENT_TELLER_ERROR = 'GET_RECONCILIATIONS_CURRENT_TELLER_ERROR';
export const GET_RECONCILIATIONS_CURRENT_TELLER_LOADING = 'GET_RECONCILIATIONS_CURRENT_TELLER_LOADING';
export const CLEAR_GET_RECONCILIATIONS_CURRENT_TELLER = 'CLEAR_GET_RECONCILIATIONS_CURRENT_TELLER';
export const CLEAR_GET_RECONCILIATIONS_CURRENT_TELLER_ERROR = 'CLEAR_GET_RECONCILIATIONS_CURRENT_TELLER_ERROR';

export const GET_RECONCILIATIONS_ALL_CURRENT = 'GET_RECONCILIATIONS_ALL_CURRENT';
export const GET_RECONCILIATIONS_ALL_CURRENT_ERROR = 'GET_RECONCILIATIONS_ALL_CURRENT_ERROR';
export const GET_RECONCILIATIONS_ALL_CURRENT_LOADING = 'GET_RECONCILIATIONS_ALL_CURRENT_LOADING';
export const CLEAR_GET_RECONCILIATIONS_ALL_CURRENT = 'CLEAR_GET_RECONCILIATIONS_ALL_CURRENT';
export const CLEAR_GET_RECONCILIATIONS_ALL_CURRENT_ERROR = 'CLEAR_GET_RECONCILIATIONS_ALL_CURRENT_ERROR';

export const GET_RECONCILIATIONS_BY_ID = 'GET_RECONCILIATIONS_BY_ID';
export const GET_RECONCILIATIONS_BY_ID_ERROR = 'GET_RECONCILIATIONS_BY_ID_ERROR';
export const GET_RECONCILIATIONS_BY_ID_LOADING = 'GET_RECONCILIATIONS_BY_ID_LOADING';
export const CLEAR_GET_RECONCILIATIONS_BY_ID = 'CLEAR_GET_RECONCILIATIONS_BY_ID';
export const CLEAR_GET_RECONCILIATIONS_BY_ID_ERROR = 'CLEAR_GET_RECONCILIATIONS_BY_ID_ERROR';

export const UPDATE_RECONCILIATIONS = 'UPDATE_RECONCILIATIONS';
export const UPDATE_RECONCILIATIONS_ERROR = 'UPDATE_RECONCILIATIONS_ERROR';
export const UPDATE_RECONCILIATIONS_LOADING = 'UPDATE_RECONCILIATIONS_LOADING';
export const CLEAR_UPDATE_RECONCILIATIONS = 'CLEAR_UPDATE_RECONCILIATIONS';
export const CLEAR_UPDATE_RECONCILIATIONS_ERROR = 'CLEAR_UPDATE_RECONCILIATIONS_ERROR';

export const UPDATE_FEE_CONFIG = 'UPDATE_FEE_CONFIG';
export const UPDATE_FEE_CONFIG_ERROR = 'UPDATE_FEE_CONFIG_ERROR';
export const UPDATE_FEE_CONFIG_LOADING = 'UPDATE_FEE_CONFIG_LOADING';
export const CLEAR_UPDATE_FEE_CONFIG = 'CLEAR_UPDATE_FEE_CONFIG';
export const CLEAR_UPDATE_FEE_CONFIG_ERROR = 'CLEAR_UPDATE_FEE_CONFIG_ERROR';

export const RUNNING_BALANCE = 'RUNNING_BALANCE';
export const RUNNING_BALANCE_ERROR = 'RUNNING_BALANCE_ERROR';
export const RUNNING_BALANCE_LOADING = 'RUNNING_BALANCE_LOADING';
export const CLEAR_RUNNING_BALANCE = 'CLEAR_RUNNING_BALANCE';
export const CLEAR_RUNNING_BALANCE_ERROR = 'CLEAR_RUNNING_BALANCE_ERROR';

export const UPDATE_REMITTANCE_REVERSAL = 'UPDATE_REMITTANCE_REVERSAL';
export const UPDATE_REMITTANCE_REVERSAL_ERROR = 'UPDATE_REMITTANCE_REVERSAL_ERROR';
export const UPDATE_REMITTANCE_REVERSAL_LOADING = 'UPDATE_REMITTANCE_REVERSAL_LOADING';
export const CLEAR_UPDATE_REMITTANCE_REVERSAL = 'CLEAR_UPDATE_REMITTANCE_REVERSAL';
export const CLEAR_UPDATE_REMITTANCE_REVERSAL_ERROR = 'CLEAR_UPDATE_REMITTANCE_REVERSAL_ERROR';

export const FEE_CONFIG = 'FEE_CONFIG';
export const FEE_CONFIG_ERROR = 'FEE_CONFIG_ERROR';
export const FEE_CONFIG_LOADING = 'FEE_CONFIG_LOADING';
export const CLEAR_FEE_CONFIG = 'CLEAR_FEE_CONFIG';
export const CLEAR_FEE_CONFIG_ERROR = 'CLEAR_FEE_CONFIG_ERROR';

export const ACTIVE_FEE = 'ACTIVE_FEE';
export const ACTIVE_FEE_ERROR = 'ACTIVE_FEE_ERROR';
export const ACTIVE_FEE_LOADING = 'ACTIVE_FEE_LOADING';
export const CLEAR_ACTIVE_FEE = 'CLEAR_ACTIVE_FEE';
export const CLEAR_ACTIVE_FEE_ERROR = 'CLEAR_ACTIVE_FEE_ERROR';

export const RUN_REPORTS = 'RUN_REPORTS';
export const RUN_REPORTS_ERROR = 'RUN_REPORTS_ERROR';
export const REPORTS_LOADING = 'REPORTS_LOADING';
export const CLEAR_REPORTS = 'CLEAR_REPORTS';
export const CLEAR_REPORTS_ERROR = 'CLEAR_REPORTS_ERROR';

export const GET_REFERENCE_CONFIG ='GET_REFERENCE_CONFIG';
export const GET_REFERENCE_CONFIG_ERROR = 'GET_REFERENCE_CONFIG_ERROR';
export const GET_REFERENCE_CONFIG_LOADING = 'GET_REFERENCE_CONFIG_LOADING';
export const CLEAR_GET_REFERENCE_CONFIG = 'CLEAR_GET_REFERENCE_CONFIG';
export const CLEAR_GET_REFERENCE_CONFIG_ERROR = 'CLEAR_GET_REFERENCE_CONFIG_ERROR';

export const UPDATE_REFERENCE_CONFIG = 'UPDATE_REFERENCE_CONFIG';
export const UPDATE_REFERENCE_CONFIG_ERROR = 'UPDATE_REFERENCE_CONFIG_ERROR';
export const UPDATE_REFERENCE_CONFIG_LOADING = 'UPDATE_REFERENCE_CONFIG_LOADING';
export const CLEAR_UPDATE_REFERENCE_CONFIG = 'CLEAR_UPDATE_REFERENCE_CONFIG';
export const CLEAR_UPDATE_REFERENCE_CONFIG_ERROR = 'CLEAR_UPDATE_REFERENCE_CONFIG_ERROR';

export const ADD_MESSAGE_CONFIG = 'ADD_MESSAGE_CONFIG';
export const ADD_MESSAGE_CONFIG_ERROR = 'ADD_MESSAGE_CONFIG_ERROR';
export const ADD_MESSAGE_CONFIG_LOADING = 'ADD_MESSAGE_CONFIG_LOADING';
export const CLEAR_ADD_MESSAGE_CONFIG = 'CLEAR_ADD_MESSAGE_CONFIG';
export const CLEAR_ADD_MESSAGE_CONFIG_ERROR = 'CLEAR_ADD_MESSAGE_CONFIG_ERROR';

export const GET_MESSAGE_CONFIG = 'GET_MESSAGE_CONFIG';
export const GET_MESSAGE_CONFIG_ERROR = 'GET_MESSAGE_CONFIG_ERROR';
export const GET_MESSAGE_CONFIG_LOADING = 'GET_MESSAGE_CONFIG_LOADING';
export const CLEAR_GET_MESSAGE_CONFIG = 'CLEAR_GET_MESSAGE_CONFIG';
export const CLEAR_GET_MESSAGE_CONFIG_ERROR = 'CLEAR_GET_MESSAGE_CONFIG_ERROR';

export const UPDATE_MESSAGE_CONFIG = 'UPDATE_MESSAGE_CONFIG';
export const UPDATE_MESSAGE_CONFIG_ERROR = 'UPDATE_MESSAGE_CONFIG_ERROR';
export const UPDATE_MESSAGE_CONFIG_LOADING = 'UPDATE_MESSAGE_CONFIG_LOADING';
export const CLEAR_UPDATE_MESSAGE_CONFIG = 'CLEAR_UPDATE_MESSAGE_CONFIG';
export const CLEAR_UPDATE_MESSAGE_CONFIG_ERROR = 'CLEAR_UPDATE_MESSAGE_CONFIG_ERROR';

export const UPDATE_MESSAGE_CONFIG_STATUS = 'UPDATE_MESSAGE_CONFIG_STATUS';
export const UPDATE_MESSAGE_CONFIG_STATUS_ERROR = 'UPDATE_MESSAGE_CONFIG_STATUS_ERROR';
export const UPDATE_MESSAGE_CONFIG_STATUS_LOADING = 'UPDATE_MESSAGE_CONFIG_STATUS_LOADING';
export const CLEAR_UPDATE_MESSAGE_CONFIG_STATUS = 'CLEAR_UPDATE_MESSAGE_CONFIG_STATUS';
export const CLEAR_UPDATE_MESSAGE_CONFIG_STATUS_ERROR = 'CLEAR_UPDATE_MESSAGE_CONFIG_STATUS_ERROR';

export const GRAPHICAL_REPORT = 'GRAPHICAL_REPORT';
export const GRAPHICAL_REPORT_ERROR = 'GRAPHICAL_REPORT_ERROR';
export const GRAPHICAL_REPORT_LOADING = 'GRAPHICAL_REPORT_LOADING';
export const CLEAR_GRAPHICAL_REPORT = 'CLEAR_GRAPHICAL_REPORT';
export const CLEAR_GRAPHICAL_REPORT_ERROR = 'CLEAR_GRAPHICAL_REPORT_ERROR';

export const REPORT_SUMMARY = 'REPORT_SUMMARY';
export const REPORT_SUMMARY_ERROR = 'REPORT_SUMMARY_ERROR';
export const SUMMARY_REPORT_LOADING = 'SUMMARY_REPORT_LOADING';
export const CLEAR_SUMMARY_REPORT = 'CLEAR_SUMMARY_REPORT';
export const CLEAR_SUMMARY_REPORT_ERROR = 'CLEAR_SUMMARY_REPORT_ERROR';