import React from 'react';
import CashIn from './CashIn';
import CashOut from './CashOut';
import TransactionReversal from './TransactionReversal';


function Teller() {
    const [active, setActive] = React.useState('cash-in');

    const tabsChange =()=>{
        if(active === 'cash-in'){
           return <CashIn/>
        }
        else if (active === 'cash-out'){
          return  <CashOut/>
        }
        else if (active === 'transaction-reversal'){
            return <TransactionReversal/>
        }
    }

    return (
        <div>
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px">
                    <li className="mr-2">
                        <button onClick={() => setActive('cash-in')} className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'cash-in' ? "border-green-700 text-green-700 dark:text-blue-500 dark:border-green-500" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}`}>Cash In</button>
                    </li>
                    <li className="mr-2">
                        <button onClick={() => setActive('cash-out')} className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'cash-out' ? "border-green-700 text-green-700 dark:text-blue-500 dark:border-green-500" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}`}>Cash Out</button>
                    </li>
                    <li className="mr-2">
                        <button onClick={() => setActive('transaction-reversal')} className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'transaction-reversal' ? "border-green-700 text-green-700 dark:text-blue-500 dark:border-green-500" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}`}>Transaction Reversal</button>
                    </li>
                </ul>
            </div>
            {tabsChange()}
        </div>
    )
}

export default Teller