import {CLEAR_ADD_PARTNERS, CLEAR_ADD_PARTNERS_ERROR, ADD_PARTNERS_LOADING, ADD_PARTNERS_ERROR, ADD_PARTNERS} from '../actions/types';

const initialState = {
    new_partner: null,
    new_partner_error: null,
    new_partner_loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ADD_PARTNERS:
            return {
                ...state,
                new_partner: null,
                new_partner_error: null,
                new_partner_loading: false
            }
        case CLEAR_ADD_PARTNERS_ERROR:
            return {
                ...state,
                new_partner_error: null,
                new_partner_loading: false
            }
        case ADD_PARTNERS_LOADING:
            return {
                ...state,
                new_partner_error: null,
                new_partner_loading: true
            }
        case ADD_PARTNERS_ERROR:
            return {
                ...state,
                new_partner_error: action.payload,
                new_partner_loading: false
            }
        case ADD_PARTNERS:
            return {
                ...state,
                new_partner: action.payload,
                new_partner_error: null,
                new_partner_loading: false
            }
        default: return state
    }
}