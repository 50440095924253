import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions';
import Pagination from "react-js-pagination";
import PageLoader from '../utils/PageLoader';
import handleDateFormat from '../utils/handleDateFormat';

function BranchTables(props) {
    const dispatch = useDispatch();
    const { transaction_logs, transaction_logs_loading, transaction_logs_error } = useSelector(getTransactionLogs => getTransactionLogs.getTransactionLogsReducer);

    const itemsCountPerPage = 15;

    const [page, setPage] = React.useState(1);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    React.useEffect(() => {
        dispatch(actions.getTransactionLogs({
            page,
            perPage: itemsCountPerPage
        }));

    }, [page,]);

    <div>
        {transaction_logs_error &&
            <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                    {transaction_logs_error}
                </div>
                <button onClick={() => dispatch(actions.clearGetTransactionLogsError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
            </div>}
    </div>



    let content = (transaction_logs_loading) ? <PageLoader /> : (transaction_logs) && <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="px-6 py-3">
                    Name
                </th>
                <th scope="col" className="px-6 py-3">
                    Role
                </th>
                <th scope="col" className="px-6 py-3">
                    Action
                </th>
                <th scope="col" className="px-6 py-3">
                    Order No.
                </th>
                <th scope="col" className="px-6 py-3">
                    Description
                </th>
                <th scope="col" className="px-6 py-3">
                    Branch
                </th>
                <th scope="col" className="px-6 py-3">
                    Address
                </th>
                <th scope="col" className="px-6 py-3">
                    Contact Person
                </th>
                <th scope="col" className="px-6 py-3">
                    <span className="">Contact Person Number</span>
                </th>
                <th scope="col" className="px-6 py-3">
                    Created At
                </th>

            </tr>
        </thead>
        <tbody>
            {(transaction_logs && transaction_logs.data.length > 0) ? transaction_logs.data.map(logs => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={logs}>

                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {logs.user.firstname + ' ' + logs.user.lastname}
                    </th>
                    <td className="px-6 py-4">
                        {logs.user.role}
                    </td>
                    <td className="px-6 py-4">
                        {logs.action}
                    </td>
                    <td className="px-6 py-4">
                        {logs.remittance.orderNumber}
                    </td>
                    <td className="px-6 py-4">
                        {logs.description}
                    </td>
                    <td className="px-6 py-4">
                        {logs.user.branch.branchName}
                    </td>
                    <td className="px-6 py-4">
                        {logs.user.branch.address}
                    </td>
                    <td className="px-6 py-4 ">
                        {logs.user.branch.contactPerson}
                    </td>
                    <td className="px-6 py-4 ">
                        {logs.user.branch.contactPhone}
                    </td>
                    <td className="px-6 py-4">
                        {handleDateFormat(logs.createdAt)}
                    </td>
                </tr>
            )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td colSpan="10" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">No data found</td>
        </tr>}
        </tbody>
    </table>

    return (
        <div>
            <div className="overflow-x-auto border sm:rounded-lg">
                {content}
                {(transaction_logs && transaction_logs.data.length > 0 && !transaction_logs_loading) &&
                    <div className="p-4 flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={transaction_logs.totalItemsCount}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                    </div>}
            </div></div>
    )
}



export default BranchTables;