import {
    CLEAR_UPDATE_REMITTERS_ERROR,
    CLEAR_UPDATE_REMITTERS,
    UPDATE_REMITTERS_LOADING,
    UPDATE_REMITTERS_ERROR,
    UPDATE_REMITTERS} 
from '../actions/types';

const initialState = {
    updateRemitter: null,
    updateRemitterLoading: false,
    updateRemitterError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_REMITTERS_ERROR:
            return {
                ...state,
                updateRemitterLoading: false,
                updateRemitterError: action.payload
            }
        case CLEAR_UPDATE_REMITTERS:
            return {
                ...state,
                updateRemitter: null,
                updateRemitterLoading: false,
                updateRemitterError: null
            }
        case UPDATE_REMITTERS_LOADING:
            return {
                ...state,
                updateRemitterLoading: true,
                updateRemitterError: null
            }
        case UPDATE_REMITTERS_ERROR:
            return {
                ...state,
                updateRemitterLoading: false,
                updateRemitterError: action.payload
            }
        case UPDATE_REMITTERS:
            return {
                ...state,
                updateRemitter: action.payload,
                updateRemitterLoading: false,
                updateRemitterError: null
            }
        default: return state
    }
}