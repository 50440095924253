import {CLEAR_ADD_BRANCH, CLEAR_ADD_BRANCH_ERROR, ADD_BRANCH_LOADING, ADD_BRANCH_ERROR, ADD_BRANCH} from '../actions/types';

const initialState = {
    new_branch: null,
    new_branch_error: null,
    new_branch_loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ADD_BRANCH:
            return {
                ...state,
                new_branch: null,
                new_branch_error: null,
                new_branch_loading: false
            }
        case CLEAR_ADD_BRANCH_ERROR:
            return {
                ...state,
                new_branch_error: null,
                new_branch_loading: false
            }
        case ADD_BRANCH_LOADING:
            return {
                ...state,
                new_branch_error: null,
                new_branch_loading: true
            }
        case ADD_BRANCH_ERROR:
            return {
                ...state,
                new_branch_error: action.payload,
                new_branch_loading: false
            }
        case ADD_BRANCH:
            return {
                ...state,
                new_branch: action.payload,
                new_branch_error: null,
                new_branch_loading: false
            }
        default: return state
    }
}