import React from 'react';
import Pagination from "react-js-pagination";
import { useDispatch,useSelector } from 'react-redux';
import * as actions from '../../../store/actions';

function ViewMessage(props) {
  const dispatch = useDispatch();
  const {handleEdit} = props;
  const {message_config}= useSelector(getMessage => getMessage.getMessageConfigReducer);
  const {update_message_config_status}= useSelector(updateStatus => updateStatus.updateMessageConfigStatusReducer);
  const {new_message_config} = useSelector(addMessageConfig => addMessageConfig.addMessageConfigReducer);
  const {update_message_config} = useSelector(updateMessage =>updateMessage.updateMessageConfigReducer);
  const [page,setPage] = React.useState(1);
  const itemsCountPerPage = 20;

  React.useEffect(()=>{
    dispatch(actions.getMessageConfig({page, perPage:10}));
  },[update_message_config_status, page,new_message_config,update_message_config]);

  const handleChangeStatus = (message) => {
    message.status = message.status === 'ACTIVE' ? message.status = 'SUSPENDED' : message.status = 'ACTIVE'
    message.status === 'ACTIVE' ? dispatch(actions.updateMessageConfigStatus(message)) : dispatch(actions.updateMessageConfigStatus(message))
  }

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  }


  let content = <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
          <th scope="col" className="px-6 py-3">
              Message Type
          </th>
          <th scope="col" className="px-4 py-3">
              Message Tag
          </th>
          <th scope="col" className="px-4 py-3">
              Message Text
          </th>
          <th scope="col" className="px-6 py-3">
             Status
          </th>
          <th scope="col" className="px-6 py-3">
              Action
          </th>
          <th scope="col" className="px-6 py-3">
            <span className="sr-only">Edit</span>
          </th>
      </tr>
  </thead>
  <tbody>
  {(message_config && message_config.data.data.length > 0) ? message_config.data.data.map(message => (
            <tr key={message.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="px-6 py-4">
              {message.messageType}
            </td>
            <td className="px-4 py-4">
                {message.tag}
            </td>
            <td className="px-4 py-4">
                {message.text}
            </td>
            <td className="px-6 py-4">
                {message.status}
            </td>
            <td className="px-6 py-4 text-right">
                {message.status === 'ACTIVE' ? 
                <button onClick={() => handleChangeStatus(message)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">De-activate</button> : 
                <button onClick={() => handleChangeStatus(message)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Activate</button> }
            </td>
            <td className="px-6 py-4 text-right">
                <button onClick={() => handleEdit(message)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</button>
            </td>
        </tr>
        ))  : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">No data found</td>
            </tr>}
    </tbody>
  </table>
  return (
    <div className="overflow-x-auto border sm:rounded-lg">
        {content}
        {message_config && message_config.data.data.length > 0 && <div className="p-4 flex justify-end">
            <Pagination
                activePage={page}
                itemsCountPerPage={itemsCountPerPage}
                totalItemsCount={10}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
            />
        </div>}
    </div>
  )
}

export default ViewMessage