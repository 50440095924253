import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import loader from '../../assets/loaders/382.gif';
import * as Yup from "yup";
import * as actions from '../../store/actions';
import FormikController from '../Formik/FormikController';
import AmountInput from '../Formik/AmountInput';
import Select from 'react-select';

function Teller() {
    const dispatch = useDispatch();
    const reasonsState = useSelector(reasonsState => reasonsState.getRemitReasonsReducer);
    const currencyState = useSelector(currencyState => currencyState.getCurrencyReducer);
    const sourceState = useSelector(sourceState => sourceState.getRemitSourceReducer);
    const searchState = useSelector(searchState => searchState.searchRemitterReducer);
    const authState = useSelector(searchState => searchState.loginReducer);
    const remittanceState = useSelector(remittanceState => remittanceState.makeRemittanceReducer);
    const beneficiaryState = useSelector(beneficiaryState => beneficiaryState.fetchRemitterBeneficiaryReducer);
    const feeConfigState = useSelector(feeConfigState => feeConfigState.activeFeeReducer);
    const feeState = useSelector(feeState => feeState.getTransactionFeesReducer);
    const remitterState = useSelector(remitterState => remitterState.addRemitterReducer);

    const corridor = 'LOCAL';

    const [recipient, setRecipient] = React.useState(null);

    const [isoCode, setIsoCode] = React.useState('');
    const [remittance, setRemittance] = React.useState({
        fees: 0.00,
        sendingAmount: 0.00,
        receivingAmount: '',
        currency: '',
        beneficiaryAddress: '',
        firstname: '',
        middlename: '',
        lastname: '',
        nationalId: '',
        mobile: '',
        email: '',
        address: '',
        city: '',
        province: '',
        idDocument: "",
        gender: '',
        dob: '',
        beneficiaryFirstname: "",
        beneficiaryMiddlename: "",
        beneficiaryLastname: "",
        beneficiaryNationalId: "",
        beneficiaryMobile: "",
        source: "",
        purpose: "",
        active: false
    })

    React.useEffect(() => {
        dispatch(actions.getBranch({page: 1, perPage: 500}));
        dispatch(actions.getRemitReasons({page: 1, perPage: 500}));
        dispatch(actions.getCurrency({page: 1, perPage: 500}));
        dispatch(actions.getRemitSource({page: 1, perPage: 500}));
        dispatch(actions.getActiveFee());
        dispatch(actions.getTransactionFees({page: 1, perPage: 500}));
    }, [dispatch]);

    React.useEffect(() => {
        if(searchState.remitter){
            dispatch(actions.fetchRemitterBeneficiary({
                page: 1, perPage: 1000, clientId: searchState.remitter.id
            }))
        }
    }, [dispatch, searchState.remitter]);

    React.useEffect(() => {
        if(remitterState.remitter_error){
            window.scrollTo(0, 0);
        }
    }, [remitterState.remitter_error]);

    React.useEffect(() => {
        let timer;
        if(remitterState.remitter || remittanceState.remittance){
            window.scrollTo(0, 0);
            clearSearch();
            setRemittance({
                ...remittance,
                fees: 0.00,
                sendingAmount: 0.00,
                receivingAmount: '',
                currency: '',
                beneficiaryAddress: '',
                firstname: "",
                middlename: "",
                lastname: "",
                nationalId: "",
                mobile: "",
                email: "",
                address: "",
                city: "",
                province: "",
                idDocument: "",
                gender: "",
                dob: "",
                beneficiaryFirstname: "",
                beneficiaryMiddlename: "",
                beneficiaryLastname: "",
                beneficiaryNationalId: "",
                beneficiaryMobile: "",
                source: "",
                purpose: "",
                active: false
            }) 

            timer = setTimeout(() => {
                dispatch(actions.clearAddRemitter())
              }, 8000);
        }

        return () => clearTimeout(timer);

    }, [remitterState.remitter, dispatch, remittanceState.remittance]);

    let reasons = reasonsState.remit_reason ? reasonsState.remit_reason.data.map(cxt => {
        let obj = {
            key: cxt.reason,
            value: cxt.id
        }

        return obj;
    }) : [];

    let source = sourceState.remit_src ? sourceState.remit_src.data.map(cxt => {
        let obj = {
            key: cxt.source,
            value: cxt.id
        }

        return obj;
    }) : [];

    const FILE_SIZE = 1024 * 1024 * 10;
    const SUPPORTED_FORMATS = [
        "application/pdf",
        "image/jpg",
        "image/jpeg",
        "image/png",
    ];

    const INITIAL_VALUE = {
        nationalId: ''
    }

    const VALIDATION_SCHEMA = Yup.object().shape({
        nationalId: Yup.string().required('Required'),
    });

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('Required'),
        middlename: Yup.string(),
        lastname: Yup.string().required('Required'),
        nationalId: Yup.string().required('Required'),
        mobile: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email'),
        address: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        province: Yup.string().required('Required'),
        idDocument: Yup.mixed().test(

            "format",

            "Only the following formats are accepted: .pdf, .jpeg, .jpg, .png",

            (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))

        ).test(

            "fileSize",

            "File too large",

            (value) =>

                value === null || ((value) => value && value.size <= FILE_SIZE)

        ).required("Required"),
        gender: Yup.string().required('Required'),
        dob: Yup.string().required('Required'),
        beneficiaryFirstname: Yup.string().required('Required'),
        beneficiaryMiddlename: Yup.string(),
        beneficiaryLastname: Yup.string().required('Required'),
        beneficiaryNationalId: Yup.string().required('Required'),
        beneficiaryMobile: Yup.string().required('Required'),
        beneficiaryAddress: Yup.string().required('Required'),
        amount: Yup.number().required('Required'),
        currency: Yup.string().required('Required'),
        source: Yup.string().required('Required'),
        purpose: Yup.string().required('Required'),

    });

    const validation = Yup.object().shape({
        partnerId: Yup.string().nullable(true),
        client: Yup.string().required('Required'),
        beneficiaryFirstname: Yup.string().required('Required'),
        beneficiaryMiddlename: Yup.string(),
        beneficiaryLastname: Yup.string().required('Required'),
        beneficiaryNationalId: Yup.string().required('Required'),
        beneficiaryMobile: Yup.string().required('Required'),
        beneficiaryAddress: Yup.string().required('Required'),
        amount: Yup.number().required('Required'),
        currency: Yup.string().required('Required'),
        source: Yup.string().required('Required'),
        purpose: Yup.string().required('Required'),

    });

    const initialState = {
        partnerId: authState.auth.partner ? authState.auth.partner.id : null,
        client: searchState.remitter ? searchState.remitter.id : null,
        firstname: searchState.remitter ? searchState.remitter.firstname : remittance.firstname,
        middlename: searchState.remitter ? searchState.remitter.middlename : remittance.middlename,
        lastname: searchState.remitter ? searchState.remitter.lastname : remittance.lastname,
        nationalId: searchState.remitter ? searchState.remitter.nationalId : remittance.nationalId,
        mobile: searchState.remitter ? searchState.remitter.mobile : remittance.mobile,
        email: searchState.remitter ? searchState.remitter.email : remittance.email,
        address: searchState.remitter ? searchState.remitter.address : remittance.address,
        city: searchState.remitter ? searchState.remitter.city : remittance.city,
        province: searchState.remitter ? searchState.remitter.province : remittance.province,
        gender: searchState.remitter ? searchState.remitter.gender : remittance.gender,
        idDocument: remittance.idDocument,
        dob: searchState.remitter ? searchState.remitter.dob : remittance.dob,
        beneficiaryFirstname: recipient ? recipient.beneficiaryFirstname : remittance.beneficiaryFirstname,
        beneficiaryMiddlename:  recipient ? recipient.beneficiaryMiddlename : remittance.beneficiaryMiddlename,
        beneficiaryLastname: recipient ? recipient.beneficiaryLastname : remittance.beneficiaryLastname,
        beneficiaryNationalId: recipient ? recipient.beneficiaryNationalId : remittance.beneficiaryNationalId,
        beneficiaryMobile: recipient ? recipient.beneficiaryMobile : remittance.beneficiaryMobile,
        beneficiaryAddress: recipient ? recipient.beneficiaryAddress : remittance.beneficiaryAddress,
        amount: remittance.receivingAmount,
        currency: remittance.currency,
        source: remittance.source,
        purpose: remittance.purpose
    }

    const gender = [
        {
            key: 'Male',
            value: 'Male'
        },
        {
            key: 'Female',
            value: 'Female'
        }
    ]

    const roundOffReceiveAmount = (locReceivedAmount) => {
        setRemittance({...remittance, receivingAmount: locReceivedAmount, active: true});       
    }

    const handleSelectRecipient = (id) => {
        const result = beneficiaryState.recipient.data.find(d => d.id === id);
        setRecipient(result);
    }

    const FEE_TYPE = (feeConfigState.activeFee) ? ((feeConfigState.activeFee.data.flat === true) ? 'FLAT' : 'PERCENTAGE')  : null;
    
    let result = feeState.get_transaction_fees && feeState.get_transaction_fees.data.filter(el => el.feetype === FEE_TYPE);

    const key = 'id';

    let arrayUniqueByKey = (result && result.length > 0) ? [...new Map(result.map(item =>
        [item.currency[key], item.currency])).values()] : null;
    
    arrayUniqueByKey = arrayUniqueByKey ? arrayUniqueByKey.filter(p => p.status !== 'SUSPENDED') : null;

    let currency = arrayUniqueByKey && arrayUniqueByKey.length > 0 ? arrayUniqueByKey.map(cxt => {
        let obj = {
            key: cxt.currencyName,
            value: cxt.id
        }

        return obj;
    }) : [];

    React.useEffect(() => {
        if(remittance.active){
            if(remittance.currency.length === 0){
                return;
            }
            let fee = 0.00;
            let locReceivedAmount = Math.round(Number(remittance.receivingAmount) / 10) * 10;

            if(FEE_TYPE === 'FLAT'){
                result && result.length > 0 ? result.forEach(el => {
                    if(el.remittanceCorridor === corridor && el.currency.id === remittance.currency && el.currency.status !== 'SUSPENDED'  && (Number(locReceivedAmount) >= Number(el.startAmount) && Number(locReceivedAmount) <= Number(el.endAmount))){
                        fee = el.fee;
                    }
                }) : fee = 0.00;
            }

            if(FEE_TYPE === 'PERCENTAGE'){
                if(result && result.length > 0 && result[0].currency.status !== 'SUSPENDED' && result[0].currency.id === remittance.currency){
                    fee = result[0].fee;
                }else{
                    fee = 0.00
                }
            }
            

            setRemittance({
                ...remittance,
                receivingAmount: locReceivedAmount,
                fees: fee,
                active: false,
                sendingAmount: Number(locReceivedAmount) + Number(fee)
            })
        }
    }, [remittance.active])

    const handleChange = (e) => {
        setRemittance({
            ...remittance,
            [e.target.name]: e.target.value
        })
    }

    const handleCurrencyChange = (id) => {
        const currency = currencyState.get_currency.data.find(el => el.id === id);
        setRemittance({
            ...remittance,
            fees: 0,
            sendingAmount: 0,
            receivingAmount: '',
            currency: currency.id
        });
        setIsoCode(currency.currencySymbol)
    }

    const handleSubmit = (data) => {
        data.charges = remittance.fees;
        dispatch(actions.addRemitter(data));
    }

    const handleSearch = (data) => {
        dispatch(actions.searchRemitter(data.nationalId));
    }

    const clearSearch = () => {
        dispatch(actions.clearSearchRemitter());
        dispatch(actions.clearFetchRemitterBeneficiary());
        setRecipient(null);
        beneficiaries = [];
    }

    let beneficiaries = beneficiaryState.recipient && beneficiaryState.recipient.data.length > 0 ? beneficiaryState.recipient.data.map(el => {
        let recipient = {
            label: el.beneficiaryFirstname + ' ' + el.beneficiaryMiddlename + ' ' + el.beneficiaryLastname,
            value: el.id
        }

        return recipient;
    }) : [];

    const handleRemit = (data) => {
        data.charges = remittance.fees;
        data.amount = data.amount.toString();
        data.currency = remittance.currency;
        dispatch(actions.makeRemittances(data));
    }

    return (
        <div>
            <div className="mb-4">
                <Formik
                    initialValues={INITIAL_VALUE}
                    validationSchema={VALIDATION_SCHEMA}
                    enableReinitialize
                    onSubmit={(values, {resetForm}) => {
                        handleSearch(values)
                        resetForm()
                    }}
                >
                    {({errors}) => {
                        return (
                            <Form>
                                <div className="flex mt-4 justify-end">
                                    <div>
                                        <Field name="nationalId" className={`border-b p-2 focus:outline-none ${errors.nationalId ? "border-red-600" : "border-gray-400"}`} placeholder="Enter ID Number" />
                                    </div>
                                    <button disabled={searchState.remitter_loading ? true : false} type="submit" className={`border px-3 ml-3 rounded-md text-sm font-medium ${errors.nationalId ? "text-red-600 border-red-600" : "text-gray-700 border-gray-700"}`}>{searchState.remitter_loading ? "Wait..." : "Search"}</button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            {searchState.remitter &&
            <div className="text-right mb-2">
                <button className="text-blue-700 text-sm underline" onClick={clearSearch}>Clear Search</button>
            </div>}
            <div>
                <Formik
                    initialValues={ initialState }
                    validationSchema={searchState.remitter ? validation : validationSchema}
                    enableReinitialize
                    onSubmit={(values, {resetForm}) => {
                        searchState.remitter ? handleRemit(values) : handleSubmit(values);
                        resetForm()
                    }}
                >
                    {({ setFieldValue, ...rest }) => {
                        return (
                            <Form>
                                {remitterState.remitter_error &&
                                <div id="alert-2" className="flex mt-4 p-4 mb-2 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                                    <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                                    <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                                        {remitterState.remitter_error}
                                    </div>
                                    <button onClick={() => dispatch(actions.clearAddRemitterError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                                        <span className="sr-only">Close</span>
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>}
                                {searchState.remitter_error &&
                                <div id="alert-2" className="flex mt-4 p-4 mb-2 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                                    <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                                    <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                                        {searchState.remitter_error}
                                    </div>
                                    <button onClick={() => dispatch(actions.clearSearchRemitterError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                                        <span className="sr-only">Close</span>
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>}
                                {remittanceState.remittance_error &&
                                    <div id="alert-2" className="flex mt-4 p-4 mb-2 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                                        <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                                        <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                                            {remittanceState.remittance_error}
                                        </div>
                                        <button onClick={() => dispatch(actions.clearMakeRemittanceError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                                            <span className="sr-only">Close</span>
                                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </button>
                                    </div>
                                }
                                {remitterState.remitter &&
                                <div id="alert-2" className="flex mt-4 p-4 mb-2 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                                    <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
                                        Transaction was processed successfully
                                    </div>
                                    <button onClick={() => dispatch(actions.clearAddRemitter())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300" data-dismiss-target="#alert-2" aria-label="Close">
                                        <span className="sr-only">Close</span>
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>
                                }
                                {
                                    remittanceState.remittance && <div id="alert-2" className="flex mt-4 p-4 mb-2 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                                    <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
                                        Transaction was processed successfully
                                    </div>
                                    <button onClick={() => dispatch(actions.clearMakeRemittance())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300" data-dismiss-target="#alert-2" aria-label="Close">
                                        <span className="sr-only">Close</span>
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>
                                }
                                <div className="grid grid-cols-2 gap-4 mb-8">
                                    <div className="border rounded-md mb-6 p-3">
                                        <h1 className="font-medium text-md my-4 px-2">Remitter Details</h1>
                                        <div className="grid grid-cols-2 gap-2 pb-8">
                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    label='First Name'
                                                    disabled={searchState.remitter}
                                                    onChange={(e) => setFieldValue('firstname', handleChange(e))}
                                                    name='firstname'
                                                />
                                            </div>

                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    placeholder='Optional'
                                                    disabled={searchState.remitter}
                                                    label='Middle Name'
                                                    onChange={(e) => setFieldValue('middlename', handleChange(e))}
                                                    name='middlename'
                                                />
                                            </div>
                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled={searchState.remitter}
                                                    label='Last Name'
                                                    onChange={(e) => setFieldValue('lastname', handleChange(e))}
                                                    name='lastname'
                                                />
                                            </div>

                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    placeholder='Optional'
                                                    disabled={searchState.remitter}
                                                    onChange={(e) => setFieldValue('email', handleChange(e))}
                                                    label='Email'
                                                    name='email'
                                                />
                                            </div>
                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    placeholder=" e.g. +263773221212"
                                                    disabled={searchState.remitter}
                                                    onChange={(e) => setFieldValue('mobile', handleChange(e))}
                                                    label='Mobile Number'
                                                    name='mobile'
                                                />
                                            </div>

                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled={searchState.remitter}
                                                    label='ID Number'
                                                    onChange={(e) => setFieldValue('nationalId', handleChange(e))}
                                                    name='nationalId'
                                                />
                                            </div>
                                            <div className=" px-2">
                                                <FormikController
                                                    control='select'
                                                    type='text'
                                                    label='Gender'
                                                    disabled={searchState.remitter}
                                                    onChange={(e) => setFieldValue('gender', handleChange(e))}
                                                    options={gender}
                                                    name='gender'
                                                />
                                            </div>

                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='date'
                                                    label='Date of Birth'
                                                    disabled={searchState.remitter}
                                                    onChange={(e) => setFieldValue('dob', handleChange(e))}
                                                    name='dob'
                                                />
                                            </div>
                                            <div className="col-span-2 px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    label='Street Address'
                                                    disabled={searchState.remitter}
                                                    onChange={(e) => setFieldValue('address', handleChange(e))}
                                                    name='address'
                                                />
                                            </div>

                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    label='City'
                                                    disabled={searchState.remitter}
                                                    onChange={(e) => setFieldValue('address', handleChange(e))}
                                                    name='city'
                                                />
                                            </div>

                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    label='State / Province'
                                                    disabled={searchState.remitter}
                                                    onChange={(e) => setFieldValue('province', handleChange(e))}
                                                    name='province'
                                                    className="py-2"
                                                />
                                            </div>
                                            
                                            <div className={`col-span-2 px-2 ${searchState.remitter ? "hidden" : "block"}`} >
                                                <FormikController
                                                    control='input'
                                                    type='file'
                                                    label='ID Document'
                                                    name='idDocument'
                                                    value={null}
                                                    onChange={(event) =>
                                                        setFieldValue("idDocument", setRemittance({...remittance, idDocument:event.target.files[0]}))
                                                    }

                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="border rounded-md mb-6 p-3">
                                        <h1 className="font-medium text-md my-4 ml-2">Add Beneficiary</h1>
                                        {beneficiaries.length > 0 &&
                                        <div className="mt-4">
                                            <div className="py-3 px-2">
                                                <Select options={beneficiaries} onChange={opt => handleSelectRecipient(opt.value)} />
                                            </div>
                                        </div>}
                                        <div className="grid grid-cols-2 gap-2 pb-8">
                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    label='First Name'
                                                    onChange={(e) => setFieldValue('beneficiaryFirstname', handleChange(e))}
                                                    name='beneficiaryFirstname'
                                                />
                                            </div>

                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    placeholder="Optional"
                                                    label='Middle Name'
                                                    onChange={(e) => setFieldValue('beneficiaryMiddlename', handleChange(e))}
                                                    name='beneficiaryMiddlename'
                                                />
                                            </div>
                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    label='Last Name'
                                                    onChange={(e) => setFieldValue('beneficiaryLastname', handleChange(e))}
                                                    name='beneficiaryLastname'
                                                />
                                            </div>
                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    label='ID Number'
                                                    onChange={(e) => setFieldValue('beneficiaryNationalId', handleChange(e))}
                                                    name='beneficiaryNationalId'
                                                />
                                            </div>
                                            <div className="col-span-2 px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    placeholder=" e.g. +263773221212"
                                                    label='Mobile Number'
                                                    onChange={(e) => setFieldValue('beneficiaryMobile', handleChange(e))}
                                                    name='beneficiaryMobile'
                                                />
                                            </div>
                                            <div className="col-span-2 px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    label='Home Address'
                                                    onChange={(e) => setFieldValue('beneficiaryAddress', handleChange(e))}
                                                    name='beneficiaryAddress'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border rounded-md p-3 col-span-2"> 
                                        <h1 className="font-medium text-md mt-4 ml-3">Transaction Quotation</h1>
                                        {isoCode &&
                                        <div className="p-4 mx-2 mt-4 mb-3 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                                            We can only payout in multiples of 10 {isoCode} for cash pickup due to unavailability of smaller notes. The payout amount will be automatically rounded to the nearest 10 {isoCode}.
                                        </div>}
                                        {(currency && currency.length === 0) &&
                                        <div className="p-4 my-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                                            You do not have any active currencies. Contact the System Administrator for assistance.
                                        </div>}
                                        <div className="grid grid-cols-2 gap-2 pb-8">
                                            <div className="pt-2 px-2">
                                                <FormikController
                                                    control='select'
                                                    type='text'
                                                    label='Currency'
                                                    onChange={(e) => setFieldValue('currency', handleCurrencyChange(e.target.value))}
                                                    options={currency}
                                                    name='currency'
                                                />
                                            </div>
                                            <div className="pt-2 px-2">
                                                <AmountInput
                                                    onChange={(e) => setFieldValue('amount', roundOffReceiveAmount(e.target.value))}
                                                    type='text'
                                                    label='Receiving Amount'
                                                    name='amount'
                                                />
                                            </div>
                                            <div className="pt-2 px-2">
                                                <FormikController
                                                    control='select'
                                                    type='text'
                                                    label='Source'
                                                    onChange={(e) => setFieldValue('source', handleChange(e))}
                                                    options={source}
                                                    name='source'
                                                />
                                            </div>
                                            <div className="pt-2 px-2">
                                                <FormikController
                                                    control='select'
                                                    onChange={(e) => setFieldValue('purpose', handleChange(e))}
                                                    type='text'
                                                    options={reasons}
                                                    label='Purpose'
                                                    name='purpose'
                                                />
                                            </div>
                                        </div>
                                        <div className='text-right mb-2 mr-2'>
                                            <h2 className='text-lg'>Transaction Fee</h2>
                                            <h1 className="text-2xl">{remittance.fees} {isoCode}</h1>
                                        </div>
                                        <div className="text-right mb-2 mr-2">
                                            <h2 className="text-lg">Sending Amount</h2>
                                            <h1 className="text-2xl">{remittance.sendingAmount} {isoCode}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-right mb-16">
                                    {remitterState.remitter_loading || remittanceState.remittance_loading ? <button className="bg-gray-500 rounded px-8 py-1"><img className="h-8" src={loader} alt="loader" /></button> :
                                    <button type="submit" className="bg-green-600 text-white rounded px-3 py-2">Submit</button>}
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default Teller