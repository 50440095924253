import {
    CLEAR_ACTIVATE_CURRENCY_ERROR,
    CLEAR_ACTIVATE_CURRENCY,
    ACTIVATE_CURRENCY_LOADING,
    ACTIVATE_CURRENCY_ERROR,
    ACTIVATE_CURRENCY
} 
from '../actions/types';

const initialState = {
    activateCurrency: null,
    activateCurrencyLoading: false,
    activateCurrencyError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_ACTIVATE_CURRENCY_ERROR:
            return {
                ...state,
                activateCurrencyLoading: false,
                activateCurrencyError: action.payload
            }
        case CLEAR_ACTIVATE_CURRENCY:
            return {
                ...state,
                activateCurrency: null,
                activateCurrencyLoading: false,
                activateCurrencyError: null
            }
        case ACTIVATE_CURRENCY_LOADING:
            return {
                ...state,
                activateCurrencyLoading: true,
                activateCurrencyError: null
            }
        case ACTIVATE_CURRENCY_ERROR:
            return {
                ...state,
                activateCurrencyLoading: false,
                activateCurrencyError: action.payload
            }
        case ACTIVATE_CURRENCY:
            return {
                ...state,
                activateCurrency: action.payload,
                activateCurrencyLoading: false,
                activateCurrencyError: null
            }
        default: return state
    }
}