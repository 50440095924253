import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions';
import Pagination from "react-js-pagination";
import PageLoader from '../utils/PageLoader';
function BranchTables(props) {
    const dispatch = useDispatch();
    const { new_branch } = props.addBranchesReducer;
    const { branch, branch_loading } = props.getBranchesReducer;
    const { getBranch, setStore, clearUpdateBranch } = props;
    const { activateBranches } = useSelector(activateBranchState => activateBranchState.activateBranchesReducer);
    const { deactivateBranches } = useSelector(deactivateBranchState => deactivateBranchState.deactiveBranchesReducer);
    const { updateBranch } = props.updateBranchReducer;
    const itemsCountPerPage = 20;

    const [page, setPage] = React.useState(1);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    React.useEffect(() => {
        getBranch({
            page,
            perPage: itemsCountPerPage
        });
        clearUpdateBranch();
    }, [new_branch, page, updateBranch, activateBranches,deactivateBranches]);

    const handleEdit = (branch) => {
        setStore(branch);
    }

    const handleChangeStatus = (branch) => {
        branch.status === 'ACTIVE' ? dispatch(actions.deactivateBranches(branch)): dispatch(actions.activateBranches(branch));
  
    }

    let content = (branch_loading) ? <PageLoader /> : (branch) && <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
            <th scope="col" className="px-6 py-3">
                Branch Name
            </th>
            <th scope="col" className="px-6 py-3">
                Address
            </th>
            <th scope="col" className="px-6 py-3">
                City
            </th>
            <th scope="col" className="px-6 py-3">
                Contact Person
            </th>
            <th scope="col" className="px-6 py-3">
                Email
            </th>
            <th scope="col" className="px-6 py-3">
                Phone Number
            </th>
            <th scope="col" className="px-6 py-3">
                Status
            </th>
            <th scope="col" className="px-10 py-3">
                Action
            </th>
            <th scope="col" className="px-6 py-3">
            <span className="sr-only">Edit</span>
            </th>
        </tr>
    </thead>
    <tbody>
    {(branch && branch.data.length > 0) ? branch.data.map(branch => (
    <tr key={branch.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">               
        <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
            {branch.branchName}
        </th>
        <td className="px-6 py-4">
            {branch.address}
        </td>
        <td className="px-6 py-4">
            {branch.city}
        </td>
        <td className="px-6 py-4">
            {branch.contactPerson}
        </td>
        <td className="px-6 py-4">
            {branch.contactEmail}
        </td>
        <td className="px-6 py-4">
            {branch.contactPhone}
        </td>
        <td className="px-6 py-4">
            {branch.status.charAt(0).toUpperCase() + branch.status.slice(1).toLowerCase()}
        </td>
        <td className="px-6 py-4 ">
                {branch.status === 'ACTIVE' ? 
                <button onClick={() => handleChangeStatus(branch)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">De-activate</button> : 
                <button onClick={() => handleChangeStatus(branch)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Activate</button> }
            </td>
        <td className="px-6 py-4 text-right">
            <a href="#" onClick={() => handleEdit(branch)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
        </td>
    
    </tr> 
    )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
    <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">No Data Found</td>
  </tr>}
    </tbody>
</table>

  return (
    <div className="overflow-x-auto border sm:rounded-lg">
        {content}
        {(branch && branch.data.length > 0 && !branch_loading) &&
        <div className="p-4 flex justify-end">
            <Pagination
                activePage={page}
                itemsCountPerPage={itemsCountPerPage}
                totalItemsCount={branch.totalItemsCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
            />
        </div>}
    </div>
  )
}

BranchTables.propTypes = {
    clearUpdateBranch: PropTypes.func.isRequired,
    getBranch: PropTypes.func.isRequired,
    addBranchesReducer: PropTypes.object.isRequired,
    getBranchesReducer: PropTypes.object.isRequired,
    updateBranchReducer: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
   addBranchesReducer: state.addBranchesReducer,
   getBranchesReducer: state.getBranchesReducer,
   updateBranchReducer: state.updateBranchReducer,
})

export default connect(mapStateToProps, actions)(BranchTables)