import {
    CLEAR_CHANGE_PASSWORD_ERROR,
    CLEAR_CHANGE_PASSWORD,
    CHANGE_PASSWORD_LOADING,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD
} from '../actions/types';

const initialState = {
    password: null,
    password_loading: false,
    password_error: null,
}

const changePasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD:
            return {
                ...state,
                password: action.payload,
                password_loading: false,
                password_error: null,
            }
        case CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                password_loading: false,
                password_error: action.payload,
            }
        case CHANGE_PASSWORD_LOADING:
            return {
                ...state,
                password_loading: true,
                password_error: null,
            }
        case CLEAR_CHANGE_PASSWORD:
            return {
                ...state,
                password: null,
                password_loading: false,
                password_error: null,
            }
        case CLEAR_CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                password_loading: false,
                password_error: action.payload,
            }
        default: return state
    }
}

export default changePasswordReducer;