import {
    CLEAR_UPDATE_MESSAGE_CONFIG_STATUS_ERROR,
    CLEAR_UPDATE_MESSAGE_CONFIG_STATUS,
    UPDATE_MESSAGE_CONFIG_STATUS_LOADING,
    UPDATE_MESSAGE_CONFIG_STATUS_ERROR,
    UPDATE_MESSAGE_CONFIG_STATUS} 
from '../actions/types';

const initialState = {
    update_message_config_status: null,
    update_message_config_statusLoading: false,
    update_message_config_statusError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_MESSAGE_CONFIG_STATUS_ERROR:
            return {
                ...state,
                update_message_config_statusLoading: false,
                update_message_config_statusError: action.payload
            }
        case CLEAR_UPDATE_MESSAGE_CONFIG_STATUS:
            return {
                ...state,
                update_message_config_status: null,
                update_message_config_statusLoading: false,
                update_message_config_statusError: null
            }
        case UPDATE_MESSAGE_CONFIG_STATUS_LOADING:
            return {
                ...state,
                update_message_config_statusLoading: true,
                update_message_config_statusError: null
            }
        case UPDATE_MESSAGE_CONFIG_STATUS_ERROR:
            return {
                ...state,
                update_message_config_statusLoading: false,
                update_message_config_statusError: action.payload
            }
        case UPDATE_MESSAGE_CONFIG_STATUS:
            return {
                ...state,
                update_message_config_status: action.payload,
                update_message_config_statusLoading: false,
                update_message_config_statusError: null
            }
        default: return state
    }
}