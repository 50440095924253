import {
    CLEAR_REPORTS_ERROR,
    CLEAR_REPORTS,
    REPORTS_LOADING,
    RUN_REPORTS_ERROR,
    RUN_REPORTS
} from '../actions/types';

const initialState = {
    reports: null,
    reportsLoading: false,
    reportsError: null
}

const reportsReducer = (state = initialState, action) => {
    switch(action.type){
        case RUN_REPORTS:
            return {
                ...state,
                reports: action.payload,
                reportsLoading: false,
                reportsError: null
            }
        case RUN_REPORTS_ERROR:
            return {
                ...state,
                reportsLoading: false,
                reportsError: action.payload
            }
        case REPORTS_LOADING:
            return {
                ...state,
                reportsLoading: true,
                reportsError: null
            }
        case CLEAR_REPORTS:
            return {
                ...state,
                reports: null,
                reportsLoading: false,
                reportsError: null
            }
        case CLEAR_REPORTS_ERROR:
            return {
                ...state,
                reportsLoading: false,
                reportsError: null
            }
        default: return state
    }
}

export default reportsReducer