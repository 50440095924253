import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import loader from '../../assets/loaders/382.gif';
import * as Yup from "yup";
import * as actions from '../../store/actions';
import FormikController from '../Formik/FormikController';

function CashOut() {
    const dispatch = useDispatch();
    const reversalState = useSelector(reversalState => reversalState.cashoutReducer);
    const processedState = useSelector(processedState => processedState.updateRemittanceReversalReducer)

    const out = reversalState.cashout ? reversalState.cashout.cashedOutAt : '';
    const up = reversalState.cashout ? reversalState.cashout.reversedAt : '';

    React.useEffect(() => {
        return () => {
            dispatch(actions.clearCashout());
        }
    }, [dispatch]);
    
    const INITIAL_VALUE = {
        orderNumber: ''
    }

    const VALIDATION_SCHEMA = Yup.object().shape({
        orderNumber: Yup.string().required('Required'),
    });

    const changeDate = (date) => {
        let d = new Date(date);
        return d.toString('YYYY-MM-dd');
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    const initialState = {
        firstname: reversalState.cashout ? reversalState.cashout.client.firstname : '',
        middlename: reversalState.cashout ? reversalState.cashout.client.middlename : '',
        lastname: reversalState.cashout ? reversalState.cashout.client.lastname : '',
        nationalId: reversalState.cashout ? reversalState.cashout.client.nationalId : '',
        mobile: reversalState.cashout ? reversalState.cashout.client.mobile : '',
        address: reversalState.cashout ? reversalState.cashout.client.address : '',
        status: reversalState.cashout ? reversalState.cashout.status : '',
        amount: reversalState.cashout ? reversalState.cashout.amount : '',
        charges: reversalState.cashout ? reversalState.cashout.charges : '',
        createdAt: reversalState.cashout ? formatDate(reversalState.cashout.createdAt) : '',
        reversedAt: reversalState.cashout ? changeDate(reversalState.cashout.reversedAt) : '',
        cashedOutAt: reversalState.cashout ? formatDate(reversalState.cashout.cashedOutAt) : '',
        purpose: reversalState.cashout ? reversalState.cashout.purpose.reason : '',
        source: reversalState.cashout ? reversalState.cashout.source.source : '',
        currency: reversalState.cashout ? reversalState.cashout.currency.currencySymbol : '',
        reversalReason: reversalState.cashout ? reversalState.cashout.reversalReason :'',
        reversalProof:'',

    }

    const FILE_SIZE = 1024 * 1024 * 10;
    const SUPPORTED_FORMATS = [
        "application/pdf",
        "image/jpg",
        "image/jpeg",
        "image/png",
    ];

    const validationSchema = Yup.object().shape({
        reversalProof: Yup.mixed().test(

            "format",

            "Only the following formats are accepted: .pdf, .jpeg, .jpg, .png",

            (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))

        ).test(

            "fileSize",

            "File too large",

            (value) =>

                value === null || ((value) => value && value.size <= FILE_SIZE)

        ).required("Required"),
    })

    const handleSearch = (data) => {
        dispatch(actions.cashOut(data));
    }

    React.useEffect(() => {
        if(processedState.updateRemittanceReversal){
            // dispatch(actions.clearUpdateRemittanceReversal());
            dispatch(actions.clearCashout());
        }
    }, [processedState.updateRemittanceReversal, reversalState,dispatch]);

    const handleSubmit = (values) => {
        let data = {
            id: reversalState.cashout.orderNumber,
            reversalReason: values.reversalReason,
            reversalProof: values.reversalProof
        }
        dispatch(actions.updateRemittanceReversal(data));
        
    }

    return (
        <div>
            <div className="mb-4">
                <Formik
                    initialValues={INITIAL_VALUE}
                    validationSchema={VALIDATION_SCHEMA}
                    enableReinitialize
                    onSubmit={(values, {resetForm}) => {
                        handleSearch(values)
                        resetForm()
                    }}
                >
                    {({errors}) => {
                        return (
                            <Form>
                                <div className="flex mt-4 justify-end">
                                    <div>
                                        <Field name="orderNumber" className={`border-b p-2 focus:outline-none ${errors.nationalId ? "border-red-600" : "border-gray-400"}`} placeholder="Enter Order Number" />
                                    </div>
                                    <button disabled={reversalState.cashoutLoading ? true : false} type="submit" className={`border px-3 ml-3 rounded-md text-sm font-medium ${errors.nationalId ? "text-red-600 border-red-600" : "text-gray-700 border-gray-700"}`}>{reversalState.cashoutLoading ? "Wait..." : "Search"}</button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            {reversalState.cashout &&
            <div className="text-right mb-2">
                <button className="text-blue-700 text-sm underline" onClick={() => dispatch(actions.clearCashout())}>Clear Search</button>
            </div>}
            <div>
                <Formik
                    initialValues={ initialState }
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={(values) => {
                        handleSubmit(values)
                    }}
                >
                    {({ setFieldValue, ...rest }) => {
                        return (
                            <Form>
                                {processedState.updateRemittanceReversal && processedState.updateRemittanceReversal &&
                                <div id="alert-2" className="flex mt-4 p-4 mb-2 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                                    <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
                                        Transaction was reversed successfully
                                    </div>
                                    <button onClick={() => dispatch(actions.clearUpdateRemittanceReversal())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300" data-dismiss-target="#alert-2" aria-label="Close">
                                        <span className="sr-only">Close</span>
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div> 
                                }

                          {reversalState.cashoutError &&
                                <div id="alert-2" className="flex mt-4 p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                                    <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                                    <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                                    {reversalState.cashoutError.response ? reversalState.cashoutError.response.data.message : null}
                                    </div>
                                    <button onClick={() => dispatch(actions.clearCashoutError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                                        <span className="sr-only">Close</span>
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>}
                                   {reversalState.cashout && reversalState.cashout.status === 'REVERSED' &&
                                <div id="alert-2" className="flex mt-4 p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                                    <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                                    <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                                        Transaction was reversed
                                    </div>
                                    <button onClick={() => dispatch(actions.clearCashout())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                                        <span className="sr-only">Close</span>
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>
                                }
                                {processedState.updateRemittanceReversalError &&
                                <div id="alert-2" className="flex mt-4 p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                                    <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                                    <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                                        {processedState.updateRemittanceReversalError}
                                    </div>
                                    <button onClick={() => dispatch(actions.clearUpdateRemittanceReversalError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                                        <span className="sr-only">Close</span>
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>}
                                <div className="grid grid-cols-2 gap-4 mb-1">
                                    <div className={`border rounded-md mb-6 p-3 ${(reversalState.cashout && reversalState.cashout.status === 'UNCOLLECTED') ? " bg-white" : (reversalState.cashout) && "border-red-600"}`}>
                                        <h1 className="font-medium text-md my-4 px-2">Transaction Details</h1>
                                        <div className="grid grid-cols-2 gap-2 pb-8">
                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled
                                                    label='Transaction Status'
                                                    name='status'
                                                />
                                            </div>

                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled
                                                    label='Currency'
                                                    name='currency'
                                                />
                                            </div>

                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled
                                                    label='Collection Amount'
                                                    name='amount'
                                                />
                                            </div>

                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled
                                                    label='Charges'
                                                    name='charges'
                                                />
                                            </div>

                                            <div className="col-span-2 px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled
                                                    label='Purpose'
                                                    name='purpose'
                                                />
                                            </div>

                                            <div className="col-span-2 px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled
                                                    label='Source of Funds'
                                                    name='source'
                                                />
                                            </div>
                                            
                                            <div className="col-span-2 px-2">
                                                <FormikController
                                                    control='input'
                                                    type='date'
                                                    disabled
                                                    label='Sent On'
                                                    name='createdAt'
                                                />
                                            </div>

                                          {up && !reversalState.cashout.cashedOutAt && reversalState.cashout.status !== 'UNCOLLECTED' &&<div className="col-span-2 px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled
                                                    label='Reversed On'
                                                    name='reversedAt'
                                                />
                                            </div>}

                                            {out &&  <div className="col-span-2 px-2">
                                                <FormikController
                                                    control='input'
                                                    type='date'
                                                    disabled
                                                    label='Collected On'
                                                    name='cashedOutAt'
                                                />
                                            </div>}

                                        </div>
                                    </div>
                                    <div className={`border rounded-md mb-6 p-3 ${(reversalState.cashout && reversalState.cashout.status === 'UNCOLLECTED') ? " bg-white" : (reversalState.cashout) && "border-red-600"}`}>
                                        <h1 className="font-medium text-md my-4 ml-2">Remitter Details</h1>
                                        <div className="grid grid-cols-2 gap-2 pb-8">
                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled
                                                    label='First Name'
                                                    name='firstname'
                                                />
                                            </div>

                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled
                                                    label='Middle Name'
                                                    name='middlename'
                                                />
                                            </div>
                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled
                                                    label='Last Name'
                                                    name='lastname'
                                                />
                                            </div>
                                            <div className=" px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled
                                                    label='ID Number'
                                                    name='nationalId'
                                                />
                                            </div>
                                            <div className="col-span-2 px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled
                                                    label='Mobile Number'
                                                    name='mobile'
                                                />
                                            </div>
                                            <div className="col-span-2 px-2">
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    disabled
                                                    label='Home Address'
                                                    name='address'
                                                />
                                            </div>
                                            <div className={`col-span-2 px-2`} >
                                            <FormikController
                                                    control='input'
                                                    type='file'
                                                    label='Reversal Proof'
                                                    name='reversalProof'
                                                    value={null}
                                                    onChange={(event) =>
                                                        setFieldValue("reversalProof", event.target.files[0])
                                                    }

                                                />
                                                  <div className="mt-2">
                                                <FormikController
                                                    control='textArea'
                                                    type='text'
                                                    label='Reversal Reason'
                                                    name='reversalReason'
                                                />
                                            </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                   

                                </div>
                 
                                {(reversalState.cashout && reversalState.cashout.status === 'UNCOLLECTED') &&
                                    <div className="text-right mb-16">
                                        {processedState.updateRemittanceReversalLoading ? <button className="bg-gray-500 rounded px-8 py-1"><img className="h-8" src={loader} alt="loader" /></button> :
                                        <button disabled={(reversalState.cashout && reversalState.cashout.status !== 'UNCOLLECTED')} type="submit" className="bg-green-600 text-white rounded px-3 py-2">Reverse Transaction</button>}
                                    </div>
                                }
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default CashOut