import {
    CLEAR_REMIT_SOURCES_ERROR,
    CLEAR_REMIT_SOURCES,
    REMIT_SOURCE_LOADING,
    REMIT_SOURCE_ERROR,
    REMIT_SOURCE
} from '../actions/types';

const initialState = {
    remit_source: null,
    remit_source_error: null,
    remit_source_loading: false
}

const remitSourceReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_REMIT_SOURCES_ERROR:
            return {
                ...state,
                remit_source_error: null,
                remit_source_loading: false
            }
        case CLEAR_REMIT_SOURCES:
            return {
                ...state,
                remit_source: null,
                remit_source_error: null,
                remit_source_loading: false
            }
        case REMIT_SOURCE_LOADING:
            return {
                ...state,
                remit_source_error: null,
                remit_source_loading: true
            }
        case REMIT_SOURCE_ERROR:
            return {
                ...state,
                remit_source_error: action.payload,
                remit_source_loading: false
            }
        case REMIT_SOURCE:
            return {
                ...state,
                remit_source: action.payload,
                remit_source_error: null,
                remit_source_loading: false
            }
        default: return state;
    }
}

export default remitSourceReducer;