import {CLEAR_ADD_RECONCILIATIONS, CLEAR_ADD_RECONCILIATIONS_ERROR, ADD_RECONCILIATIONS_LOADING, ADD_RECONCILIATIONS_ERROR, ADD_RECONCILIATIONS} from '../actions/types';

const initialState = {
    new_reconcilitiation: null,
    new_reconcilitiation_error: null,
    new_reconcilitiation_loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ADD_RECONCILIATIONS:
            return {
                ...state,
                new_reconcilitiation: null,
                new_reconcilitiation_error: null,
                new_reconcilitiation_loading: false
            }
        case CLEAR_ADD_RECONCILIATIONS_ERROR:
            return {
                ...state,
                new_reconcilitiation_error: null,
                new_reconcilitiation_loading: false
            }
        case ADD_RECONCILIATIONS_LOADING:
            return {
                ...state,
                new_reconcilitiation_error: null,
                new_reconcilitiation_loading: true
            }
        case ADD_RECONCILIATIONS_ERROR:
            return {
                ...state,
                new_reconcilitiation_error: action.payload,
                new_reconcilitiation_loading: false
            }
        case ADD_RECONCILIATIONS:
            return {
                ...state,
                new_reconcilitiation: action.payload,
                new_reconcilitiation_error: null,
                new_reconcilitiation_loading: false
            }
        default: return state
    }
}