import {
    CLEAR_UPDATE_REMITTANCE_REVERSAL_ERROR,
    CLEAR_UPDATE_REMITTANCE_REVERSAL,
    UPDATE_REMITTANCE_REVERSAL_LOADING,
    UPDATE_REMITTANCE_REVERSAL_ERROR,
    UPDATE_REMITTANCE_REVERSAL} 
from '../actions/types';

const initialState = {
    updateRemittanceReversal: null,
    updateRemittanceReversalLoading: false,
    updateRemittanceReversalError: null
}

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_REMITTANCE_REVERSAL_ERROR:
            return {
                ...state,
                updateRemittanceReversalLoading: false,
                updateRemittanceReversalError: action.payload
            }
        case CLEAR_UPDATE_REMITTANCE_REVERSAL:
            return {
                ...state,
                updateRemittanceReversal: null,
                updateRemittanceReversalLoading: false,
                updateRemittanceReversalError: null
            }
        case UPDATE_REMITTANCE_REVERSAL_LOADING:
            return {
                ...state,
                updateRemittanceReversalLoading: true,
                updateRemittanceReversalError: null
            }
        case UPDATE_REMITTANCE_REVERSAL_ERROR:
            return {
                ...state,
                updateRemittanceReversalLoading: false,
                updateRemittanceReversalError: action.payload
            }
        case UPDATE_REMITTANCE_REVERSAL:
            return {
                ...state,
                updateRemittanceReversal: action.payload,
                updateRemittanceReversalLoading: false,
                updateRemittanceReversalError: null
            }
        default: return state
    }
}