import React from 'react'
import AddTellerFloat from '../components/operations/AddTellerFloat';
import ViewFloat from '../components/operations/ViewFloat';

function Operations() {
    const [store, setStore] = React.useState(null);

    const handleEdit = (el) =>{
      setStore(el);
    
    }
    return (
        <div className="grid grid-cols-12 gap-4">
            <div className="col-span-9 p-8">
                <ViewFloat setStore={setStore} handleEdit={handleEdit}/>
            </div>
            <div className="col-span-3 border-l min-h-screen p-8">
                <AddTellerFloat store={store} setStore={setStore} />
            </div>
        </div>
    )
}

export default Operations