import React from 'react';
import * as actions from '../../store/actions';
import { useSelector, useDispatch } from "react-redux";
import FormikController from '../Formik/FormikController';
import * as Yup from "yup";
import { useHistory } from 'react-router-dom';
import loader from '../../assets/loaders/382.gif';
import { Formik, Form } from "formik";

function ChangePassword() {
    const dispatch = useDispatch();
    const history = useHistory();
    const changePasswordState = useSelector(changePasswordState => changePasswordState.changePasswordReducer);

    const initialValues = {
        currentPassword: '',
        newPassword: ''
    }

    React.useEffect(() => {
        if(changePasswordState.password){
            dispatch(actions.logout());
            history.push('/');
        }
    }, [changePasswordState.password]);

    const validationSchema = Yup.object({
        currentPassword: Yup.string().required('This field is required'),
        newPassword: Yup.string().required('This field is required').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
          ),
        confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    })

    const handleSubmit = (values) => {
        dispatch(actions.changePassword(values));
    }

    return (
        <div className="rounded-md overflow-y-auto bg-white shadow hover:shadow-md ease-in-out duration-700 max-h-fit">
            <div className="p-4 border-b border-gray-300">
                <h2 className="font-medium text-gray-800 mb-1">Change Password</h2>
            </div>
            <div className="p-4">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values, {resetForm}) => {
                    handleSubmit(values)
                    resetForm();
                }}
            >
                {() => (
                    <Form>
                        {changePasswordState.password_error &&
                        <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                            <svg className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                            <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                                {changePasswordState.password_error}
                            </div>
                            <button onClick={() => dispatch(actions.clearChangePasswordError())} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                                <span className="sr-only">Close</span>
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </button>
                        </div>}
                        <div className="mb-3 ">
                            <FormikController
                                control='input'
                                type='password'
                                label='Current Password'
                                name='currentPassword'
                            />
                        </div>
                        <div className="mb-3 ">
                            <FormikController
                                control='input'
                                type='password'
                                label='New Password'
                                name='newPassword'
                            />
                        </div>
                        <div className="mb-3 ">
                            <FormikController
                                control='input'
                                type='password'
                                label='Confirm Password'
                                name='confirmPassword'
                            />
                        </div>
                        <div className="mb-3 pt-2 text-right">
                            {changePasswordState.password_loading ? <button className="bg-gray-500 rounded px-8 py-1"><img className="h-8" src={loader} alt="loader" /></button> : 
                            <button className="bg-green-600 text-white rounded px-3 py-2">Submit</button>}
                        </div>
                    </Form>
                )
                }
            </Formik>
            </div>
        </div>
    )
}

export default ChangePassword