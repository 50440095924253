import {
    CLEAR_RUNNING_BALANCE_ERROR,
    CLEAR_RUNNING_BALANCE,
    RUNNING_BALANCE_LOADING,
    RUNNING_BALANCE_ERROR,
    RUNNING_BALANCE,
} from '../actions/types';

const initialState = {
    running_balance: null,
    running_balance_error: null,
    running_balance_loading: false
}

const runningBalanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case RUNNING_BALANCE:
            return {
                ...state,
                running_balance: action.payload,
                running_balance_error: null,
                running_balance_loading: false
            }
        case RUNNING_BALANCE_ERROR:
            return {
                ...state,
                running_balance_error: action.payload,
                running_balance_loading: false
            }
        case RUNNING_BALANCE_LOADING:
            return {
                ...state,
                running_balance_error: null,
                running_balance_loading: true
            }
        case CLEAR_RUNNING_BALANCE:
            return {
                ...state,
                running_balance: null,
                running_balance_error: null,
                running_balance_loading: false
            }
        case CLEAR_RUNNING_BALANCE_ERROR:
            return {
                ...state,
                running_balance_error: null,
                running_balance_loading: false
            }
        default: return state
    }
}

export default runningBalanceReducer