import React from 'react';
import ReactApexChart from "react-apexcharts";

function TransactionChart(props) {
    const [title, setTitle] = React.useState([]);
    const [value, setValue] = React.useState([]);

    React.useEffect(() => {
        if(props.reportsState.reports && props.reportsState.reports.data.transactions.length > 0){
            const action = [];
            const total = [];
            props.reportsState.reports.data.transactions.map(el => {
                action.push(el.action);
                total.push(Number(el.total));
            });
            setTitle(action);
            setValue(total);
        }
    }, [props.reportsState.reports]);

    let series = value;

    let options = {
        chart: {
          type: 'donut',
        },
        labels: title,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
    };

    return (
        <div className="app">
            <div className="row">
                <div className="bg-gray-100 border-b px-4 py-2 text-lg">
                    <h2>Transactions</h2>
                </div>
                <div className="mixed-chart px-4 py-2">
                    <ReactApexChart options={options} series={series} type="donut" />
                </div>
            </div>
        </div>
    )
}

export default TransactionChart